import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "../../styles/main.css";

export default function IntroTexts ({isMobile, width, genislik}) {
    const {t} = useTranslation();
    const introDurum = localStorage.getItem("introDurum");
    const [texts] = useState([t("hos_geldin"), t("uygulaman"), t("evin"), t("dunyan"), ""]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {
        if (isFinished === false) {
            const timer = setInterval(() => {
                setIsVisible((prevVisible) => !prevVisible);
            }, 1900);

            return () => {
                clearInterval(timer);
            };
        }
    }, [isFinished]);

    useEffect(() => {
        if (currentIndex === 4) {
            setIsFinished(true)
            localStorage.setItem('introDurum', "introIzlendi");
        } else {
            if (!isVisible) {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
            }
        }
    }, [isVisible, texts.length, isFinished, currentIndex]);

    return (
        <>
            {introDurum !== "introIzlendi" ? (
                <>
                    {currentIndex > 0 && currentIndex <= 3 ? (
                        <div style={{width: "100%", height: "50%"}} className="flex justify-center items-end">
                            <span style={{color: "#fff"}} className={`${isMobile || width <= genislik ? "introTextMobileStyle1" : "introTextPcStyle1"}`}>{t("senin")}</span>
                        </div>
                    ) : null}
                    <div style={{width: "100%", height: currentIndex > 0 ? "50%" : "100%"}} className={`flex justify-center ${currentIndex > 0 ? "items-start" : "items-center"}`}>
                        {isVisible && (
                            <span style={{color: "#fff"}} className={`${isMobile || width <= genislik ? "introTextMobileStyle2" : "introTextPcStyle2"} blinking-text`}>
                                {texts[currentIndex]}
                            </span>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {introDurum === "introIzlendi" && isFinished === false ? (
                        <>
                            {currentIndex > 0 && currentIndex <= 3 ? (
                                <div style={{width: "100%", height: "50%"}} className="flex justify-center items-end">
                                    <span style={{color: "#fff"}} className={`${isMobile || width <= genislik ? "introTextMobileStyle1" : "introTextPcStyle1"}`}>{t("senin")}</span>
                                </div>
                            ) : null}
                            <div style={{width: "100%", height: currentIndex > 0 ? "50%" : "100%"}} className={`flex justify-center ${currentIndex > 0 ? "items-center" : "items-center"}`}>
                                {isVisible && (
                                    <span  style={{color: "#fff"}} className={`${isMobile || width <= genislik ? "introTextMobileStyle2" : "introTextPcStyle2"} blinking-text`}>
                                        {texts[currentIndex]}
                                    </span>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            {isMobile || width <= genislik ? (
                                <span className="loginMobileBaslikStyle tiklanmaEngelle">Mozared</span>
                            ) : (
                                <span className="loginPcBaslikStyle tiklanmaEngelle">Mozared</span>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};
