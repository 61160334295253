import React,{useEffect, useState} from "react";
import "../../styles/main.css";
import {useTranslation} from "react-i18next";
import Sozlesme from "../Sozlesme";
import {isMobile} from "react-device-detect";

export default function SartlarModal({sartlarId, sartlarModalButton, categoryId, pageId}) {
    const {t} = useTranslation();
    const [width, setWidth] = useState(window.innerWidth);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const sartlarTabButton = (id, cId, pId) => {
        sartlarModalButton(id, cId, pId)
    }

    return (
        <div style={{width: "100%", height: "100%"}}>
            <div className="flex shadow-sm" style={{width: "100%", height: "7%", backgroundColor: "#fff"}}>
                <div onClick={() => sartlarTabButton("kullanim-kosullari-id", 1, 2)} style={{width: "33%", height: "100%", backgroundColor: sartlarId === "sartlar-id" ? "#00000005" : ""}}
                     className={`flex flex-column cursorPointer tiklanmaEngelle`}>
                        <div style={{width: "100%", height: "93%"}} className="flex justify-center items-center">
                            <span style={{fontSize: isMobile ? 8 :  width <= 450 ? 10 : width <= 730 ? 12 : 15}} className={`${sartlarId === "kullanim-kosullari-id" ? "kosullarTabBarTextStyle" : "kosullarTabBarTextStyle1"}`}>{t("kullanim_kosullari")}</span>
                        </div>
                        <div style={{width: "100%", height: "7%"}} className={`${sartlarId === "kullanim-kosullari-id" ? "bottomBorderStyle" : ""}`}>

                        </div>
                    </div>
                <div onClick={() => sartlarTabButton("kisisel-veri-aktarimi-id",1, 8)} style={{width: "33%", height: "100%", backgroundColor: sartlarId === "kisisel-veri-aktarimi-id" ? "#00000005" : ""}}
                     className={`flex flex-column cursorPointer tiklanmaEngelle`}>
                    <div style={{width: "100%", height: "93%"}} className="flex justify-center items-center">
                        <span style={{fontSize: isMobile ? 8 :  width <= 450 ? 10 : width <= 730 ? 12 : 15}} className={`${sartlarId === "kisisel-veri-aktarimi-id" ? "kosullarTabBarTextStyle" : "kosullarTabBarTextStyle1"}`}>{t("kisisel_verilerin_yurt_disina_aktarilmasi")}</span>
                    </div>

                    <div style={{width: "100%", height: "7%"}} className={`${sartlarId === "kisisel-veri-aktarimi-id" ? "bottomBorderStyle" : ""}`}>
                    </div>
                </div>
                <div onClick={() => sartlarTabButton("gizlilik-politikasi-id",1, 1)} style={{width: "33%", height: "100%", backgroundColor: sartlarId === "gizlilik-politikasi-id" ? "#00000005" : ""}}
                     className={`flex flex-column cursorPointer tiklanmaEngelle`}>
                        <div style={{width: "100%", height: "93%"}} className="flex justify-center items-center">
                            <span style={{fontSize: isMobile ? 8 :  width <= 450 ? 10 : width <= 730 ? 12 : 15}} className={`${sartlarId === "gizlilik-politikasi-id" ? "kosullarTabBarTextStyle" : "kosullarTabBarTextStyle1"}`}>{t("gizlilik_politikasi")}</span>
                        </div>

                        <div style={{width: "100%", height: "7%"}} className={`${sartlarId === "gizlilik-politikasi-id" ? "bottomBorderStyle" : ""}`}>
                        </div>
                    </div>
                <div onClick={() => sartlarTabButton("cerez-politikasi-id",1, 3)} style={{width: "33%", height: "100%", backgroundColor: sartlarId === "cerez-politikasi-id" ? "#00000005" : ""}}
                     className={`flex flex-column cursorPointer tiklanmaEngelle`}>
                        <div style={{width: "100%", height: "93%"}} className="flex justify-center items-center">
                            <span style={{fontSize: isMobile ? 8 :  width <= 450 ? 10 : width <= 730 ? 12 : 15}} className={`${sartlarId === "cerez-politikasi-id" ? "kosullarTabBarTextStyle" : "kosullarTabBarTextStyle1"}`}>{t("cerez_politikasi")}</span>
                        </div>

                        <div style={{width: "100%", height: "7%"}} className={`${sartlarId === "cerez-politikasi-id" ? "bottomBorderStyle" : ""}`}>
                        </div>
                    </div>
            </div>

            {sartlarId === "kullanim-kosullari-id" ? (
                <div style={{width: "100%", height: "93%"}}>
                    <Sozlesme categoryId={categoryId} pageId={pageId} />
                </div>
            ) : sartlarId === "kisisel-veri-aktarimi-id" ? (
                <div style={{width: "100%", height: "93%"}}>
                    <Sozlesme categoryId={categoryId} pageId={pageId} />
                </div>
            ) : sartlarId === "gizlilik-politikasi-id" ? (
                <div style={{width: "100%", height: "93%"}}>
                    <Sozlesme categoryId={categoryId} pageId={pageId} />
                </div>
            ) : sartlarId === "cerez-politikasi-id" ? (
                <div style={{width: "100%", height: "93%"}}>
                    <Sozlesme categoryId={categoryId} pageId={pageId} />
                </div>
            ) : null}
        </div>
    )
}
