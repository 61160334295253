// reducers/begenenlerReducer.js

import {
    BEGENENLER_PAGE,
    BEGENENLER_REQUEST,
    BEGENENLER_SUCCESS,
    BEGENENLER_IS_FETCHING,
    BEGENENLER_HAS_MORE
} from '../../types';

const initialState = {
    begenenlerData: [],
    page: 0,
    isFetching: false,
    error: "",
    hasMore: true
};

export default function begenenlerReducer(state = initialState, action) {
    switch(action.type) {
        case BEGENENLER_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case BEGENENLER_SUCCESS:
            return {
                ...state,
                begenenlerData: action.payload,
                isFetching: false
            };
        case BEGENENLER_IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            };
        case BEGENENLER_HAS_MORE:
            return {
                ...state,
                hasMore: action.payload
            };
        case BEGENENLER_PAGE:
            return {
                ...state,
                page: state.page + 1
            };
        default:
            return state;
    }
}
