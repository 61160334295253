import React, { useEffect, useRef, useState } from "react";
import "../../styles/main.css";
import { isMobile } from "react-device-detect";
import {
  faAngleLeft,
  faAngleRight,
  faPencil,
  faPlus,
  faRotateRight,
  faSmoking,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import EvcilHayvanSigaraKullanimiSelect from "../../components/EvcilHayvanSigaraKullanimiSelect";
import CinsiyetVeCinselYonelimSec from "../../components/CinsiyetVeCinselYonelimSec";
import { useTranslation } from "react-i18next";
import axios from "../../utils/CustomAxios";
import { showToast } from "../../utils/Functions";
import { datas } from "../../utils/datas";
import AvatarEditor from "react-avatar-editor";
import LanguageSelector from "../../components/LanguageSelector";

const maxNumber = 3;

function ProfilDuzenle() {
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [hakkimda, setHakkimda] = useState("");
  const [ilgiAlanlari, setIlgiAlanlari] = useState("");
  const [isUnvani, setIsUnvani] = useState("");
  const [sirket, setSirket] = useState("");
  const [okul, setOkul] = useState("");
  const [evcilHayvan, setEvcilHayvan] = useState("");
  const [sigaraKullanimi, setSigaraKullanimi] = useState("");
  const [cinsiyet, setCinsiyet] = useState("");
  const [cinselYonelim, setCinselYonelim] = useState("");
  const [modals, setModals] = useState("modalKapali");
  const hakkimdaLenght = 500 - hakkimda.length;
  const [user, setUser] = useState(null);

  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const [images, setImages] = React.useState([]);
  const [rotate, setRotate] = useState(0);
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [scale, setScale] = useState(1);
  const editorRef = useRef(null);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    if (token && selectedLanguages.length > 0) {
      const user_data = JSON.parse(localStorage.getItem("customer_data"));
      const savedLanguages = user_data?.languages?.map(lang => lang.label).join(",");
      const currentLanguages = selectedLanguages.map(lang => lang.label).join(",");

      // Eğer diller değişmemişse, backend'e istek gönderme
      if (savedLanguages !== currentLanguages) {

        axios.put(`customer/profile/languages`, {
          languages: selectedLanguages,
        })
            .then((res) => {
              // showToast(res.data.messages);
              if (res.data.status === 1) {
                let updatedUserData = user;
                updatedUserData.languages = selectedLanguages;
                setUser(updatedUserData);
                localStorage.setItem("customer_data", JSON.stringify(updatedUserData));
                showToast(res.data.messages);
              }
            })
            .catch((err) => {
              if (err.response) {
                showToast(err.response.data.messages);
              } else {
                showToast([{type: "error", message: err.message, second: 5}]);
              }
            });
      }

    }
  }, [selectedLanguages, token, user]);

  useEffect(() => {
    if (token) {
      let user_data = JSON.parse(localStorage.getItem("customer_data"));
      setImages(user_data.media || "");
      setHakkimda(user_data.description || "");
      setIsUnvani(user_data.job_title || "");
      setSirket(user_data.company || "");
      setOkul(user_data.school || "");

      // setEvcilHayvan(user_data.||'');
      setSigaraKullanimi(user_data.smoking || "");
      setCinsiyet(user_data.gender || "");
      setCinselYonelim(user_data.sexual_orientation || "");
      setUser(user_data);
      setSelectedLanguages(
        user_data?.languages?.map((i) => {
          return { id: i.id + "", value: i.value + "", label: i.label };
        })
      );
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
    }
  }, [token]);


  const onImageChange = async (imageList, addUpdateIndex, callback) => {
    if (token) {
      setImages(imageList);
      //Yeni yüklenen resim varsa upload ediyoruz
      if (addUpdateIndex && addUpdateIndex.length > 0) {
        imageList = imageList.filter((v, k) => addUpdateIndex.includes(k));
        await imageUpload(imageList);
        if (callback) {
          callback();
        }
      }
    }
  };
  const onClickPicture = (index) => {
    if (token) {
      const image = images[index];
      if (image) {
        image.index = index;
        setSelectedPicture(image);
      } else {
        console.error("Invalid image URL:", image);
      }
    }
  };
  const onSave = async () => {
    if (token) {
      if (editorRef.current) {
        const canvasScaled = editorRef.current.getImageScaledToCanvas();
        canvasScaled.toBlob(
          (blob) => {
            // Blob objesini Base64 data URL'ye dönüştür
            const reader = new FileReader();
            reader.onloadend = function () {
              const base64data = reader.result;

              // Oluşturulan Base64 data URL'yi ve diğer bilgileri içeren bir obje oluştur
              const fileData = {
                data_url: base64data,
                file: {
                  lastModified: Date.now(), // or selectedPicture.lastModified,
                  name: selectedPicture.file_name,
                  size: blob.size, // or selectedPicture.size,
                  type: "image/jpeg",
                },
              };

              // Mevcut resmi sil
              removeImage(selectedPicture.id).then(() => {
                // Bu yeni oluşturulan dosyayı, imageList'inize ekler
                const updatedImageList = [...images, fileData];

                // Yeni resmi yükle
                onImageChange(updatedImageList, [images.length], () =>
                  setSelectedPicture(false)
                );
              });
            };
            reader.readAsDataURL(blob);
          },
          "image/jpeg",
          1
        );
      }
    }
  };
  // const handleBurcSelectButton = (e) => {
  //     setBurc(e)
  //     setModals("modalKapali")
  // }
  const handleEvcilHayvanSelectButton = (e) => {
    setEvcilHayvan(e);
    setModals("modalKapali");
  };
  const handleSigaraKullanimiSelectButton = (e) => {
    sigaraKullanimiGuncelle(e);
    setModals("modalKapali");
  };
  const cinsiyetSelectButton = (e) => {
    cinsiyetGuncelle(e);
    setModals("modalKapali");
  };
  const cinselYonelimSelectButton = (e) => {
    cinselYonelimGuncelle(e);
    setModals("modalKapali");
  };
  const ilgiAlanlariSelectButton = (e) => {
    setIlgiAlanlari(e);
    setModals("modalKapali");
  };


  const modalsButton = (id) => {
    setModals(id);
  };
  const modalClose = (id) => {
    setModals(id);
  };
  const TamamButonu = () => {
    navigate(`/AnaSayfa${location.search}`, {
      state: { id: "profilSayfalariGeriId", name: "ProfilSayfalariGeri" },
    });
  };
  const [timer, setTimer] = useState(null);
  const hakkimdaGuncelle = async (value) => {
    if (token) {
      setHakkimda(value);
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(
        setTimeout(() => {
          axios
            .put(`customer/profile/description`, {
              description: value,
            })
            .then((res) => {
              showToast(res.data.messages);
              if (res.data.status === 1) {
                let user_data = user;
                user_data.description = value;
                setUser(user_data);
                localStorage.setItem(
                  "customer_data",
                  JSON.stringify(user_data)
                );
              }
            })
            .catch((err) => {
              if (err.response) {
                showToast(err.response.data.messages);
              } else {
                showToast([{ type: "error", message: err.message, second: 5 }]);
              }
            });
        }, 3000)
      );
    }
  };
  const imageUpload = async (upload_images) => {
    if (token) {
      let temp = upload_images.map((item) => {
        return {
          data_url: item.data_url,
          file: {
            name: item.file.name,
            size: item.file.size,
            type: item.file.type,
          },
        };
      });
      return axios
        .post(`customer/image`, {
          images: temp,
        })
        .then((res) => {
          showToast(res.data.messages);
          if (res.data.status === 1) {
            let user_data = user;
            user_data.media = res.data.data.media;
            setUser(user_data);
            setImages(res.data.data.media);
            localStorage.setItem("customer_data", JSON.stringify(user_data));
          }
        })
        .catch((err) => {
          if (err.response) {
            showToast(err.response.data.messages);
          } else {
            showToast([{ type: "error", message: err.message, second: 5 }]);
          }
        });
    }
  };
  const removeImage = (id) => {
    if (token) {
      return axios
        .delete(`customer/image/${id}`)
        .then((res) => {
          showToast(res.data.messages);
          if (res.data.status === 1) {
            let user_data = user;
            user_data.media = res.data.data.media;
            setUser(user_data);
            localStorage.setItem("customer_data", JSON.stringify(user_data));
          }
        })
        .catch((err) => {
          if (err.response) {
            showToast(err.response.data.messages);
          } else {
            showToast([{ type: "error", message: err.message, second: 5 }]);
          }
        });
    }
  };

  const isUnvaniGuncelle = async (value) => {
    if (token) {
      setIsUnvani(value);
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(
        setTimeout(() => {
          axios
            .put(`customer/profile/job-title`, {
              job_title: value,
            })
            .then((res) => {
              showToast(res.data.messages);
              if (res.data.status === 1) {
                let user_data = user;
                user_data.job_title = value;
                setUser(user_data);
                localStorage.setItem(
                  "customer_data",
                  JSON.stringify(user_data)
                );
              }
            })
            .catch((err) => {
              if (err.response) {
                showToast(err.response.data.messages);
              } else {
                showToast([{ type: "error", message: err.message, second: 5 }]);
              }
            });
        }, 3000)
      );
    }
  };
  const sirketGuncelle = async (value) => {
    if (token) {
      setSirket(value);
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(
        setTimeout(() => {
          axios
            .put(`customer/profile/company`, {
              company: value,
            })
            .then((res) => {
              showToast(res.data.messages);
              if (res.data.status === 1) {
                let user_data = user;
                user_data.company = value;
                setUser(user_data);
                localStorage.setItem(
                  "customer_data",
                  JSON.stringify(user_data)
                );
              }
            })
            .catch((err) => {
              if (err.response) {
                showToast(err.response.data.messages);
              } else {
                showToast([{ type: "error", message: err.message, second: 5 }]);
              }
            });
        }, 3000)
      );
    }
  };
  const okulGuncelle = async (value) => {
    if (token) {
      setOkul(value);
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(
        setTimeout(() => {
          axios
            .put(`customer/profile/school`, {
              school: value,
            })
            .then((res) => {
              showToast(res.data.messages);
              if (res.data.status === 1) {
                let user_data = user;
                user_data.school = value;
                setUser(user_data);
                localStorage.setItem(
                  "customer_data",
                  JSON.stringify(user_data)
                );
              }
            })
            .catch((err) => {
              if (err.response) {
                showToast(err.response.data.messages);
              } else {
                showToast([{ type: "error", message: err.message, second: 5 }]);
              }
            });
        }, 3000)
      );
    }
  };
  const cinsiyetGuncelle = async (value) => {
    if (token) {
      setCinsiyet(value);
      axios
        .put(`customer/profile/gender`, {
          gender: value,
        })
        .then((res) => {
          showToast(res.data.messages);
          if (res.data.status === 1) {
            let user_data = user;
            user_data.gender = value;
            setUser(user_data);
            localStorage.setItem("customer_data", JSON.stringify(user_data));
          }
        })
        .catch((err) => {
          if (err.response) {
            showToast(err.response.data.messages);
          } else {
            showToast([{ type: "error", message: err.message, second: 5 }]);
          }
        });
    }
  };
  const cinselYonelimGuncelle = async (value) => {
    if (token) {
      setCinselYonelim(value);
      axios
        .put(`customer/profile/sexual-orientation`, {
          sexual_orientation: value,
        })
        .then((res) => {
          showToast(res.data.messages);
          if (res.data.status === 1) {
            let user_data = user;
            user_data.sexual_orientation = value;
            setUser(user_data);
            localStorage.setItem("customer_data", JSON.stringify(user_data));
          }
        })
        .catch((err) => {
          if (err.response) {
            showToast(err.response.data.messages);
          } else {
            showToast([{ type: "error", message: err.message, second: 5 }]);
          }
        });
    }
  };
  const sigaraKullanimiGuncelle = async (value) => {
    if (token) {
      setSigaraKullanimi(value);
      axios
        .put(`customer/profile/smoking`, {
          smoking: value,
        })
        .then((res) => {
          showToast(res.data.messages);
          if (res.data.status === 1) {
            let user_data = user;
            user_data.smoking = value;
            setUser(user_data);
            localStorage.setItem("customer_data", JSON.stringify(user_data));
          }
        })
        .catch((err) => {
          if (err.response) {
            showToast(err.response.data.messages);
          } else {
            showToast([{ type: "error", message: err.message, second: 5 }]);
          }
        });
    }
  };

  const ayarlarDivStyle = {
    width: isMobile ? width : width <= 475 ? width : 425,
    height: isMobile ? height : height <= 795 ? height : 788,
    borderWidth: isMobile ? 0 : 1,
    borderRadius: isMobile ? 0 : 8,
  };
  const ayarlarDivStyle1 = {
    width: "100%",
    height: "100%",
    position: "relative",
    zIndex: 50,
    borderRadius: isMobile ? 0 : 8,
  };
  const ayarlarDivStyle2 = {
    width: "100%",
    height: 51,
    backgroundColor: "#fff",
    borderTopLeftRadius: isMobile ? 0 : 8,
    borderTopRightRadius: isMobile ? 0 : 8,
  };
  const ayarlarDivStyle3 = {
    width: "100%",
    height: "calc(100% - 51px)", // aradaki boşlukları silinmesin yoksa çalışmıyor
    backgroundColor: "#fbfafa",
    borderRadius: isMobile ? 0 : 8,
  };
  const modalsStyle = {
    width: isMobile ? width : width <= 475 ? width : 425,
    height: isMobile ? height : height <= 795 ? height : 788,
    position: "absolute",
    zIndex: 55,
    backgroundColor: "#000000a1",
    borderRadius: isMobile ? 0 : 8,
  };
  const modalsStyle2 = {
    width: isMobile ? "95%" : width < 350 ? width : "90%",
    height: isMobile ? "85%" : height < 370 ? height : 350,
    backgroundColor: "#fbfafa",
    borderRadius: 8,
  };
  const modalsStyle3 = {
    width: isMobile ? width : width <= 475 ? width : "95%",
    height: isMobile ? height : height <= 795 ? height : "95%",
    backgroundColor: "#fbfafa",
    borderRadius: 8,
  };
  /*    const modalDivStyle = {
            width: "100%", height: 52, backgroundColor: "#fff", borderBottomWidth: 1, borderBottomWidthColor: "#21262E"
        }*/
  const modalDivStyle1 = {
    width: "100%",
    height: 52,
    backgroundColor: "#fff",
  };

  const combinedLabels = selectedLanguages?.map((item) => item?.label).join(",");
  const truncatedLabels = combinedLabels?.length > 10 ? combinedLabels.substring(0, 15) + "..." : combinedLabels;

  return (
    <div className="flex justify-center items-center" style={ayarlarDivStyle}>
      <div style={ayarlarDivStyle1} className="flex-column">
        {/*sol ok ve tamam butonu bölümü*/}
        <div className="flex" style={ayarlarDivStyle2}>
          <div className="closebtn flex justify-start items-center" style={{ width: "25%" }}>
            <button onClick={() => TamamButonu()} className="arrow flex justify-center items-center px-3 py-3">
              <FontAwesomeIcon icon={faAngleLeft} className="sliderButton fa-xl"/>
            </button>
          </div>
          <div className={`flex justify-center items-center tiklanmaEngelle ${isMobile ? "profilDuzenleHeaderTextStyle01" : width <= 500 || height <= 550 ? "profilDuzenleHeaderTextStyle01" : "profilDuzenleHeaderTextStyle02"}`}
               style={{ height: "100%", width: "50%" }}>
            {t("bilgileri_duzenle")}
          </div>
          <div onClick={() => TamamButonu()} className="flex justify-end items-center mr-3 buttonTextStyle1111 cursorPointer"
               style={{ height: "100%", width: "25%" }}>
            <span className="profilDuzenleHeaderTextStyle03 tiklanmaEngelle">
              {t("tamam")}
            </span>
          </div>
        </div>

        {/*hakkında, ilgi alanları, yaşam tarzı, iş unvanı, şirket, okul, yaşadığı şehir, cinsiyet, cinsel yönelim bölümü*/}
        <div style={ayarlarDivStyle3} className="active-scroll">
          {/*resim ekleme bölümü*/}
          <div className="flex justify-center items-center tiklanmaEngelle" style={{ width: "100%", height: 200 }}>
            <ImageUploading multiple value={images} onChange={onImageChange} maxNumber={maxNumber} dataURLKey="data_url">
              {({imageList, onImageUpload, onImageRemove, dragProps}) => (
                <div className={`grid grid-cols-3 ${images.length <= 0 ? "flex justify-start items-center" : imageList.length === 1 ? "flex justify-start items-center" : imageList.length >= 2 ? "flex justify-center items-center" : "flex"}`}
                     style={{ width: "95%", height: "90%" }}>
                  {imageList.map((image, index) => (
                    <div style={{position: "relative", width: "100%", height: "90%"}} className={`flex justify-content-center align-items-center px-2`} key={index}>
                      <div className="flex justify-start items-end" style={{position: "absolute", top: "85%", left: 5, marginleft: 0, zIndex: 2}}>
                        <div onClick={() => {onClickPicture(index);}}
                             className="hesapOlusturFotoEkleDiv11 flex justify-center items-center text-white" style={{ background: "#45B649" }}>
                          <FontAwesomeIcon className={"fa-sm"} icon={faPencil}/>
                        </div>
                      </div>
                      <div className="profilFotoEkle222 flex justify-content-center align-items-center" style={{position: "relative", borderWidth: 3, borderColor: "#d4d8de", borderRadius: 8, borderStyle: "dashed", width: "100%", height: "100%"}}>
                        {image["url"] && image["file_name"] ? (
                          <img className="profilFotoEkle333 flex justify-content-center align-items-center tiklanmaEngelle" src={image["url"] + image["file_name"]} alt=""/>
                        ) : (
                          <div style={{ width: "100%", height: "100%" }} className="flex justify-center items-center my-3">
                            <div className="flex justify-center items-center" style={{width: "90%", height: 5, backgroundColor: "#ddd", borderRadius: 100}}>
                              <div id="animatedDiv" />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex justify-end items-end" style={{position: "absolute", top: "85%", right: 5, marginRight: 0, zIndex: 2}}>
                        <div onClick={() => {onImageRemove(index);removeImage(image["id"]);}}
                             className="hesapOlusturFotoEkleDiv11 flex justify-center items-center text-white">
                          <FontAwesomeIcon className={"fa-sm"} icon={faXmark} />
                        </div>
                      </div>
                    </div>
                  ))}
                  {imageList.length <= 2 && (
                    <div style={{position: "relative", width: "100%", height: "90%",}} className="flex justify-content-center align-items-center px-2">
                      <button className="profilFotoEkle111 flex items-end justify-end" type="button" onClick={onImageUpload}{...dragProps}>
                        <div className="hesapOlusturFotoEkleDiv11 flex justify-center items-center text-white" style={{position: "absolute", right: 5, top: "85%",}}>
                          <FontAwesomeIcon className={"fa-md"} icon={faPlus} />
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              )}
            </ImageUploading>
          </div>

          <div style={{ width: "100%", height: "calc(100% - 200px)" }}>
            {/* hakkında */}
            <div style={{ width: "100%" }} className="mt-4">
              <div className="profilDuzenleBaslikTextStyle01 mb-2 ml-2 tiklanmaEngelle">
                {t("bana_kendinden_bahset")}
              </div>

              <textarea
                style={{ width: "100%" }}
                onChange={(e) =>
                  !token ? null : hakkimdaGuncelle(e.target.value)
                }
                value={hakkimda}
                maxLength={500}
                rows={4}
                placeholder={t("bana_kendinden_bahset")}
                className="profilDuzenleTextStyle04 appearance-none border-1 border-white py-2 pl-2 pr-5 leading-tight focus:outline-none bg-white focus:border-white"
                id="inline-full-name"
                autoCapitalize="none"
              />
              <div className="flex justify-end items-center px-2 py-2"
                   style={{width: "100%", height: 33, backgroundColor: "#fff0", marginTop: "-40px",}}>
                <div className={`tiklanmaEngelle ${hakkimdaLenght <= 10 ? "profilDuzenleTextStyle22" : "profilDuzenleTextStyle01"}`}>
                  {hakkimdaLenght}
                </div>
              </div>
            </div>
            {/* İLGİ ALANLARI */}
            {/*  <div style={{width: "100%"}} className="mt-4">
                            <div className="profilDuzenleBaslikTextStyle01 mb-2 ml-2 tiklanmaEngelle">
                                {t("ilgi_alanlari")}
                            </div>
                            <div onClick={() => modalsButton("ilgiAlanlariModalId")} style={modalDivStyle1}
                                 className={`flex cursorPointer ${ilgiAlanlari === "" ? "profilDuzenleRightArrowIcon" : "profilDuzenleRightArrowIcon2"}`}>
                                <div style={{width: "55%", height: "100%"}} className="flex justify-start items-center">
                                    <div>
                                        <span className="profilDuzenleTextStyle01 ml-2 tiklanmaEngelle">{t("ilgi_alani_ekle")}</span>
                                    </div>
                                </div>
                                <div style={{width: "45%", height: "100%"}} className="flex justify-end items-center">
                                    <span
                                        className="profilDuzenleTextStyle03 mr-2 tiklanmaEngelle">{ilgiAlanlari === "" ? `${t("bos")}` : ilgiAlanlari}</span>
                                    <FontAwesomeIcon icon={faAngleRight} className="mr-2"/>
                                </div>
                            </div>
                        </div>*/}
            {/* YAŞAM TARZI */}
            <div style={{ width: "100%" }} className="mt-4">
              <div className="profilDuzenleBaslikTextStyle01 mb-2 ml-2">
                {t("yasam_tarzi")}
              </div>
              {/*       <div onClick={() => modalsButton("burcModalId")} style={modalDivStyle}
                                     className={`flex cursorPointer ${burc === "" ? "profilDuzenleRightArrowIcon" : "profilDuzenleRightArrowIcon2"}`}>
                                    <div style={{width: "80%", height: "100%"}} className="flex justify-start items-center">
                                        <div className="flex items-center ml-2">
                                            {burc === t("akrep") ? <AkrepIcon/> :
                                                burc === t("aslan") ? <AslanIcon/> :
                                                    burc === t("balikBurc") ? <BalikIcon/> :
                                                        burc === t("basak") ? <BasakIcon/> :
                                                            burc === t("boga") ? <BogaIcon/> :
                                                                burc === t("ikizler") ? <IkizlerIcon/> :
                                                                    burc === t("koc") ? <KocIcon/> :
                                                                        burc === t("kova") ? <KovaIcon/> :
                                                                            burc === t("oglak") ? <OglakIcon/> :
                                                                                burc === t("terazi") ? <TeraziIcon/> :
                                                                                    burc === t("yay") ? <YayIcon/> :
                                                                                        burc === t("yengec") ?
                                                                                            <YengecIcon/> :
                                                                                            <MoonAndStarIcon/>}
                                        </div>
                                        <div>
                                            <span className="profilDuzenleTextStyle01 ml-3 tiklanmaEngelle">{t("burclar")}</span>
                                        </div>
                                    </div>
                                    <div style={{width: "20%", height: "100%"}} className="flex justify-end items-center">
                                        <span
                                            className={`profilDuzenleTextStyle03 mr-2 tiklanmaEngelle`}>{burc === "" ? `${t("bos")}` : burc}</span>
                                        <FontAwesomeIcon icon={faAngleRight} className={`mr-2`}/>
                                    </div>
                                </div>
                                <div onClick={() => modalsButton("evcilHayvanlarModalId")} style={modalDivStyle}
                                     className={`flex cursorPointer ${evcilHayvan === "" ? "profilDuzenleRightArrowIcon" : "profilDuzenleRightArrowIcon2"}`}>
                                    <div style={{width: "55%", height: "100%"}} className="flex justify-start items-center">
                                        <div className="flex justify-center items-center ml-2">
                                            <FontAwesomeIcon icon={faPaw} style={{color: "rgba(33,38,46,0.86)"}}/>
                                        </div>
                                        <div>
                                            <span className="profilDuzenleTextStyle01 ml-3 tiklanmaEngelle">{t("evcil_hayvanlar")}</span>
                                        </div>
                                    </div>
                                    <div style={{width: "45%", height: "100%"}} className="flex justify-end items-center">
                                        <span
                                            className="profilDuzenleTextStyle03 mr-2 tiklanmaEngelle">{evcilHayvan === "" ? `${t("bos")}` : evcilHayvan}</span>
                                        <FontAwesomeIcon icon={faAngleRight} className="mr-2"/>
                                    </div>
                                </div>*/}
              <div onClick={() => !token ? null : modalsButton("sigaraKullanimiModalId")} style={modalDivStyle1}
                   className={`flex cursorPointer ${sigaraKullanimi === "" ? "profilDuzenleRightArrowIcon" : "profilDuzenleRightArrowIcon2"}`}>
                <div style={{ width: "60%", height: "100%" }} className="flex justify-start items-center">
                  <div className="flex justify-center items-center ml-2">
                    <FontAwesomeIcon icon={faSmoking} style={{ color: "rgba(33,38,46,0.86)" }}/>
                  </div>
                  <div>
                    <span className="profilDuzenleTextStyle01 ml-3 tiklanmaEngelle">
                      {t("sigara_icmek")}
                    </span>
                  </div>
                </div>
                <div style={{ width: "40%", height: "100%" }} className="flex justify-end items-center">
                  <span className="profilDuzenleTextStyle03 mr-2 tiklanmaEngelle">
                    {sigaraKullanimi === "" ? `${t("bos")}` : datas().sigaraKullanimiData[sigaraKullanimi]?.label}
                  </span>
                  <FontAwesomeIcon icon={faAngleRight} className="mr-2" />
                </div>
              </div>
            </div>
            {/* en çok ne zaman mutlu hissedersin */}
            <div style={{ width: "100%" }} className="mt-4">
              <div className="profilDuzenleBaslikTextStyle01 mb-2 ml-2 tiklanmaEngelle">
                {t("kendini_en_mutlu_hissettigin_an")}
              </div>
              <div style={modalDivStyle1} className="flex items-center">
                <input value={isUnvani} onChange={(e) => !token ? null : isUnvaniGuncelle(e.target.value)}
                       placeholder={t("kendini_en_mutlu_hissettigin_an")}
                       className="profilDuzenleInputStyle1 profilDuzenleTextStyle04 ml-2 focus:outline-none"
                       autoCapitalize="none"/>
              </div>
            </div>
            {/* bir süper güç seçebilseydin hangisini seçerdin */}
            <div style={{ width: "100%" }} className="mt-4">
              <div className="profilDuzenleBaslikTextStyle01 mb-2 ml-2 tiklanmaEngelle">
                {t("bir_super_guc_secebilseydin_hangisini_secerdin")}
              </div>
              <div style={modalDivStyle1} className="flex items-center">
                <input value={sirket} onChange={(e) => !token ? null : sirketGuncelle(e.target.value)}
                       placeholder={t("bir_super_guc_secebilseydin_hangisini_secerdin")}
                       className="profilDuzenleInputStyle1 profilDuzenleTextStyle04 ml-2 focus:outline-none"
                       autoCapitalize="none"/>
              </div>
            </div>
            {/* OKUL */}
            <div style={{ width: "100%" }} className="mt-4">
              <div className="profilDuzenleBaslikTextStyle01 mb-2 ml-2 tiklanmaEngelle">
                {t("okul")}
              </div>
              <div style={modalDivStyle1} className="flex items-center">
                <input value={okul} onChange={(e) => !token ? null : okulGuncelle(e.target.value)}
                       placeholder={t("okul_ekle")}
                       className="profilDuzenleInputStyle1 profilDuzenleTextStyle04 ml-2 focus:outline-none"
                       autoCapitalize="none"/>
              </div>
            </div>
            {/* YAŞADIĞI ŞEHİR */}
            {/* <div style={{width: "100%"}} className="mt-4">
                            <div className="profilDuzenleBaslikTextStyle01 mb-2 ml-2">
                                {t("yasadiginiz_sehir")}
                            </div>
                            <div onClick={() => modalsButton("yasadigiSehirId")} style={modalDivStyle1}
                                 className="flex cursorPointer profilDuzenleRightArrowIcon">
                                <div style={{width: "80%", height: "100%"}} className="flex justify-start items-center">
                                    <span className="profilDuzenleTextStyle01 ml-2">{t("sehir_ekle")}</span>
                                </div>
                                <div style={{width: "20%", height: "100%"}} className="flex justify-end items-center">
                                    <FontAwesomeIcon icon={faAngleRight} className="mr-2"/>
                                </div>
                            </div>
                        </div>*/}
            {/* CİNSİYET */}
            <div style={{ width: "100%" }} className="mt-4">
              <div className="profilDuzenleBaslikTextStyle01 mb-2 ml-2 tiklanmaEngelle">
                {t("cinsiyet_buyuk_harf")}
              </div>
              <div onClick={() => !token ? null : modalsButton("cinsiyetModalId")}
                   style={modalDivStyle1}
                   className={`flex cursorPointer ${cinsiyet === "" ? "profilDuzenleRightArrowIcon" : "profilDuzenleRightArrowIcon2"}`}>
                <div style={{ width: "80%", height: "100%" }} className="flex justify-start items-center">
                  <span className={`profilDuzenleTextStyle03 ml-2 tiklanmaEngelle`}>
                    {cinsiyet === "" ? `${t("bos")}` : datas().cinsiyetData[cinsiyet].label || ""}
                  </span>
                </div>
                <div style={{ width: "20%", height: "100%" }} className="flex justify-end items-center">
                  <FontAwesomeIcon icon={faAngleRight} className="mr-2" />
                </div>
              </div>
            </div>
            {/*  CİNSEL YÖNELİM */}
            <div style={{ width: "100%" }} className="mt-4">
              <div className="profilDuzenleBaslikTextStyle01 mb-2 ml-2 tiklanmaEngelle">
                {t("cinsel_yonelim_buyuk_harf")}
              </div>
              <div onClick={() => !token ? null : modalsButton("cinselYonelimModalId")}
                   style={modalDivStyle1}
                   className={`flex cursorPointer ${
                     cinselYonelim === "" ? "profilDuzenleRightArrowIcon" : "profilDuzenleRightArrowIcon2"}`}>
                <div style={{ width: "60%", height: "100%" }} className="flex justify-start items-center">
                  <span className="profilDuzenleTextStyle01 ml-2 tiklanmaEngelle">
                    {t("cinsel_yonelim_ekle")}
                  </span>
                </div>
                <div style={{ width: "40%", height: "100%" }} className="flex justify-end items-center">
                  <span className={`profilDuzenleTextStyle03 mr-2 tiklanmaEngelle`}>
                    {cinselYonelim === "" ? `${t("bos")}` : datas().cinselYonelimData[cinselYonelim]?.label || ""}
                  </span>
                  <FontAwesomeIcon icon={faAngleRight} className="mr-2" />
                </div>
              </div>
            </div>
            {/*  KONUŞULAN DİLLER */}
            <div style={{ width: "100%" }} className="mt-4">
              <div className="profilDuzenleBaslikTextStyle01 mb-2 ml-2 tiklanmaEngelle">
                {t("konusulan_diller")}
              </div>
              <div onClick={() => !token ? null : modalsButton("konusulanDillerModalId")}
                   style={modalDivStyle1}
                   className={`flex cursorPointer ${!truncatedLabels ? "profilDuzenleRightArrowIcon" : "profilDuzenleRightArrowIcon2"}`}>
                <div style={{ width: "60%", height: "100%" }} className="flex justify-start items-center">
                  <span className="profilDuzenleTextStyle01 ml-2 tiklanmaEngelle">
                    {t("konusulan_diller")}
                  </span>
                </div>
                <div style={{ width: "40%", height: "100%" }} className="flex justify-end items-center">
                  <span className={`profilDuzenleTextStyle03 mr-2 tiklanmaEngelle`}>
                    {!truncatedLabels ? `${t("bos")}` : truncatedLabels}
                  </span>
                  <FontAwesomeIcon icon={faAngleRight} className="mr-2" />
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>

      {modals === "ilgiAlanlariModalId" && (
        <div className="flex justify-center items-center" style={modalsStyle}>
          <div className="flex flex-column items-center justify-center" style={modalsStyle2}>
            <div style={{ width: "100%", height: "25%" }} className="flex justify-center items-center">
              <div className="flex justify-content-center align-items-center" style={{ width: "85%", height: "100%" }}>
                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                  {t("ilgi_alani_ekle")}
                </span>
              </div>
              {/*modal kapat tuşu*/}
              <div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">
                <div onClick={() => modalClose("modalKapat")} className="flex box justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", height: "75%" }} className="active-scroll">
              <EvcilHayvanSigaraKullanimiSelect
                  ilgiAlanlari={ilgiAlanlari ? datas().ilgiAlanlariData[ilgiAlanlari].label : ilgiAlanlari}
                  data={datas().ilgiAlanlariData}
                  handleDataSelectButton={ilgiAlanlariSelectButton}/>
            </div>
          </div>
        </div>
      )}
      {/*{modals === "burcModalId" && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column items-center justify-center" style={modalsStyle2}>
                        <div style={{width: "100%", height: "40%"}} className="flex justify-center items-center">
                            <div className="flex justify-content-center align-items-center"
                                 style={{width: "85%", height: "100%"}}>
                                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">{t("burcunuz_nedir")}</span>
                            </div>
                            modal kapat tuşu
                            <div style={{width: "15%", height: "100%"}}
                                 className="flex justify-content-center align-items-center">
                                <div onClick={() => modalClose("modalKapat")}
                                     className="flex box justify-content-center align-items-center">
                                     <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "60%"}}>
                            <BurcSelect burc={burc} data={zodiac().getNames(localStorage.getItem("i18nextLng") || 'en')}
                                                                  handleDataSelectButton={handleBurcSelectButton}/>
                        </div>
                    </div>
                </div>
            )}*/}
      {modals === "evcilHayvanlarModalId" && (
        <div className="flex justify-center items-center" style={modalsStyle}>
          <div className="flex flex-column items-center justify-center" style={modalsStyle2}>
            <div style={{ width: "100%", height: "40%" }} className="flex justify-center items-center">
              <div className="flex justify-content-center align-items-center" style={{ width: "85%", height: "100%" }}>
                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                  {t("evcil_hayvanin_var_mi")}
                </span>
              </div>
              {/*modal kapat tuşu*/}
              <div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">
                <div onClick={() => modalClose("modalKapat")} className="flex box justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", height: "60%" }}>
              <EvcilHayvanSigaraKullanimiSelect evcilHayvan={datas().evcilHayvanlarData[evcilHayvan].label || ""}
                                                data={Object.values(datas().evcilHayvanlarData)}
                                                handleDataSelectButton={handleEvcilHayvanSelectButton}/>
            </div>
          </div>
        </div>
      )}
      {modals === "sigaraKullanimiModalId" && (
        <div className="flex justify-center items-center" style={modalsStyle}>
          <div className="flex flex-column items-center justify-center" style={modalsStyle2}>
            <div style={{ width: "100%", height: "40%" }} className="flex justify-center items-center">
              <div className="flex justify-content-center align-items-center" style={{ width: "85%", height: "100%" }}>
                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                  {t("sigara_kullaniyor_musun")}
                </span>
              </div>
              {/*modal kapat tuşu*/}
              <div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">
                <div onClick={() => modalClose("modalKapat")} className="flex box justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", height: "60%" }}>
              <EvcilHayvanSigaraKullanimiSelect sigaraKullanimi={sigaraKullanimi ? datas().sigaraKullanimiData[sigaraKullanimi].label : sigaraKullanimi}
                                                data={Object.values(datas().sigaraKullanimiData)}
                                                handleDataSelectButton={handleSigaraKullanimiSelectButton}/>
            </div>
          </div>
        </div>
      )}
      {modals === "yasadigiSehirId" && (
        <div className="flex justify-center items-center" style={modalsStyle}>
          <div className="flex flex-column align-items-center justify-content-center" style={modalsStyle2}>
            <div>yasadigiSehirId</div>
            {/*modal kapat tuşu*/}
            <div style={{ width: "80%", height: "15%" }}>
              <div onClick={() => modalClose()}>
                <div className="hesapOluşturModalButtonStyle1 flex justify-content-center align-items-center">
                  <span className="loginPcButtonTextStyle2">Onaylıyorum</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modals === "cinsiyetModalId" && (
        <div className="flex justify-center items-center" style={modalsStyle}>
          <div className="flex flex-column items-center justify-center" style={modalsStyle2}>
            <div style={{ width: "100%", height: "20%" }} className="flex justify-center items-center">
              <div className="flex justify-content-center align-items-center" style={{ width: "85%", height: "100%" }}>
                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                  {t("cinsiyet")}
                </span>
              </div>
              {/*modal kapat tuşu*/}
              <div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">
                <div onClick={() => modalClose("modalKapat")} className="flex box justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                </div>
              </div>
            </div>

            <div style={{ width: "100%", height: "80%" }}>
              <CinsiyetVeCinselYonelimSec cinsiyet={datas().cinsiyetData[cinsiyet].value || ""}
                                          handleDataSelectButton={cinsiyetSelectButton}
                                          data={Object.values(datas().cinsiyetData)}/>
            </div>
          </div>
        </div>
      )}
      {modals === "cinselYonelimModalId" && (
        <div className="flex justify-center items-center" style={modalsStyle}>
          <div className="flex flex-column items-center justify-center" style={modalsStyle2}>
            <div style={{ width: "100%", height: "20%" }} className="flex justify-center items-center">
              <div className="flex justify-content-center align-items-center" style={{ width: "85%", height: "100%" }}>
                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                  {t("cinsel_yonelim")}
                </span>
              </div>
              {/*modal kapat tuşu*/}
              <div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">
                <div onClick={() => modalClose("modalKapat")} className="flex box justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", height: "80%" }}>
              <CinsiyetVeCinselYonelimSec cinselYonelim={datas().cinselYonelimData[cinselYonelim].value || ""}
                                          handleDataSelectButton={cinselYonelimSelectButton}
                                          data={Object.values(datas().cinselYonelimData)}
                                          data2={datas().cinselYonelimData}/>
            </div>
          </div>
        </div>
      )}
      {modals === "konusulanDillerModalId" && (
        <div className="flex justify-center items-center" style={modalsStyle}>
          <div className="flex flex-column items-center justify-center" style={modalsStyle2}>
            <div style={{ width: "100%", height: "20%" }} className="flex justify-center items-center">
              <div className="flex justify-content-center align-items-center" style={{ width: "85%", height: "100%" }}>
                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                  {t("konusulan_diller")}
                </span>
              </div>
              {/*modal kapat tuşu*/}
              <div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">
                <div onClick={() => modalClose("modalKapat")} className="flex box justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                </div>
              </div>
            </div>
            <div className="flex justify-center" style={{ width: "100%", height: "80%" }}>
              <LanguageSelector className={"col-10 mt-3"} selectedLanguages={selectedLanguages} setSelectedLanguages={setSelectedLanguages}/>
            </div>
          </div>
        </div>
      )}
      {selectedPicture && (
        <div className="flex justify-center items-center" style={modalsStyle}>
          <div className="flex flex-column items-center justify-center" style={modalsStyle3}>
            <div style={{ width: "100%", height: 51 }} className="flex justify-center items-center">
              <div className="flex justify-content-center align-items-center" style={{ width: "85%", height: "100%" }}>
                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                  {t("fotograf_duzenle")}
                </span>
              </div>
              {/*modal kapat tuşu*/}
              <div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">
                <div onClick={() => setSelectedPicture(false)} className="flex box justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", height: "calc(100% - 51px)" }} className="active-scroll">
              <AvatarEditor style={{margin: "auto", maxHeight: "86%", width: "auto", maxWidth: "100%",}}
                            ref={editorRef}
                            image={selectedPicture.url + selectedPicture.file_name}
                            width={400} height={600} border={50}
                            scale={scale} rotate={rotate} crossOrigin="anonymous"
              />
              <div style={{ marginTop: "-68px" }}>
                <div className={"row m-0 p-0"} style={{backgroundColor: "#000000ab", position: "relative", zIndex: 222222}}>
                  <div className={"col-8 flex items-center justify-center "}>
                    <label className={"profilDuzenleBaslikTextStyle01 tiklanmaEngelle text-white"}>
                      {t("yakinlastir")}
                      <input className={"form-range my-2"} name="scale" type="range" onChange={(e) => setScale(parseFloat(e.target.value))} min="1" max="2" step="0.01" defaultValue="1"/>
                    </label>
                  </div>
                  <div className={"col-4"}>
                    <div className={"profilDuzenleBaslikTextStyle01 tiklanmaEngelle text-white"}>
                      {t("dondur")}
                    </div>
                    <div onClick={() => setRotate(rotate + 90)} className="my-2 flex yenileButtonuStyle justify-center items-center">
                      <FontAwesomeIcon icon={faRotateRight} className="fa-xl" />
                    </div>
                  </div>
                </div>

                <div onClick={onSave} style={{ width: 190, height: 50 }} className="m-auto flex justify-center items-center etkinlikOlusturButtonStyle cursorPointer mt-1">
                  <div style={{ width: "100%", height: "100%" }} className="flex justify-center items-center">
                    <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
                      {t("resmi_kaydet")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfilDuzenle;
