import React, { useLayoutEffect, useState } from 'react'
import {
    useSpringRef,
    animated,
    useTransition
} from '@react-spring/web'
import styles from "../styles/main.css";

const IMAGES = [
    "M O Z A R E D",
    "M O Z A R E D",
    "M O Z A R E D",
]

export default function Yukleniyor() {
    const [activeIndex, setActiveIndex] = useState(0)
    const springApi = useSpringRef()

    const transitions = useTransition(activeIndex, {
        from: {
            clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
        },
        enter: {
            clipPath: 'polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%)',
        },
        leave: {
            clipPath: 'polygon(100% 0%, 100% 100%, 100% 100%, 100% 0%)',
        },
        onRest: (_springs, _ctrl, item) => {
            if (activeIndex === item) {
                setActiveIndex(activeIndex === IMAGES.length - 1 ? 0 : activeIndex + 1)
            }
        },
        exitBeforeEnter: true,
        config: {
            duration: 4000,
        },
        delay: 1000,
        ref: springApi,
    })

    useLayoutEffect(() => {
        springApi.start()
    }, [activeIndex, springApi])

    return (
        <div className="container1">
            <div style={{backgroundColor: "#fff", padding: 25, borderRadius: 50}}>
                {transitions((springs, item) => (
                    <animated.div className={styles.img__container} style={springs}>
                        <span className="logoTextStyle1 tiklanmaEngelle">{IMAGES[item]}</span>
                    </animated.div>
                ))}
            </div>
        </div>
    )
}
