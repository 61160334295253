// blockEylemiAction.js

import axios from "../../../utils/CustomAxios";
import { BLOCK_EYLEMI_SUCCESS } from '../../types';
import {showToast} from "../../../utils/Functions";

export const blockEylemi = (uyeId) => {
    return (dispatch) => {
        axios.post(`customer/block/${uyeId}`)
            .then((res) => {
                console.log("res", res)
                if (res.data.status === 1) {
                    dispatch(blockEylemiBasarili(res.data.status));
                    window.location.reload();
                }
            })
            .catch((err) => {
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{ type: "error", message: err.message, second: 5 }]);
                }
            });
    };
};

export const blockEylemiBasarili = (begenUpdatedData) => {
    return {
        type: BLOCK_EYLEMI_SUCCESS,
        payload: begenUpdatedData
    };
};
