import React, {useEffect, useMemo, useState} from "react";
import "../../styles/main.css";
import {isMobile} from "react-device-detect";
import {
    faAngleLeft,
    faAngleRight,
    faArrowUpRightFromSquare,
    faCheck,
    faDesktop,
    faEarthAmerica,
    faEarthAsia,
    faGear,
    faLocationDot,
    faMobileButton, faTrash,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MesafeTercihi from "../../components/MesafeTercihi";
import YasTercihi from "../../components/YasTercihi";
/*import CokluSecim from "../../components/CokluSecim";*/
import axios from "../../utils/CustomAxios";
import {showToast} from "../../utils/Functions";
import EngellenenlerListesi from "./EngellenenlerListesi";
import Sozlesme from "../../components/Sozlesme";
import i18next from "i18next";
/*import Destek from "../../components/Destek";*/
import {useDispatch, connect} from "react-redux";
import {cikisEylemiAction} from "../../store/actions/cikisEylemAction";
import BeniDestekleModal from "../../components/Modallar/BeniDestekleModal";
// import {Alert} from "react-bootstrap";

function Ayarlar({socketRef}) {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const {t} = useTranslation();
    let location = useLocation();
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    let [herkeseGosterToggle, setHerkeseGosterToggle] = useState(true);
    let [beniGizleToggle, setBeniGizleToggle] = useState(false);
    let [beniHaritadaGizleToggle, setBeniHaritadaGizleToggle] = useState(false);
    let [eslesmesizMesajKabuluToggle, setEslesmesizMesajKabuluToggle] = useState(true);
    /*let [secilenSehirdeGosterToggle, setSecilenSehirdeGosterToggle] = useState(false);*/
    let [cevremdekileriGosterToggle, setCevremdekileriGosterToggle] = useState(false);
    let [mesafeTercih, setMesafeTercih] = useState([25]);
    let [yasTercih, setYasTercih] = useState([18, 45]);
    /* const [multiSelectValue, setMultiSelectValue] = useState([]);*/
    const [modals, setModals] = useState("modalKapali");
    const [user, setUser] = useState(null);
    const [categoryId, setCategoryId] = useState();
    const [pageId, setPageId] = useState();
    const storedDevicePlatform = localStorage.getItem("devicePlatform");

    const selected = localStorage.getItem("i18nextLng") || "en";
    const languageMap1 = useMemo(() =>  ({
        en: {
            label: t("ingilizce"),
            id: 1,
            kod: "en",
            dir: "ltr",
            active: true,
            showMenu: true,
        },
        "en-US": {
            label: t("ingilizce"),
            id: 2,
            kod: "en",
            dir: "ltr",
            active: false,
            showMenu: false,
        },
        tr: {
            label: t("turkce"),
            id: 3,
            kod: "tr",
            dir: "ltr",
            active: false,
            showMenu: false,
        },
        "tr-TR": {
            label: t("turkce"),
            id: 4,
            kod: "tr",
            dir: "ltr",
            active: false,
            showMenu: true,
        },
        de: {
            label: t("almanca"),
            id: 5,
            kod: "de",
            dir: "ltr",
            active: false,
            showMenu: true,
        },
        "de-DE": {
            label: t("almanca"),
            id: 6,
            kod: "de",
            dir: "ltr",
            active: false,
            showMenu: false,
        },
    }), [t]);

    const [menuAnchor, setMenuAnchor] = React.useState(null);
    React.useEffect(() => {
        document.body.dir = languageMap1[selected]?.dir;
    }, [languageMap1, menuAnchor, selected]);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        if (token) {
            let user_data = JSON.parse(localStorage.getItem("customer_data"));
            setCevremdekileriGosterToggle(user_data.just_show_me_the_people_around);
            setHerkeseGosterToggle(user_data.show_me_to_everyone);
            setEslesmesizMesajKabuluToggle(user_data.unmatched_message_acceptance);
            setBeniGizleToggle(user_data.hide_me);
            setBeniHaritadaGizleToggle(user_data.hide_me_map);
            setYasTercih([
                user_data.minimum_age_preference,
                user_data.maximum_age_preference,
            ]);
            setMesafeTercih([user_data.maximum_distance]);
            setUser(user_data);

            window.addEventListener("resize", updateDimensions);
            return () => window.removeEventListener("resize", updateDimensions);
        }
    }, [token]);

    const signOut = () => {
        if (token) {
            dispatch(cikisEylemiAction(socketRef));
            navigate(`/AnaSayfa/${location.search}`);
        } else {
            navigate(`/AnaSayfa`);
        }
    };

    let eslesmesizMesajKabuluToggleButton = () => {
        if (token) {
            setEslesmesizMesajKabuluToggle(eslesmesizMesajKabuluToggle === false);
            axios
                .put(`customer/profile/unmatched-message-acceptance`, {
                    unmatched_message_acceptance: eslesmesizMesajKabuluToggle === false,
                })
                .then((res) => {
                    showToast(res.data.messages);
                    if (res.data.status === 1) {
                        let user_data = user;
                        user_data.unmatched_message_acceptance =
                            eslesmesizMesajKabuluToggle === false;
                        localStorage.setItem("customer_data", JSON.stringify(user_data));
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        showToast(err.response.data.messages);
                    } else {
                        showToast([{type: "error", message: err.message, second: 5}]);
                    }
                });
        }
    };
    let herkeseGosterToggleButton = () => {
        if (token) {
            setHerkeseGosterToggle(herkeseGosterToggle === false);
            setCevremdekileriGosterToggle(!(herkeseGosterToggle === false));
            axios
                .put(`customer/profile/show-me-to-everyone`, {
                    show_me_to_everyone: herkeseGosterToggle === false,
                })
                .then((res) => {
                    showToast(res.data.messages);
                    if (res.data.status === 1) {
                        let user_data = user;
                        user_data.show_me_to_everyone = herkeseGosterToggle === false;
                        user_data.just_show_me_the_people_around = !(
                            herkeseGosterToggle === false
                        );
                        localStorage.setItem("customer_data", JSON.stringify(user_data));
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        showToast(err.response.data.messages);
                    } else {
                        showToast([{type: "error", message: err.message, second: 5}]);
                    }
                });
        }
    };
    let beniGizleToggleButton = () => {
        if (token) {
            setBeniGizleToggle(beniGizleToggle === false);
            axios
                .put(`customer/profile/hide-me`, {
                    hide_me: beniGizleToggle === false,
                })
                .then((res) => {
                    showToast(res.data.messages);
                    if (res.data.status === 1) {
                        let user_data = user;
                        user_data.hide_me = beniGizleToggle === false;
                        localStorage.setItem("customer_data", JSON.stringify(user_data));
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        showToast(err.response.data.messages);
                    } else {
                        showToast([{type: "error", message: err.message, second: 5}]);
                    }
                });
        }
    };

    let beniHaritadaGizleToggleButton = () => {
        if (token) {
            setBeniHaritadaGizleToggle(beniHaritadaGizleToggle === false);
            axios
                .put(`customer/profile/hide-me-map`, {
                    hide_me_map: beniHaritadaGizleToggle === false,
                })
                .then((res) => {
                    showToast(res.data.messages);
                    if (res.data.status === 1) {
                        let user_data = user;
                        user_data.hide_me_map = beniHaritadaGizleToggle === false;
                        localStorage.setItem("customer_data", JSON.stringify(user_data));
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        showToast(err.response.data.messages);
                    } else {
                        showToast([{type: "error", message: err.message, second: 5}]);
                    }
                });
        }
    };
    /*  let secilenSehirdeGostertoggleButton = (id) => {
          setSecilenSehirdeGosterToggle(id);
      }*/

    const [timerMesafe, setTimerMesafe] = useState(null);
    const [timerYas, setTimerYas] = useState(null);

    const cevremdekileriGostertoggleButton = async () => {
        if (token) {
            setCevremdekileriGosterToggle(cevremdekileriGosterToggle === false);
            setHerkeseGosterToggle(!(cevremdekileriGosterToggle === false));

            axios
                .put(`customer/profile/just-show-me-the-people-around`, {
                    just_show_me_the_people_around: cevremdekileriGosterToggle === false,
                })
                .then((res) => {
                    showToast(res.data.messages);
                    if (res.data.status === 1) {
                        let user_data = user;
                        user_data.just_show_me_the_people_around =
                            cevremdekileriGosterToggle === false;
                        user_data.show_me_to_everyone = !(
                            cevremdekileriGosterToggle === false
                        );
                        localStorage.setItem("customer_data", JSON.stringify(user_data));
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        showToast(err.response.data.messages);
                    } else {
                        showToast([{type: "error", message: err.message, second: 5}]);
                    }
                });
        }
    };
    const mesafeTercihiHandleClick = (e) => {
        if (token) {
            setMesafeTercih(e);
            if (timerMesafe) {
                clearTimeout(timerMesafe);
            }
            setTimerMesafe(
                setTimeout(() => {
                    axios
                        .put(`customer/profile/distance-preference`, {
                            maximum_distance: e[0],
                        })
                        .then((res) => {
                            showToast(res.data.messages);
                            if (res.data.status === 1) {
                                let user_data = user;
                                user_data.maximum_distance = e[0];
                                localStorage.setItem(
                                    "customer_data",
                                    JSON.stringify(user_data)
                                );
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                showToast(err.response.data.messages);
                            } else {
                                showToast([{type: "error", message: err.message, second: 5}]);
                            }
                        });
                }, 3000)
            );
        }
    };
    const yasTercihiHandleClick = (e) => {
        if (token) {
            setYasTercih(e);
            let minimum_age_preference = e[0];
            let maximum_age_preference = e[1];
            if (timerYas) {
                clearTimeout(timerYas);
            }
            setTimerYas(
                setTimeout(() => {
                    axios
                        .put(`customer/profile/age-preference`, {
                            minimum_age_preference: minimum_age_preference,
                            maximum_age_preference: maximum_age_preference,
                        })
                        .then((res) => {
                            showToast(res.data.messages);
                            if (res.data.status === 1) {
                                let user_data = user;
                                user_data.minimum_age_preference = minimum_age_preference;
                                user_data.maximum_age_preference = maximum_age_preference;
                                localStorage.setItem(
                                    "customer_data",
                                    JSON.stringify(user_data)
                                );
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                showToast(err.response.data.messages);
                            } else {
                                showToast([{type: "error", message: err.message, second: 5}]);
                            }
                        });
                }, 3000)
            );
        }
    };
    /*    const handleMultiSelectButton = (e) => {
              setMultiSelectValue(e)
          }*/
    /*    const options = [
              {value: 'chocolate', label: 'Chocolate'},
              {value: 'strawberry', label: 'Strawberry'},
              {value: 'vanilla', label: 'Vanilla'},
              {value: 'aaa', label: 'aaa'},
              {value: 'bbb', label: 'bbb'},
              {value: 'ccc', label: 'ccc'},
              {value: 'ddd', label: 'ddd'},
              {value: 'eee', label: 'eee'},
              {value: 'fff', label: 'fff'},
          ]*/
    const modalsButton = (id, category_id, page_id) => {
        setModals(id);
        setCategoryId(category_id);
        setPageId(page_id);
    };
    const modalClose = () => {
        setModals("");
    };

    const ayarlarDivStyle = {
        width: isMobile ? width : width <= 475 ? width : 425,
        height: isMobile ? height : height <= 795 ? height : 788,
    };
    const ayarlarDivStyle1 = {
        width: "100%",
        height: "100%",
        position: "relative",
        zIndex: 50,
        borderWidth: isMobile ? 0 : 1,
        borderRadius: isMobile ? 0 : 8,
    };
    const ayarlarDivStyle2 = {
        width: "100%",
        height: 51,
        backgroundColor: "#fff",
        borderTopLeftRadius: isMobile ? 0 : 8,
        borderTopRightRadius: isMobile ? 0 : 8,
    };
    const ayarlarDivStyle3 = {
        width: "100%",
        height: "calc(100% - 51px)", // aradaki boşlukları silinmesin yoksa çalışmıyor
        backgroundColor: "#fbfafa",
        borderBottomLeftRadius: isMobile ? 0 : 8,
        borderBottomRightRadius: isMobile ? 0 : 8,
    };
    const ayarlarDivStyle4 = {
        width: "100%",
        height: 62,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        backgroundColor: "#fff",
    };
    const modalsStyle = {
        width: isMobile ? width : width <= 475 ? width : 425,
        height: isMobile ? height : height <= 795 ? height : 788,
        position: "absolute",
        zIndex: 55,
        borderWidth: isMobile ? 0 : 1,
        borderRadius: isMobile ? 0 : 8,
    };
    const modalsStyle2 = {
        width: "100%",
        height: "100%",
        backgroundColor: "#fbfafa",
        borderRadius: isMobile ? 0 : 8,
    };
    const modalHeaderDivStyle = {
        width: "100%",
        height: 51,
        backgroundColor: "#fff",
        borderTopLeftRadius: isMobile ? 0 : 8,
        borderTopRightRadius: isMobile ? 0 : 8,
    };

    const TamamButonu = () => {
        navigate(`/AnaSayfa${location.search}`, {
            state: {id: "profilSayfalariGeriId", name: "ProfilSayfalariGeri"},
        });
    };

    const hesapSil = () => {
        if (selected.charAt(0) + selected.charAt(1) === "tr") {
            window.location.href = "http://mozared.com/delete-account-tr.html";
        } else {
            window.location.href = "http://mozared.com/delete-account.html";
        }
    };

    const konumSilIslem = () => {
        if (token) {
            axios
                .post(`customer/location/deleteLocation`, {
                    latitude: "",
                    longitude: "",
                })
                .then((res) => {
                    window.location = '/AnaSayfa';
                })
                .catch((err) => {
                    if (err.response) {
                        console.log(err.response.data.messages);
                    } else {
                        console.log([{type: "error", message: err.message, second: 5}]);
                    }
                });
        }
    };

    return (
        <div className="flex justify-center items-center" style={ayarlarDivStyle}>
            <div style={ayarlarDivStyle1} className="flex-column boxShadowTop">
                <div className="flex" style={ayarlarDivStyle2}>
                    <div className="closebtn flex justify-start items-center"
                        style={{width: "25%"}}>
                        <button onClick={() => TamamButonu()}
                            className="arrow flex justify-center items-center px-3 py-3">
                            <FontAwesomeIcon icon={faAngleLeft} className="sliderButton fa-xl"/>
                        </button>
                    </div>
                    <div className="flex justify-center items-center ayarlarTextStyle01 tiklanmaEngelle"
                         style={{height: "100%", width: "50%"}}>
                        {t("ayarlar")}
                    </div>
                    <div onClick={() => TamamButonu()}
                         className="flex justify-end items-center mr-3 buttonTextStyle1111 cursorPointer"
                         style={{height: "100%", width: "25%"}}>
                        <span className="profilDuzenleHeaderTextStyle03 tiklanmaEngelle">
                            {t("tamam")}
                        </span>
                    </div>
                </div>
                <div style={ayarlarDivStyle3} className="active-scroll">
                    {!token ? null : (
                        <>
                            {storedDevicePlatform === "ios" ? null : (
                                <>
                                    <div className="ayarlarTextStyle1122 ml-3 mt-3 mb-3 tiklanmaEngelle">
                                        {t("kesif_ayarlari_title")}
                                    </div>

                                    {/*Beni herkese göster başlangıç*/}
                                    <div style={ayarlarDivStyle4} className="flex">
                                        <div style={{width: "65%", height: "100%"}} className="flex justify-start items-center pl-3">
                                            <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                                {t("bana_herkesi_goster")}
                                            </span>
                                        </div>

                                        <div style={{width: "35%", height: "100%"}} className="flex justify-end items-center mr-3 cursorPointer">
                                            <div style={{
                                                width: "65%",
                                                height: "55%",
                                                backgroundColor: "#fff",
                                                borderRadius: 10,
                                                borderWidth: 1,
                                            }} className={`flex justify-center items-center`}>
                                                <div className={`flex `} style={{width: "100%", height: "100%"}}
                                                     onClick={() => herkeseGosterToggleButton()}>
                                                    <div style={{width: "35%"}}
                                                         className={`flex justify-center items-center ${
                                                             herkeseGosterToggle === false 
                                                                 ? "toggleKapaliStyle1" 
                                                                 : "toggleAcikStyle1"}`}>
                                                        {herkeseGosterToggle === false ? (
                                                            <FontAwesomeIcon icon={faXmark} className={`${herkeseGosterToggle === false ? "slideLeftKapaliIcon" : ""}`} inverse/>
                                                        ) : (
                                                            <FontAwesomeIcon icon={faCheck} className={`${herkeseGosterToggle === true ? "slideRightAcikIcon" : ""}`} inverse/>
                                                        )}
                                                    </div>
                                                    <div style={{width: "65%"}} className="flex justify-center items-center">
                                                        {herkeseGosterToggle === false ? (
                                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                                 className={`toggleKapaliTextStyle1 ${herkeseGosterToggle === false ? "slideLeftKapaliText" : ""}`}>
                                                                {t("kapali")}
                                                            </div>
                                                        ) : (
                                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                                className={`toggleAcikTextStyle1 ${herkeseGosterToggle === true ? "slideRightAcikText" : ""}`}>
                                                                {t("acik")}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*Beni herkese göster bitiş*/}

                                    {/*sadece çevremdekilere göster başlangıç*/}
                                    <div style={ayarlarDivStyle4} className="flex">
                                        <div style={{width: "65%", height: "100%"}} className="flex justify-start items-center pl-3">
                                            <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                                {t("sadece_cevremdeki_insanlari_goster")}
                                            </span>
                                        </div>
                                        <div style={{width: "35%", height: "100%"}} className="flex justify-end items-center mr-3 cursorPointer">
                                            <div style={{
                                                width: "65%",
                                                height: "55%",
                                                backgroundColor: "#fff",
                                                borderRadius: 10,
                                                borderWidth: 1}} className={`flex justify-center items-center`}>
                                                <div className={`flex`}
                                                     style={{width: "100%", height: "100%"}}
                                                     onClick={() => cevremdekileriGostertoggleButton()}>
                                                    <div style={{width: "35%"}}
                                                         className={`flex justify-center items-center ${
                                                             cevremdekileriGosterToggle === false 
                                                                 ? "toggleKapaliStyle1" : "toggleAcikStyle1"}`}>
                                                        {cevremdekileriGosterToggle === false ? (
                                                            <FontAwesomeIcon icon={faXmark} className={`${cevremdekileriGosterToggle === false ? "slideLeftKapaliIcon" : ""}`} inverse/>
                                                        ) : (
                                                            <FontAwesomeIcon icon={faCheck} className={`${cevremdekileriGosterToggle === true ? "slideRightAcikIcon" : ""}`} inverse/>
                                                        )}
                                                    </div>
                                                    <div style={{width: "65%"}} className="flex justify-center items-center">
                                                        {cevremdekileriGosterToggle === false ? (
                                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                                 className={`toggleKapaliTextStyle1 tiklanmaEngelle ${cevremdekileriGosterToggle === false ? "slideLeftKapaliText" : ""}`}>
                                                                {t("kapali")}
                                                            </div>
                                                        ) : (
                                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                                 className={`toggleAcikTextStyle1 tiklanmaEngelle ${cevremdekileriGosterToggle === true ? "slideRightAcikText" : ""}`}>
                                                                {t("acik")}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {cevremdekileriGosterToggle === true && (
                                        <div style={ayarlarDivStyle4}
                                             className={`flex ${cevremdekileriGosterToggle === true ? "slidecevremdekileriGosterTopKapali" : ""}`}>
                                            <div style={{width: "100%", height: "100%"}}
                                                 className={`flex flex-column justify-center items-center`}>
                                                <div style={{width: "95%", height: "50%"}} className="flex items-end">
                                                    <div className="flex justify-start ml-1 slideTextStyle222 tiklanmaEngelle"
                                                         style={{width: "50%", textAlign: "start"}}>
                                                        {t("mesafe_tercihi")}
                                                    </div>
                                                    <div className="flex justify-end mr-1 slideTextStyle222 tiklanmaEngelle" style={{width: "50%", textAlign: "end"}}>
                                                        {mesafeTercih} km.
                                                    </div>
                                                </div>

                                                <MesafeTercihi mesafeTercihiHandleClick={mesafeTercihiHandleClick} mesafeTercih={mesafeTercih}/>
                                            </div>
                                        </div>
                                    )}
                                    {/*sadece çevremdekilere göster bitiş*/}

                                    {/*unmatched_message_acceptance başlangıç*/}
                                    <div style={ayarlarDivStyle4} className="flex">
                                        <div style={{width: "65%", height: "100%"}} className="flex justify-start items-center pl-3">
                                            <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                                {t("eslesmesiz_mesaj_kabulu")}
                                            </span>
                                        </div>

                                        <div style={{width: "35%", height: "100%"}}
                                             className="flex justify-end items-center mr-3 cursorPointer">
                                            <div style={{
                                                width: "65%",
                                                height: "55%",
                                                backgroundColor: "#fff",
                                                borderRadius: 10,
                                                borderWidth: 1}} className={`flex justify-center items-center`}>
                                                <div className={`flex `} style={{width: "100%", height: "100%"}}
                                                     onClick={() => eslesmesizMesajKabuluToggleButton()}>
                                                    <div style={{width: "35%"}}
                                                         className={`flex justify-center items-center ${eslesmesizMesajKabuluToggle === false ? "toggleKapaliStyle1" : "toggleAcikStyle1"}`}>
                                                        {eslesmesizMesajKabuluToggle === false ? (
                                                            <FontAwesomeIcon icon={faXmark} className={`${eslesmesizMesajKabuluToggle === false ? "slideLeftKapaliIcon" : ""}`} inverse/>
                                                        ) : (
                                                            <FontAwesomeIcon icon={faCheck} className={`${eslesmesizMesajKabuluToggle === true ? "slideRightAcikIcon" : ""}`} inverse/>
                                                        )}
                                                    </div>
                                                    <div style={{width: "65%"}} className="flex justify-center items-center">
                                                        {eslesmesizMesajKabuluToggle === false ? (
                                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                                 className={`toggleKapaliTextStyle1 tiklanmaEngelle ${eslesmesizMesajKabuluToggle === false ? "slideLeftKapaliText" : ""}`}>
                                                                {t("kapali")}
                                                            </div>
                                                        ) : (
                                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                                 className={`toggleAcikTextStyle1 tiklanmaEngelle ${eslesmesizMesajKabuluToggle === true ? "slideRightAcikText" : ""}`}>
                                                                {t("acik")}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*unmatched_message_acceptance bitiş*/}

                                    {/*hide_me başlangıç*/}
                                    <div style={ayarlarDivStyle4} className="flex">
                                        <div style={{width: "65%", height: "100%"}} className="flex justify-start items-center pl-3">
                                            <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                                {t("beni_gizle")}
                                            </span>
                                        </div>

                                        <div style={{width: "35%", height: "100%"}} className="flex justify-end items-center mr-3 cursorPointer">
                                            <div style={{
                                                width: "65%",
                                                height: "55%",
                                                backgroundColor: "#fff",
                                                borderRadius: 10,
                                                borderWidth: 1,}}
                                                 className={`flex justify-center items-center`}>
                                                <div className={`flex `} style={{width: "100%", height: "100%"}}
                                                     onClick={() => beniGizleToggleButton()}>
                                                    <div style={{width: "35%"}} className={`flex justify-center items-center ${beniGizleToggle === false ? "toggleKapaliStyle1" : "toggleAcikStyle1"}`}>
                                                        {beniGizleToggle === false ? (
                                                            <FontAwesomeIcon icon={faXmark} className={`${beniGizleToggle === false ? "slideLeftKapaliIcon" : ""}`} inverse/>
                                                        ) : (
                                                            <FontAwesomeIcon icon={faCheck} className={`${beniGizleToggle === true ? "slideRightAcikIcon" : ""}`} inverse/>
                                                        )}
                                                    </div>
                                                    <div style={{width: "65%"}} className="flex justify-center items-center">
                                                        {beniGizleToggle === false ? (
                                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                                 className={`toggleKapaliTextStyle1 tiklanmaEngelle ${beniGizleToggle === false ? "slideLeftKapaliText" : ""}`}>
                                                                {t("kapali")}
                                                            </div>
                                                        ) : (
                                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                                 className={`toggleAcikTextStyle1 tiklanmaEngelle ${beniGizleToggle === true ? "slideRightAcikText" : ""}`}>
                                                                {t("acik")}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*hide_me bitiş*/}

                                    {/*bana bu şehirdekileri göster başlangıç*/}
                                    {/*   <div style={ayarlarDivStyle4} className="flex">
                                    <div style={{width: "65%", height: "100%"}} className="flex justify-start items-center pl-3">
                                        <span className="ayarlarTextStyle11 tiklanmaEngelle">{t("bana_bu_sehirdeki_insanlari_goster")}</span>
                                    </div>

                                    <div style={{width: "35%", height: "100%"}} className="flex justify-end items-center mr-3 cursorPointer">
                                        <div style={{width: "65%", height: "55%", backgroundColor: "#fff", borderRadius: 10, borderWidth: 1}} className={`flex justify-center items-center`}>
                                            <div className={`flex `} style={{width: "100%", height: "100%"}} onClick={() => secilenSehirdeGostertoggleButton(secilenSehirdeGosterToggle === false ? true : false)}>
                                                <div style={{width: "35%"}} className={`flex justify-center items-center ${secilenSehirdeGosterToggle === false ? "toggleKapaliStyle1" : "toggleAcikStyle1"}`}>
                                                    {secilenSehirdeGosterToggle === false ? (
                                                        <FontAwesomeIcon icon={faXmark} className={`${secilenSehirdeGosterToggle === false ? "slideLeftKapaliIcon" : ""}`} inverse/>
                                                    ) : (
                                                        <FontAwesomeIcon icon={faCheck} className={`${secilenSehirdeGosterToggle === true ? "slideRightAcikIcon" : ""}`} inverse/>
                                                    )}
                                                </div>
                                                <div style={{width: "65%"}} className="flex justify-center items-center">
                                                    {secilenSehirdeGosterToggle === false ? (
                                                        <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}} className={`toggleKapaliTextStyle1 tiklanmaEngelle ${secilenSehirdeGosterToggle === false ? "slideLeftKapaliText" : ""}`}>{t('kapali')}</div>
                                                    ) : (
                                                        <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}} className={`toggleAcikTextStyle1 tiklanmaEngelle ${secilenSehirdeGosterToggle === true ? "slideRightAcikText" : ""}`}>{t('acik')}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}
                                    {/*   {secilenSehirdeGosterToggle === true ? (
                                    <div style={{width: "100%", borderBottomWidth: 1, borderTopWidth: 1, backgroundColor: "#fff"}} className={`flex ${cevremdekileriGosterToggle === true ? "slidecevremdekileriGosterTopKapali" : ""}`}>
                                        <CokluSecim handleMultiSelectButton={handleMultiSelectButton} options={options}/>
                                    </div>
                                ) : (null)}*/}
                                    {/*bana bu şehirdekileri göster bitiş*/}

                                    {/*Seçilen ülkelere gizle başlangıç*/}
                                    {/*  <div style={ayarlarDivStyle4} className="flex">
                                        <div style={{width: "100%", height: "100%"}} className="flex justify-start items-center pl-3">

                                        <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                        {t("secili_ulkelere_gizle")}
                                        </span>

                                        </div>
                                    </div>*/}
                                    {/*Seçilen ülkelere gizle bitiş*/}

                                    {/*Yaş Tercihi başlangıç*/}
                                    <div style={ayarlarDivStyle4} className="flex">
                                        <div style={{width: "100%", height: "100%"}} className={`flex flex-column justify-center items-center`}>
                                            <div style={{width: "95%", height: "50%"}} className="flex items-end">
                                                <div className="flex justify-start ml-1 slideTextStyle222 tiklanmaEngelle" style={{width: "50%", textAlign: "start"}}>
                                                    {t("yas_tercihi")}
                                                </div>
                                                <div className="flex justify-end mr-1 slideTextStyle222 tiklanmaEngelle" style={{width: "50%", textAlign: "end"}}>
                                                    {yasTercih[0]} - {yasTercih[1]}
                                                </div>
                                            </div>
                                            <YasTercihi yasTercihiHandleClick={yasTercihiHandleClick} yasTercih={yasTercih}/>
                                        </div>
                                    </div>
                                    {/*Yaş Tercihi bitiş*/}
                                </>
                            )}
                        </>
                    )}


                    <div className="ayarlarTextStyle1122 ml-3 mt-3 mb-3 tiklanmaEngelle">
                        {t("harita_ayarlari")}
                    </div>
                    {/* mevcut konumumu sil başlangıç*/}
                    <div style={ayarlarDivStyle4} className="flex" onClick={() => setModals("konumSilModal")}>
                        <div style={{width: "75%", height: "100%"}} className="flex justify-start items-center pl-3">
                            <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                {t("mevcut_konumumu_sil")}
                            </span>
                        </div>

                        <div style={{width: "25%", height: "100%"}} className="flex justify-end items-center mr-3 cursorPointer">
                            <div style={{width: "65%", height: "55%", backgroundColor: "#fff"}} className={`flex justify-center items-center`}>
                                <div className={`flex justify-center items-center`}
                                    style={{width: "100%", height: "100%"}}>
                                        <div className="flex filterIconStyle justify-content-end align-items-center">
                                            <FontAwesomeIcon icon={faTrash} className="fa-lg" />
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* mevcut konumumu sil bitiş*/}

                    {/*hide_me_map başlangıç*/}
                    <div style={ayarlarDivStyle4} className="flex">
                        <div style={{width: "65%", height: "100%"}} className="flex justify-start items-center pl-3">
                            <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                {t("beni_haritada_gizle")}
                            </span>
                        </div>

                        <div style={{width: "35%", height: "100%"}} className="flex justify-end items-center mr-3 cursorPointer">
                            <div style={{width: "65%", height: "55%", backgroundColor: "#fff", borderRadius: 10, borderWidth: 1}}
                                 className={`flex justify-center items-center`}>
                                <div className={`flex`} style={{width: "100%", height: "100%"}}
                                     onClick={() => beniHaritadaGizleToggleButton()}>
                                    <div style={{width: "35%"}} className={`flex justify-center items-center ${beniHaritadaGizleToggle === false ? "toggleKapaliStyle1" : "toggleAcikStyle1"}`}>
                                        {beniHaritadaGizleToggle === false ? (
                                            <FontAwesomeIcon icon={faXmark} className={`${beniHaritadaGizleToggle === false ? "slideLeftKapaliIcon" : ""}`} inverse/>
                                        ) : (
                                            <FontAwesomeIcon icon={faCheck} className={`${beniHaritadaGizleToggle === true ? "slideRightAcikIcon" : ""}`} inverse/>
                                        )}
                                    </div>
                                    <div style={{width: "65%"}} className="flex justify-center items-center">
                                        {beniHaritadaGizleToggle === false ? (
                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                 className={`toggleKapaliTextStyle1 tiklanmaEngelle ${beniHaritadaGizleToggle === false ? "slideLeftKapaliText" : ""}`}>
                                                {t("kapali")}
                                            </div>
                                        ) : (
                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                 className={`toggleAcikTextStyle1 tiklanmaEngelle ${beniHaritadaGizleToggle === true ? "slideRightAcikText" : ""}`}>
                                                {t("acik")}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*hide_me_map bitiş*/}


                    <div className="ayarlarTextStyle1122 ml-3 mt-3 mb-3 tiklanmaEngelle">
                        {t("dil_ayarlari")}
                    </div>
                    {/*dil değiştir başlangıç*/}
                    <div style={ayarlarDivStyle4} className="flex">
                        <div onClick={() => modalsButton("dilSecAcik")} style={{width: "100%", height: "100%"}}
                             className="flex justify-start items-center pl-3 cursorPointer ayarlarlanguageIconStyle">
                            <div style={{width: "60%"}}>
                                <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                    {t("dil")}
                                </span>
                            </div>
                            <div style={{width: "40%"}} className="flex justify-end items-center pr-3">
                                {languageMap1[selected].kod === "en" ? (
                                    <FontAwesomeIcon icon={faEarthAmerica}/>
                                ) : languageMap1[selected].kod === "tr" ? (
                                    <FontAwesomeIcon icon={faEarthAsia}/>
                                ) : null}
                                <span className="ml-2 languageSelectDropDownText1 tiklanmaEngelle">
                                    {languageMap1[selected]?.label}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/*dil değiştir bitiş*/}

                    <div className="ayarlarTextStyle1122 ml-3 mt-3 mb-3 tiklanmaEngelle">
                        {t("guvenlik")}
                    </div>
                    {/*topluluk kurallarıbaşlangıç*/}
                    <div style={ayarlarDivStyle4} className="flex">
                        <div onClick={() => modalsButton("toplulukKurallariModal", 1, 4)}
                             style={{width: "100%", height: "100%"}}
                             className="flex justify-start items-center pl-3 cursorPointer ayarlarLinkIconStyle">
                            <div style={{width: "70%"}}>
                                <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                    {t("topluluk_kurallari")}
                                </span>
                            </div>
                            <div style={{width: "30%"}} className="flex justify-end pr-3">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                            </div>
                        </div>
                    </div>
                    {/*topluluk kuralları bitiş*/}

                    {/*güvenlik ipuclari başlangıç*/}
                    <div style={ayarlarDivStyle4} className="flex">
                        <div onClick={() => modalsButton("guvenlikIpuclariModal", 1, 5)}
                             style={{width: "100%", height: "100%"}}
                             className="flex justify-start items-center pl-3 cursorPointer ayarlarLinkIconStyle">
                            <div style={{width: "70%"}}>
                                <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                    {t("guvenlik_ipuclari")}
                                </span>
                            </div>
                            <div style={{width: "30%"}} className="flex justify-end pr-3">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                            </div>
                        </div>
                    </div>
                    {/*güvenlik ipuclari bitiş*/}

                    {/*engellenenler başlangıç*/}
                    {!token ? null : (
                        <div style={ayarlarDivStyle4} className="flex">
                            <div onClick={() => modalsButton("engellenenlerModal")}
                                 style={{width: "100%", height: "100%"}}
                                 className="flex justify-start items-center pl-3 cursorPointer ayarlarLinkIconStyle">
                                <div style={{width: "70%"}}>
                                    <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                        {t("engellenenler")}
                                    </span>
                                </div>
                                <div style={{width: "30%"}} className="flex justify-end pr-3">
                                    <FontAwesomeIcon icon={faAngleRight}/>
                                </div>
                            </div>
                        </div>
                    )}
                    {/*engellenenler bitiş*/}

                    <div className="ayarlarTextStyle1122 ml-3 mt-3 mb-3 tiklanmaEngelle">
                        {t("yasal")}
                    </div>

                    {/*kullanim koşulları başlangıç*/}
                    <div style={ayarlarDivStyle4} className="flex">
                        <div onClick={() => modalsButton("kullanimKosullariModal", 1, 2)}
                             style={{width: "100%", height: "100%"}}
                             className="flex justify-start items-center pl-3 cursorPointer ayarlarLinkIconStyle">
                            <div style={{width: "70%"}}>
                                <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                    {t("kullanim_kosullari")}
                                </span>
                            </div>
                            <div style={{width: "30%"}} className="flex justify-end pr-3">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                            </div>
                        </div>
                    </div>
                    {/*kullanim koşulları bitiş*/}

                    {/*Kişisel Verilerin Yurt Dışına Aktarılmasına İlişkin Açık Rıza başlangıç*/}
                    <div style={ayarlarDivStyle4} className="flex">
                        <div onClick={() => modalsButton("verilerinAktarilmasiIcinAcikRizaModalId", 1, 8)}
                             style={{width: "100%", height: "100%"}}
                             className="flex justify-start items-center pl-3 cursorPointer ayarlarLinkIconStyle">
                            <div style={{width: "70%"}}>
                                <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                    {t("kisisel_verilerin_yurt_disina_aktarilmasina_iliskin_acik_riza")}
                                </span>
                            </div>
                            <div style={{width: "30%"}} className="flex justify-end pr-3">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                            </div>
                        </div>
                    </div>
                    {/*Kişisel Verilerin Yurt Dışına Aktarılmasına İlişkin Açık Rıza bitiş*/}

                    {/*çerez politikası başlangıç*/}
                    <div style={ayarlarDivStyle4} className="flex">
                        <div onClick={() => modalsButton("cerezPolitikasiModal", 1, 3)}
                             style={{width: "100%", height: "100%"}}
                             className="flex justify-start items-center pl-3 cursorPointer ayarlarLinkIconStyle">
                            <div style={{width: "70%"}}>
                                <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                    {t("cerez_politikasi")}
                                </span>
                            </div>
                            <div style={{width: "30%"}} className="flex justify-end pr-3">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                            </div>
                        </div>
                    </div>
                    {/*çerez politikası bitiş*/}

                    {/*gizlilik politikası başlangıç*/}
                    <div style={ayarlarDivStyle4} className="flex">
                        <div onClick={() => modalsButton("gizlilikPolitikasiModal", 1, 1)}
                             style={{width: "100%", height: "100%"}}
                             className="flex justify-start items-center pl-3 cursorPointer ayarlarLinkIconStyle">
                            <div style={{width: "70%"}}>
                                <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                    {t("gizlilik_politikasi")}
                                </span>
                            </div>
                            <div style={{width: "30%"}} className="flex justify-end pr-3">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                            </div>
                        </div>
                    </div>
                    {/*gizlilik politikası bitiş*/}

                    {/* <div className="ayarlarTextStyle1122 ml-3 mt-3 mb-3 tiklanmaEngelle">Destek Merkezi</div>

                        destek
                      <div style={ayarlarDivStyle4} className="flex">
                            <div onClick={() => modalsButton("destekModal")}
                                 style={{width: "100%", height: "100%"}}
                                 className="flex justify-start items-center pl-3 cursorPointer ayarlarLinkIconStyle">
                                <div style={{width: "70%"}}>
                                    <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                        Destek Merkezi
                                    </span>
                                </div>
                                <div style={{width: "30%"}} className="flex justify-end pr-3">
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                </div>
                            </div>
                        </div>*/}

                    {/*hesap ayarları başlangıç*/}
                    <div className="ayarlarTextStyle1122 ml-3 mt-3 mb-3 tiklanmaEngelle">
                        {t("hesap_ayarlari")}
                    </div>

                    <div onClick={() => hesapSil()} style={ayarlarDivStyle4} className="flex">
                        <div style={{width: "100%", height: "100%"}}
                             className="flex justify-start items-center pl-3 cursorPointer ayarlarLinkIconStyle">
                            <div style={{width: "70%"}}>
                                <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                    {t("hesabimi_sil")}
                                </span>
                            </div>
                            <div style={{width: "30%"}} className="flex justify-end pr-3">
                                <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                            </div>
                        </div>
                    </div>
                    {/*hesap ayarları bitiş*/}

                    {/*beni destekle başlangıç*/}
                    {/*<div className="ayarlarTextStyle1122 ml-3 mt-3 mb-3 tiklanmaEngelle">Beni Destekle</div>*/}

                    {/*<div style={ayarlarDivStyle4} className="flex">*/}
                    {/*        <div onClick={() => modalsButton("beniDestekleModal")}*/}
                    {/*             style={{width: "100%", height: "100%"}}*/}
                    {/*             className="flex justify-start items-center pl-3 cursorPointer ayarlarLinkIconStyle">*/}
                    {/*            <div style={{width: "70%"}}>*/}
                    {/*                <span className="ayarlarTextStyle11 tiklanmaEngelle">*/}
                    {/*                    Beni Destekle*/}
                    {/*                </span>*/}
                    {/*            </div>*/}
                    {/*            <div style={{width: "30%"}} className="flex justify-end pr-3">*/}
                    {/*                <FontAwesomeIcon icon={faAngleRight}/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*beni destekle bitiş*/}

                    {/*çıkış yap başlangıç*/}
                    <div style={ayarlarDivStyle4} className="flex mt-3 mb-3">
                        <div style={{width: "100%", height: "100%"}}
                             className="flex justify-center items-center cursorPointer" onClick={signOut}>
                            <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                {t("cikis_yap")}
                            </span>
                        </div>
                    </div>
                    {/*çıkış yap bitiş*/}
                </div>
            </div>

            {modals === "toplulukKurallariModal" && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column align-items-center justify-content-center" style={modalsStyle2}>
                        <div style={modalHeaderDivStyle} className="flex justify-center items-center boxShadowTop">
                            <div className="flex justify-content-center align-items-center" style={{width: "15%", height: "100%"}}></div>
                            <div className="flex justify-content-center align-items-center" style={{width: "70%", height: "100%"}}>
                                <span className="ayarlarTextStyle01 tiklanmaEngelle">
                                    {t("topluluk_kurallari")}
                                </span>
                            </div>
                            {/* modal kapat tuşu*/}
                            <div style={{width: "15%", height: "100%"}} className="flex justify-content-center align-items-center">
                                <div onClick={() => modalClose("modalKapali")} className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}} className="flex flex-column active-scroll ">
                            <Sozlesme categoryId={categoryId} pageId={pageId}/>
                        </div>
                    </div>
                </div>
            )}

            {modals === "guvenlikIpuclariModal" && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column align-items-center justify-content-center" style={modalsStyle2}>
                        <div style={modalHeaderDivStyle} className="flex justify-center items-center boxShadowTop">
                            <div className="flex justify-content-center align-items-center" style={{width: "15%", height: "100%"}}></div>
                            <div className="flex justify-content-center align-items-center" style={{width: "70%", height: "100%"}}>
                                <span className="ayarlarTextStyle01 tiklanmaEngelle">
                                    {t("guvenlik_ipuclari")}
                                </span>
                            </div>
                            {/* modal kapat tuşu*/}
                            <div style={{width: "15%", height: "100%"}} className="flex justify-content-center align-items-center">
                                <div onClick={() => modalClose("modalKapali")} className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}} className="flex flex-column active-scroll ">
                            <Sozlesme categoryId={categoryId} pageId={pageId}/>
                        </div>
                    </div>
                </div>
            )}

            {modals === "engellenenlerModal" && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column align-items-center justify-content-center" style={modalsStyle2}>
                        <div style={modalHeaderDivStyle} className="flex justify-center items-center boxShadowTop">
                            <div className="flex justify-content-center align-items-center" style={{width: "15%", height: "100%"}}></div>
                            <div className="flex justify-content-center align-items-center" style={{width: "70%", height: "100%"}}>
                                <span className="ayarlarTextStyle01 tiklanmaEngelle">
                                    {t("engellenenler")}
                                </span>
                            </div>
                            {/* modal kapat tuşu*/}
                            <div style={{width: "15%", height: "100%"}} className="flex justify-content-center align-items-center">
                                <div onClick={() => modalClose("modalKapali")} className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}} className="flex flex-column active-scroll ">
                            <EngellenenlerListesi/>
                        </div>
                    </div>
                </div>
            )}

            {modals === "kullanimKosullariModal" && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column align-items-center justify-content-center" style={modalsStyle2}>
                        <div style={modalHeaderDivStyle} className="flex justify-center items-center boxShadowTop">
                            <div className="flex justify-content-center align-items-center" style={{width: "15%", height: "100%"}}></div>
                            <div className="flex justify-content-center align-items-center" style={{width: "70%", height: "100%"}}>
                                <span className="ayarlarTextStyle01 tiklanmaEngelle">
                                    {t("kullanim_kosullari")}
                                </span>
                            </div>
                            {/* modal kapat tuşu*/}
                            <div style={{width: "15%", height: "100%"}} className="flex justify-content-center align-items-center">
                                <div onClick={() => modalClose("modalKapali")} className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}} className="flex flex-column active-scroll">
                            <Sozlesme categoryId={categoryId} pageId={pageId}/>
                        </div>
                    </div>
                </div>
            )}

            {modals === "verilerinAktarilmasiIcinAcikRizaModalId" && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column align-items-center justify-content-center" style={modalsStyle2}>
                        <div style={modalHeaderDivStyle} className="flex justify-center items-center boxShadowTop">
                            <div className="flex justify-content-center align-items-center" style={{width: "15%", height: "100%"}}></div>
                            <div className="flex justify-content-center align-items-center" style={{width: "70%", height: "100%"}}>
                                <span className="ayarlarTextStyle01 tiklanmaEngelle">
                                    {t("kisisel_verilerin_yurt_disina_aktarilmasina_iliskin_acik_riza")}
                                </span>
                            </div>
                            {/* modal kapat tuşu*/}
                            <div style={{width: "15%", height: "100%"}} className="flex justify-content-center align-items-center">
                                <div onClick={() => modalClose("modalKapali")} className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}} className="flex flex-column active-scroll ">
                            <Sozlesme categoryId={categoryId} pageId={pageId}/>
                        </div>
                    </div>
                </div>
            )}

            {modals === "cerezPolitikasiModal" && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column align-items-center justify-content-center" style={modalsStyle2}>
                        <div style={modalHeaderDivStyle} className="flex justify-center items-center boxShadowTop">
                            <div className="flex justify-content-center align-items-center" style={{width: "15%", height: "100%"}}></div>
                            <div className="flex justify-content-center align-items-center" style={{width: "70%", height: "100%"}}>
                                <span className="ayarlarTextStyle01 tiklanmaEngelle">
                                    {t("cerez_politikasi")}
                                </span>
                            </div>
                            {/* modal kapat tuşu*/}
                            <div style={{width: "15%", height: "100%"}} className="flex justify-content-center align-items-center">
                                <div onClick={() => modalClose("modalKapali")} className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}} className="flex flex-column active-scroll ">
                            <Sozlesme categoryId={categoryId} pageId={pageId}/>
                        </div>
                    </div>
                </div>
            )}

            {modals === "gizlilikPolitikasiModal" && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column align-items-center justify-content-center" style={modalsStyle2}>
                        <div style={modalHeaderDivStyle} className="flex justify-center items-center boxShadowTop">
                            <div className="flex justify-content-center align-items-center" style={{width: "15%", height: "100%"}}></div>
                            <div className="flex justify-content-center align-items-center" style={{width: "70%", height: "100%"}}>
                                <span className="ayarlarTextStyle01 tiklanmaEngelle">
                                    {t("gizlilik_politikasi")}
                                </span>
                            </div>
                            {/* modal kapat tuşu*/}
                            <div style={{width: "15%", height: "100%"}} className="flex justify-content-center align-items-center">
                                <div onClick={() => modalClose("modalKapali")} className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}} className="flex flex-column active-scroll ">
                            <Sozlesme categoryId={categoryId} pageId={pageId}/>
                        </div>
                    </div>
                </div>
            )}

            {modals === "dilSecAcik" && (
                <div style={{
                    width: isMobile ? width : width <= 475 ? width : 425,
                    height: isMobile ? height : height <= 795 ? height : 788,
                    position: "absolute",
                    zIndex: 55,
                    borderWidth: 1,
                    backgroundColor: "#000000a1",
                    borderRadius: isMobile ? 0 : 8,
                }} className="flex justify-content-center align-items-center">
                    <div style={{
                        width: isMobile ? "95%" : width < 350 ? width : "90%",
                        height: isMobile ? "85%" : height < 370 ? height : 500,
                        backgroundColor: "#ffffff",
                        borderRadius: 8,
                    }} className="flex-column justify-content-center align-items-center  active-scroll">
                        {/*modal kapat tuşu*/}
                        <div style={{width: "100%", height: 48}} className="flex justify-content-end align-items-end pr-5">
                            <div onClick={() => modalClose("modalKapat")} className="flex box justify-content-center align-items-center">
                                <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                            </div>
                        </div>
                        {/*baslik*/}
                        <div className="flex-row justify-center items-center" style={{width: "100%", height: 70}}>
                            <div style={{width: "100%", height: "100%"}} className="flex justify-content-center align-items-center">
                                <span className=" logoTextStyle1 tiklanmaEngelle">MOZARED</span>
                            </div>
                        </div>
                        {/*secili olan dil*/}
                        <div className="flex justify-content-center" style={{width: "100%", height: 70}}>
                            <div style={{
                                width: "90%",
                                height: "100%",
                                borderBottomColor: "#ddd",
                                borderBottomWidth: 2,
                            }}>
                                <div className="languageSelectDropDownText3 tiklanmaEngelle">
                                    {t("dil_sec")}
                                </div>
                                <div className="languageSelectDropDownText4 tiklanmaEngelle">
                                    {languageMap1[selected].label}
                                </div>
                            </div>
                        </div>
                        {/*dil sec*/}
                        <div className="flex justify-content-center" style={{width: "100%"}}>
                            <div className="grid gap-2 grid-cols-2 my-3" style={{width: "90%", height: 40}}>
                                {Object.values(languageMap1)
                                    .filter((item) => item?.showMenu)
                                    .map((item) => (
                                        <React.Fragment key={item?.id}>
                                            <div className={`flex justify-center items-center cursorPointer pt-2 pb-2 tiklanmaEngelle ${
                                                item.kod === languageMap1[selected].kod
                                                    ? "languageSelectDropDownText22"
                                                    : "languageSelectDropDownText2"
                                            }`} onClick={() => {
                                                i18next.changeLanguage(item.kod);
                                                setMenuAnchor(null);
                                            }}>
                                                {item.label}
                                            </div>
                                        </React.Fragment>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {modals === "konumSilModal" && (
                <div style={{
                    width: isMobile ? width : width <= 475 ? width : 425,
                    height: isMobile ? height : height <= 795 ? height : 788,
                    position: "absolute",
                    zIndex: 55,
                    borderWidth: 1,
                    backgroundColor: "#000000a1",
                    borderRadius: isMobile ? 0 : 8,
                }} className="flex justify-content-center align-items-center">
                    <div style={{
                        width: isMobile ? "100%" : width < 350 ? width : "90%",
                        height: isMobile ? "100%" : height < 370 ? height : "90%",
                        backgroundColor: "#ffffff",
                        borderRadius: isMobile ? 0 : 8,
                    }} className="flex-column justify-content-center align-items-center active-scroll">
                        {/*modal kapat tuşu*/}
                        <div style={{width: "100%", height: 48}} className="flex justify-content-end align-items-end pr-5">
                            <div onClick={() => modalClose("modalKapat")} className="flex box justify-content-center align-items-center">
                                <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                            </div>
                        </div>
                        {/*mozared*/}
                        <div className="flex-row justify-center items-center" style={{width: "100%", height: 70}}>
                            <div style={{width: "100%", height: "100%"}}
                                className="flex justify-content-center align-items-center">
                                <span className=" logoTextStyle1 tiklanmaEngelle">MOZARED</span>
                            </div>
                        </div>
                        {/*başlık*/}
                        <div className="flex justify-content-center align-items-center"
                            style={{width: "100%", height: 80}}>
                            <div style={{
                                    width: "90%",
                                    height: "100%",
                                    borderBottomColor: "#ddd",
                                    borderBottomWidth: 2,
                                }}>
                                <div style={{width: "100%"}} className="flex flex-column justify-content-center align-items-center">
                                    <span className="languageSelectDropDownText4 tiklanmaEngelle mb-1">
                                        {t("uyari")}
                                    </span>
                                    <span className="languageSelectDropDownText3 tiklanmaEngelle text-center">
                                        {t("konumunuz_silinecektir")}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/*bilgilendirme ve konum sil butonu*/}
                        <div className="flex flex-column justify-content-center align-items-center"
                            style={{width: "100%", height: "calc(100% - 188px)"}}>
                            <div style={{width: "90%", height: "100%"}}>
                                <div style={{borderBottomColor: "#ddd", borderBottomWidth: 2}}
                                    className="flex justify-content-center align-items-center py-1">
                                    <div className="mx-2">
                                        <FontAwesomeIcon
                                            icon={faLocationDot}
                                            className="fa-lg mr-2"
                                            style={{color: "#21262E", opacity: 0.7}}
                                        />
                                    </div>
                                    <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                        {t("konum_silme_islemi_uyari_1")}
                                    </span>
                                </div>

                                <div style={{borderBottomColor: "#ddd", borderBottomWidth: 2}}
                                    className="flex justify-content-center align-items-center py-1">
                                    <div className="mx-2">
                                        <FontAwesomeIcon
                                            icon={faMobileButton}
                                            className="fa-lg mr-2"
                                            style={{color: "#21262E", opacity: 0.7}}
                                        />
                                    </div>

                                    <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                        {t("konum_silme_islemi_uyari_2")}
                                    </span>
                                </div>

                                <div style={{borderBottomColor: "#ddd", borderBottomWidth: 2}}
                                    className="flex justify-content-center align-items-center py-1">
                                    <div className="mx-2">
                                        <FontAwesomeIcon
                                            icon={faDesktop}
                                            className="fa-lg mr-2"
                                            style={{color: "#21262E", opacity: 0.7}}
                                        />
                                    </div>

                                    <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                        {t("konum_silme_islemi_uyari_3")}
                                    </span>
                                </div>

                                <div style={{borderBottomColor: "#ddd", borderBottomWidth: 2}}
                                    className="flex justify-content-center align-items-center py-1">
                                    <div className="mx-2">
                                        <FontAwesomeIcon
                                            icon={faGear}
                                            className="fa-lg mr-2"
                                            style={{color: "#21262E", opacity: 0.7}}
                                        />
                                    </div>

                                    <span className="ayarlarTextStyle11 tiklanmaEngelle">
                                        {t("konum_silme_islemi_uyari_4")}
                                    </span>
                                </div>

                                <div style={{width: "100%", height: 90}}
                                    className="flex justify-content-center align-items-center mt-2">
                                    <div onClick={() => konumSilIslem()}
                                        style={{width: "100%", height: 43}}
                                        className="flex justify-center items-center etkinlikOlusturButtonStyle cursorPointer">
                                        <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
                                            {t("mevcut_konumumu_sil")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {modals === "beniDestekleModal" && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column align-items-center justify-content-center"
                        style={modalsStyle2}>
                        <div style={modalHeaderDivStyle}
                            className="flex justify-center items-center boxShadowTop">
                            <div className="flex justify-content-center align-items-center"
                                style={{width: "15%", height: "100%"}}>

                            </div>
                            <div className="flex justify-content-center align-items-center"
                                style={{width: "70%", height: "100%"}}>
                                <span className="ayarlarTextStyle01 tiklanmaEngelle">
                                    Beni Destekle
                                </span>
                            </div>
                            {/* modal kapat tuşu*/}
                            <div style={{width: "15%", height: "100%"}}
                                className="flex justify-content-center align-items-center">
                                <div onClick={() => modalClose("modalKapali")}
                                    className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}}
                            className="flex flex-column active-scroll ">
                            <BeniDestekleModal/>
                        </div>
                    </div>
                </div>
            )}

            {/*  {modals === "destekModal" && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column align-items-center justify-content-center " style={modalsStyle2}>
                        <div style={{width: "100%", height: 51, backgroundColor: "#fff"}}
                             className="flex justify-center items-center">
                            <div className="flex justify-content-center align-items-center"
                                 style={{width: "15%", height: "100%"}}>

                            </div>
                            <div className="flex justify-content-center align-items-center"
                                 style={{width: "70%", height: "100%"}}>
                                <span className="ayarlarTextStyle01 tiklanmaEngelle">Destek Merkezi</span>
                            </div>
                             modal kapat tuşu
                            <div style={{width: "15%", height: "100%"}}
                                 className="flex justify-content-center align-items-center">
                                <div onClick={() => modalClose("modalKapali")}
                                     className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}} className="flex flex-column active-scroll ">
                            <Destek />
                        </div>
                    </div>
                </div>
            )}*/}
        </div>
    );
}

export default connect(null, {cikisEylemiAction})(Ayarlar);
