import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import reportWebVitals from './reportWebVitals';
import "./i18nextInit";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './styles/main.css';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://bd085022ca4a420c93fcbf35bc5623fc@o4505499577614336.ingest.sentry.io/4505499579908096",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", "https:mozared.com"],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const lang = localStorage.getItem("i18nextLng") || "en";

function RootComponent() {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const devicePlatform = urlParams.get('devicePlatform');
        const deviceId = urlParams.get('device_id');
        const token = urlParams.get('token'); // OneSignal Player ID

        if(devicePlatform) {
            localStorage.setItem('devicePlatform', devicePlatform);
        }
        if (deviceId) {
            localStorage.setItem('deviceId', deviceId);
        }
        if (token) {
            localStorage.setItem('oneSignalToken', token);
        }
    }, []);

    return (
        <BrowserRouter>
            <ToastContainer
                position="top-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"/>

            <div lang={lang} className="flex justify-content-center align-items-center disable-scroll zort"
                 style={{backgroundColor: "#dcd1d11c"}}>
                <App/>
            </div>
        </BrowserRouter>
    );
}


root.render(<RootComponent />);

reportWebVitals();
