import React from "react";

import {useTranslation} from "react-i18next";


export default function BeniDestekleModal() {
    const {t} = useTranslation();

    return (
        <div style={{width: "100%", height: "100%"}} className="active-scroll">

        </div>
    );
};
