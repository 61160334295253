// reducer/Etkinlik/etkinlikKatilimIstekleriReducer.js

import {
    ETKINLIK_KATILIM_ISTEKLERI_REQUEST,
    ETKINLIK_KATILIM_ISTEKLERI_SUCCESS,
    ETKINLIK_KABUL_VE_RED_BUTTON,
    ETKINLIGE_KABUL_ET_EYLEM_SUCCESS,
    ETKINLIGE_REDDET_EYLEM_SUCCESS,
    ETKINLIK_KATILIM_ISTEKLERI_DATA_CLEAR
} from '../../types';

const initialState = {
    loading: false,
    etkinlikKatilimIstekleriData: [],
    message: null,
    error: ''
}

const etkinlikKatilimIstekleriReducer = (state = initialState, action) => {
    switch (action.type) {
        case ETKINLIK_KATILIM_ISTEKLERI_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ETKINLIK_KATILIM_ISTEKLERI_SUCCESS:
            return {
                loading: false,
                etkinlikKatilimIstekleriData: action.payload,
                error: ''
            }
        case ETKINLIK_KABUL_VE_RED_BUTTON:
            return {
                ...state,
                etkinlikKatilimIstekleriData: action.payload
            };
        case ETKINLIGE_KABUL_ET_EYLEM_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: ''
            }
        case ETKINLIGE_REDDET_EYLEM_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: ''
            }
        case ETKINLIK_KATILIM_ISTEKLERI_DATA_CLEAR:
            return {
                ...state,
                etkinlikKatilimIstekleriData: []
            }
        default: return state
    }
}

export default etkinlikKatilimIstekleriReducer;
