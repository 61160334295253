import React, {useEffect, useState} from "react";
import "../../styles/main.css";
import {isMobile} from "react-device-detect";
import {useLocation, useNavigate} from "react-router-dom";
import ImageUploading from "react-images-uploading";
import {useTranslation} from "react-i18next";

const maxNumber = 3;
function MedyaEkle() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    let location = useLocation();
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [images, setImages] = React.useState([]);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const ayarlarDivStyle = {
        width: isMobile ? width : width <= 475 ? width : 425,
        height: isMobile ? height : height <= 795 ? height : 788,
        borderWidth: 1,
        borderRadius: isMobile ? 0 : 8,
    }
    const ayarlarDivStyle1 = {
        width: "100%",
        height: "100%",
        position: "relative",
        zIndex: 50
    }
    const ayarlarDivStyle2 = {
        width: "100%",
        height: "5%",
        borderBottomWidth: 1
    }
    const ayarlarDivStyle3 = {
        width: "100%",
        height: "95%",
        backgroundColor: "#f0f2f4"
    }

    const TamamButonu = () => {
        navigate(`/AnaSayfa${location.search}`, {state:{id:"profilSayfalariGeriId", name:"ProfilSayfalariGeri"}})
    }

    return (
        <div className="flex justify-center items-center" style={ayarlarDivStyle}>
            <div style={ayarlarDivStyle1} className="flex-column">
                <div className="flex" style={ayarlarDivStyle2}>
                    <div style={{height: "100%", width: "25%"}}>

                    </div>
                    <div className={`flex justify-center items-center tiklanmaEngelle ${isMobile ? "profilDuzenleHeaderTextStyle01" : width <= 500 || height <= 550 ? "profilDuzenleHeaderTextStyle01" : "profilDuzenleHeaderTextStyle02"}`} style={{height: "100%", width: "50%"}}>
                        {t("medya_ekle")}
                    </div>
                    <div onClick={() => TamamButonu()} className="flex justify-end items-center mr-3 buttonTextStyle1111 cursorPointer" style={{height: "100%", width: "25%"}}>
                        <span className="profilDuzenleHeaderTextStyle03 tiklanmaEngelle">{t("tamam")}</span>
                    </div>
                </div>

                <div style={ayarlarDivStyle3} className="active-scroll">
                    <div style={{width: "100%", height: "40%"}}>
                        <ImageUploading
                            multiple
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url">
                            {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                              }) => (
                                <div className={`${images.length <= 0 ? "flex" : imageList.length === 1  ? "flex justify-start items-center pt-3 pb-3 pl-3" : imageList.length >= 2 ? "flex justify-center items-center pt-3 pb-3" : "flex"}`} style={{width: "100%", height: "100%"}}>
                                    {imageList.map((image, index) => (
                                        <div style={{width: "33%", height: "100%"}} className={`flex mr-2 ml-2`} key={index}>
                                            <div className="profilFotoEkle222" style={{position: "relative", width: "100%", height: "100%"}}>
                                                <img className="profilFotoEkle333 tiklanmaEngelle" src={image['data_url']} alt=""/>
                                            </div>
                                            <div className="flex justify-end items-end" style={{position: "relative", top: 8, right: 15, marginRight: -20}}>
                                                <div onClick={() => onImageRemove(index)} className="hesapOlusturFotoEkleDiv11 flex justify-center items-center">
                                                    <span className="hesapOlusturFotoEkleTextStyle11 tiklanmaEngelle">x</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {imageList.length <= 0 ? (
                                        <div style={{width: imageList.length <= 0 ? "40%" : "33%", height: "100%"}} className="flex justify-center pt-3 pl-3">
                                            <button className="profilFotoEkle111 flex items-end justify-end" type="button"
                                                    onClick={onImageUpload} {...dragProps}>
                                                <div className="hesapOlusturFotoEkleDiv11 flex justify-center items-center"
                                                     style={{position: "relative", left: 8, top: 8}}>
                                                    <span className="hesapOlusturFotoEkleTextStyle11 tiklanmaEngelle">+</span>
                                                </div>
                                            </button>
                                        </div>
                                    ) : imageList.length >= 3 ? null : (
                                        <div style={{width: "33%", height: "100%"}} className="flex justify-center mr-2 ml-2">
                                            <button className="profilFotoEkle111 flex justify-end items-end" type="button"
                                                    onClick={onImageUpload} {...dragProps}>
                                                <div className="hesapOlusturFotoEkleDiv11 flex justify-center items-center"
                                                     style={{position: "relative", left: 8, top: 8}}>
                                                    <span className="hesapOlusturFotoEkleTextStyle11 tiklanmaEngelle">+</span>
                                                </div>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </ImageUploading>
                    </div>
                    <div style={{width: "100%", height: "60%"}}>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MedyaEkle;
