import { toast, Slide } from "react-toastify";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader?.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function generateTree(list, parent_id = null) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent_id !== parent_id) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parent_id]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export function getTimeSince(date) {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " Yıl önce";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " Ay önce";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " Gün önce";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " Saat önce";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " Dakika önce";
  }

  return Math.floor(seconds === 0 ? 1 : seconds) + " saniye önce";
}

export function getTokensWithDefault() {
  let tokens = localStorage.getItem("tokens");

  if (tokens) {
    try {
      tokens = JSON.parse(tokens);

      if (Array.isArray(tokens)) {
        const defaultToken = tokens.find((x) => x.default);

        if (defaultToken !== undefined) {
          return [true, tokens, defaultToken];
        }
      }
    } catch (err) {
      localStorage.removeItem("tokens");
    }
  }

  return [false];
}

export function getCurrentToken() {
  return localStorage.getItem("token");
}

export function getLocale() {
  let country = localStorage.getItem("country_id");
  let language = localStorage.getItem("language_id");
  let currency = localStorage.getItem("currency_id");

  return {
    currency_id: currency,
    country_id: country,
    language_id: language,
  };
}

export function getImagePath(image, shop_id) {
  return `${process.env.REACT_APP_CDN_LINK}${shop_id}/${image}`;
}

let activeToasts = [];
let toastQueue = [];
export function showToast(messages) {
  messages.forEach((item) => {
    let message = i18n.t(item.message);
    let type = item.type;
    let icon = null;

    if (item.type === "message") {
      type = "info";
      icon = (
        <FontAwesomeIcon
          icon={faEnvelope}
          className="fa-lg"
          style={{
            color: "#EB3428",
            opacity: 0.7,
          }}
        />
      );
    }

    const showNewToast = () => {
      if (activeToasts.length < 2) {
        const id = toast[type](message, {
          autoClose: item.second * 1000,
          onClose: () => {
            activeToasts = activeToasts.filter((toastId) => toastId !== id);
            if (toastQueue.length > 0) {
              const nextToast = toastQueue.shift();
              nextToast();
            }
          },
          className: "custom-toast", // Custom class for styling
          bodyClassName: "custom-toast-body", // Custom body class for styling
          closeButton: false,
          transition: Slide,
          icon: icon,
          progressStyle: {
            background: "linear-gradient(45deg, #ff0009, #EB3428)",
          },
        });
        activeToasts.push(id);
      } else {
        toastQueue.push(showNewToast);
      }
    };

    showNewToast();
  });
  return false;
}

export function profileLogo(first_name, last_name = null, userColor = null) {
  // Resim haline gelecek dikdörtgen bir tuval oluşturun.
  var initials = first_name[0] + (last_name && last_name.length > 0 ? last_name[0] : "");
  var randomColor = userColor;

  // Resim haline gelecek dikdörtgen bir tuval oluşturun.
  var canvas = document.createElement("canvas");
  var context = canvas.getContext("2d");
  canvas.width = canvas.height = 100;

  // Arka plandaki daireyi beyaz renkle (veya istediğiniz herhangi bir arka plan rengiyle) çizin.
  context.fillStyle = "#e7e8ec"; // Arkaplan rengini beyaz olarak ayarlıyoruz
  context.beginPath();
  context.ellipse(
      canvas.width / 2,
      canvas.height / 2, // x ve y'yi ortala.
      canvas.width / 2,
      canvas.height / 2,// Yatay ve dikey "yarıçap".
      0, // Rotation, useless for perfect circle.
      0,
      Math.PI * 2 // açı ve açı: Radyan cinsinden tam daire.
  );
  context.fill();

  // Metnin yazı tipi boyutunu ve stilini ayarlayın
  context.font = canvas.height / 3 + "px monospace";

  // Metin rengini rastgele bir renge ayarlayın
  context.fillStyle = randomColor;

  // Metni tuvalin ortasına gelecek şekilde hizalayın
  context.textAlign = "center";
  context.textBaseline = "middle";

  // Tuval üzerine baş harfleri çiziyoruz
  context.fillText(initials.toUpperCase(), canvas.width / 2, canvas.height / 2);

  // Resmi veri URL'si olarak döndür
  return canvas.toDataURL();
}
