import i18n from 'i18next';

export const datas = () => {

    return {
        evcilHayvanlarData: {
            "catP": {value: 'catP', label: i18n.t("kedi")},
            "dogP": {value: 'dogP', label: i18n.t("kopek")},
            "reptileP": {value: 'reptileP', label: i18n.t("surungen")},
            "amphibiousP": {value: 'amphibiousP', label: i18n.t("amfibi")},
            "theFishP": {value: 'theFishP', label: i18n.t("balikHayvan")},
            "iDontLikeP": {value: 'iDontLikeP', label: i18n.t("sevmiyorum")},
            "allPetsP": {value: 'allPetsP', label: i18n.t("tum_evcil_hayvanlar")}
        },
        sigaraKullanimiData: {
            "social_smoker": {value: 'social_smoker', label: i18n.t("sosyal_icici")},
            "with_the_drink": {value: 'with_the_drink', label: i18n.t("icki_ile")},
            "i_dont_use": {value: 'i_dont_use', label: i18n.t("kullanmiyorum")},
            "smoker": {value: 'smoker', label: i18n.t("sigara_icen")}
        },
        cinsiyetData: {
            "male": {value: 'male', label: i18n.t("erkek")},
            "female": {value: 'female', label: i18n.t("kadin")},
            "unspecified": {value: "unspecified", label: i18n.t("belirtilmemis")}
        },
        cinselYonelimData: {
            "heterosexual": {value: 'heterosexual', label: i18n.t("heteroseksuel")},
            "bisexual": {value: 'bisexual', label: i18n.t("biseksuel")},
            "homosexual": {value: 'homosexual', label: i18n.t("homoseksuel")},
            /*"unspecified": {value: "unspecified", label: i18n.t("belirtilmemis")}*/

        },
        ilgiAlanlariData: [
            {value: 'men', label: i18n.t("erkekler")},
            {value: 'women', label: i18n.t("kadinlar")},
            {value: 'computerGames', label: i18n.t("bilgisayar_oyunlari")},
            {value: 'football', label: i18n.t("fubol")},
            {value: 'basketball', label: i18n.t("basketbol")},
            {value: 'volleyball', label: i18n.t("voleybol")},
            {value: 'phoneGames', label: i18n.t("telefon_oyunlari")},
            {value: 'bodySports', label: i18n.t("vucut_sporlari")},
            {value: 'fashionDesign', label: i18n.t("moda_tasarimi")},
            {value: 'makeUp', label: i18n.t("makyaj_yapmak")},
            {value: 'computerProgramming', label: i18n.t("bilgisayar_proglamlama")},
            {value: 'astrology', label: i18n.t("astroloji")},
            {value: 'riding', label: i18n.t("binicilik")},
            {value: 'drawing', label: i18n.t("resim_cizme")},
            {value: 'motorCycle', label: i18n.t("motosiklet")},
            {value: 'metaverse', label: i18n.t("sanal_evren")},
            {value: 'bourse', label: i18n.t("borsa")},
            {value: 'theatre', label: i18n.t("tiyatro")},
        ]
    }
}
