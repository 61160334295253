// actions/Etkinlik/etkinlikKatilimIstekleriAction.js

import axios from "../../../../utils/CustomAxios";
import {
    ETKINLIK_KATILIM_ISTEKLERI_REQUEST,
    ETKINLIK_KATILIM_ISTEKLERI_SUCCESS,
    ETKINLIK_KATILIM_ISTEKLERI_DATA_CLEAR
} from '../../../types';
import {showToast} from "../../../../utils/Functions";


export const etkinlikKatilimIstekleriRequest = () => {
    return {
        type: ETKINLIK_KATILIM_ISTEKLERI_REQUEST
    }
}

export const etkinlikKatilimIstekleriSuccess = (events) => {
    return {
        type: ETKINLIK_KATILIM_ISTEKLERI_SUCCESS,
        payload: events
    }
}

export const etkinlikKatilimIstekleriDataClear = () => {
    return {
        type: ETKINLIK_KATILIM_ISTEKLERI_DATA_CLEAR
    }
}

export const etkinlikKatilimIstekleriAction = (secilenEventId) => {
    return (dispatch) => {
        dispatch(etkinlikKatilimIstekleriRequest())
        axios.get(`customer/event-participant/${secilenEventId}?filter=pending`).then((res) => {
            if (res.data.status === 1) {
                const seciliEvent = res.data.data
                dispatch(etkinlikKatilimIstekleriSuccess(seciliEvent))
            }
        }).catch((err) => {
            if(err.response){
                showToast(err.response.data.messages);
            }else{
                showToast([{type:"error",message:err.message,second:5}]);
            }
        })
    }
}
