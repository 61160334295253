// begenmemeEylemiAction.js

import axios from "../../../utils/CustomAxios";
import {BEGENMEME_EYLEMI_SUCCESS, UYEYI_CIKAR, UYEYI_EKLE, BEGEN_VE_BEGENMEME_EYLEMI_YUKLENIYOR} from '../../types';
import {showToast} from "../../../utils/Functions";

export const begenmemeEylemi = (uyeId) => {
    return (dispatch, getState) => {
        dispatch(begenmemeEylemiYukleniyor(true));
        return axios.post(`customer/dislike/send/${uyeId}`)
            .then((res) => {
                dispatch(begenmemeEylemiYukleniyor(false));
                if (res.data.status === 1) {
                    const begenmemeUpdatedData = {
                        sent_like: false,
                        dislike: true
                    };
                    dispatch(begenmemeEylemiBasarili(begenmemeUpdatedData));

                    // Uyeyi begendiklerim listesine ekle
                    const begendiklerinState = getState().begendiklerinSorgu;
                    const uyeDetay = begendiklerinState.begendiklerinData.find(
                        (uye) => uye.customer_id === uyeId
                    );
                    if (uyeDetay) {
                        dispatch(uyeyiCikarBegendiklerimden(uyeId));
                        dispatch(uyeyiEkleBegenmediklerime(uyeDetay));
                    }
                }
            })
            .catch((err) => {
                dispatch(begenmemeEylemiYukleniyor(false));
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{ type: "error", message: err.message, second: 5 }]);
                }
            });
    };
};

export const begenmemeEylemiBasarili = (begenmemeUpdatedData) => {
    return {
        type: BEGENMEME_EYLEMI_SUCCESS,
        payload: begenmemeUpdatedData
    };
};

export const uyeyiCikarBegendiklerimden = (uyeId) => {
    return {
        type: UYEYI_CIKAR,
        payload: {
            list: "begendiklerim",
            uyeId: uyeId
        }
    };
};

export const uyeyiEkleBegenmediklerime = (uyeDetay) => {
    return {
        type: UYEYI_EKLE,
        payload: {
            list: "begenmediklerim",
            uyeDetay: uyeDetay
        }
    };
};

export const begenmemeEylemiYukleniyor = (isLoading) => {
    return {
        type: BEGEN_VE_BEGENMEME_EYLEMI_YUKLENIYOR,
        payload: isLoading
    };
};
