import React, { useState, useEffect } from 'react';
import axios from "../utils/CustomAxios";
import {showToast} from "../utils/Functions";
import Select from 'react-select';

function LanguageSelector({selectedLanguages, setSelectedLanguages,className}) {
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get('web/language');
                if (response.data.status === 1) {
                    setLanguages(response.data.data.languages.map(lang => ({
                        id: lang.id,
                        value: lang.id,
                        label: lang.name
                    })));
                }
            } catch (error) {
                if (error.response) {
                    showToast(error.response.data.messages);
                } else {
                    showToast([{type: "error", message: error.message, second: 5}]);
                }
            }
        }

        fetchData();
    }, []);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderWidth: 2,
            borderColor: state.isFocused ? '#EB3428' : '#f0f2f4', // eğer odaklanıldıysa kırmızı, değilse açık gri
            boxShadow: state.isFocused ? '#EB3428' : null, // odaklanma gölgesini kırmızı yap
            "&:hover": {
                borderColor: '#EB3428' // fare ile üzerine gelindiğinde kırmızı
            }
        })
    }

    return (
        <div className={className}>
            <Select styles={customStyles}
                isMulti
                options={languages}
                value={selectedLanguages}
                onChange={setSelectedLanguages}
            />
        </div>
    );
}

export default LanguageSelector;
