import React from "react";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


export default function LikeInfoEmoji({text, emoji}) {
    return (
        <OverlayTrigger overlay={(
            <Tooltip>
                {text}
            </Tooltip>
        )}>
            <span  className={"bg-white rounded-circle shadow"}>
                {emoji}
            </span>
        </OverlayTrigger>
    )
}
