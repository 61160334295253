import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import UyeProfilDetay from "../../components/UyeProfilDetay";
import LikeInfoEmoji from "../../components/LikeInfoEmoji";
import { Logo } from "../../assets/svg/Logo";
import { useDispatch, useSelector } from "react-redux";
import { begenenlerAction } from "../../store/actions/BegenenlerBegendiklerinBegenmediklerin/begenenlerAction";
import { begendiklerinAction } from "../../store/actions/BegenenlerBegendiklerinBegenmediklerin/begendiklerinAction";
import { begenmediklerinAction } from "../../store/actions/BegenenlerBegendiklerinBegenmediklerin/begenmediklerinAction";
import {animated, useSpring} from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

function SeniBegenenlerVeSeninBegendiklerin({girisYapUyariModal}) {
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const receivedLikes = useSelector(
    (state) => state.begenenlerSorgu.begenenlerData
  );
  const likes = useSelector(
    (state) => state.begendiklerinSorgu.begendiklerinData
  );
  const dislike = useSelector(
    (state) => state.begenmediklerinSorgu.begenmediklerinData
  );

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [show, setShow] = useState("gelen-begeniler");
  const [profilDetayModal, setProfilDetayModal] = useState("modalKapatId");
  const [tiklananUyeId, setTiklananUyeId] = useState();
  const [durum, setDurum] = useState();
  const [infoText, setInfoText] = useState(t("begenenler_burada_gorunecek"));
  const [data, setData] = useState([]);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (token) {
      dispatch(begenenlerAction());
      dispatch(begendiklerinAction());
      dispatch(begenmediklerinAction());
    }
  }, [dispatch, token]);

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  useEffect(() => {
    if (token) {
      if (show === "gelen-begeniler") {
        setData(receivedLikes);
      } else if (show === "begendiklerin") {
        setData(likes);
      } else if (show === "begenmediklerin") {
        setData(dislike);
      }
    }
  }, [receivedLikes, likes, dislike, show, token]);

  const SeniBegenenlerButton = () => {
    setInfoText(t("begenenler_burada_gorunecek"));
    setShow("gelen-begeniler");
    if (token) {
      setData(receivedLikes);
      // Scroll pozisyonunu sıfırla
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }
  };
  const SeninBegendiklerinButton = () => {
    setInfoText(t("begendiklerin_burada_gorunecek"));
    setShow("begendiklerin");
    if (token) {
      setData(likes);
      // Scroll pozisyonunu sıfırla
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }
  };
  const SeninBegenmediklerinButton = () => {
    setInfoText(t("begenmediklerin_burada_gorunecek"));
    setShow("begenmediklerin");
    if (token) {
      setData(dislike);
      // Scroll pozisyonunu sıfırla
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }
  };
  const UyeProfilDetayModalAc = (userId, profilDetayModalId, durumId) => {
    setProfilDetayModal(profilDetayModalId);
    setTiklananUyeId(userId);
    setDurum(durumId);
  };
  const UyeProfilDetayModalKapat = (profilDetayModalId) => {
    setProfilDetayModal(profilDetayModalId);
  };

  const begeniDivStyle = {
    width: isMobile ? width : width <= 475 ? width : 425,
    height: isMobile ? height - 96 : height <= 795 ? height - 96 : 788 - 96,
  };
  const begeniDivStyle1 = {
    height: isMobile ? height - 147 : height <= 795 ? height - 147 : 788 - 147,
  };
  const begeniDivStyle2 = {
    height: isMobile ? height - 147 : height <= 795 ? height - 147 : 788 - 147,
  };
  const etkinlikOlusturModalStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
  };

  const scrollEvent = (e) => {
    if (token) {
      if (
        Math.abs(
          e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
        ) < 1
      ) {
        if (show === "gelen-begeniler") {
          const lastReceivedLike = receivedLikes[receivedLikes.length - 1];
          dispatch(begenenlerAction(lastReceivedLike?.id));
        } else if (show === "begendiklerin") {
          const lastLike = likes[likes.length - 1];
          dispatch(begendiklerinAction(lastLike?.id));
        } else if (show === "begenmediklerin") {
          const lastDislike = dislike[dislike.length - 1];
          dispatch(begenmediklerinAction(lastDislike?.id));
        }
      }
    }
  };

  const tokensizGirisButonu = (uyarilar) => {
    girisYapUyariModal(uyarilar);
  };

  const [{ y }, api] = useSpring(() => ({ y: 0 }));

  const bind = useDrag(({ movement: [, my], velocity, last }) => {
    // Eşik değeri (80 piksel)
    const threshold = 80;

    if (last) {
      // Eşik değerini geçip geçmediğine bakıyoruz
      if (my > threshold || velocity > 0.05) {
        // Eşik geçilmişse modalı tamamen kaydır
        api.start({ y: 1000 }); // Modalı ekrandan çıkar
        setTimeout(() => {
          api.start({ y: 0 }); // y pozisyonunu sıfırla
          // Modal türüne göre işlemleri yap
          UyeProfilDetayModalKapat("modalKapat");
        }, 300); // Animasyon süresi
      } else {
        // Eşik geçilmediyse modalı eski yerine getir
        api.start({ y: 0 });
      }
    } else {
      // Eşik değerinden önce modalın parmağı takip etmesini sağla
      if (my > 0) {
        api.start({ y: my }); // Modalı parmak hareketine göre kaydır
      }
    }
  });

  const modalSpring = useSpring({
    transform: profilDetayModal === "profilDetayId" ? "translateY(0%)" : "translateY(100%)",  // Açılma ve kapanma animasyonu
    opacity: profilDetayModal === "profilDetayId" ? 1 : 0,
    config: { tension: 300, friction: 30 }, // Animasyon hızını ve ataletini ayarlar
  });

  return (
    <div style={begeniDivStyle}>
      <div style={{width: "100%", height: "100%", position: "absolute", zIndex: 555}}>
        <div className="flex shadow-sm profilDetayShadow"
             style={{width: "100%", height: 51, backgroundColor: "#fff", borderLeftWidth: 1, borderRightWidth: 1}}>
          <div onClick={SeniBegenenlerButton} style={{ width: "50%", height: "100%" }} className="flex flex-column cursorPointer">
            <div style={{ width: "100%", height: "93%" }} className="flex justify-center items-center">
              {width <= 400 ? null : (
                <div style={{ opacity: 0.5 }}>
                  <LikeInfoEmoji text={t("begenenler")} emoji="🥰" />
                </div>
              )}
              <span style={{ fontSize: isMobile ? 12 : width <= 400 ? 12 : 13 }} className={`tiklanmaEngelle ml-1 text-center ${!show || show === "gelen-begeniler" ? "begenenlerVeBegendiklerimTabBarTextStyle" : "begenenlerVeBegendiklerimTabBarTextStyle1"}`}>
                {t("begenenler")}
              </span>
            </div>
            <div style={{ width: "100%", height: "7%" }} className={`${!show || show === "gelen-begeniler" ? "begenenlerTabBarStyle" : ""}`}></div>
          </div>
          <div onClick={SeninBegendiklerinButton} style={{ width: "50%", height: "100%" }} className="flex flex-column cursorPointer">
            <div style={{ width: "100%", height: "93%" }} className="flex justify-center items-center">
              {width <= 400 ? null : (
                <div style={{ opacity: 0.5 }}>
                  <LikeInfoEmoji text={t("begendiklerin")} emoji="😍" />
                </div>
              )}
              <span style={{ fontSize: isMobile ? 12 : width <= 400 ? 12 : 13 }} className={`tiklanmaEngelle ml-1 text-center ${show === "begendiklerin" ? "begenenlerVeBegendiklerimTabBarTextStyle" : "begenenlerVeBegendiklerimTabBarTextStyle1"}`}>
                {t("begendiklerin")}
              </span>
            </div>
            <div style={{ width: "100%", height: "7%" }} className={`${show === "begendiklerin" ? "begenenlerTabBarStyle" : ""}`}></div>
          </div>
          <div onClick={SeninBegenmediklerinButton} style={{ width: "50%", height: "100%" }} className="flex flex-column cursorPointer">
            <div style={{ width: "100%", height: "93%" }} className="flex justify-center items-center">
              {width <= 400 ? null : (
                <div style={{ opacity: 0.5 }}>
                  <LikeInfoEmoji text={t("begenmediklerin")} emoji="😏" />
                </div>
              )}
              <span style={{ fontSize: isMobile ? 12 : width <= 400 ? 12 : 13 }} className={`tiklanmaEngelle ml-1 text-center ${show === "begenmediklerin" ? "begenenlerVeBegendiklerimTabBarTextStyle" : "begenenlerVeBegendiklerimTabBarTextStyle1"}`}>
                {t("begenmediklerin")}
              </span>
            </div>
            <div style={{ width: "100%", height: "7%" }} className={`${show === "begenmediklerin" ? "begenenlerTabBarStyle" : ""}`}></div>
          </div>
        </div>

        <div style={begeniDivStyle1} ref={scrollRef} onScroll={scrollEvent} className="active-scroll profilDetayShadow">
          {data?.length === 0 ? (
            <div style={begeniDivStyle2} className="flex flex-column justify-content-center align-items-center">
              <div>
                <FontAwesomeIcon icon={faHeart} className="fa-2x" style={{ color: "#ddd" }}/>
              </div>
              <div className="begenenlerTextStyle1 tiklanmaEngelle">
                {infoText}
              </div>

              {!token ? (
                <div onClick={() => tokensizGirisButonu(t("begenenler_begendiklerin_ve_begenmediklerini_gorebilmek_icin_lutfen_oturum_acin"))}
                     style={{ width: 180, height: 40 }}
                     className="flex justify-center items-center cursorPointer etkinlikOlusturButtonStyle">
                  <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
                    {t("oturum_ac_button_text")}
                  </span>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="position-relative grid gap-2 grid-cols-2 mb-3 mx-2 my-2">
              {Object.values(data)?.map((item) => (
                <div
                  onClick={() => UyeProfilDetayModalAc(item?.customer_id, "profilDetayId", show)}
                  key={item?.customer_id}
                  className="flex-column align-items-center justify-content-center">
                  <div className="position-absolute fs-6 tiklanmaEngelle" style={{ margin: "-5px 0 0 -5px" }}>
                    {item?.match && (
                      <LikeInfoEmoji text={t("bu_kisiyle_eslestiniz")} emoji="💞"/>
                    )}
                    {item?.dislike && (
                      <LikeInfoEmoji text={t("bu_kisiyi_begenmediniz")} emoji="😏"/>
                    )}
                    {item?.sent_like && (
                      <LikeInfoEmoji text={t("bu_kisiyi_begendiniz")} emoji="😍"/>
                    )}
                    {item?.incoming_like && (
                      <LikeInfoEmoji text={t("bu_kisi_sizi_begendi")} emoji="🥰"/>
                    )}
                  </div>

                  <div style={{ width: "100%", height: 200 }}>
                    {item?.media ? (
                      <img className="begenilerFotoDivStyle1 tiklanmaEngelle" src={item?.media.url + item?.media?.file_name} alt=""/>
                    ) : (
                      <Logo className="tiklanmaEngelle begenilerFotoDivStyle1" />
                    )}
                  </div>
                  <div className="flex flex-column kartGolgeStyle1 pointer bg-white" style={{ width: "100%", height: 50 }}>
                    <div className="flex justify-content-center align-items-center tiklanmaEngelle" style={{ width: "100%", height: "100%" }}>
                      <span className={`begenenlerTextStyle2 tiklanmaEngelle ${
                          isMobile || width <= 475
                            ? "begenenlerTextStyle2Mobile"
                            : "begenenlerTextStyle2DesktopBuyukEkran"
                        }`}
                      >
                        {item?.first_name} {item?.last_name} {item?.age}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {profilDetayModal === "profilDetayId" && (
        <animated.div {...bind()} className="modal-drag flex justify-center items-center" style={{width: "100%", height: "100%", position: "absolute", zIndex: 55555, backgroundColor: "rgba(0,0,0,0.63)", ...modalSpring, y, touchAction: 'none'}}>
          <div className="flex flex-column items-center justify-center" style={etkinlikOlusturModalStyle}>
            <div style={{width: "100%", height: 51, borderLeftWidth: 1, borderRightWidth: 1}} className="flex justify-center items-center">
              <div className="flex justify-content-center align-items-center" style={{ width: 64, height: "100%" }}></div>
              <div className="flex justify-content-center align-items-center" style={{ width: "calc(100% - 128px)", height: "100%" }}>
                <div className="scaleStyle" style={{width: "70%", height: 8, backgroundColor: "#ddd", borderRadius: 8, cursor: "pointer"}}>

                </div>
              </div>
              {/* modal kapat tuşu*/}
              <div style={{width: 64, height: "100%"}}>

              </div>
              {/*<div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">*/}
              {/*  <div onClick={() => UyeProfilDetayModalKapat("modalKapat")} className="flex box justify-content-center align-items-center">*/}
              {/*    <FontAwesomeIcon icon={faXmark} className="fa-lg" />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <div style={{ width: "100%", height: "calc(100% - 51px)" }} className="flex flex-column active-scroll">
              <UyeProfilDetay
                tiklananUyeId={tiklananUyeId}
                profilDetayModal={profilDetayModal}
                durum={durum}
              />
            </div>
          </div>
        </animated.div>
      )}
    </div>
  );
}

export default SeniBegenenlerVeSeninBegendiklerin;
