// cikisEylemiAction.js

export const cikisEylemiAction = (socketRef) => {
    return (dispatch) => {
        if (socketRef.current) {
            socketRef.current.disconnect();
        }
        localStorage.removeItem('token');
        localStorage.removeItem('customer_data');

        // Harita konumunu ve zoom seviyesini siler
        localStorage.removeItem("mapLatitude");
        localStorage.removeItem("mapLongitude");
        localStorage.removeItem("mapZoom");
        localStorage.removeItem("northEastLat");
        localStorage.removeItem("northEastLng");
        localStorage.removeItem("southWestLat");
        localStorage.removeItem("southWestLng");

        // Filtre verilerini siler
        localStorage.removeItem("genderFilter");
        localStorage.removeItem("orientationFilter");
        localStorage.removeItem("ageRange");

        localStorage.removeItem('userGroupInfo');
        localStorage.removeItem('userIdGroupInfo');

        dispatch(userLogout());
    };
};

export const userLogout = () => {
    return {
        type: 'USER_LOGOUT'
    };
};
