import React, { useEffect, useState } from "react";
import "../../styles/main.css";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faUser,
  faSatelliteDish,
  faRotateRight,
  faInfinity,
} from "@fortawesome/free-solid-svg-icons";
import EtkinlikDetay from "./EtkinlikDetay";
import EtkinlikOlustur from "./EtkinlikOlustur";
import { Logo } from "../../assets/svg/Logo";
import { useDispatch, useSelector } from "react-redux";
import {
  etkinlikListClear,
  etkinlikListesiAction,
} from "../../store/actions/Etkinlik/etkinlikListesiAction";
import UyeBittiLoading from "../../components/UyeBittiLoading";

function EtkinlikListesi({ user, girisYapUyariModal }) {
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [etkinlikOlusturSayfalariGecis, setEtkinlikOlusturSayfalariGecis] =
    useState("etkinlik-listesi-ekraninda");
  const [etkinlikDetaySayfasiGit, setEtkinlikDetaySayfasiGit] = useState(
    "etkinlik-detay-sayfasi-git-id-0"
  );
  const [secilenEvent, setSecilenEvent] = useState();
  const etkinlikListesiData = useSelector(
    (state) => state.etkinlikListesi.eventListData
  );

  const loading = useSelector((state) => state.etkinlikListesi.loading);
  const [scrollY, setScrollY] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    if (token) {
      dispatch(etkinlikListClear());
      dispatch(etkinlikListesiAction());
    }
  }, [dispatch, token]);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const etkinlikListesiIslem = () => {
    if (token) {
      dispatch(etkinlikListesiAction());
    }
  };
  const etkinlikOlusturYonlendirIslem = (id) => {
    setEtkinlikOlusturSayfalariGecis(id);
  };
  const etkinlikOlusturDetayYonlendirIslem = (id, item = null) => {
    setEtkinlikDetaySayfasiGit(id);
    if (id === "etkinlik-detay-sayfasi-git-id-0") {
      setSecilenEvent();
    } else {
      setSecilenEvent(item);
    }
  };
  const handleScrollPosition = () => {
    const scrollTop = document.getElementById("ScrollY").scrollTop;
    setScrollY(scrollTop);
  };

  const begeniDivStyle = {
    width: isMobile ? width : width <= 475 ? width : 425,
    height: isMobile ? height - 96 : height <= 795 ? height - 96 : 788 - 96,
  };

  const tokensizGirisButonu = (uyarilar) => {
    girisYapUyariModal(uyarilar)
  };

  return (
    <div style={begeniDivStyle} className="profilDetayShadow">
      {etkinlikDetaySayfasiGit === "etkinlik-detay-sayfasi-git-id-0" ? (
        <div
          className="flex justify-center items-end"
          style={{ width: "100%", height: "100%" }}
        >
          {/*etkinlik olustur ve etkinlik listesi ekranı*/}
          {etkinlikOlusturSayfalariGecis === "etkinlik-listesi-ekraninda" ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            >
              {/*etkinlik listesi baslik*/}
              <div
                className="flex profilDetayShadow"
                style={{
                  width: "100%",
                  height: 51,
                  backgroundColor: "#fff",
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                }}
              >
                <div
                  className="flex justify-start items-center"
                  style={{ width: "85%", height: "100%" }}
                >
                  <span
                    className={`tiklanmaEngelle ml-5 ${
                      width <= 400
                        ? "etkinlikOlusturListeleBaslikTextFont1Style"
                        : "etkinlikOlusturListeleBaslikTextFont2Style"
                    }`}
                  >
                    {t("yakindaki_etkinlikler")}
                  </span>
                </div>
                <div
                  className="flex justify-center items-center"
                  style={{ width: "15%", height: "100%" }}
                >
                  <div
                    onClick={() => (!token ? null : etkinlikListesiIslem())}
                    className="flex yenileButtonuStyle justify-content-center align-items-center"
                  >
                    <FontAwesomeIcon icon={faRotateRight} className="fa-xl" />
                  </div>
                  {/* <FontAwesomeIcon icon={faFilter} className="etkinlikOlusturFiltre"/>
                          <span className="tiklanmaEngelle begenenlerVeBegendiklerimTabBarTextStyle1 ml-1"> filtrele</span>*/}
                </div>
              </div>
              {/*etkinlik listesi*/}
              <div
                style={{ width: "100%", height: "calc(100% - 51px)" }}
                id="ScrollY"
                onScroll={handleScrollPosition}
                className="flex-column justify-content-center align-items-center active-scroll"
              >
                <>
                  {loading === true ? (
                    <div
                      className="flex flex-column justify-center items-center"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <div style={{ width: "100%", height: "50%" }}>
                        <UyeBittiLoading width={width} height={height} />
                      </div>
                      <div
                        style={{ width: "100%", height: "20%" }}
                        className="flex flex-column justify-start items-center"
                      >
                        <div className="flex flex-column items-center">
                          <span
                            className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${
                              width <= 350
                                ? "uyeBittiLoadingUyariTextFontMobileStyle"
                                : "uyeBittiLoadingUyariTextFontDesktopStyle"
                            }`}
                          >
                            {t("yukleniyor")}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {etkinlikListesiData.length <= 0 || !token ? (
                        <div
                          style={{ width: "100%", height: "100%" }}
                          className="flex flex-column justify-center items-center"
                        >
                          <div>
                            <FontAwesomeIcon
                              icon={faSatelliteDish}
                              className="fa-2x"
                              style={{ color: "#ddd" }}
                            />
                          </div>
                          <span className="flex flex-column etkinlikOlusturEtkinlikYokTextStyle tiklanmaEngelle">
                            {t("yakinizdaki_etkinlikler_burada_listelenir")}
                          </span>

                          {!token ? (
                            <div
                              onClick={() => tokensizGirisButonu(t("etkinlik_olusturabilmek_ve_var_olan_etkinlikleri_görebilmek_icin_lutfen_oturum_acin"))}
                              style={{ width: 180, height: 40 }}
                              className="flex justify-center items-center cursorPointer etkinlikOlusturButtonStyle"
                            >
                              <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
                                {t("oturum_ac_button_text")}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="grid gap-1 grid-cols-1 mt-2 mb-5">
                          {Object.values(etkinlikListesiData)?.map((item) => {
                            let date_future = new Date(item.end_date);
                            let date_now = new Date(item?.start_date);

                            let seconds = Math.floor(
                              (date_future - date_now) / 1000
                            );
                            let minutes = Math.floor(seconds / 60);
                            let hours = Math.floor(minutes / 60);
                            let days = Math.floor(hours / 24);

                            let hours2 = hours - days * 24;
                            let minutes2 =
                              minutes - days * 24 * 60 - hours2 * 60;
                            return (
                              <div
                                onClick={() =>
                                  etkinlikOlusturDetayYonlendirIslem(
                                    "etkinlik-detay-sayfasi-git-id-1",
                                    item
                                  )
                                }
                                key={item.id}
                                className="flex justify-content-center tiklanmaEngelle align-items-center"
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div
                                  className="shadow-sm"
                                  style={{
                                    width: "95%",
                                    height: 120,
                                    backgroundColor: "#fff",
                                    borderRadius: 8,
                                  }}
                                >
                                  <div
                                    className="flex"
                                    style={{
                                      width: "100%",
                                      height: "40%",
                                    }}
                                  >
                                    <div
                                      className="flex items-center justify-center"
                                      style={{
                                        width: "20%",
                                        marginTop: 55,
                                      }}
                                    >
                                      {item?.customer?.media ? (
                                        <img
                                          className={`etkinlikImageStyle tiklanmaEngelle`}
                                          src={
                                            item?.customer?.media?.url +
                                            item?.customer?.media?.file_name
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        <Logo
                                          className={`tiklanmaEngelle etkinlikImageStyle`}
                                        />
                                      )}
                                    </div>
                                    <div
                                      className="flex items-center"
                                      style={{ width: "60%" }}
                                    >
                                      <span className="tiklanmaEngelle etkinlikOlusturKonuTextStyle1">
                                        {item?.customer?.first_name}{" "}
                                        {item?.customer?.last_name}
                                      </span>
                                    </div>
                                    <div
                                      className="flex justify-center items-center"
                                      style={{ width: "20%" }}
                                    >
                                      <span className="tiklanmaEngelle etkinlikOlusturKonuTextStyle2">
                                        {item?.distance}km
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="flex"
                                    style={{
                                      width: "100%",
                                      height: "60%",
                                    }}
                                  >
                                    <div style={{ width: "20%" }}></div>
                                    <div
                                      className="flex flex-column justify-start"
                                      style={{ width: "60%" }}
                                    >
                                      <div
                                        className="tiklanmaEngelle etkinlikOlusturKonuTextStyle2"
                                        style={{ width: "100%" }}
                                      >
                                        {item?.name}
                                      </div>
                                      <div style={{ width: "100%" }}>
                                        <span className="tiklanmaEngelle etkinlikOlusturKonuTextStyle2">
                                          {days} {t("gunYazi")} {hours2}{" "}
                                          {t("saat")} {minutes2} {t("dakika")}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="flex justify-center"
                                      style={{ width: "20%" }}
                                    >
                                      <div
                                        className="flex justify-center items-center"
                                        style={{
                                          width: 45,
                                          height: 45,
                                          borderWidth: 1,
                                          borderRadius: 100,
                                        }}
                                      >
                                        {item?.number_of_participants ===
                                        101 ? (
                                          <FontAwesomeIcon
                                            icon={faInfinity}
                                            className="etkinlikUserIconStyle"
                                          />
                                        ) : (
                                          <span className="tiklanmaEngelle etkinlikOlusturKonuTextStyle2">
                                            {item?.number_of_participants}
                                          </span>
                                        )}
                                        <FontAwesomeIcon
                                          icon={faUser}
                                          className="etkinlikUserIconStyle"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 555555,
                backgroundColor: "#fff",
                borderLeftWidth: 1,
                borderRightWidth: 1,
              }}
            >
              <div
                className="flex"
                style={{
                  width: "100%",
                  height: 51,
                  backgroundColor: "#fff",
                }}
              >
                <div
                  className="closebtn flex justify-start items-center"
                  style={{ width: "25%" }}
                >
                  <button
                    onClick={() =>
                      etkinlikOlusturYonlendirIslem(
                        "etkinlik-listesi-ekraninda"
                      )
                    }
                    className="arrow flex justify-center items-center px-3 py-3"
                  >
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      className="profileHeaderSolOkStyle"
                    />
                  </button>
                </div>
                <div
                  className="flex justify-center items-center ayarlarTextStyle01 tiklanmaEngelle"
                  style={{ height: "100%", width: "50%" }}
                ></div>
                <div
                  className="flex justify-end items-center mr-3 buttonTextStyle1111"
                  style={{ height: "100%", width: "25%" }}
                ></div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "calc(100% - 51px)",
                  backgroundColor: "#f9f8f8",
                }}
              >
                <EtkinlikOlustur
                  etkinlikOlusturYonlendirIslem={etkinlikOlusturYonlendirIslem}
                  tokensizGirisButonu={tokensizGirisButonu}
                />
              </div>
            </div>
          )}

          {/*etkinlik olustur modalını açan buton*/}
          {scrollY > 40 || loading === true ? null : (
            <div style={{ position: "absolute", zIndex: 44444, bottom: 25 }}>
              <div
                onClick={() =>
                  etkinlikOlusturYonlendirIslem(
                    "etkinlik-olustur-ekranina-yonlendir"
                  )
                }
                style={{ width: 190, height: 50 }}
                className="flex justify-center items-center etkinlikOlusturButtonStyle cursorPointer"
              >
                <div
                  className="flex justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#fbfafa",
                    borderRadius: "100%",
                    width: 43,
                    height: 43,
                    position: "absolute",
                    left: "37.8%",
                    top: "-52%",
                  }}
                ></div>
                <div
                  className="etkinlikOlusturButtonArtiStyle flex justify-content-center align-items-center"
                  style={{
                    position: "absolute",
                    left: "40.1%",
                    top: "-45%",
                  }}
                >
                  <span className="etkinlikOlusturButtonArtiTextStlye1 tiklanmaEngelle">
                    +
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                  }}
                  className="flex justify-center items-center"
                >
                  <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
                    {t("etkinlik_olustur")}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="flex flex-column"
          style={{ width: "100%", height: "100%" }}
        >
          <EtkinlikDetay
            secilenEvent={secilenEvent}
            user={user}
            etkinlikOlusturDetayYonlendirIslem={
              etkinlikOlusturDetayYonlendirIslem
            }
          />
        </div>
      )}
    </div>
  );
}

export default EtkinlikListesi;
