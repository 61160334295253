import React, { useEffect } from "react";
import { faXmark, faUserXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { blockListAction } from "../../store/actions/Profil/blockListAction";
import { unBlockEylemiAction } from "../../store/actions/Profil/unBlockEylemiAction";
import { Logo } from "../../assets/svg/Logo";

export default function EngellenenlerListesi() {
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const blockListData = useSelector((state) => state.blockList.blockListData);

  useEffect(() => {
    if (token) {
      dispatch(blockListAction());
    }
  }, [token, dispatch]);

  const unBlockIslem = (id) => {
    if (token) {
      dispatch(unBlockEylemiAction(id));
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }} className="active-scroll">
      {blockListData.length > 0 && token ? (
        <div>
          <div className="ayarlarTextStyle1122 ml-3 mt-3 mb-3 tiklanmaEngelle">
            {t("engellenenler")}
          </div>
          {blockListData?.map((item) => {
            return (
              <div
                key={item?.id}
                className="flex mt-2 mb-2"
                style={{
                  width: "100%",
                  height: 65,
                  backgroundColor: "#fff",
                  cursor: "auto",
                }}
              >
                <div
                  className="flex justify-center items-center"
                  style={{ width: "20%", height: "100%" }}
                >
                  {item?.media === null ? (
                    <Logo className={`etkinlikImageStyle tiklanmaEngelle`} />
                  ) : (
                    <img
                      className={`etkinlikImageStyle tiklanmaEngelle`}
                      src={item?.media?.url + item?.media?.file_name}
                      alt=""
                    />
                  )}
                </div>

                <div
                  className="flex justify-start items-center"
                  style={{ width: "70%", height: "100%" }}
                >
                  <span className="etkinlikOlusturDetaySaatVeKmTextStyle1 tiklanmaEngelle">
                    {item?.first_name} {item?.last_name}
                  </span>
                </div>
                <div
                  onClick={() => unBlockIslem(item?.customer_id)}
                  className="flex justify-center items-center kapatButtonStyle cursorPointer"
                  style={{ width: "15%", height: "100%" }}
                >
                  <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          style={{ width: "100%", height: "100%" }}
          className="flex flex-column justify-center items-center"
        >
          <FontAwesomeIcon
            icon={faUserXmark}
            className="fa-xl"
            style={{ color: "#ddd" }}
          />
          <span className="begenenlerTextStyle1 tiklanmaEngelle">
            {t("engellediginiz_kullanicilar_burada_listelenir")}
          </span>
        </div>
      )}
    </div>
  );
}
