// actions/Etkinlik/etkinlikListesiAction.js

import axios from "../../../utils/CustomAxios";
import {
    ETKINLIK_LISTESI_REQUEST,
    ETKINLIK_LISTESI_SUCCESS,
    ETKINLIK_LISTESI_CLEAR,
    ETKINLIK_LISTESI_LOADING
} from '../../types';
import {showToast} from "../../../utils/Functions";


export const etkinlikListRequest = () => {
    return {
        type: ETKINLIK_LISTESI_REQUEST
    }
}

export const etkinlikListSuccess = (events) => {
    return {
        type: ETKINLIK_LISTESI_SUCCESS,
        payload: events
    }
}
export const etkinlikListClear = () => {
    return {
        type: ETKINLIK_LISTESI_CLEAR
    }
}
export const etkinlikListLoading = (loading) => {
    return {
        type: ETKINLIK_LISTESI_LOADING,
        payload: loading
    }
}


export const etkinlikListesiAction = () => {
    return (dispatch) => {
        dispatch(etkinlikListLoading(true));
        dispatch(etkinlikListRequest())
        axios.get('customer/event')
            .then(response => {
                const events = response.data.data.events
                dispatch(etkinlikListSuccess(events));
                dispatch(etkinlikListLoading(false));
            })
            .catch(error => {
                dispatch(etkinlikListLoading(false));
                if(error.response){
                    showToast(error.response.data.messages);
                }else{
                    showToast([{type:"error",message:error.message,second:5}]);
                }
            })
    }
}
