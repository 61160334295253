import React, {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {Logo} from "../assets/svg/Logo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faPersonWalkingLuggage, faUsers, faXmark} from "@fortawesome/free-solid-svg-icons";
import UyeProfilDetay from "./UyeProfilDetay";
// import {useDispatch} from "react-redux";
import axios from "../utils/CustomAxios";
import {showToast} from "../utils/Functions";


export default function EtkinlikProfilDetay({currentRoom, txtRoomImage, etkinlikUyeProfiliAcikMi, setEtkinlikUyeProfiliAcikMi, tiklananUyeId, setTiklananUyeId}) {
    const {t} = useTranslation();
    let user_data = JSON.parse(localStorage.getItem('customer_data'));
    let eventId = parseInt(currentRoom?.event_id);
    const [setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [showAllMembers, setShowAllMembers] = useState(false); // Tüm üyeleri göstermek için yeni state
    const [engelleUyariModal, setEngelleUyariModal] = useState("engelle-uyari-modal-kapali");

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    // Gruplama işlevini tanımlayın
    const groupMembersByUserId = (members) => {
        const groupedMembers = {};
        members.forEach((member) => {
            const userId = member?.userId;
            if (userId) {
                if (groupedMembers[userId]) {
                    groupedMembers[userId].push(member);
                } else {
                    groupedMembers[userId] = [member];
                }
            }
        });
        return groupedMembers;
    };

    let userId = user_data?.id; // Kullanıcının ID'sini al

    const groupedMembers = groupMembersByUserId(currentRoom?.users);

    // groupedMembers nesnesini diziye çevir ve kullanıcıya ait grubu ayır
    let userGroup = null;
    let otherGroups = [];
    Object.entries(groupedMembers).forEach(([key, group]) => {
        if (key === userId.toString()) {
            userGroup = [key, group];
        } else {
            otherGroups.push([key, group]);
        }
    });

    // Kullanıcı grubunu diğer grupların başına ekle
    if (userGroup) {
        otherGroups.unshift(userGroup);
    }
    // İlk 5 grubu al
    const firstFiveGroups = otherGroups.slice(0, 5);

    // "Tümünü Gör" butonuna tıklandığında çalışacak fonksiyon
    const handleShowAllMembers = (id) => {
        if (id === "tumunu_gor") {
            setShowAllMembers(true);
        } else {
            setShowAllMembers(false);
        }
    };

    const uyeProfilDetayIslem = (uyeId, profilDetayIslemlId) => {
        setTiklananUyeId(uyeId);
        setEtkinlikUyeProfiliAcikMi(profilDetayIslemlId);
    }
    const etkinlikSohbetGrubundanAyrilIslem = () => {
        axios.delete(`customer/event-participant/quit/${eventId}`)
            .then((res) => {
                showToast(res.data.messages);
                if (res.data.status === 1) {
                    window.location.reload();
                }
            })
            .catch((err) => {
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{type: "error", message: err.message, second: 5}]);
                }
            });
    }

    const engelleUyariModalIslem = (id) => {
        setEngelleUyariModal(id)
    }

    return (
        <>
            {etkinlikUyeProfiliAcikMi === false ? (
                <>
                    <div style={{width: "100%", height: "100%", position: "relative", zIndex: 555}}>
                        <div style={{width: "100%", padding: 25}}
                             className="flex flex-column justify-content-center align-items-center mb-2">
                            <div className="flex flex-column justify-content-center align-items-center">
                                {currentRoom?.image ? (
                                    <img className={`tiklanmaEngelle`} src={txtRoomImage} alt=""/>
                                ) : (
                                    <Logo className={`tiklanmaEngelle`}/>
                                )}
                            </div>
                            <span className="etkinlikOlusturDetaySohbeteKatilTextStyle tiklanmaEngelle mt-4">
                            {currentRoom?.name}
                        </span>
                            <span className="mesajlarProfilTextStyle2 tiklanmaEngelle mt-1">
                           {t('etkinlik')} · {Object.values(groupedMembers).length} {t('uye')}
                        </span>
                        </div>
                        <div style={{width: "100%", height: isMobile ? height - 375 : 788 - 375}}>
                            {Object.values(groupedMembers).length <= 0 ? (
                                <div style={{width: "100%", height: "100%"}}
                                     className="flex flex-column justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faUsers} className="fa-2x" style={{color: "#ddd"}}/>
                                    <div className="begenenlerTextStyle1 tiklanmaEngelle">
                                        {t("etkinlik_katilimcilari_burada_listelenir")}
                                    </div>
                                </div>
                            ) : (
                                <div style={{width: "100%"}} className="flex justify-center">
                                    <div style={{width: "90%"}}>
                                        <div style={{
                                            backgroundColor: "#fff",
                                            width: "100%",
                                            height: 40,
                                            borderTopRightRadius: 8,
                                            borderTopLeftRadius: 8
                                        }} className="flex items-center profilDetayShadow mb-2">
                                        <span
                                            className="etkinlikOlusturDetaySohbeteKatilTextStyle tiklanmaEngelle pl-4">
                                             {t('uye')}: {Object.values(groupedMembers).length}
                                        </span>
                                        </div>

                                        {(showAllMembers ? otherGroups : firstFiveGroups).map(([key, group]) => {
                                            const item = group[0]; // Her bir grubun ilk üyesini alın
                                            return (
                                                <div onClick={() => uyeProfilDetayIslem(item?.userId, true)}
                                                     key={key}
                                                     style={{
                                                         backgroundColor: "#fff",
                                                         width: "100%",
                                                         height: 80,
                                                         borderRadius: 8
                                                     }}
                                                     className="flex mb-2 profilDetayShadow sohbetiSonlandirHoverStyle">
                                                    <div style={{width: "20%", height: "100%"}}
                                                         className="flex justify-center items-center">
                                                        {item?.image ? (
                                                            <img className={`tiklanmaEngelle etkinlikImageStyle`}
                                                                 src={item?.image?.url + item?.image?.file_name} alt=""/>
                                                        ) : (
                                                            <Logo className={`tiklanmaEngelle etkinlikImageStyle`}/>
                                                        )}
                                                    </div>
                                                    <div style={{width: "50%", height: "100%"}}
                                                         className="flex justify-start items-center">
                                                <span
                                                    className="etkinlikOlusturDetaySaatVeKmTextStyle1 tiklanmaEngelle">
                                                    {item?.first_name} {item?.last_name}
                                                </span>
                                                    </div>
                                                    <div style={{width: "30%", height: "100%"}}>

                                                    </div>
                                                </div>
                                            );
                                        })}


                                        {otherGroups.length > 5 && (
                                            <div style={{
                                                backgroundColor: "#fff",
                                                width: "100%",
                                                height: 40,
                                                borderBottomRightRadius: 8,
                                                borderBottomLeftRadius: 8
                                            }} className="flex items-center profilDetayShadow mb-4"
                                                 onClick={() => !showAllMembers && otherGroups.length > 5 ? handleShowAllMembers("tumunu_gor") : handleShowAllMembers("sinirla")}>
                                                {!showAllMembers ? (
                                                    <div style={{width: "100%"}} className="flex justify-between px-3 sohbetiSonlandirHoverStyle">
                                                        <div
                                                            className="etkinlikOlusturDetaySohbeteKatilTextStyle tiklanmaEngelle">
                                                            {t('tumunu_gor')}
                                                        </div>
                                                        <div>
                                                            <FontAwesomeIcon icon={faAngleDown} className="fa-lg"
                                                                             style={{color: '#21262E', opacity: 0.70}}/>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{width: "100%"}} className="flex justify-between px-3 sohbetiSonlandirHoverStyle">
                                                        <div
                                                            className="etkinlikOlusturDetaySohbeteKatilTextStyle tiklanmaEngelle">
                                                            {t('sinirla')}
                                                        </div>
                                                        <div>
                                                            <FontAwesomeIcon icon={faAngleUp} className="fa-lg"
                                                                             style={{color: '#21262E', opacity: 0.70}}/>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <div style={{backgroundColor: "#fff", width: "100%", height: 40, borderRadius: 8}}
                                             className="flex items-center profilDetayShadow mb-5 sohbetiSonlandirHoverStyle"
                                             onClick={() => engelleUyariModalIslem('engelle-uyari-modal-acik')}>
                                            <div style={{width: "100%"}} className="flex justify-between px-3">
                                                <div className="etkinlikOlusturDetaySohbeteKatilTextStyle tiklanmaEngelle"
                                                     style={{color: '#EB3428'}}>
                                                    {t('gruptan_ayril')}
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon icon={faPersonWalkingLuggage} className="fa-lg"
                                                                     style={{color: '#EB3428', opacity: 0.70}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {engelleUyariModal === "engelle-uyari-modal-acik" && (
                        <div className="flex flex-column justify-center items-center" style={{width: "100%", height: "97%", position: "absolute", zIndex: 55555, backgroundColor: "rgba(0,0,0,0.63)"}}>
                            <div className="flex flex-column active-scroll" style={{width: "90%", height: "70%", backgroundColor: "#fff", borderRadius: 8}}>
                                <div className="flex justify-end items-end px-3 py-2" style={{width: "100%", height: 51}}>
                                    <div onClick={() => engelleUyariModalIslem("engelle-uyari-modal-kapali")} className="flex box justify-content-center align-items-center">
                                        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                    </div>
                                </div>
                                <div style={{width: "100%", height: 'calc(100% - 51px)'}} className="flex flex-column justify-center items-center px-3">
                                    <div style={{width: "100%", height: "50%"}} className="flex flex-column justify-center items-center">
                                        <span className="engelleBaslikTextStyle tiklanmaEngelle">{t("uyari")}</span>
                                        <span className="profilDetayNameTextStyle tiklanmaEngelle pt-4 px-4">{t('gruptan_ayrılmak_istedigine_emin_misin')}</span>
                                    </div>
                                    <div style={{width: "100%", height: "50%"}} onClick={() => etkinlikSohbetGrubundanAyrilIslem()} className="flex justify-center items-center py-4">
                                        <div className="engelleBaslikTextStyle3">{t("gruptan_ayril")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div style={{width: "100%", height: "100%"}}>
                    <UyeProfilDetay tiklananUyeId={tiklananUyeId}/>
                </div>
            )}
        </>
    );
}
