// actions/begenmediklerinAction.js

import axios from "../../../utils/CustomAxios";
import {
    BEGENMEDIKLERIN_PAGE,
    BEGENMEDIKLERIN_REQUEST,
    BEGENMEDIKLERIN_SUCCESS,
    BEGENMEDIKLERIN_IS_FETCHING,
    BEGENMEDIKLERIN_HAS_MORE
} from '../../types';
import {showToast} from "../../../utils/Functions";

export const begenmediklerinAction = () => {
    return async (dispatch, getState) => {
        const { page, isFetching, hasMore, begenmediklerinData } = getState().begenmediklerinSorgu;

        if (!isFetching && hasMore) {
            dispatch(begenmediklerinRequest());
            dispatch({ type: BEGENMEDIKLERIN_IS_FETCHING, payload: true });
            try {
                const res = await axios.get(`customer/dislike?limit=10&page=${page}`);
                if (res.data.status === 1) {
                    const newData = res.data.data.dislikes || [];
                    const updatedData = mergeData(begenmediklerinData, newData); // Verileri birleştir
                    dispatch(begenmediklerinSuccess(updatedData));
                    if (newData.length < 10) {
                        dispatch({ type: BEGENMEDIKLERIN_HAS_MORE, payload: false });
                    } else {
                        dispatch(begenmediklerinIncrementPage(page));
                    }
                }
            } catch (err) {
                if(err.response){
                    showToast(err.response.data.messages);
                }else{
                    showToast([{type:"error",message:err.message,second:5}]);
                }
            } finally {
                dispatch({ type: BEGENMEDIKLERIN_IS_FETCHING, payload: false });
            }
        }
    };
};

// Verileri birleştir
const mergeData = (existingData, newData) => {
    // Önceki verilerden kullanıcıların 'id'lerini al ve bir Set oluştur
    const userIdSet = new Set(existingData.map(item => item.customer_id));

    // Yeni veri kümesini filtrele, yalnızca önceki veri kümesinde olmayan 'id'lere sahip olanları tut
    const filteredNewData = newData.filter(item => !userIdSet.has(item.customer_id));

    // Önceki verilere yeni verileri ekle
    return [...existingData, ...filteredNewData];
};

const begenmediklerinRequest = () => {
    return {
        type: BEGENMEDIKLERIN_REQUEST
    };
};

const begenmediklerinSuccess = (data) => {
    return {
        type: BEGENMEDIKLERIN_SUCCESS,
        payload: data
    };
};

export const begenmediklerinIncrementPage = (currentPage) => {
    return {
        type: BEGENMEDIKLERIN_PAGE,
        payload: currentPage + 1
    };
};
