import React, {useEffect, useRef, useState} from "react";
import "../../styles/main.css";
import UyariModal from "../../components/Modallar/UyariModal";
import {isMobile} from "react-device-detect";
import ImageUploading from "react-images-uploading";
import {useLocation, useNavigate} from "react-router-dom";
import IlerlemeCubugu from "../../components/IlerlemeCubugu";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft, faPencil, faPlus, faRotateRight, faXmark} from '@fortawesome/free-solid-svg-icons';
import BilgiModal from "../../components/Modallar/BilgiModal";
import {useTranslation} from "react-i18next";
import axios from "../../utils/CustomAxios";
import Yukleniyor from "../../components/Yukleniyor";
import Baslangic from "./Baslangic";
import AvatarEditor from "react-avatar-editor";
import LanguageSelector from "../../components/LanguageSelector";
import Compressor from 'compressorjs';

let genislik = 1050;
const maxNumber = 3;


function HesapOlustur() {
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const storedDevicePlatform = localStorage.getItem("devicePlatform");

    const cinsiyetData = [
        {id: 1, value: 'male', label: t("erkek")},
        {id: 2, value: 'female', label: t("kadin")},
    ]
    const cinselYonelimData = [
        {id: 11, value: 'heterosexual', label: t("heteroseksuel")},
        {id: 22, value: 'bisexual', label: t("biseksuel")},
        {id: 33, value: 'homosexual', label: t("homoseksuel")},
    ]

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const [uyariModal, setUyariModal] = useState("uyariModal");
    const [ad, setAd] = useState("");
    const [soyAd, setSoyAd] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cinsiyetSecimi, setCinsiyetSecimi] = useState("");
    const [cinselYonelim, setCinselYonelim] = useState("");
    const [images, setImages] = React.useState([]);
    const [adim, setAdim] = useState(1);
    const ilerlemeCubugu = (100 / 6) * adim;

    const [error, setError] = useState(false);
    const [gunError, setGunError] = useState("bos");
    const [ayError, setAyError] = useState("bos");
    const [yilError, setYilError] = useState("bos");
    const [gun, setGun] = useState("");
    const [ay, setAy] = useState("");
    const [yil, setYil] = useState("");
    const adimiGeriYap = adim <= 1 ? 1 : adim - 1;
    const [loading, setLoading] = useState(false);
    const [bilgi, setBilgi] = useState()
    const [rotate, setRotate] = useState(0);

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedPicture, setSelectedPicture] = useState(null);
    const [scale, setScale] = useState(1);
    const editorRef = useRef(null);

    useEffect(() => {
        if (location?.state?.email && location?.state?.password) {
            setEmail(location.state.email);
            setPassword(location.state.password);
            window.addEventListener("resize", updateDimensions);
            return () => window.removeEventListener("resize", updateDimensions);
        } else {
            navigate(`/${location.search}`);
        }
    }, [location.state.email, location.state.password, navigate]);
    const modalKapat = (id) => {
        setUyariModal(id)
    }
    const cinsiyetSecimButtonu = (id) => {
        setCinsiyetSecimi(id)
    }
    const cinselYonelimSecimButtonu = (id) => {
        setCinselYonelim(id)
    }
    const onChange = (imageList, addUpdateIndex) => {
        // Resimleri değiştirme işlevine resim sıkıştırmayı ekleyin
        imageList.forEach((image, index) => {
            if (image.file) {
                new Compressor(image.file, {
                    quality: 0.6,  // Kaliteyi düşürerek dosya boyutunu azaltın
                    maxWidth: 1000,  // Maksimum genişliği sınırlayın
                    maxHeight: 1000,  // Maksimum yüksekliği sınırlayın
                    success(result) {
                        const reader = new FileReader();
                        reader.readAsDataURL(result);
                        reader.onloadend = () => {
                            imageList[index] = {
                                data_url: reader.result,
                                file: result
                            };
                            setImages([...imageList]);
                        };
                    },
                    error(err) {
                        console.error(err.message);
                        setBilgi("imageUploadError"); // Hata durumunda modal gösteriliyor
                    },
                });
            }
        });
    };
    const onClickPicture = (index) => {
        const image = images[index];
        if (image) {
            image.index = index;
            setSelectedPicture(image);
        } else {
            console.error('Invalid image URL:', image);
            setBilgi("imageError");
        }
    };
    const onSave = async () => {
        if (editorRef.current) {
            const canvasScaled = editorRef.current.getImageScaledToCanvas();
            canvasScaled.toBlob(
                (blob) => {
                    new Compressor(blob, {
                        quality: 0.6,  // Kaydetme işleminden önce kaliteyi düşürün
                        maxWidth: 1000,  // Maksimum genişliği sınırlayın
                        maxHeight: 1000,  // Maksimum yüksekliği sınırlayın
                        success(result) {
                            // Blob objesini Base64 data URL'ye dönüştür
                             const reader = new FileReader();
                             reader.readAsDataURL(blob);
                             reader.onloadend = function () {
                                 const base64data = reader.result;
                                 // Oluşturulan Base64 data URL'yi ve diğer bilgileri içeren bir obje oluştur
                                 const fileData = {
                                     data_url: base64data,
                                     file: {
                                         lastModified: Date.now(), // or selectedPicture.lastModified,
                                         name: selectedPicture.file_name,
                                         size: blob.size, // or selectedPicture.size,
                                         type: "image/jpeg"
                                     }
                                 };
                                 let imagesData = images;
                                 imagesData[selectedPicture.index] = fileData
                                 setSelectedPicture(null);
                                 setImages(imagesData);
                             };
                             },
                        error(err) {
                            console.error(err.message);
                            setBilgi("imageSaveError")
                            },
                    });
                    },
                'image/jpeg',
                1
            );
        }
    };
    const hesapOlusturButton = async () => {

        if (ad === "" || ay === "" || gun === "" || yil === "" || cinsiyetSecimi === "" || cinselYonelim === "" || images.length < 1 || !selectedLanguages) {
            setError(true);
        } else {
            let sifirli_ay = (ay < 10) ? "0" + ay : ay;
            let sifirli_gun = (gun < 10) ? "0" + gun : gun;

            let temp = images.map((item) => {
                return {data_url: item.data_url, file: {name: item.file.name, size: item.file.size, type: item.file.type}};
            })

            const bodyFormData = {
                email: email,
                password: password,
                first_name: ad,
                last_name: soyAd,
                date_of_birth: `${yil}-${sifirli_ay}-${sifirli_gun}`,
                gender: cinsiyetSecimi,
                sexual_orientation: cinselYonelim,
                images: temp,
                languages: selectedLanguages
            }

            setLoading(true);
            axios.post(`auth/register/second_step`, bodyFormData,).then((res) => {
                setLoading(false)
                setBilgi(res.data.messages)
                if (res.data.status === 1) {
                    localStorage.setItem('token', res.data?.data?.token);
                    localStorage.setItem('customer_data', JSON.stringify(res.data?.data?.customer_data));
                    navigate(`/AnaSayfa${location.search}`,
                        {state: {token: res.data?.data?.token}})
                }
            }).catch((err) => {
                setLoading(false);
                setBilgi(err.response.data.messages);

            });
        }
        setLoading(false);
    }

    const devamButton = ad === "" || gun === "" || ay === "" || yil === "" || cinsiyetSecimi === "" || cinselYonelim === "" || images.length <= 0 || gunError !== "dolu" || ayError !== "dolu" || yilError !== "dolu" || !selectedLanguages ? (
        <div style={{width: 280, height: 50}} className="sifremiUnuttumButtonStyle22 flex justify-content-center align-items-center pt-2 pb-2 pr-6 pl-6 tiklanmaEngelle">
            {t("devam_button_text")}
        </div>
    ) : (
        <div style={{width: 280, height: 50}} onClick={() => hesapOlusturButton()}
             className="sifremiUnuttumButtonStyle1 flex justify-content-center align-items-center pt-2 pb-2 pr-6 pl-6 tiklanmaEngelle">
            {t("devam_button_text")}
        </div>
    )
    const adimAtlat = (id) => {
        setAdim(id)
        if (id === "bitti") {
            hesapOlusturButton();
        }
    }
    const adimGeri = () => {
        setAdim(adimiGeriYap)
    }
    const kayitIptalModalAc = (id) => {
        setUyariModal(id)
    }
    const kayitIptalEt = () => {
        let path = `/Baslangic${location.search}`;
        navigate(path)
    }

    const modalsStyle = {
        width: isMobile ? width : width <= 475 ? width : "100%",
        height: isMobile ? height : height <= 795 ? height : "100%",
        position: "absolute",
        zIndex: 55,
        backgroundColor: "#000000a1"
    }
    const modalsStyle3 = {
        width: isMobile ? width : width <= 475 ? width : 550,
        height: isMobile ? height : height <= 795 ? height : "90%",
        backgroundColor: "#fbfafa",
        borderRadius: isMobile ? 0 : 8
    }
    const hesapOlusturDivStyle = {
        width: width, height: height, backgroundColor: "#fff"
    }
    const hesapOlusturDivStyle1 = {
        width: "100%", height: "100%", zIndex: 50, position: "absolute"
    }
    const hesapOlusturDivStyle2 = {
        width: "100%", height: "100%"
    }
    const hesapOlusturModalStyle = {
        width: width,
        height: height,
        position: "absolute",
        zIndex: 55,
        backgroundColor: "rgba(0,0,0,0.63)"
    }
    const hesapOlusturModalStyle1 = {
        width: isMobile || width <= genislik || height <= 600 ? "100%" : 460,
        height: isMobile || width <= genislik || height <= 600 ? "100%" : 640,
        backgroundColor: "#fff",
        borderRadius: isMobile || width <= genislik ? 0 : 8
    }
    const hesapOlusturModalStyle2 = {
        width: 320,
        height: 264,
        backgroundColor: "#fff",
        borderRadius: 8
    }

    const handleChangeInputGun = (g) => {
        const re = /^[0-9\b]+$/;
        if (g.target.value === "" || re.test(g.target.value)) {
            if (g.target.value <= 0 || g.target.value === "") {
                setGunError("bos")
                setGun("")
            }
            if (g.target.value >= 1 && g.target.value <= 31) {
                setGunError("dolu")
                setGun(g.target.value);
            }
        }
    }
    const handleChangeInputAy = (a) => {
        const re = /^[0-9\b]+$/;
        if (a.target.value === "" || re.test(a.target.value)) {
            if (a.target.value <= 0 || a.target.value === "") {
                setAyError("bos")
                setAy("")
            }
            if (a.target.value >= 1 && a.target.value <= 12) {
                setAyError("dolu")
                setAy(a.target.value);
            }
        }
    }
    const handleChangeInputYil = (y) => {
        const yilAl = new Date().getFullYear()
        const re = /^[0-9\b]+$/;
        if (y.target.value === "" || re.test(y.target.value)) {
            if (y.target.value <= 0 || y.target.value === "") {
                setYilError("bos")
                setYil("")
            } else {
                if (y.target.value.length < 4) {
                    setYil(y.target.value)
                    setYilError("yilKisa")
                } else {
                    if (y.target.value <= yilAl - 18 && y.target.value >= 1923) {
                        setYil(y.target.value);
                        setYilError("dolu")
                    }
                }
            }
        }
    }
    const ZatenHesabinVarMiButonu = () => {
        navigate(`/${location.search}`, {state: {id: "hesapOlusturdanGeldi", name: "oturumAcAcik"}})
    }

    return (
        <div style={hesapOlusturDivStyle}>
            <div style={hesapOlusturDivStyle1}>
                {isMobile || width <= genislik || height <= 500 ? (
                    // mobile ekranı
                    <div style={hesapOlusturDivStyle2}>
                        <div style={{width: "100%", height: 10}}>
                            <IlerlemeCubugu completed={ilerlemeCubugu}/>
                        </div>
                        <div style={{width: "100%", height: 'calc(100% - 10px)'}}>
                            {adim <= 1 ? (
                                <div style={hesapOlusturDivStyle2} className="flex flex-column justify-content-center align-items-center">
                                    {/*kayıt iptal butonu*/}
                                    <div onClick={() => kayitIptalModalAc("kayitIptalModal")} style={{width: "100%", height: 70}} className="flex align-items-center">
                                        <span className="flex justify-center items-center hesapOlusturMobileTextStyle11 tiklanmaEngelle">
                                            X
                                        </span>
                                    </div>
                                    {/*başlık, ad, soyad inputlar ve adım atlat butonu*/}
                                    <div style={{width: "90%", height: "calc(100% - 70px)"}} className="flex flex-column">
                                        {/*başlık*/}
                                        <div style={{width: "100%", height: 115}} className="flex align-items-center">
                                            <span className="hesapOlusturAdim1TextBaslikStyle1 tiklanmaEngelle">
                                                {t("ad")}{t("ve")}{t("soyad")}
                                            </span>
                                        </div>
                                        {/*ad, soyad inputları*/}
                                        <div style={{width: "100%", height: "calc(100% - 240px)"}} className="flex justify-content-center align-items-center tiklanmaEngelle">
                                            {/*ad input*/}
                                            <input className="hesapOlusturAdim1InputStyle1 py-2 px-2 mx-3 focus:outline-none"
                                                   placeholder={t("ad")} value={ad}
                                                   onChange={e => setAd(e.target.value)} autoCapitalize='none'/>

                                            {/*soyad input*/}
                                            <input className="hesapOlusturAdim1InputStyle1 py-2 px-2 mx-3 focus:outline-none"
                                                   placeholder={`${t("soyad")}(${t("gerekli_degil")})`}
                                                   value={soyAd} onChange={e => setSoyAd(e.target.value)}
                                                   autoCapitalize='none'/>
                                        </div>
                                        {/*adım atlat butonu*/}
                                        <div style={{width: "100%", height: 125}} className="flex justify-content-center align-items-center">
                                            {ad === "" ? (
                                                <div style={{width: 280, height: 50}} className="sifremiUnuttumButtonStyle22 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("devam_button_text")}
                                                </div>
                                            ) : (
                                                <div style={{width: 280, height: 50}} onClick={() => adimAtlat(2)} className="sifremiUnuttumButtonStyle1 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("devam_button_text")}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : adim === 2 ? (
                                <div style={hesapOlusturDivStyle2} className="flex flex-column justify-content-center align-items-center">
                                    {/*adım geri butonu*/}
                                    <div style={{width: "100%", height: 70}} className="flex align-items-center">
                                        <div onClick={() => adimGeri()}
                                             className="flex justify-center items-center hesapOlusturMobileTextStyle11">
                                            <FontAwesomeIcon icon={faAngleLeft}/>
                                        </div>
                                    </div>
                                    {/*başlık, gun, ay, yıl inputlar ve adım atlat butonu*/}
                                    <div style={{width: "90%", height: "calc(100% - 70px)"}} className="flex flex-column">
                                        {/*başlık*/}
                                        <div style={{width: "100%", height: 115}} className="flex align-items-center">
                                            <span className="hesapOlusturAdim1TextBaslikStyle1 tiklanmaEngelle">
                                                {t("dogun_gunu")}
                                            </span>
                                        </div>
                                        {/*gün, ay ve yıl inputlar*/}
                                        <div style={{width: "100%", height: "calc(100% - 240px)"}} className="flex justify-content-center align-items-center tiklanmaEngelle active-scroll">
                                            <div style={{width: "100%", height: "100%"}} className="flex flex-row justify-content-between align-items-center">
                                                {/*gün input*/}
                                                <div style={{width: "30%"}}>
                                                    <input style={{width: "100%", height: 54}}
                                                           className="bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 pl-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                                           id="inline-full-name" placeholder={t("gun")} value={gun}
                                                           onChange={handleChangeInputGun}
                                                           maxLength={2} autoCapitalize='none'/>
                                                    {gunError === "dolu" &&
                                                        <span className="flex justify-content-between align-items-center hesapOlusturTarihErrorTextStyle2 tiklanmaEngelle">
                                                            ✓
                                                        </span>
                                                    }
                                                </div>
                                                {/*ay input*/}
                                                <div style={{width: "30%"}}>
                                                    <input style={{width: "100%", height: 54}}
                                                           className="bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 pl-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                                           id="inline-full-name" placeholder={t("ay")} value={ay}
                                                           maxLength={2} onChange={handleChangeInputAy}
                                                           autoCapitalize='none'/>
                                                    {ayError === "dolu" &&
                                                        <span className="flex justify-content-between align-items-center hesapOlusturTarihErrorTextStyle2 tiklanmaEngelle">
                                                            ✓
                                                        </span>
                                                    }
                                                </div>
                                                {/*yıl input*/}
                                                <div style={{width: "30%"}}>
                                                    <input style={{width: "100%", height: 54}}
                                                           className="bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 pl-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                                           id="inline-full-name" placeholder={t("yil")} value={yil}
                                                           maxLength={4} onChange={handleChangeInputYil}
                                                           autoCapitalize='none'/>
                                                    {yilError === "yilKisa" ? (
                                                        <span className="flex justify-content-between align-items-center hesapOlusturTarihErrorTextStyle1 tiklanmaEngelle">
                                                            {t("yil_kisa")}
                                                        </span>
                                                    ) : yilError === "dolu" && (
                                                        <span className="flex justify-content-between align-items-center hesapOlusturTarihErrorTextStyle2 tiklanmaEngelle">
                                                            ✓
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {/*adım atlat butonu*/}
                                        <div style={{width: "100%", height: 125}} className="flex justify-content-center align-items-center">
                                            {gunError !== "dolu" || ayError !== "dolu" || yilError !== "dolu" ? (
                                                <div style={{width: 280, height: 50}}
                                                     className="sifremiUnuttumButtonStyle22 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("devam_button_text")}
                                                </div>
                                            ) : (
                                                <div style={{width: 280, height: 50}}
                                                     onClick={() => adimAtlat(3)}
                                                     className="sifremiUnuttumButtonStyle1 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("devam_button_text")}
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : adim === 3 ? (
                                <div style={hesapOlusturDivStyle2} className="flex flex-column justify-content-center align-items-center">
                                    {/*adım geri butonu*/}
                                    <div style={{width: "100%", height: 70}} className="flex align-items-center">
                                        <div onClick={() => adimGeri()} className="flex justify-center items-center hesapOlusturMobileTextStyle11">
                                            <FontAwesomeIcon icon={faAngleLeft}/>
                                        </div>
                                    </div>
                                    {/*başlık, cinsiyet seç ve adım atlat butonu*/}
                                    <div style={{width: "90%", height: "calc(100% - 70px)"}} className="flex flex-column">
                                        {/*başlık*/}
                                        <div style={{width: "100%", height: 115}} className="flex align-items-center">
                                            <span className="hesapOlusturAdim1TextBaslikStyle1 tiklanmaEngelle">
                                                {t("cinsiyet")}
                                            </span>
                                        </div>

                                        {/*cinsiyet seçim*/}
                                        <div style={{width: "100%", height: "calc(100% - 240px)"}} className="flex justify-content-center align-items-center tiklanmaEngelle active-scroll">
                                            <div style={{width: "100%", height: "50%"}} className={`justify-content-center align-items-center grid gap-2 ${width <= 340 ? "grid-cols-1" : "grid-cols-2"}`}>
                                                {cinsiyetData.map((item) => {
                                                    return (
                                                        <div style={{width: "100%"}} key={item.id}
                                                             onClick={() => cinsiyetSecimButtonu(item.value)}
                                                             className={`flex justify-center items-center textTransformCapitalize tiklanmaEngelle ${cinsiyetSecimi === item.value ? "hspOlstrCinsiyetSecimiMobileDivStyle22" : "hspOlstrCinsiyetSecimiMobileDivStyle11"}`}>
                                                            {item.label}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        {/*adım atlat butonu*/}
                                        <div style={{width: "100%", height: 125}} className="flex justify-content-center align-items-center">
                                            {cinsiyetSecimi === "" ? (
                                                <div style={{width: 280, height: 50}}
                                                     className="sifremiUnuttumButtonStyle22 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("devam_button_text")}
                                                </div>
                                            ) : (
                                                <div style={{width: 280, height: 50}}
                                                     onClick={() => adimAtlat(4)}
                                                     className="sifremiUnuttumButtonStyle1 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("devam_button_text")}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : adim === 4 ? (
                                <div style={hesapOlusturDivStyle2} className="flex flex-column justify-content-center align-items-center">
                                    {/* adım geri butonu */}
                                    <div style={{width: "100%", height: 70}} className="flex align-items-center">
                                        <div onClick={() => adimGeri()}
                                             className="flex justify-center items-center hesapOlusturMobileTextStyle11">
                                            <FontAwesomeIcon icon={faAngleLeft}/>
                                        </div>
                                    </div>
                                    {/*başlık, cinsel yönelim seçim ve adım atlat butonu*/}
                                    <div style={{width: "90%", height: "calc(100% - 70px)"}} className="flex flex-column">
                                        {/*başlık*/}
                                        <div style={{width: "100%", height: 115}} className="flex align-items-center">
                                            <span className="hesapOlusturAdim1TextBaslikStyle1 tiklanmaEngelle">
                                                {t("cinsel_yonelim")}
                                            </span>
                                        </div>

                                        {/*cinsel yönelim seçim*/}
                                        <div style={{width: "100%", height: "calc(100% - 240px)"}} className="flex justify-content-center align-items-center tiklanmaEngelle active-scroll">
                                            <div style={{width: "100%", height: "50%"}} className={`justify-content-center align-items-center grid gap-2 ${width <= 340 ? "grid-cols-1" : "grid-cols-2"}`}>
                                                {cinselYonelimData.map((item) => {
                                                    return (
                                                        <div style={{width: "100%"}} key={item.id}
                                                             onClick={() => cinselYonelimSecimButtonu(item.value)}
                                                             className={`flex justify-center items-center textTransformCapitalize tiklanmaEngelle ${cinselYonelim === item.value ? "hspOlstrCinsiyetSecimiMobileDivStyle22" : "hspOlstrCinsiyetSecimiMobileDivStyle11"}`}>
                                                            {item.label}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>

                                        {/*adım atlat butonu*/}
                                        <div style={{width: "100%", height: 125}} className="flex justify-content-center align-items-center">
                                            {cinselYonelim === "" ? (
                                                <div style={{width: 280, height: 50}}
                                                     className="sifremiUnuttumButtonStyle22 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("devam_button_text")}
                                                </div>
                                            ) : (
                                                <div style={{width: 280, height: 50}}
                                                     onClick={() => adimAtlat(5)}
                                                     className="sifremiUnuttumButtonStyle1 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("devam_button_text")}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : adim === 5 ? (
                                <div style={hesapOlusturDivStyle2} className="flex flex-column justify-content-center align-items-center">
                                    {/* adım geri butonu */}
                                    <div style={{width: "100%", height: 70}} className="flex align-items-center">
                                        <div onClick={() => adimGeri()}
                                             className="flex justify-center items-center hesapOlusturMobileTextStyle11">
                                            <FontAwesomeIcon icon={faAngleLeft}/>
                                        </div>
                                    </div>
                                    {/*başlık, uyarı, konuşulan dilleri ekleme bölümü ve adımı atlat butonu*/}
                                    <div style={{width: "90%", height: "calc(100% - 70px)"}} className="flex flex-column">
                                        {/*başlık ve uyarı*/}
                                        <div style={{width: "100%", height: 115}} className="flex flex-column justify-content-center">
                                            <span className="hesapOlusturAdim1TextBaslikStyle1 tiklanmaEngelle">
                                                {t("konusulan_diller")}
                                            </span>
                                            <span className="hesapOlusturGenelTextMobileStyle12">
                                                {t("devam_etmek_icin_en_az_1_dil_ekle")}
                                            </span>
                                            {bilgi?.map((item) => {
                                                return (
                                                    <div className="flex justify-center items-center" key={item?.message}>
                                                        <span className="uyariTextStyle tiklanmaEngelle">
                                                            {item?.message}
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        {/*dil ekleme bölümü ve adımı atlat butonu*/}
                                        <div style={{width: "100%", height: "calc(100% - 240px)"}} className="flex justify-content-center align-items-center tiklanmaEngelle active-scroll">
                                            <LanguageSelector className={"col-10 m-auto"}
                                                              selectedLanguages={selectedLanguages}
                                                              setSelectedLanguages={setSelectedLanguages}/>
                                        </div>

                                        {/*adımı atlat butonu*/}
                                        <div style={{width: "100%", height: 125}} className="flex justify-content-center align-items-center">
                                            {selectedLanguages.length === 0 ? (
                                                <div style={{width: 280, height: 50}}
                                                     className="sifremiUnuttumButtonStyle22 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("devam_button_text")}
                                                </div>
                                            ) : (
                                                <div style={{width: 280, height: 50}}
                                                     onClick={() => adimAtlat(6)}
                                                     className="sifremiUnuttumButtonStyle1 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("devam_button_text")}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : adim === 6 || adim === "bitti" ? (
                                <div style={hesapOlusturDivStyle2} className="flex flex-column justify-content-center align-items-center">
                                    {/*adım geri butonu*/}
                                    <div style={{width: "100%", height: 70}} className="flex align-items-center">
                                        <div onClick={() => adimGeri()} className="flex justify-center items-center hesapOlusturMobileTextStyle11">
                                            <FontAwesomeIcon icon={faAngleLeft}/>
                                        </div>
                                    </div>
                                    {/*başlık, uyarı,fotoğraf ekleme bölümü ve adımı atlat butonu*/}
                                    <div style={{width: "90%", height: "calc(100% - 70px)"}} className="flex flex-column">
                                        {/*başlık ve uyarı*/}
                                        <div style={{width: "100%", height: 115}} className="flex flex-column justify-content-center">
                                            <span className="hesapOlusturAdim1TextBaslikStyle1 tiklanmaEngelle">{t("profil_fotografi")}</span>
                                            <span className="hesapOlusturGenelTextMobileStyle12">
                                                {t("devam_etmek_icin_en_az_1_foto_ekle")}
                                            </span>
                                            {bilgi?.map((item) => {
                                                return (
                                                    <div className="flex justify-center items-center" key={item?.message}>
                                                        <span className="uyariTextStyle tiklanmaEngelle">
                                                            {item?.message}
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        {/*fotoğraf ekleme bölümü ve adımı atlat butonu*/}
                                        <div style={{width: "100%", height: "calc(100% - 240px)"}} className="flex justify-content-center align-items-center tiklanmaEngelle active-scroll">
                                            {/*fotoğraf ekleme bölümü*/}
                                            <ImageUploading
                                                multiple
                                                value={images}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url">
                                                {({imageList, onImageUpload, onImageRemove, dragProps}) => (
                                                    <div className={`grid ${width <= 600 && width > 425 ? "grid-cols-2" : width <= 425 ? "grid-cols-1" : "grid-cols-3"} justify-content-center align-items-center`} style={{width: "95%", height: "90%"}}>
                                                        {imageList.map((image, index) => (
                                                            <div style={{position: "relative", width: "100%", height: 300}} className={`flex justify-content-center align-items-center mb-4 px-2`} key={index}>
                                                                <div className="flex justify-start items-end" style={{position: "absolute", marginLeft: 0, zIndex: 2, top: "93%", left: 5}}>
                                                                    <div onClick={() => {onClickPicture(index)}} className="hesapOlusturFotoEkleDiv11 flex justify-center items-center text-white" style={{background: "#45B649"}}>
                                                                        <FontAwesomeIcon className={"fa-sm"} icon={faPencil}/>
                                                                    </div>
                                                                </div>
                                                                <div className="profilFotoEkle222 flex justify-content-center align-items-center" style={{position: "relative", borderWidth: 3, borderColor: "#d4d8de", borderRadius: 8, borderStyle: "dashed", width: "100%", height: "100%"}}>
                                                                    <img className="hesapOluşturFotoEkleStyle flex justify-content-center align-items-center tiklanmaEngelle" src={image['data_url']} alt=""/>
                                                                </div>
                                                                <div className="flex justify-end items-end" style={{position: "absolute", top: "93%", right: 5, marginRight: 0}}>
                                                                    <div onClick={() => onImageRemove(index)} className="hesapOlusturFotoEkleDiv11 flex justify-center items-center text-white">
                                                                        <FontAwesomeIcon className={"fa-sm"} icon={faXmark}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {imageList.length <= 2 && (
                                                            <div style={{position: "relative", width: "100%", height: 300}} className="flex justify-content-center align-items-center mb-4 px-2">
                                                                <button className="profilFotoEkle111 flex items-end justify-end" type="button" onClick={onImageUpload} {...dragProps}>
                                                                    <div className="hesapOlusturFotoEkleDiv11 flex justify-center items-center text-white" style={{position: "absolute", right: 5, top: "93%"}}>
                                                                        <FontAwesomeIcon className={"fa-md"} icon={faPlus}/>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </ImageUploading>
                                        </div>

                                        {/*adımı atlat butonu*/}
                                        <div style={{width: "100%", height: 125}} className="flex justify-content-center align-items-center">
                                            {ad === "" || gun === "" || ay === "" || yil === "" || cinsiyetSecimi === "" || cinselYonelim === "" || images.length <= 0 || gunError !== "dolu" || ayError !== "dolu" || yilError !== "dolu" ? (
                                                <div style={{width: 280, height: 50}}
                                                     className="sifremiUnuttumButtonStyle22 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("kayit_ol_buton_text")}
                                                </div>
                                            ) : (
                                                <div style={{width: 280, height: 50}} onClick={() => adimAtlat("bitti")}
                                                     className="sifremiUnuttumButtonStyle1 flex justify-content-center align-items-center tiklanmaEngelle">
                                                    {t("kayit_ol_buton_text")}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (<Baslangic/>)}
                        </div>
                    </div>
                ) : (
                    // desktop ekranı
                    <div style={hesapOlusturDivStyle2}>
                        {/*mozared yazısı*/}
                        <div style={{width: "100%", height: 92}} className="flex flex-row align-items-center hesapOlusturAppHeaderBorderBottomStyle11">
                            <div onClick={() => kayitIptalEt()} className="buttonTextStyle1111">
                                <div className="logoTextStyle1 pl-4 tiklanmaEngelle">
                                    <span>
                                        MOZARED
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/*başlık inputlar, fotoğraf ekleme, ve zaten hesabım var butonu kısmı*/}
                        <div style={{width: "100%", height: 'calc(100% - 92px)'}} className="flex flex-column justify-center items-center">
                            {/*başlık kısmı*/}
                            <div className="flex justify-content-center align-items-center" style={{width: "100%", height: 70}}>
                                <span className="hesapOluşturBaslikTextStyle11 tiklanmaEngelle">
                                    {t("kayit_ol_baslik_text")}
                                </span>
                            </div>

                            {/*inputlar ve fotoğraf ekleme kısmı*/}
                            <div style={{width: "90%", height: 'calc(100% - 140px)'}} className="flex active-scroll">
                                {/*sol taraftaki inputlar*/}
                                <div style={{width: "50%", height: "100%"}}>
                                    <div style={{width: "80%"}}>
                                        {/*ad, soyad inputlar*/}
                                        <div className="flex mb-3 justify-content-between">
                                            {/*ad input*/}
                                            <div style={{width: "48%", height: "100%"}}>
                                                <span className="hesapOlusturGenelTextStyle11 tiklanmaEngelle">
                                                    {t("ad")}
                                                </span>
                                                <input style={{width: "100%", height: 54}}
                                                       className="mt-2 bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                                       id="inline-full-name" type="text" placeholder={t("ad")}
                                                       value={ad}
                                                       onChange={e => setAd(e.target.value)} autoCapitalize='none'/>
                                            </div>
                                            {/*soyad input*/}
                                            <div style={{width: "48%", height: "100%"}}>
                                                <span className="hesapOlusturGenelTextStyle11 tiklanmaEngelle">
                                                    {t("soyad")}({t("gerekli_degil")})
                                                </span>
                                                <input style={{width: "100%", height: 54}}
                                                       className="mt-2 bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                                       id="inline-full-name" type="text" placeholder={t("soyad")}
                                                       value={soyAd}
                                                       onChange={e => setSoyAd(e.target.value)} autoCapitalize='none'/>
                                            </div>
                                        </div>
                                        {/*doğum tarihi  inputlar*/}
                                        <div className="flex flex-column mb-3" style={{width: "100%"}}>
                                            <span className="hesapOlusturGenelTextStyle11 mt-2 mb-2 tiklanmaEngelle">{t("dogun_gunu")}</span>
                                            <div style={{width: "100%", height: "100%"}}
                                                 className="flex flex-row justify-content-between">
                                                {/*gün input*/}
                                                <div style={{width: "30%"}}>
                                                    <input style={{width: "100%", height: 54}}
                                                           className="bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 pl-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                                           id="inline-full-name" placeholder={t("gun")} value={gun}
                                                           onChange={handleChangeInputGun}
                                                           maxLength={2} autoCapitalize='none'/>
                                                    {gunError === "dolu" &&
                                                        <span className="flex justify-content-between align-items-center hesapOlusturTarihErrorTextStyle2 tiklanmaEngelle">
                                                            ✓
                                                        </span>
                                                    }
                                                </div>
                                                {/*ay input*/}
                                                <div style={{width: "30%"}}>
                                                    <input style={{width: "100%", height: 54}}
                                                           className="bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 pl-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                                           id="inline-full-name" placeholder={t("ay")} value={ay}
                                                           maxLength={2} onChange={handleChangeInputAy}
                                                           autoCapitalize='none'/>
                                                    {ayError === "dolu" &&
                                                        <span className="flex justify-content-between align-items-center hesapOlusturTarihErrorTextStyle2 tiklanmaEngelle">
                                                            ✓
                                                        </span>
                                                    }
                                                </div>
                                                {/*yıl input*/}
                                                <div style={{width: "30%"}}>
                                                    <input style={{width: "100%", height: 54}}
                                                           className="bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 pl-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                                           id="inline-full-name" placeholder={t("yil")} value={yil}
                                                           maxLength={4} onChange={handleChangeInputYil}
                                                           autoCapitalize='none'/>
                                                    {yilError === "yilKisa" ? (
                                                        <span className="flex justify-content-between align-items-center hesapOlusturTarihErrorTextStyle1 tiklanmaEngelle">
                                                            {t("yil_kisa")}
                                                        </span>
                                                    ) : yilError === "dolu" &&
                                                        <span className="flex justify-content-between align-items-center hesapOlusturTarihErrorTextStyle2 tiklanmaEngelle">
                                                            ✓
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/*cinsiyet seçim*/}
                                        <div className="flex flex-column mb-3">
                                            <span className="hesapOlusturGenelTextStyle11 mt-2 mb-2 tiklanmaEngelle">
                                                {t("cinsiyet")}
                                            </span>
                                            <div className="flex flex-row pt-2 pb-2">
                                                {cinsiyetData.map((item) => {
                                                    return (
                                                        <div key={item.id}
                                                             onClick={() => cinsiyetSecimButtonu(item.value)}
                                                             className={`flex justify-center items-center mr-2 p-3 textTransformCapitalize tiklanmaEngelle ${cinsiyetSecimi === item.value ? "hspOlstrCinsiyetSecimiDivStyle22" : "hspOlstrCinsiyetSecimiDivStyle11"}`}>
                                                            {item.label}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        {cinsiyetSecimi !== "" && (
                                            <div className="flex flex-column mb-3">
                                                <span className="hesapOlusturGenelTextStyle11 mt-2 mb-2 tiklanmaEngelle">{t("cinsel_yonelim")}</span>
                                                <div className="flex flex-row pt-2 pb-2">
                                                    {cinselYonelimData.map((item) => {
                                                        return (
                                                            <div key={item.id} onClick={() => cinselYonelimSecimButtonu(item.value)}
                                                                 className={`flex flex-column justify-center items-center mr-2 pr-2 pl-2 textTransformCapitalize tiklanmaEngelle ${cinselYonelim === item.value ? "hspOlstrCinsiyetSecimiDivStyle22" : "hspOlstrCinsiyetSecimiDivStyle11"}`}>
                                                                {item.label}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )}

                                        <div className="flex flex-column mb-3">
                                            <span className="hesapOlusturGenelTextStyle11 mt-2 mb-2 tiklanmaEngelle">{t("konusulan_diller")}</span>
                                            <LanguageSelector className={"col-10"} selectedLanguages={selectedLanguages}
                                                              setSelectedLanguages={setSelectedLanguages}/>
                                        </div>
                                    </div>
                                </div>

                                {/*başlık, fotoğraf ekleme bölümü, uyarı ve devam butonu*/}
                                <div style={{width: "50%", height: "100%"}} className="flex-column align-items-center">
                                    {/*başlık*/}
                                    <div className="flex items-start mb-2" style={{width: "100%", height: 20}}>
                                        <span className="hesapOlusturGenelTextStyle11 tiklanmaEngelle">
                                            {t("profil_fotografi")}
                                        </span>
                                    </div>
                                    {/*fotoğraf ekleme bölümü*/}
                                    <div style={{width: "100%"}} className="flex">
                                        <ImageUploading
                                            multiple
                                            value={images}
                                            onChange={onChange}
                                            maxNumber={maxNumber}
                                            dataURLKey="data_url">
                                            {({imageList, onImageUpload, onImageRemove, dragProps}) => (
                                                <div className={`grid grid-cols-3 justify-content-center align-items-center`} style={{width: "100%", height: "90%"}}>
                                                    {imageList.map((image, index) => (
                                                        <div style={{position: "relative", width: "100%", height: 300}} className={`flex justify-content-center align-items-center mb-4 pr-2`} key={index}>
                                                            <div className="flex justify-start items-end" style={{position: "absolute", zIndex: 2, top: "93%", left: -2, marginLeft: 0}}>
                                                                <div onClick={() => {onClickPicture(index)}}
                                                                     className="hesapOlusturFotoEkleDiv11 flex justify-center items-center text-white" style={{background: "#45B649"}}>
                                                                    <FontAwesomeIcon className={"fa-sm"} icon={faPencil}/>
                                                                </div>
                                                            </div>
                                                            <div className="profilFotoEkle222 flex justify-content-center align-items-center" style={{position: "relative", borderWidth: 3, borderColor: "#d4d8de", borderRadius: 8, borderStyle: "dashed", width: "100%", height: "100%"}}>
                                                                <img className="hesapOluşturFotoEkleStyle flex justify-content-center align-items-center tiklanmaEngelle" src={image['data_url']} alt="" />
                                                            </div>
                                                            <div className="flex justify-end items-end" style={{position: "absolute", zIndex: 2, top: "93%", right: 8, marginRight: 0}}>
                                                                <div onClick={() => onImageRemove(index)} className="hesapOlusturFotoEkleDiv11 flex justify-center items-center text-white">
                                                                    <FontAwesomeIcon className={"fa-sm"} icon={faXmark}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                    {imageList.length <= 2 && (
                                                        <div style={{position: "relative", width: "100%", height: 300}} className="flex justify-content-center align-items-center mb-4 pr-2">
                                                            <button className="profilFotoEkle111 flex items-end justify-end" type="button" onClick={onImageUpload} {...dragProps}>
                                                                <div className="hesapOlusturFotoEkleDiv11 flex justify-center items-center text-white" style={{position: "absolute", right: 5, top: "93%"}}>
                                                                    <FontAwesomeIcon className={"fa-md"} icon={faPlus}/>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </ImageUploading>
                                    </div>
                                    {/*uyarı bölümü*/}
                                    <div style={{width: "100%", height: 80}} className="flex justify-content-center align-items-center">
                                        <span className="hesapOlusturGenelTextStyle12 tiklanmaEngelle">
                                            {t("devam_etmek_icin_en_az_1_foto_ekle")}
                                        </span>
                                        {bilgi?.map((item) => {
                                            return (
                                                <div className="flex justify-center items-center" key={item?.message}>
                                                    <span className="uyariTextStyle tiklanmaEngelle">{item?.message}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {/*devam butonu*/}
                                    <div style={{width: "100%", height: 100}} className="flex justify-content-center align-items-center">
                                        {devamButton}
                                    </div>
                                </div>
                            </div>

                            {/*zaten hesabım var butonu*/}
                            <div style={{width: "100%", height: 70}} className="flex justify-content-center">
                                <div style={{width: "75%", height: "100%"}} className="flex justify-content-center align-items-center">
                                    <div onClick={() => ZatenHesabinVarMiButonu()}
                                         className="hesapOlusturGenelTextStyle222 cursorPointer tiklanmaEngelle">
                                        {t("zaten_hesabiniz_var_mi_giris_yapin")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {loading === true && (
                <div style={{width: "100%", height: "100%", position: "absolute", zIndex: 55, backgroundColor: "#00000063"}} className="flex justify-content-center align-items-center">
                    <Yukleniyor/>
                </div>
            )}

            {uyariModal === "uyariModal" && (
                <div style={hesapOlusturModalStyle} className="flex justify-content-center align-items-center" onClick={() => modalKapat("")}>
                    <div className="flex flex-column items-center justify-center" style={hesapOlusturModalStyle1}>
                        <UyariModal width={width} height={height} handleDataSelectButton={modalKapat}/>
                    </div>
                </div>
            )}

            {uyariModal === "kayitIptalModal" && (
                <div style={hesapOlusturModalStyle} className="flex justify-content-center align-items-center ">
                    <div className="flex flex-column align-items-center justify-content-center" style={hesapOlusturModalStyle2}>
                        <div style={{width: "90%", height: "55%"}}>
                            <BilgiModal/>
                        </div>
                        {/*modal kapat tuşu*/}
                        <div style={{width: "90%", height: "45%"}}>
                            <div style={{width: "100%", height: "50%"}} className="pt-2">
                                <div onClick={() => kayitIptalEt()}
                                     className="hsapOlusturBilgiModalMobileButtonStyle2 flex justify-content-center align-items-center">
                                    <span className="hsapOlusturBilgiModalMobileButtonTextStyle2 tiklanmaEngelle">
                                        {t("evet")}
                                    </span>
                                </div>
                            </div>
                            <div style={{width: "100%", height: "50%"}}>
                                <div onClick={() => modalKapat("")} style={{width: "100%"}}
                                     className="hsapOlusturBilgiModalMobileButtonStyle1 flex justify-content-center align-items-center">
                                    <span className="hsapOlusturBilgiModalMobileButtonTextStyle1 tiklanmaEngelle">
                                        {t("hayir")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {selectedPicture && (
                <div className="flex justify-center items-center" style={modalsStyle}>
                    <div className="flex flex-column items-center justify-center" style={modalsStyle3}>
                        <div style={{width: "100%", height: 51}} className="flex justify-center items-center">
                            <div className="flex justify-content-center align-items-center" style={{width: "85%", height: "100%"}}>
                                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                                    {t("fotograf_duzenle")}
                                </span>
                            </div>
                            {/*modal kapat tuşu*/}
                            <div style={{width: "15%", height: "100%"}}
                                 className="flex justify-content-center align-items-center">
                                <div onClick={() => setSelectedPicture(false)} className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", height: 'calc(100% - 51px)'}} className="active-scroll">
                            <AvatarEditor
                                style={{margin: "auto", maxHeight: "86%", width: "auto", maxWidth: "100%"}}
                                ref={editorRef}
                                image={selectedPicture.data_url}
                                width={400}
                                height={600}
                                border={50}
                                scale={scale}
                                rotate={rotate}
                                crossOrigin='anonymous'
                            />
                            <div style={{marginTop: "-68px"}}>
                                <div className={"row m-0 p-0"} style={{backgroundColor: "#000000ab", position: "relative", zIndex: 222222}}>
                                    <div className={"col-8 flex items-center justify-center "}>
                                        <label className={"profilDuzenleBaslikTextStyle01 tiklanmaEngelle text-white"}>
                                            {t('yakinlastir')}
                                            <input
                                                className={"form-range my-2"}
                                                name='scale'
                                                type='range'
                                                onChange={(e) => setScale(parseFloat(e.target.value))}
                                                min='1'
                                                max='2'
                                                step='0.01'
                                                defaultValue='1'
                                            />
                                        </label>
                                    </div>
                                    <div className={"col-4"}>
                                        <div className={"profilDuzenleBaslikTextStyle01 tiklanmaEngelle text-white"}>
                                            {t('dondur')}
                                        </div>
                                        <div onClick={() => setRotate(rotate + 90)}
                                             className="my-2 flex yenileButtonuStyle justify-center items-center">
                                            <FontAwesomeIcon icon={faRotateRight} className="fa-xl"/>
                                        </div>
                                    </div>
                                </div>

                                <div onClick={onSave} style={{width: 190, height: 50}}
                                     className="m-auto flex justify-center items-center etkinlikOlusturButtonStyle cursorPointer mt-1">
                                    <div style={{width: "100%", height: "100%"}}
                                         className="flex justify-center items-center">
                                        <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
                                            {t("resmi_kaydet")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default HesapOlustur;
