import {useOutlet} from "react-router-dom";

export const GeneralRoute = () => {
    const outlet = useOutlet();

       return (
           <div>
               {outlet}
           </div>
       )

};
