// actions/begenenlerAction.js

import axios from "../../../utils/CustomAxios";
import {
    BEGENENLER_PAGE,
    BEGENENLER_REQUEST,
    BEGENENLER_SUCCESS,
    BEGENENLER_IS_FETCHING,
    BEGENENLER_HAS_MORE
} from '../../types';
import {showToast} from "../../../utils/Functions";

export const begenenlerAction = () => {
    return async (dispatch, getState) => {
        const { page, isFetching, hasMore, begenenlerData } = getState().begenenlerSorgu;

        if (!isFetching && hasMore) {
            dispatch(begenenlerRequest());
            dispatch({ type: BEGENENLER_IS_FETCHING, payload: true });
            try {
                const res = await axios.get(`customer/received-like?limit=10&page=${page}`);
                if (res.data.status === 1) {
                    const newData = res.data.data.likes || [];
                    const updatedData = mergeData(begenenlerData, newData); // Verileri birleştir
                    dispatch(begenenlerSuccess(updatedData));
                    if (newData.length < 10) {
                        dispatch({ type: BEGENENLER_HAS_MORE, payload: false });
                    } else {
                        dispatch(begenenlerIncrementPage(page));
                    }
                }
            } catch (err) {
                if(err.response){
                    showToast(err.response.data.messages);
                }else{
                    showToast([{type:"error",message:err.message,second:5}]);
                }
            } finally {
                dispatch({ type: BEGENENLER_IS_FETCHING, payload: false });
            }
        }
    };
};

// Verileri birleştir
const mergeData = (existingData, newData) => {
    // Önceki verilerden kullanıcıların 'id'lerini al ve bir Set oluştur
    const userIdSet = new Set(existingData.map(item => item.customer_id));

    // Yeni veri kümesini filtrele, yalnızca önceki veri kümesinde olmayan 'id'lere sahip olanları tut
    const filteredNewData = newData.filter(item => !userIdSet.has(item.customer_id));

    // Önceki verilere yeni verileri ekle
    return [...existingData, ...filteredNewData];
};

const begenenlerRequest = () => {
    return {
        type: BEGENENLER_REQUEST
    };
};

const begenenlerSuccess = (data) => {
    return {
        type: BEGENENLER_SUCCESS,
        payload: data
    };
};

export const begenenlerIncrementPage = (currentPage) => {
    return {
        type: BEGENENLER_PAGE,
        payload: currentPage + 1
    };
};
