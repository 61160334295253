import { UYE_DETAY_BILGILERI, BEGEN_EYLEMI_SUCCESS, BEGENMEME_EYLEMI_SUCCESS, BURC_BILGILERI, BEGEN_VE_BEGENMEME_EYLEMI_YUKLENIYOR, UYE_DETAY_BILGILERI_YUKLENIYOR } from '../../types';

const initialState = {
    loading: false,
    begenVeBegenmeLoading: false
};

const uyeDetayBilgileriReducer = (state = initialState, action) => {
    switch (action.type) {
        case UYE_DETAY_BILGILERI:
            return {
                ...state,
                ...action.payload
            };
        case UYE_DETAY_BILGILERI_YUKLENIYOR:
            return {
                ...state,
                loading: action.payload
            };
        case BEGEN_EYLEMI_SUCCESS:
            return {
                ...state,
                sent_like: action.payload.sent_like,
                dislike: action.payload.dislike
            };
        case BEGENMEME_EYLEMI_SUCCESS:
            return {
                ...state,
                sent_like: action.payload.sent_like,
                dislike: action.payload.dislike
            };
        case BURC_BILGILERI:
            return {
                ...state,
                burc: action.payload
            };
        case BEGEN_VE_BEGENMEME_EYLEMI_YUKLENIYOR:
            return {
                ...state,
                begenVeBegenmeLoading: action.payload
            };
        default:
            return state;
    }
};

export default uyeDetayBilgileriReducer;
