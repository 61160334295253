import React, { useState, useEffect } from "react";
import "../../styles/main.css";
import { useTranslation } from "react-i18next";
import EtkinlikOlusturMesafe from "../../components/EtkinlikOlusturMesafe";
import EtkinlikOlusturYasTercihi from "../../components/EtkinlikOlusturYasTercih";
import EtkinlikOlusturKisiSayisi from "../../components/EtkinlikOlusturKisiSayisi";
import DatePicker from "react-datepicker";
import "../../styles/datePicker.css";
import { datas } from "../../utils/datas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faXmark } from "@fortawesome/free-solid-svg-icons";
import CinsiyetVeCinselYonelimSec from "../../components/CinsiyetVeCinselYonelimSec";
import axios from "../../utils/CustomAxios";
import { showToast } from "../../utils/Functions";
import { isMobile } from "react-device-detect";

function EtkinlikOlustur({ etkinlikOlusturYonlendirIslem, tokensizGirisButonu}) {
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [yasTercihValue, setYasTercihValue] = useState([18, 45]);
  const [katilimciMesafesi, setKatilimciMesafesi] = useState([25]);
  const [katilimciSayisi, setKatilimciSayisi] = useState([1]);
  const [baslangicTarih, setBaslangicTarih] = useState("");
  const [bitisTarih, setBitisTarih] = useState("");
  const [cinsiyet, setCinsiyet] = useState("unspecified");
  const [cinselYonelim, setCinselYonelim] = useState("");
  const [modals, setModals] = useState("modalKapali");
  const [bitisTarihiBelliMi, setBitisTarihiBelliMi] = useState("secim-yok");

  const yasTercihHandleClick = (e) => {
    setYasTercihValue(e);
  };
  const katilimciMesafesiHandleClick = (e) => {
    setKatilimciMesafesi(e);
  };
  const katilimciSayisiHandleClick = (e) => {
    setKatilimciSayisi(e);
  };

  const baslangicTarihButton = (date) => {
    if (date > new Date()) {
      setBaslangicTarih(date);
    }
  };

  const bitisTarihiBelliMiButton = (id) => {
    setBitisTarihiBelliMi(id);

    if (id === "tarih-belli-degil") {
      const newDate = new Date();
      newDate.setFullYear(newDate.getFullYear() + 1);
      setBitisTarih(newDate);
    }
  };
  const bitisTarihButton = (date) => {
    if (date > new Date()) {
      if (date > baslangicTarih) {
        setBitisTarih(date);
      }
    }
  };
  const bitisTarihiSecimSifirla = () => {
    setBitisTarih("");
    setBitisTarihiBelliMi("secim-yok");
  };

  const EtkinlikOlusturModalsButton = (id) => {
    setModals(id);
  };
  const EtkinlikOlusturModalsCloseButton = (id) => {
    setModals(id);
  };
  const cinsiyetSelectButton = (e) => {
    setCinsiyet(e);
    setModals("modalKapali");
  };
  const cinselYonelimSelectButton = (e) => {
    setCinselYonelim(e);
    setModals("modalKapali");
  };

  const modalsStyle2 = {
    width: isMobile ? "95%" : width < 350 ? width : "90%",
    height: isMobile ? "85%" : height < 370 ? height : 450,
    backgroundColor: "#fbfafa",
    borderRadius: 8,
  };

  const EtkinlikOlustur = () => {
    if (token) {
      const bodyFormData = {
        name: name,
        description: description,
        start_date: baslangicTarih,
        end_date: bitisTarih,
        minimum_age: yasTercihValue[0],
        maximum_age: yasTercihValue[1],
        participant_distance: katilimciMesafesi,
        number_of_participants: katilimciSayisi,
        gender: cinsiyet,
        sexual_orientation:
          cinsiyet === "unspecified" ? "unspecified" : cinselYonelim,
      };

      axios
        .post("customer/event", bodyFormData)
        .then((res) => {
          showToast(res.data.messages);
          if (res.data.status === 1) {
            etkinlikOlusturYonlendirIslem("etkinlik-listesi-ekraninda");
          }
        })
        .catch((err) => {
          if (err.response) {
            showToast(err.response.data.messages);
          } else {
            showToast([{ type: "error", message: err.message, second: 5 }]);
          }
        });
    } else {
      tokensizGirisButonu(t("etkinlik_olusturabilmek_icin_lutfen_oturum_acin"));
    }
  };

  let bitisTarih1 = new Date(bitisTarih);
  let ddBitisTarih = String(bitisTarih1.getDate()).padStart(2, "0");
  let mmBitisTarih = String(bitisTarih1.getMonth() + 1).padStart(2, "0");
  let yyyyBitisTarih = bitisTarih1.getFullYear();
  let bitisTarihiToplam =
    ddBitisTarih + "/" + mmBitisTarih + "/" + yyyyBitisTarih;
  let saatBitisTarih = String(bitisTarih1.getHours()).padStart(2, "0");
  let dakikaBitisTarih = String(bitisTarih1.getMinutes()).padStart(2, "0");
  let bitisSaatVeDakika = saatBitisTarih + ":" + dakikaBitisTarih;

  return (
    <div>
      {/*<div onClick={() => EtkinlikOlustur()} className="cursorPointer" style={{position: "absolute", zIndex: 55555, right: 10, marginTop: -40}}>*/}
      {/*    <span className="etkinlikOlusturTextButtonStyle tiklanmaEngelle">{t("etkinlik_olustur")}</span>*/}
      {/*</div>*/}

      <div
        className="pb-4 active-scroll"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 48,
        }}
      >
        {/*etkinlik olustur baslik*/}
        <div
          className="flex justify-center"
          style={{ width: "100%", height: 55 }}
        >
          <div
            className="flex items-center"
            style={{ width: "90%", height: 45 }}
          >
            <span className="etkinlikOlusturNameTextStyle tiklanmaEngelle">
              {t("etkinlik_olustur")}
            </span>
          </div>
        </div>
        {/* konu başlığı ve açıklama*/}
        <div
          className="flex flex-column justify-center items-center"
          style={{ width: "100%", height: 125, backgroundColor: "#fff" }}
        >
          <div
            className="flex justify-center items-center"
            style={{ width: "90%", height: 50 }}
          >
            <input
              style={{ width: "100%", height: 40 }}
              className="bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus: opacity-75 focus:border-red-400"
              id="inline-full-name"
              type="text"
              placeholder={t("etkinlik_adi")}
              value={name}
              onChange={(event) => setName(event.target.value)}
              autoCapitalize="none"
            />
          </div>
          <div
            className="flex justify-center items-center"
            style={{ width: "90%", height: 50 }}
          >
            <input
              style={{ width: "100%", height: 40 }}
              className="bg-gray-50 appearance-none border-2 border-gray-200 rounded py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus: opacity-75 focus:border-red-400"
              id="inline-full-name"
              type="text"
              placeholder={t("etkinlik_aciklamasi")}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              autoCapitalize="none"
            />
          </div>
        </div>
        {/*etkinlik olustur mesafe*/}
        <div
          className="flex flex-column justify-center items-center"
          style={{ width: "100%", height: 100, backgroundColor: "#fff" }}
        >
          <div
            className="flex justify-center items-center"
            style={{ width: "100%", height: 45, backgroundColor: "#f9f8f8" }}
          >
            <div className="flex" style={{ width: "90%", height: "100%" }}>
              <div
                className="flex items-center"
                style={{ width: "50%", height: "100%" }}
              >
                <span className="etkinlikOlusturNameTextStyle tiklanmaEngelle">
                  {t("mesafe")}
                </span>
              </div>
              <div
                className="flex items-center justify-end"
                style={{ width: "50%", height: "100%" }}
              >
                <span className="etkinlikOlusturNameTextStyle tiklanmaEngelle">
                  {" "}
                  {katilimciMesafesi} km
                </span>
              </div>
            </div>
          </div>
          <div
            className="flex justify-center items-center"
            style={{ width: "90%", height: 55 }}
          >
            <EtkinlikOlusturMesafe
              katilimciMesafesi={katilimciMesafesi}
              katilimciMesafesiHandleClick={katilimciMesafesiHandleClick}
            />
          </div>
        </div>
        {/*etkinlik olustur yas aralığı*/}
        <div
          className="flex flex-column justify-center items-center"
          style={{ width: "100%", height: 100, backgroundColor: "#fff" }}
        >
          <div
            className="flex justify-center items-center"
            style={{ width: "100%", height: 45, backgroundColor: "#f9f8f8" }}
          >
            <div className="flex" style={{ width: "90%", height: "100%" }}>
              <div
                className="flex items-center"
                style={{ width: "50%", height: "100%" }}
              >
                <span className="etkinlikOlusturNameTextStyle tiklanmaEngelle">
                  {t("yas_araligi")}
                </span>
              </div>
              <div
                className="flex items-center justify-end"
                style={{ width: "50%", height: "100%" }}
              >
                <span className="etkinlikOlusturNameTextStyle tiklanmaEngelle">
                  {" "}
                  {yasTercihValue[0]} - {yasTercihValue[1]}
                </span>
              </div>
            </div>
          </div>
          <div
            className="flex justify-center items-center"
            style={{ width: "90%", height: 55 }}
          >
            <EtkinlikOlusturYasTercihi
              yasTercihValue={yasTercihValue}
              yasTercihHandleClick={yasTercihHandleClick}
            />
          </div>
        </div>
        {/*etkinlik olustur tarih başlangıç ve bitiş*/}
        <div
          className="flex flex-column"
          style={{ width: "100%", height: 170, backgroundColor: "#fff" }}
        >
          <div
            className="flex justify-center items-center"
            style={{ width: "100%", height: 50, backgroundColor: "#f9f8f8" }}
          >
            <div
              className="flex items-center etkinlikOlusturNameTextStyle tiklanmaEngelle"
              style={{ width: "90%", height: "100%" }}
            >
              {t("tarih")}
            </div>
          </div>
          <div
            className="flex justify-center items-center"
            style={{ width: "100%", height: "70%" }}
          >
            <div
              className="flex justify-center items-center"
              style={{ width: "90%", height: "100%" }}
            >
              <div
                className="flex flex-column justify-center items-start"
                style={{ width: "50%", height: "100%" }}
              >
                <div
                  className="flex justify-start items-center"
                  style={{ width: "100%", height: 40 }}
                >
                  <span className="etkinlikOlusturName2TextStyle tiklanmaEngelle">
                    {t("baslangic")}
                  </span>
                </div>
                <div
                  className="flex flex-column justify-center items-start"
                  style={{ width: "85%", height: 75 }}
                >
                  <DatePicker
                    placeholderText={t("baslangic_tarihi_sec")}
                    className={
                      "tiklanmaEngelle appearance-none text-gray-700 leading-tight border-2 px-2 py-2 border-gray-200 rounded w-full focus:outline-none focus:bg-white focus:border-red-400"
                    }
                    selected={baslangicTarih}
                    onChange={(date) => baslangicTarihButton(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    withPortal
                  />
                </div>
              </div>
              <div
                className="flex flex-column justify-center items-start"
                style={{ width: "50%", height: "100%" }}
              >
                <div
                  className="flex justify-start items-center"
                  style={{ width: "100%", height: 40 }}
                >
                  <span className="etkinlikOlusturName2TextStyle tiklanmaEngelle">
                    {t("bitis")}
                  </span>
                </div>
                <div
                  className="flex flex-column justify-center items-center"
                  style={{ width: "100%", height: 75 }}
                >
                  {baslangicTarih ? (
                    <div
                      className="flex"
                      style={{ width: "100%", height: "100%" }}
                    >
                      {bitisTarihiBelliMi === "secim-yok" ? (
                        <>
                          <div
                            className="flex justify-center items-center"
                            style={{ width: "40%", height: "100%" }}
                          >
                            <div
                              onClick={() =>
                                bitisTarihiBelliMiButton("tarih-sec")
                              }
                              style={{ width: "90%", height: 35 }}
                              className="flex justify-center items-center etkinlikOlusturButtonStyle cursorPointer"
                            >
                              <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
                                {t("sec")}
                              </span>
                            </div>
                          </div>
                          <div
                            className="flex justify-center items-center"
                            style={{ width: "60%", height: "100%" }}
                          >
                            <div
                              onClick={() =>
                                bitisTarihiBelliMiButton("tarih-belli-degil")
                              }
                              className="flex justify-center items-center  cursorPointer"
                            >
                              <span className="etkinlikOlusturTextButtonStyle tiklanmaEngelle">
                                {t("belli_degil")}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {bitisTarihiBelliMi === "tarih-sec" ? (
                            <>
                              <div
                                className="flex justify-start items-center"
                                style={{ width: "75%", height: "100%" }}
                              >
                                <DatePicker
                                  placeholderText={t("bitis_tarihi_sec")}
                                  className={
                                    "tiklanmaEngelle appearance-none text-gray-700 leading-tight border-2 px-2 py-2 border-gray-200 rounded w-full focus:outline-none focus:bg-white focus:border-red-400"
                                  }
                                  selected={bitisTarih}
                                  onChange={(date) => bitisTarihButton(date)}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={5}
                                  timeCaption="time"
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                  withPortal
                                />
                              </div>
                              <div
                                className="flex items-center justify-end"
                                style={{ width: "25%", height: "100%" }}
                              >
                                <div
                                  onClick={() => bitisTarihiSecimSifirla()}
                                  className="flex justify-content-end align-items-center"
                                >
                                  <div className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                      icon={faXmark}
                                      className="fa-lg"
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : bitisTarihiBelliMi === "tarih-belli-degil" ? (
                            <>
                              <div
                                className="flex items-center"
                                style={{ width: "75%", height: "100%" }}
                              >
                                <span className="etkinlikOlusturName2TextStyle tiklanmaEngelle">
                                  {bitisTarihiToplam} {bitisSaatVeDakika}
                                </span>
                              </div>
                              <div
                                className="flex items-center justify-end"
                                style={{ width: "25%", height: "100%" }}
                              >
                                <div
                                  onClick={() => bitisTarihiSecimSifirla()}
                                  className="flex justify-content-end align-items-center"
                                >
                                  <div className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                      icon={faXmark}
                                      className="fa-lg"
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*etkinlik olustur kişi sayısı*/}
        <div
          className="flex flex-column justify-center items-center"
          style={{ width: "100%", height: 100, backgroundColor: "#fff" }}
        >
          <div
            className="flex justify-center items-center"
            style={{ width: "100%", height: 45, backgroundColor: "#f9f8f8" }}
          >
            <div className="flex" style={{ width: "90%", height: "100%" }}>
              <div
                className="flex justify-start items-center"
                style={{ width: "50%", height: "100%" }}
              >
                <span className="etkinlikOlusturNameTextStyle tiklanmaEngelle">
                  {t("kisi_sayisi")}
                </span>
              </div>
              <div
                className="flex justify-end items-center"
                style={{ width: "50%", height: "100%" }}
              >
                <span className="etkinlikOlusturNameTextStyle tiklanmaEngelle">
                  {katilimciSayisi > 100 ? t("sinirsiz") : katilimciSayisi}{" "}
                  {t("kisi")}
                </span>
              </div>
            </div>
          </div>
          <div
            className="flex justify-center items-center"
            style={{ width: "90%", height: 55 }}
          >
            <EtkinlikOlusturKisiSayisi
              katilimciSayisi={katilimciSayisi}
              katilimciSayisiHandleClick={katilimciSayisiHandleClick}
            />
          </div>
        </div>
        {/*etkinlik olustur cinsiyet ve cinsel yönelim seçimi*/}
        <div
          className="flex flex-column justify-center items-center"
          style={{
            width: "100%",
            height: cinsiyet === "unspecified" ? 100 : 200,
          }}
        >
          {/* cinsiyet*/}
          <div
            className="flex flex-column justify-center"
            style={{ width: "100%", height: 100 }}
          >
            <div
              style={{ width: "100%", height: 40 }}
              className="flex justify-center"
            >
              <div className="flex items-center" style={{ width: "90%" }}>
                <span className="etkinlikOlusturNameTextStyle tiklanmaEngelle">
                  {t("cinsiyet_buyuk_harf")}
                </span>
              </div>
            </div>
            <div
              onClick={() =>
                EtkinlikOlusturModalsButton("etkinlikOlusturCinsiyetModalId")
              }
              style={{ height: 60, backgroundColor: "#fff" }}
              className={`flex cursorPointer justify-center items-center profilDuzenleRightArrowIcon ${
                cinsiyet === ""
                  ? "profilDuzenleRightArrowIcon"
                  : "profilDuzenleRightArrowIcon2"
              }`}
            >
              <div className="flex" style={{ width: "90%" }}>
                <div
                  style={{ width: "45%", height: "100%" }}
                  className="flex justify-start items-center"
                >
                  <span className="etkinlikOlusturName2TextStyle tiklanmaEngelle">
                    {t("cinsiyet_buyuk_harf")}
                  </span>
                </div>
                <div
                  style={{ width: "55%", height: "100%" }}
                  className="flex justify-end items-center"
                >
                  <span
                    className={`etkinlikOlusturName3TextStyle mr-2 tiklanmaEngelle`}
                  >
                    {cinsiyet === ""
                      ? `${t("bos")}`
                      : datas().cinsiyetData[cinsiyet]?.label || ""}
                  </span>
                  <FontAwesomeIcon icon={faAngleRight} className="mr-2" />
                </div>
              </div>
            </div>
          </div>

          {/*cinsel yonelim*/}
          {cinsiyet === "unspecified" ? null : (
            <div
              className="flex flex-column justify-center"
              style={{ width: "100%", height: 100 }}
            >
              <div
                style={{ width: "100%", height: 40 }}
                className="flex justify-center"
              >
                <div className="flex items-center" style={{ width: "90%" }}>
                  <span className="etkinlikOlusturNameTextStyle tiklanmaEngelle">
                    {" "}
                    {t("cinsel_yonelim_buyuk_harf")}
                  </span>
                </div>
              </div>
              <div
                onClick={() =>
                  EtkinlikOlusturModalsButton(
                    "etkinlikOlusturCinselYonelimModalId"
                  )
                }
                style={{ height: 60, backgroundColor: "#fff" }}
                className={`flex cursorPointer justify-center items-center ${
                  cinselYonelim === ""
                    ? "profilDuzenleRightArrowIcon"
                    : "profilDuzenleRightArrowIcon2"
                }`}
              >
                <div className="flex" style={{ width: "90%" }}>
                  <div
                    style={{ width: "60%", height: "100%" }}
                    className="flex justify-start items-center"
                  >
                    <span className="etkinlikOlusturName2TextStyle tiklanmaEngelle">
                      {t("cinsel_yonelim_ekle")}
                    </span>
                  </div>
                  <div
                    style={{ width: "40%", height: "100%" }}
                    className="flex justify-end items-center"
                  >
                    <span
                      className={`etkinlikOlusturName3TextStyle mr-2 tiklanmaEngelle`}
                    >
                      {cinselYonelim === ""
                        ? `${t("bos")}`
                        : datas().cinselYonelimData[cinselYonelim]?.label || ""}
                    </span>
                    <FontAwesomeIcon icon={faAngleRight} className="mr-2" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/*etkinlik oluştur butonu*/}
        <div
          style={{ width: "100%", height: 65, marginTop: 30, marginBottom: 50 }}
          className="flex justify-center items-center"
        >
          <div
            onClick={() => (!token ? tokensizGirisButonu(t("etkinlik_olusturabilmek_icin_lutfen_oturum_acin")) : EtkinlikOlustur())}
            style={{ width: 190, height: 50 }}
            className="flex justify-center items-center cursorPointer etkinlikOlusturButtonStyle"
          >
            <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
              {t("etkinlik_olustur")}
            </span>
          </div>
        </div>
      </div>

      {modals === "etkinlikOlusturCinsiyetModalId" && (
        <div
          className="flex justify-center items-center"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            marginTop: -51,
            zIndex: 55,
            backgroundColor: "rgba(0,0,0,0.63)",
          }}
        >
          <div
            className="flex flex-column items-center justify-center"
            style={modalsStyle2}
          >
            <div
              style={{ width: "100%", height: "20%" }}
              className="flex justify-center items-center"
            >
              <div
                className="flex justify-content-center align-items-center"
                style={{ width: "85%", height: "100%" }}
              >
                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                  {t("cinsiyet_tercihi")}
                </span>
              </div>
              {/* modal kapat tuşu*/}
              <div
                style={{ width: "15%", height: "100%" }}
                className="flex justify-content-center align-items-center"
              >
                <div
                  onClick={() => EtkinlikOlusturModalsCloseButton("modalKapat")}
                  className="flex box justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", height: "80%" }}>
              <CinsiyetVeCinselYonelimSec
                cinsiyet={datas()?.cinsiyetData[cinsiyet]?.label || ""}
                handleDataSelectButton={cinsiyetSelectButton}
                data={Object.values(datas().cinsiyetData)}
              />
            </div>
          </div>
        </div>
      )}
      {modals === "etkinlikOlusturCinselYonelimModalId" && (
        <div
          className="flex justify-center items-center"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            marginTop: -51,
            zIndex: 55,
            backgroundColor: "rgba(0,0,0,0.63)",
          }}
        >
          <div
            className="flex flex-column items-center justify-center"
            style={modalsStyle2}
          >
            <div
              style={{ width: "100%", height: "20%" }}
              className="flex justify-center items-center"
            >
              <div
                className="flex justify-content-center align-items-center"
                style={{ width: "85%", height: "100%" }}
              >
                <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                  {t("cinsel_yonelim")}
                </span>
              </div>
              {/*modal kapat tuşu*/}
              <div
                style={{ width: "15%", height: "100%" }}
                className="flex justify-content-center align-items-center"
              >
                <div
                  onClick={() => EtkinlikOlusturModalsCloseButton("modalKapat")}
                  className="flex box justify-content-center align-items-center"
                >
                  <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", height: "80%" }}>
              <CinsiyetVeCinselYonelimSec
                cinselYonelim={
                  datas()?.cinselYonelimData[cinselYonelim]?.label || ""
                }
                handleDataSelectButton={cinselYonelimSelectButton}
                data={Object.values(datas().cinselYonelimData)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EtkinlikOlustur;
