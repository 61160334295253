import {Navigate, useOutlet} from "react-router-dom";

export const ProtectedRoute = () => {

    const outlet = useOutlet();
     if (!localStorage.getItem('token') || !localStorage.getItem('customer_data') || localStorage.getItem('customer_data')==="undefined") {
        // user is not authenticated
        return <Navigate to={`/AnaSayfa?devicePlatform=${localStorage.getItem('devicePlatform')}`} />;
    } else {
        return (
            <div>
                {outlet}
            </div>
        )
    }
};
