export function Logo({className, color}) {
    return (
        <svg
            id="Mozared"
            data-name="Mozared"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
            style={{background: color ? color : "#fff"}}
        >
            <defs>
                <style>
                    {
                        ".mozared-1,.mozared-2{stroke-width:17px;stroke:#fff;stroke-miterlimit:10}.mozared-1{fill:none}.mozared-4{fill:#a71b1b}.mozared-5{fill:#851c1c}.mozared-2{fill:#ce2527}.mozared-6{fill:#ee3724}"
                    }
                </style>
            </defs>
            <ellipse
                className="mozared-2"
                cx={256.31}
                cy={253.19}
                rx={242.79}
                ry={242.85}
            />
            <path
                className="mozared-1"
                d="m102.3 64.56 13.92 88.42 192.81 69.77L315 23.55"
            />
            <path className="mozared-2" d="m13.4 266.31 102.11-119.76-4.54-26.61" />
            <path
                d="m34.76 353.91 81.25 2.76 13.8.47c107.83 1.63 215.66 3.27 323.49 4.9-48.28-46.6-96.56-93.21-144.84-139.81-60.41 44.94-120.81 89.88-181.22 134.82"
                style={{
                    strokeWidth: 16,
                    fill: "none",
                    stroke: "#fff",
                    strokeMiterlimit: 10,
                }}
            />
            <path
                className="mozared-2"
                d="m309.04 222.75 165.54-75.49M115.51 152.01 315 67.33"
            />
            <path
                className="mozared-1"
                d="m115.51 154.03 16.28 198.39M163.12 357.64l76.27 132.1 120.63-126.42"
            />
            <path
                className="mozared-6"
                d="M124.36 163.98c4.71 57.99 9.41 115.99 14.12 173.98l154.7-112.97c-56.27-20.34-112.54-40.68-168.82-61.01Z"
            />
            <path
                className="mozared-5"
                d="m176.3 364.72 64.71 111.93c34.29-35.93 68.58-71.87 102.87-107.8-55.86-1.38-111.72-2.75-167.58-4.13Z"
            />
            <path
                className="mozared-6"
                d="M365.07 369.37c-37.63 39.5-75.26 78.99-112.9 118.49 20.64.4 48.53-1.62 79.18-11.87 76.85-25.68 115.7-84.49 127.74-104.64l-94.03-1.98Z"
            />
            <path
                className="mozared-4"
                d="M322.6 225.08c49.33-22.51 98.65-45.03 147.98-67.54 11.76 26.68 16.47 50.26 18.51 66.88 2.5 20.38 4.54 59.48-10.95 104.43a233.625 233.625 0 0 1-14.94 34.2c-46.87-45.99-93.73-91.98-140.6-137.97ZM96.56 79.87a238.123 238.123 0 0 0-18.37 19.02c-7.57 8.77-21.13 25.78-33.06 49.98-6.14 12.46-16.28 35.82-21.19 66.75-1.89 11.9-2.54 22.02-2.76 29.42l85.83-100.65-10.44-64.52Z"
            />
            <path
                className="mozared-6"
                d="M323.14 27.46c-1.9 60.93-3.79 121.86-5.69 182.79 48.81-22.21 97.63-44.42 146.45-66.62-8.44-17.16-25.84-46.75-58.88-73.56-31.47-25.53-63.13-37.19-81.88-42.61Z"
            />
            <path
                className="mozared-5"
                d="M110.84 67.3c16.66-13.07 52.64-37.62 105.33-46.52 39.17-6.62 71.8-1.92 91.37 2.43-.57 13.18-1.13 26.36-1.7 39.54l-183.24 77.8c-3.92-24.41-7.84-48.83-11.77-73.24Z"
            />
        </svg>
    );
}
