import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';

import begenenlerReducer from "./reducers/BegenenlerBegendiklerinBegenmediklerin/begenenlerReducer";
import begendiklerinReducer from "./reducers/BegenenlerBegendiklerinBegenmediklerin/begendiklerinReducer";
import begenmediklerinReducer from "./reducers/BegenenlerBegendiklerinBegenmediklerin/begenmediklerinReducer";
import uyeDetayBilgileriReducer from "./reducers/Profil/uyeDetayBilgileriReducer";
import blockEylemiReducer from "./reducers/Profil/blockEylemiReducer";
import blockListReducer from "./reducers/Profil/blockListReducer";

import etkinlikListesiReducer from "./reducers/Etkinlik/etkinlikListesiReducer";
import secilenEtkinlikDetayReducer from "./reducers/Etkinlik/secilenEtkinlikDetayReducer";
import etkinlikKatilimIstekleriReducer from "./reducers/Etkinlik/etkinlikKatilimIstekleriReducer";
import etkinlikKatilimcilariReducer from "./reducers/Etkinlik/etkinlikKatilimcilariReducer";
import etkinlikKatilimIstekleriReddedilenlerReducer from "./reducers/Etkinlik/etkinlikKatilimIstekleriReddedilenlerReducer";
import exploreReducer from "./reducers/Explore/exploreReducer";
import matchedUsersReducer from './reducers/EslestigimKullaniciSohbetleri/matchedUsersReducer';
import paketReducer from "./reducers/paketReducer";

const reducers = combineReducers({
    begenenlerSorgu: begenenlerReducer,
    begendiklerinSorgu: begendiklerinReducer,
    begenmediklerinSorgu: begenmediklerinReducer,
    uyeDetayBilgileri: uyeDetayBilgileriReducer,
    blockEylemi: blockEylemiReducer,
    blockList: blockListReducer,
    etkinlikListesi: etkinlikListesiReducer,
    secilenEtkinlikDetay: secilenEtkinlikDetayReducer,
    etkinlikKatilimIstekleri: etkinlikKatilimIstekleriReducer,
    etkinlikKatilimcilari: etkinlikKatilimcilariReducer,
    etkinlikKatilimIstekleriReddedilenler: etkinlikKatilimIstekleriReddedilenlerReducer,
    explore: exploreReducer,
    matchedUsers: matchedUsersReducer,
    paketDurumBilgisi: paketReducer,
})


// Hesap çıkışında durumu temizlemek için bir ana reducer oluşturuyoruz
const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }
    return reducers(state, action);
};

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
