// begenEylemiAction.js

import axios from "../../../utils/CustomAxios";
import {
    BEGEN_EYLEMI_SUCCESS,
    UYEYI_CIKAR,
    UYEYI_EKLE,
    BEGEN_VE_BEGENMEME_EYLEMI_YUKLENIYOR
} from '../../types';
import {showToast} from "../../../utils/Functions";

export const begenEylemi = (uyeId) => {
    return (dispatch, getState) => {
        dispatch(begenEylemiYukleniyor(true));
        return axios.post(`customer/like/send/${uyeId}`)
            .then((res) => {
                dispatch(begenEylemiYukleniyor(false));
                if (res.data.status === 1) {
                    const begenUpdatedData = {
                        sent_like: true,
                        dislike: false
                    };
                    dispatch(begenEylemiBasarili(begenUpdatedData));

                    // Uyeyi begendiklerim listesine ekle
                    const begenmediklerinState = getState().begenmediklerinSorgu;
                    const uyeDetay = begenmediklerinState.begenmediklerinData.find(
                        (uye) => uye.customer_id === uyeId
                    );
                    if (uyeDetay) {
                        dispatch(uyeyiCikarBegenmediklerimden(uyeId));
                        dispatch(uyeyiEkleBegendiklerime(uyeDetay));
                    }
                }
            })
            .catch((err) => {
                dispatch(begenEylemiYukleniyor(false));
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{ type: "error", message: err.message, second: 5 }]);
                }
            });
    };
};
export const begenEylemiBasarili = (begenUpdatedData) => {
    return {
        type: BEGEN_EYLEMI_SUCCESS,
        payload: begenUpdatedData
    };
};

export const uyeyiCikarBegenmediklerimden = (uyeId) => {
    return {
        type: UYEYI_CIKAR,
        payload: {
            list: "begenmediklerim",
            uyeId: uyeId
        }
    };
};

export const uyeyiEkleBegendiklerime = (uyeDetay) => {
    return {
        type: UYEYI_EKLE,
        payload: {
            list: "begendiklerim",
            uyeDetay: uyeDetay
        }
    };
};

export const begenEylemiYukleniyor = (isLoading) => {
    return {
        type: BEGEN_VE_BEGENMEME_EYLEMI_YUKLENIYOR,
        payload: isLoading
    };
};
