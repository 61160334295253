import React from "react";
import "../styles/main.css";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function CinsiyetVeCinselYonelimSec({handleDataSelectButton, data, cinsiyet, cinselYonelim, data2}) {

    const x1 = (id) => {
        handleDataSelectButton(id)
    }

    const modalDivStyle = {
        width: "100%", height: 52, backgroundColor: "#fff", borderTopWidth: 1, borderBottomWidth: 1, marginBottom: -1, borderWidthColor: "#21262E"
    }

    return (
        <div style={{width: "100%", height: "100%"}} className="active-scroll">
            {data.map((item) => (
                <div style={modalDivStyle} className={`flex pl-3 cursorPointer ${item.value === cinsiyet || item.value === cinselYonelim ? "profilDuzenleRightArrowIcon22" : "profilDuzenleRightArrowIcon2"}`} onClick={() => x1(item.value)} key={item.value}>
                    <div style={{width: "65%", height: "100%"}} className="flex items-center profilDuzenleTextStyle03 tiklanmaEngelle">{item.label}</div>
                    <div style={{width: "35%", height: "100%"}} className="flex items-center justify-end pr-4 tiklanmaEngelle">
                        {cinsiyet === item.value || cinselYonelim === item.value ? (
                            <FontAwesomeIcon icon={faCheck}/>
                        ) : null}
                    </div>
                </div>
            ))}
        </div>
    )
}
