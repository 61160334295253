// actions/Etkinlik/secilenEtkinlikDetayAction.js

import axios from "../../../utils/CustomAxios";
import {
    SECILEN_ETKINLIK_DETAY_REQUEST,
    SECILEN_ETKINLIK_DETAY_SUCCESS,
    SECILEN_ETKINLIK_DATA_CLEAR,
    SECILEN_ETKINLIK_DETAY_LOADING
} from '../../types';
import {showToast} from "../../../utils/Functions";


export const seciliEtkinlikDetayRequest = () => {
    return {
        type: SECILEN_ETKINLIK_DETAY_REQUEST
    }
}

export const seciliEtkinlikDetaySuccess = (events) => {
    return {
        type: SECILEN_ETKINLIK_DETAY_SUCCESS,
        payload: events
    }
}

export const secilenEtkinlikDetayDataClear = () => {
    return {
        type: SECILEN_ETKINLIK_DATA_CLEAR
    }
}

export const secilenEtkinlikDetayLoading = (loading) => {
    return {
        type: SECILEN_ETKINLIK_DETAY_LOADING,
        payload: loading
    }
}


export const secilenEtkinlikDetayAction = (secilenEventId) => {
    return (dispatch) => {
        dispatch(secilenEtkinlikDetayLoading(true));
        dispatch(seciliEtkinlikDetayRequest())
        axios.get(`customer/event/${secilenEventId}`).then((res) => {
            if (res.data.status === 1) {
                const seciliEvent = res.data.data.event
                dispatch(seciliEtkinlikDetaySuccess(seciliEvent))
                dispatch(secilenEtkinlikDetayLoading(false));
            }
        }).catch((err) => {
            dispatch(secilenEtkinlikDetayLoading(false));
            if(err.response){
                showToast(err.response.data.messages);
            }else{
                showToast([{type:"error",message:err.message,second:5}]);
            }
        })
    }
}
