// reducer/Etkinlik/secilenEtkinlikDetayReducer.js

import {
    SECILEN_ETKINLIK_DETAY_REQUEST,
    SECILEN_ETKINLIK_DETAY_SUCCESS,
    ETKINLIK_KATIL_EYLEM_SUCCESS,
    ETKINLIK_ISTEGI_GERI_CEK_SUCCESS,
    ETKINLIK_KATIL_ISTEK_GERI_CEK_EYLEM_LOADING,
    SECILEN_ETKINLIK_DATA_CLEAR,
    SECILEN_ETKINLIK_DETAY_LOADING
} from '../../types';

const initialState = {
    loading: false,
    secilenEtkinlikDetayData: [],
    error: '',
    message: null,
    etkinlikKatilimIstekVeGeriCekLoading: false
}

const secilenEtkinlikDetayReducer = (state = initialState, action) => {
    switch (action.type) {
        case SECILEN_ETKINLIK_DETAY_REQUEST:
            return {
                ...state,
                message: null
            }
        case SECILEN_ETKINLIK_DETAY_SUCCESS:
            return {
                message: null,
                secilenEtkinlikDetayData: action.payload,
                error: ''
            }
        case ETKINLIK_KATIL_EYLEM_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                secilenEtkinlikDetayData: {
                    ...state.secilenEtkinlikDetayData,
                    participation_status: "pending"
                },
                error: '',
                etkinlikKatilimIstekVeGeriCekLoading: false
            };
        case ETKINLIK_ISTEGI_GERI_CEK_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                secilenEtkinlikDetayData: {
                    ...state.secilenEtkinlikDetayData,
                    participation_status: "canceled"
                },
                error: '',
                etkinlikKatilimIstekVeGeriCekLoading: false
            };
        case ETKINLIK_KATIL_ISTEK_GERI_CEK_EYLEM_LOADING:
            return {
                ...state,
                etkinlikKatilimIstekVeGeriCekLoading: action.payload
            };
        case SECILEN_ETKINLIK_DATA_CLEAR:
            return {
                ...state,
                secilenEtkinlikDetayData: []
            }
        case SECILEN_ETKINLIK_DETAY_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default: return state
    }
}

export default secilenEtkinlikDetayReducer;
