// matchedUsersReducer.js dosyasında
const initialState = {
    userIdToMatchedUsers: [],
};

const matchedUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MATCHED_USERS':
            return {
                ...state,
                userIdToMatchedUsers: action.payload,
            };
        default:
            return state;
    }
};

export default matchedUsersReducer;
