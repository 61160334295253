import React, {useEffect, useState} from "react";
import axios from "../utils/CustomAxios";
import {showToast} from "../utils/Functions";
import UyeBittiLoading from "./UyeBittiLoading";
import {useTranslation} from "react-i18next";

export default function Sozlesme({categoryId, pageId}) {
    const {t} = useTranslation();
    const [contracts, setContracts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    useEffect(() => {
        setLoading(false)
        axios.post(`auth/contracts/${categoryId}/${pageId}`).then((res) => {
            if (res.data.status === 1) {
                setContracts(res.data.data)
                setLoading(true)
            }
        }).catch((err) => {
            if (err.response) {
                showToast(err.response.data.messages);
            } else {
                showToast([{type: "error", message: err.message, second: 5}]);
            }
        })
    },[categoryId, pageId])

    return (
        <div style={{width: "100%", height: "100%"}} className="px-3 py-3 active-scroll">
            {loading === false ? (
                <div style={{width: "100%", height: "100%"}}>
                    <div style={{width: "100%", height: "50%"}}>
                        <UyeBittiLoading width={width} height={height}/>
                    </div>
                    <div style={{width: "100%", height: "20%"}} className="flex flex-column justify-start items-center">
                        <div className="flex flex-column items-center">
                            <span className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" : "uyeBittiLoadingUyariTextFontDesktopStyle"}`}>
                                {t("yukleniyor")}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="sozlesmelerTextStyle" dangerouslySetInnerHTML={{__html: `${contracts?.page_text}`}} />
            )}
        </div>
    )
}
