import React, {useEffect, useState} from "react";
import "../../styles/main.css";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import UyeBittiLoading from "../../components/UyeBittiLoading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faMobilePhone, faGear} from "@fortawesome/free-solid-svg-icons";
import CameraSlashSvg from "../../assets/svg/CameraSlashSvg";


function CameraPermission() {
    const {t} = useTranslation();
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    const cameraPermissionDivStyle = {
        width: isMobile ? width : width <= 475 ? width : 425,
        height: isMobile ? height : height <= 795 ? height : 788,
        borderWidth: isMobile ? 0 : 1,
        borderRadius: isMobile ? 0 : 8,
    }
    const cameraPermissionDivStyle1 = {
        width: "100%",
        height: "100%",
    }
    const cameraPermissionDivStyle2 = {
        width: "100%",
        height: 51,
        borderBottomWidth: 1,
        backgroundColor: "#ffffff",
        borderTopRightRadius: isMobile ? 0 : 8,
        borderTopLeftRadius: isMobile ? 0 : 8
    }
    const cameraPermissionDivStyle3 = {
        width: "100%",
        height: "93.513%",
        backgroundColor: "#fbfafa"
    }

    return (
        <div className="flex justify-center items-center" style={cameraPermissionDivStyle}>
            <div style={cameraPermissionDivStyle1} className="flex-column">
                <div className="flex justify-content-center align-items-center " style={cameraPermissionDivStyle2}>
                    <div className="anaSayfaMarkaTextStyle tiklanmaEngelle logoTextStyle1">
                        MOZARED
                    </div>
                </div>
                <div style={cameraPermissionDivStyle3}>
                    <div style={{width: "100%", height: "35%"}}>
                        <UyeBittiLoading/>
                    </div>
                    <div className="flex justify-center items-center" style={{width: "100%", height: "65%"}}>
                        <div className="flex flex-column justify-start items-center" style={{width: "85%", height: "100%"}}>
                            <CameraSlashSvg style={{width: 32, height: 32, color: "#21262E", opacity: 0.50, marginBottom: 16, marginTop: 24}}/>
                            <span className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" : "uyeBittiLoadingUyariTextFontDesktopStyle"}`}>{t("uygulamayi_kullanmaya_devam_etmek_istiyorsaniz_kameraya_izin_vermelisiniz")}</span>
                            <div className="mt-4 mb-3">
                                <FontAwesomeIcon icon={faMobilePhone} style={{color: "#21262E", opacity: 0.50}} className="fa-xl"/>
                                <FontAwesomeIcon icon={faAngleRight} style={{color: "#21262E", opacity: 0.50}} className="fa-lg mr-2 ml-2"/>
                                <FontAwesomeIcon icon={faGear} style={{color: "#21262E", opacity: 0.50}} className="fa-xl"/>
                            </div>
                            <span className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" : "uyeBittiLoadingUyariTextFontDesktopStyle"}`}>{t("uygulama_izinlerini_telefon_ayarlarindan_degistirebilirsiniz")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CameraPermission;
