// actions/Etkinlik/etkinligeKatilimIsteginiReddetEylemAction.js

import axios from "../../../../utils/CustomAxios";
import {
    ETKINLIGE_REDDET_EYLEM_SUCCESS,
    ETKINLIK_KABUL_VE_RED_BUTTON
} from '../../../types';
import {showToast} from "../../../../utils/Functions";
import {
    etkinlikKatilimIstekleriReddedilenlerAction
} from "../IsteklerUyelerReddedilenlerDataCek/etkinlikKatilimIstekleriReddedilenlerAction";

export const etkinligeReddetEylemSuccess = (message) => {
    return {
        type: ETKINLIGE_REDDET_EYLEM_SUCCESS,
        payload: message
    }
}

export const etkinlikKabulVeRedButton = data => {
    return {
        type: ETKINLIK_KABUL_VE_RED_BUTTON,
        payload: data
    }
};

export const etkinligeKatilimIsteginiReddetEylemAction = (customerId, secilenEventId, etkinlikKatilimIstekleriData) => {
    return (dispatch) => {
        return axios.put(`customer/event-participant/reject/${secilenEventId}`, {customer_id: customerId})
            .then((res) => {
                showToast(res.data.messages);
                if (res.data.status === 1) {
                    dispatch(etkinligeReddetEylemSuccess(res.data.messages))
                    let updatedParticipants = etkinlikKatilimIstekleriData.event_participants.filter((item) => {
                        return parseInt(item.customer_id) !== parseInt(customerId);
                    });
                    let updatedData = {...etkinlikKatilimIstekleriData, event_participants: updatedParticipants};
                    dispatch(etkinlikKabulVeRedButton(updatedData));
                    dispatch(etkinlikKatilimIstekleriReddedilenlerAction(secilenEventId)); // Reddedilenleri güncelle
                }
            })
            .catch((err) => {
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{type: "error", message: err.message, second: 5}]);
                }
            });
    }
}
