import React from "react";
import "../styles/main.css";


export default function EvcilHayvanSigaraKullanimiSelect({handleDataSelectButton, data, burc, evcilHayvan, sigaraKullanimi, ilgiAlanlari}) {
    const x = (id) => {
        handleDataSelectButton(id)
    }

    return (
        <div className="flex flex-wrap justify-content-center align-items-center py-2" style={{backgroundColor: "#fff"}}>
            {data?.map((item) => (
                <div onClick={() => x(item.value)} key={item.value} className={`mr-1 ml-1 mb-1 px-3 py-1 cursorPointer ${item.label === evcilHayvan || item.label === sigaraKullanimi || item.label === ilgiAlanlari ? "profilDuzenleModalStyle22" : "profilDuzenleModalStyle11"}`}>
                    <div className="profilDuzenleTextStyle03 tiklanmaEngelle">{item?.label}</div>
                </div>
            ))}
        </div>
    )
}
