import "../styles/main.css";
const IlerlemeCubugu = (props) => {
    const { completed } = props;

    const containerStyles = {
        height: "100%",
        width: '100%',
        backgroundColor: "#e0e0de"
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`
    }

   /* const labelStyles = {
        padding: 5,
        color: 'white',
    }*/

    return (
        <div style={containerStyles}>
            <div style={fillerStyles} className="progressBarStyle11 tiklanmaEngelle">
                {/*<span style={labelStyles}>{`${completed}%`}</span>*/}
            </div>
        </div>
    );
};

export default IlerlemeCubugu;
