import axios from "axios";

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_LINK}/${process.env.REACT_APP_API_VERSION}`,
    withCredentials: false,
});

instance.interceptors.request.use(async function (config) {

    const token = localStorage.getItem("token");
    const locale = localStorage.getItem("i18nextLng") || "en";

    if (token) {
        config.headers["language"] = locale ? locale : 'en';
        config.headers["Authorization"] = "Bearer " + token;
    } else {
        config.headers["language"] = 'en';
    }

    return config;
});

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // console.log(error.response);
        if (error.response?.status === 401) {
            if (window.location.pathname !== "/logout") {
                localStorage.removeItem('token');
                localStorage.removeItem('customer_data');
                window.location = '/AnaSayfa';
            }
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);

export default instance;
