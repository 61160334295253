import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import "../styles/main.css";
import HotOrNot from "./HotOrNot/HotOrNot";
import SeniBegenenlerVeSeninBegendiklerin from "./Begeniler/SeniBegenenlerVeSeninBegendiklerin";
import KonusmaListesi from "./Konusmalar/KonusmaListesi";
import Profil from "./Profil/Profil";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import axios from "../utils/CustomAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faComments,
  faHeart,
  faCompass,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import EtkinlikListesi from "./Etkinlik/EtkinlikListesi";
import socketIOClient from "socket.io-client";
import { profileLogo, showToast } from "../utils/Functions";
import Bildirim from "./Bildirim/Bildirim";
import { HotOrNotIcon } from "../assets/svg/HotOrNotIcon";
import { EventIcon } from "../assets/svg/EventIcon";
import { Logo } from "../assets/svg/Logo";
import HaritaScreen from "./Harita/HaritaScreen";
import {animated, useSpring} from "@react-spring/web";
import {useDrag} from "@use-gesture/react";
import { useDispatch } from "react-redux";
import { paketAction } from "../store/actions/paketAction";

export default function AnaSayfa({ socketRef }) {
  const token = localStorage.getItem("token");
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 50,
    });
  let location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const aktifPaketVarMi = useSelector((state) => state.paketDurumBilgisi.hasActivePackage);
  const [tiklananId, setTiklananId] = useState("haritaScreen-id");
  let mesajlarYonlendir =
    location?.state?.id === "mesajlarGeriId"
      ? "message-id"
      : location?.state?.id === "etkinlikDetayGeriId"
      ? "etkinlik-id"
      : location?.state?.id === "begeniSayfasiGeriId"
      ? "begeni-id"
      : tiklananId;
  let ayarlarYonlendir =
    location?.state?.id === "profilSayfalariGeriId" ? "acik" : "kapali";
  let [focus, setFocus] = useState(mesajlarYonlendir);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  let [profileStatus, setProfileStatus] = useState(ayarlarYonlendir);
  const [messageList, setMessageList] = useState([]);
  const [mapMessageList, setMapMessageList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [mapRoomList, setMapRoomList] = useState([]);
  const [bildirim, setBildirim] = useState("bildirim-kapali-id");
  const [notificationList, setNotificationList] = useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [focusKonusmaListesiInput, setFocusKonusmaListesiInput] = useState(false);
  const storedDevicePlatform = localStorage.getItem("devicePlatform");
  const [user, setUser] = useState(null);
  const [totalMessageCount, setTotalMessageCount] = useState(0);
  const [girisYapUyarilar, setGirisYapUyarilar] = useState("");
  const [modal, setModal] = useState("modalKapatId");

  useEffect(() => {
    setFocus(mesajlarYonlendir);
    setBildirim("bildirim-kapali-id");
  }, [mesajlarYonlendir]);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const konumSenkronizasyonu = async () => {
    axios
      .post(`customer/location`, {
        latitude: coords.latitude,
        longitude: coords.longitude,
      })
      .then((res) => {
        if (res.data.status === 1) {

        }
      })
      .catch((err) => {
        /*console.log(err);*/
      });
  };
  useEffect(() => {
    if (token) {
      if (isGeolocationAvailable) {
        if (isGeolocationEnabled) {
          if (coords) {
            konumSenkronizasyonu();
          }
        }
      }
    }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled, token]);
  useEffect(() => {
    let user_data = JSON.parse(localStorage.getItem("customer_data"));
    if (!user_data) {
      localStorage.removeItem("token");
      localStorage.removeItem("customer_data");
    }

    if (token) {
      user_data.profilePhotoThumb = user_data?.media
        ? user_data?.media[0]
          ? user_data?.media[0].url +
            "conversations/preview_thumbnail-" +
            user_data?.media[0]?.file_name
          : ""
        : "";
      user_data.profilePhoto = user_data?.media
        ? user_data?.media[0]
          ? user_data?.media[0].url + user_data?.media[0]?.file_name
          : ""
        : "";
    }
    setUser(user_data);

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [navigate]);

  const focusClick = (id) => {
    setFocus(id);
    setTiklananId(id);
    navigate(`${location.search}`, { state: { id: "", name: "" } });
  };
  const openNav = () => {
    setProfileStatus("acik");
  };
  const closeNav = () => {
    navigate("", { state: { id: "", name: "" } });
    setProfileStatus("kapali");
    document.getElementById("myNav").style.width = "0%";
    document.getElementById("myNav").style.borderWidth = "0px";
    document.getElementById("myNav").style.borderRadius = "0px";
  };

  // Odaları sıralama ve güncelleme işlevi
  const sortAndUpdateRooms = (currentRoomList, newMessage) => {
    let roomsWithTimestamp = currentRoomList
      .filter((room) => room.lastMessageTimestamp !== undefined)
      .map((room) => {
        if (newMessage && room?.id === newMessage?.group_id) {
          return {
            ...room,
            lastMessage: newMessage.message,
            lastMessageRead: newMessage.lastMessageRead,
            lastMessageTimestamp: newMessage.createdDate,
            lastMessageSender: newMessage.lastMessageSender,
            readBy: newMessage.readBy
          };
        }
        return room;
      })
      .sort(
        (a, b) =>
          new Date(b.lastMessageTimestamp) - new Date(a.lastMessageTimestamp)
      );

    let roomsWithoutTimestamp = currentRoomList
      .filter((room) => room.lastMessageTimestamp === undefined)
      .sort(() => Math.random() - 0.5);

    return [...roomsWithTimestamp, ...roomsWithoutTimestamp];
  };

  const updateRoomList = (groupId, messageContent) => {
    setRoomList((currentRoomList) => {
      return currentRoomList.map((room) => {
        if (room.id === groupId) {
          // İlgili mesaj ve okuma durumunu güncelle
          return {
            ...room,
            lastMessage: messageContent,
            lastMessageRead: false,
          };
        }
        return room;
      });
    });
  };

  const userProfileImages = {};

  const loadUserProfileImage = async (userId, firstName, lastName) => {
    if (!userProfileImages[userId]) {
      try {
        const response = await axios.get(`customer/explore/${userId}`);
        if (response.status === 200) {
          const media = response?.data?.data?.customer?.media;
          const image =
              media.length > 0 && media !== null
                  ? media[0]?.url + media[0]?.file_name
                  : profileLogo(firstName, lastName);
          userProfileImages[userId] = image;
        } else {
          userProfileImages[userId] = profileLogo(firstName, lastName);
        }
      } catch (error) {
        userProfileImages[userId] = profileLogo(firstName, lastName);
      }
    }
    return userProfileImages[userId];
  };

  const socket = () => {
    const customer_data = JSON.parse(localStorage.getItem("customer_data"));
    const playerId = localStorage.getItem('oneSignalToken');

    if (!customer_data || !customer_data.id || isNaN(parseInt(customer_data.id))) {
      console.error("Invalid customer data or customer ID.");
      return;
    }

    // Önceki bağlantıyı kapat
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    // Yeni bir soket bağlantısı oluştur
    socketRef.current = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT, {
      query: { token: token },
    });

    // Socket bağlantısı kurulduğunda playerId'yi gönder
    socketRef.current.on("connect", () => {
      if (playerId) {
        socketRef.current.emit("register_player_id", {
          userId: customer_data?.id,
          playerId: playerId,
        });

        axios.put(`customer/profile/player-id`, {player_id: playerId})
            .then((res) => {
              if (res.data.status === 1) {

                }
              })
              .catch((err) => {
                console.log(err);
              });
      }

      // Paket durumu kontrolü yap
      socketRef.current.emit('check_package_status', { userId: customer_data?.id });

      // Paket durum yanıtını işleyin
      socketRef.current.on('package_status', (response) => {
        if (response.hasActivePackage) {
          dispatch(paketAction(response.hasActivePackage));
        }
      });
    });

    // Soketten gelen bildirimleri işle
    socketRef.current.on("notification", (notification) => {
      // Bildirim listesini güncelle
      setNotificationList((current) => [...notification, ...current]);
      notification.forEach((item) => {
        if (item.type === "message") {
          let groupId = item.extra_data.group_id;

          // İlgili konuşmayı bul ve mesaj bilgisini güncelle
          updateRoomList(groupId, item.content);
        }
      });
      showToast(notification);
    });
    socketRef.current.on("notifications", (notifications) => {
      setUnreadNotificationCount(notifications.unread_count);
      setNotificationList(notifications.notifications);
    });
    socketRef.current.on("send_message", (msg) => {
      if (!msg.userId || isNaN(parseInt(msg.userId))) {
        console.error("Invalid message user ID:", msg.userId);
        return;
      }

      let message = {
        id: msg._id,
        userId: parseInt(msg.userId),
        username: msg.username,
        message: msg?.message || msg?.content,
        readMessage: msg.readMessage,
        readBy: msg.readBy,
        readAt: msg.readAt,
        readByDetails: msg.readByDetails,
        createdDate: msg.createdDate,
        replyMessage: msg.replyMessage,
        editContent: msg.editContent,
        edited: msg.edited
      };

      if (msg.currentRoom) {
        let currentUser = msg.currentRoom.users.find((item) => {
          return parseInt(item.userId) === parseInt(customer_data.id);
        });
        if (currentUser) {
          message.color = currentUser.color;
        }
      }

      setMessageList((current) => [...current, message]);

      // Odaları sıralayıp güncelle
      setRoomList((currentRoomList) =>
        sortAndUpdateRooms(currentRoomList, msg)
      );
    });
    socketRef.current.on('rooms', async ({ groups, mapGroups }) => {
      const processRooms = async (rooms) => {
        return await Promise.all(
            rooms?.map(async (element) => {
              let name = element.name;
              let image = null;
              let users = element.users;

              if (element.type === "match") {
                const filteredUsers = element.users.filter(
                    (item) => item.userId !== parseInt(customer_data.id)
                );

                if (filteredUsers.length > 0) {
                  name = `${filteredUsers[0].first_name} ${filteredUsers[0].last_name}`;

                  try {
                    const response = await axios.get(
                        `customer/explore/${filteredUsers[0].userId}`
                    );
                    if (response.status === 200) {
                      const media = response?.data?.data?.customer?.media;

                      image =
                          media.length > 0 && media !== null
                              ? media[0]?.url + media[0]?.file_name
                              : profileLogo(
                                  filteredUsers[0]?.first_name,
                                  filteredUsers[0]?.last_name,
                                  filteredUsers[0]?.color
                              );
                    }
                  } catch (error) {
                    image = profileLogo(
                        filteredUsers[0].first_name,
                        filteredUsers[0].last_name,
                        filteredUsers[0]?.color
                    );
                  }
                }
              } else {
                image = profileLogo(element.name.trimStart(), null, element.roomColor);
              }

              return {
                name: name,
                id: element._id,
                lastMessage: element.lastMessage,
                lastMessageTimestamp: element.lastMessageTimestamp,
                lastMessageRead: element.lastMessageRead,
                lastMessageSender: element.lastMessageSender,
                image: image,
                users: users,
                type: element.type,
                event_id: element.eventId,
                readBy: element.readBy
              };
            })
        );
      };

      const groupRooms = await processRooms(groups);
      const mapGroupRooms = await processRooms(mapGroups);

      const sortedRooms = sortAndUpdateRooms(groupRooms);
      const sortedMapGroupRooms = sortAndUpdateRooms(mapGroupRooms);

      setRoomList(sortedRooms);
      setMapRoomList(sortedMapGroupRooms);
    });
    socketRef.current.on("old_messages", (data) => {
      const { messages, totalMessagesCount } = data; // Sunucudan gelen totalMessagesCount değişkenini kullan

      const formattedMessages = messages?.map((element) => {
        const currentUser = data?.room?.users?.find(
          (user) => user?.userId === parseInt(element?.userId)
        );
        return {
          id: element._id,
          userId: element.userId,
          username: element.username,
          message: element.content,
          readMessage: element.readMessage,
          readBy: element.readBy,
          readByDetails: element.readByDetails,
          readAt: element.readAt,
          createdDate: element.createdDate,
          color: currentUser ? currentUser.color : null,
          replyMessage: element.replyMessage,
          editContent: element.editContent,
          edited: element.edited
        };
      });

      setMessageList(formattedMessages); // Mesaj listesini state'e kaydet
      setTotalMessageCount(totalMessagesCount); // Toplam mesaj sayısını state'e kaydet
    });
    socketRef.current.on("group_updated", (updatedGroup) => {
      setRoomList((currentRooms) => {
        return currentRooms.map((room) => {
          if (room.id === updatedGroup.groupId) {
            // Eğer güncellenen grup mevcut oda ise, lastMessageRead değerini ve diğer ilgili bilgileri güncelle
            return {
              ...room,
              lastMessageRead: updatedGroup.lastMessageRead,
              readBy: updatedGroup.readBy
            };
          }
          return room;
        });
      });
    });

    socketRef.current.on("send_map_message", async (msg) => {
      if (!msg.userId || isNaN(parseInt(msg.userId))) {
        console.error("Invalid message user ID:", msg.userId);
        return;
      }

      // Kullanıcı profil resmini yükle veya önceden yüklenmiş olanı kullan
      let image = null;
      if (msg.userId) {
        const currentUser = {
          userId: msg.userId,
          first_name: msg.username.split(" ")[0],
          last_name: msg.username.split(" ")[1] || ""
        };
        image = await loadUserProfileImage(currentUser.userId, currentUser.first_name, currentUser.last_name);
      }

      let message = {
        id: msg._id,
        userId: parseInt(msg.userId),
        username: msg.username,
        message: msg.content,
        createdDate: msg.createdDate,
        lat: msg.lat,
        lng: msg.lng,
        replyMessage: msg.replyMessage,
        image: image,
        zoomLevel: msg.zoomLevel
      };

      setMapMessageList((current) => {
        const updatedMessages = [...current, message];
        updatedMessages.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));
        return updatedMessages;
      });
    });
    socketRef.current.on("old_map_messages", async (data) => {
      const { messages, totalMessagesCount } = data;

      const processMessages = async (messages) => {
        return await Promise.all(
            messages?.map(async (element) => {
              const currentUser = data?.room?.users?.find(
                  (user) => user?.userId === parseInt(element?.userId)
              );

              let image = null;
              if (currentUser) {
                try {
                  const response = await axios.get(
                      `customer/explore/${currentUser.userId}`
                  );
                  if (response.status === 200) {
                    const media = response?.data?.data?.customer?.media;

                    image =
                        media.length > 0 && media !== null
                            ? media[0]?.url + media[0]?.file_name
                            : profileLogo(
                                currentUser?.first_name,
                                currentUser?.last_name
                            );
                  }
                } catch (error) {
                  image = profileLogo(
                      currentUser?.first_name,
                      currentUser?.last_name
                  );
                }
              }

              return {
                id: element._id,
                userId: element.userId,
                username: element.username,
                message: element.content,
                createdDate: element.createdDate,
                color: currentUser ? currentUser.color : null,
                image: image,
                lat: element.lat,
                lng: element.lng,
                replyMessage: element.replyMessage,
                zoomLevel: element.zoomLevel
              };
            })
        );
      };

      const formattedMessages = await processMessages(messages);
      formattedMessages.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));

      setMapMessageList(formattedMessages);
      setTotalMessageCount(totalMessagesCount);
    });
    socketRef.current.on("user_location_updated", (data) => {
      const { userId, groupId, lat, lng, zoomLevel } = data;

      setMapRoomList((currentRooms) => {
        return currentRooms.map((room) => {
          if (room.id === groupId) {
            return {
              ...room,
              users: room.users.map((user) => {
                if (user.userId === userId) {
                  return {
                    ...user,
                    lat: lat,
                    lng: lng,
                    zoomLevel: zoomLevel
                  };
                }
                return user;
              })
            };
          }
          return room;
        });
      });
    });

    // Diğer kullanıcıların durumunu dinleyin
    socketRef.current.on("update_user_status", (data) => {
      const { userId, groupId, isOnline } = data;

      setRoomList((currentRoomList) =>
          currentRoomList.map((room) => {
            if (room.id === groupId) {
              return {
                ...room,
                users: room.users.map((user) =>
                    user.userId === userId ? { ...user, isOnline: isOnline } : user
                ),
              };
            }
            return room;
          })
      );
    });
    socketRef.current.on("update_user_map_status", (data) => {
      const { userId, groupId, isOnline } = data;

      setMapRoomList((currentRoomList) =>
          currentRoomList.map((room) => {
            if (room.id === groupId) {
              return {
                ...room,
                users: room.users.map((user) =>
                    user.userId === userId ? { ...user, isOnline: isOnline } : user
                ),
              };
            }
            return room;
          })
      );
    });

    // Diğer kullanıcıların sohbet durumunu dinleyin
    socketRef.current.on("user_in_chat_status", (data) => {
      const { userId, groupId, inChat } = data;

      setRoomList((currentRoomList) =>
          currentRoomList.map((room) => {
            if (room.id === groupId) {
              return {
                ...room,
                users: room.users.map((user) =>
                    user.userId === userId ? { ...user, inChat: inChat } : user
                ),
              };
            }
            return room;
          })
      );
      // Burada kullanıcı durumu ile ilgili gerekli işlemleri yapabilirsiniz
    });
    socketRef.current.on('message_edited', (msg) => {
      setMessageList((current) =>
          current.map((message) =>
              message.id === msg._id ? {...message, editContent: msg.editContent, edited: msg.edited} : message
          )
      );

      // Cevap verilen mesajların içeriğini güncelle
      setMessageList((current) =>
          current.map((message) =>
              message.replyMessage && message.replyMessage._id === msg._id
                  ? {
                    ...message,
                    replyMessage: {
                      ...message.replyMessage,
                      editContent: msg.editContent,
                      edited: msg.edited,
                    },
                  }
                  : message
          )
      );

      updateRoomList(msg.group_id, msg.editContent);
    });
  };

  useEffect(() => {
    if (token) {
      socket();
    }
  }, [token]);

  const bildirimAcButon = (id) => {
    socketRef.current.emit("read_all_notifications");
    setBildirim(id);
  };
  const bildirimKapatButon = (id) => {
    setBildirim(id);
  };
  const inputFocusButton = (e) => {
    setFocusKonusmaListesiInput(e);
  };
  const girisYapUyariModal = (uyarilar) => {
    setModal("tokensiz-giris-uyari-modal-id");
    setGirisYapUyarilar(uyarilar);
  };
  const tokensizGirisButonu = (id) => {
    window.location = `/Baslangic?devicePlatform=${localStorage.getItem(
      "devicePlatform"
    )}`;
  };

  const anaSayfaDivStyle = {
    width: isMobile ? width : width <= 475 ? width : 425,
  };
  const anaSayfaDivStyle1 = {
    width: isMobile ? width : width <= 475 ? width : 425,
    height: 48,
    position: "relative",
    zIndex: 52,
    backgroundColor: "#fff",
  };
  const anaSayfaDivStyle2 = {
    position: "relative",
    zIndex: 51,
  };
  const anaSayfaDivStyle3 = {
    width: isMobile ? width : width <= 475 ? width : 425,
    height: 48,
    position: "relative",
    zIndex: 52,
    backgroundColor: "#fff",
    borderTopWidth: 1,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  };
  const bildirimDivStyle1 = {
    width: isMobile ? width : width <= 475 ? width : 425,
    height: isMobile ? height : height <= 795 ? height : 788,
    position: "absolute",
    zIndex: 10000,
  };
  const bildirimDivStyle2 = {
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderRadius: isMobile ? 0 : 8,
  };

  const [{ y }, api] = useSpring(() => ({ y: 0 }));

  const bind = useDrag(({ movement: [, my], velocity, last }) => {
    // Eşik değeri (80 piksel)
    const threshold = 80;

    if (last) {
      // Eşik değerini geçip geçmediğine bakıyoruz
      if (my > threshold || velocity > 0.05) {
        // Eşik geçilmişse modalı tamamen kaydır
        api.start({ y: 1000 }); // Modalı ekrandan çıkar
        setTimeout(() => {
          api.start({ y: 0 }); // y pozisyonunu sıfırla
          // Modal türüne göre işlemleri yap
          setModal("modalKapatId");
        }, 300); // Animasyon süresi
      } else {
        // Eşik geçilmediyse modalı eski yerine getir
        api.start({ y: 0 });
      }
    } else {
      // Eşik değerinden önce modalın parmağı takip etmesini sağla
      if (my > 0) {
        api.start({ y: my }); // Modalı parmak hareketine göre kaydır
      }
    }
  });

  const modalSpring = useSpring({
    transform: modal === "paket-modal-id" ? "translateY(0%)" : "translateY(100%)",  // Açılma ve kapanma animasyonu
    opacity: modal === "paket-modal-id" ? 1 : 0,
    config: { tension: 300, friction: 30 }, // Animasyon hızını ve ataletini ayarlar
  });

  return (
    <>
      {!isMobile && (height <= 300 || width <= 275) ? (
        <div className="flex justify-center items-center capitalize">
          <span className="ekranDesteklenmiyorTextStyle text-center px-3">
            {t("ekran_boyutu_desteklenmiyor")}
          </span>
        </div>
      ) : (
        <div className="flex" style={anaSayfaDivStyle}>
          <div style={{ position: "relative" }} className=" tiklanmaEngelle">
            {/*profil sayfası*/}
            {token && (
              <Profil
                profileStatus={profileStatus}
                user={user}
                closeNav={() => closeNav()}
              />
            )}

            {/* <Bildirim bildirim={bildirim}  bildirimKapatButon={() => bildirimKapatButon()}/>*/}
            {/*header ve profil giriş butonu*/}
            <div style={anaSayfaDivStyle1}
              className={`flex ${
                isMobile ? "" : "borderTopStyle1 boxShadowTop"
              } `}>
              <div style={{ width: "15%", height: "100%" }}
                className="flex justify-content-center align-items-center cursorPointer"
                onClick={() => (!token ? girisYapUyariModal(t("profile_erisebilmek_icin_lutfen_oturum_acin")) : openNav())}>
                {user?.profilePhotoThumb && token ? (
                  <div className="flex justify-center items-center profilFotoStyle" style={{ borderWidth: 1 }}>
                    <img style={{
                        width: 28,
                        height: 28,
                        borderRadius: "100%",
                        objectFit: "cover",
                      }}
                      className="tiklanmaEngelle"
                      src={user?.profilePhotoThumb || ""}
                      alt=""
                    />
                  </div>
                ) : (
                  <div style={{ borderWidth: 1, borderRadius: 100 }}>
                    <Logo className={`profilFotoStyle tiklanmaEngelle`} />
                  </div>
                )}
              </div>
              <div style={{ width: "70%", height: "100%" }}
                   className="flex justify-content-center align-items-center logoTextStyle1">
                <Link to={`/Baslangic${location.search}`} className="anaSayfaMarkaTextStyle tiklanmaEngelle">
                  MOZARED
                </Link>
              </div>
              <div onClick={() =>
                  !token ? girisYapUyariModal(t("bildirimlere_erisebilmek_icin_lutfen_oturum_acin")) : bildirimAcButon("bildirim-acik-id")
                } style={{ width: "15%", height: "100%" }} className="flex justify-center items-center">
                <div style={{ position: "relative", zIndex: 1 }}
                  className={`flex ${
                    unreadNotificationCount > 0
                      ? "bildirimButtonuStyle1"
                      : "bildirimButtonuStyle"
                  } justify-center items-center`}>
                  <FontAwesomeIcon
                    icon={faBell}
                    className="fa-xl bildirimButtonuStyleSvg"
                  />
                </div>
                {token && (
                  <>
                    {unreadNotificationCount ? (
                      <div style={{
                          width: 18,
                          height: 18,
                          position: "absolute",
                          zIndex: 2,
                          right: "2%",
                          top: "10%",
                          backgroundColor: "#eb3428",
                          borderRadius: 100,
                        }} className="flex justify-center items-center">
                        <span className="bildirimTextStyle tiklanmaEngelle">
                          {unreadNotificationCount >= 10
                            ? "9+"
                            : unreadNotificationCount}
                        </span>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            {/*ana ekranlar*/}
            <div style={anaSayfaDivStyle2}>
              {focus === "home-id" ? (
                <HotOrNot
                  user={user}
                  loading={loading}
                  coords={coords}
                  isGeolocationEnabled={isGeolocationEnabled}
                  isGeolocationAvailable={isGeolocationAvailable}
                  setLoading={setLoading}
                  girisYapUyariModal={girisYapUyariModal}
                />
              ) : focus === "haritaScreen-id" ? (
                <HaritaScreen
                  user={user}
                  coords={coords}
                  isGeolocationEnabled={isGeolocationEnabled}
                  isGeolocationAvailable={isGeolocationAvailable}
                  girisYapUyariModal={girisYapUyariModal}
                  mapRoomList={mapRoomList}
                  mapMessageList={mapMessageList}
                  socketRef={socketRef}
                  setModal2={setModal}
                />
              ) : focus === "etkinlik-id" ? (
                <EtkinlikListesi user={user}  girisYapUyariModal={girisYapUyariModal}/>
              ) : focus === "begeni-id" ? (
                <SeniBegenenlerVeSeninBegendiklerin user={user}  girisYapUyariModal={girisYapUyariModal}/>
              ) : focus === "message-id" ? (
                <KonusmaListesi
                  setMessageList={setMessageList}
                  messageList={messageList}
                  roomList={roomList}
                  socketRef={socketRef}
                  user={user}
                  socket={socket}
                  setRoomList={setRoomList}
                  inputFocusButton={inputFocusButton}
                  focusKonusmaListesiInput={focusKonusmaListesiInput}
                  totalMessageCount={totalMessageCount}
                  setTotalMessageCount={setTotalMessageCount}
                  girisYapUyariModal={girisYapUyariModal}
                />
              ) : null}
            </div>
            {/*alt butonlar*/}
            <>
              {focusKonusmaListesiInput === true ? null : (
                <div style={anaSayfaDivStyle3}
                  className={`${
                    storedDevicePlatform === "ios"
                      ? "grid grid-cols-3 gap-3"
                      : "grid grid-cols-5 gap-4"
                  } items-center justify-center ${
                    isMobile ? "" : "borderBottomStyle1 boxShadowBottom"
                  }  cursorPointer`}>
                  <div onClick={() => focusClick("etkinlik-id")}
                    className="flex items-center justify-center event-icon py-2">
                    {focus === "etkinlik-id" ? (
                      <EventIcon className={""} active={true} />
                    ) : (
                      <EventIcon className={""} />
                    )}
                  </div>

                  {storedDevicePlatform !== "ios" && (
                    <div onClick={() => focusClick("home-id")}
                         className="flex items-center justify-center py-1 tiklanmaEngelle">
                      {focus === "home-id" ? (
                        <HotOrNotIcon active={true} />
                      ) : (
                        <HotOrNotIcon />
                      )}
                    </div>
                  )}

                  <div className="flex items-center justify-center py-1 tiklanmaEngelle">
                    <div style={{
                        width: 55,
                        height: 35,
                        top: -10,
                        position: "absolute",
                        zIndex: 1,
                        backgroundColor: "#fff",
                        borderTopLeftRadius: 100,
                        borderTopRightRadius: 100,
                        borderTopWidth: 1,
                      }}>
                    </div>

                    <div style={{ position: "relative", zIndex: 22 }}
                      onClick={() => {
                        focusClick("haritaScreen-id");
                        if (token) {
                          socketRef.current.emit("all_chat_groups");
                        }
                      }}
                      className="harita-icon tiklanmaEngelle">
                      {focus === "haritaScreen-id" ? (
                        <FontAwesomeIcon
                          icon={faCompass}
                          style={{ fontSize: 32, color: "#ED1F24" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCompass}
                          style={{ fontSize: 32, color: "#ddd" }}
                        />
                      )}
                    </div>
                  </div>

                  {storedDevicePlatform !== "ios" && (
                    <div onClick={() => focusClick("begeni-id")}
                      className="flex items-center justify-center heart-icon py-1 tiklanmaEngelle">
                      {focus === "begeni-id" ? (
                        <FontAwesomeIcon
                          icon={faHeart}
                          className="fa-xl"
                          style={{ color: "#ED1F24" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faHeart}
                          className="fa-xl"
                          style={{ color: "#ddd" }}
                        />
                      )}
                    </div>
                  )}

                  <div onClick={() => {focusClick("message-id");
                      if (token) {
                        socketRef.current.emit("all_chat_groups");
                      }
                    }} className="flex items-center justify-center py-1 tiklanmaEngelle">
                    {focus === "message-id" ? (
                      <FontAwesomeIcon
                        icon={faComments}
                        className="fa-xl"
                        style={{ color: "#ED1F24" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faComments}
                        className="fa-xl"
                        style={{ color: "#ddd" }}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          </div>

          {bildirim === "bildirim-acik-id" && (
            <div style={bildirimDivStyle1} className=" tiklanmaEngelle">
              <div className="flex flex-column items-center justify-center" style={bildirimDivStyle2}>
                <div style={{ width: "100%", height: 51 }}
                  className={`flex justify-center items-center ${
                    isMobile ? "" : "boxShadowTop"
                  }`}>
                  <div className="flex justify-content-center align-items-center" style={{ width: "15%", height: "100%" }}></div>
                  <div className="flex justify-content-center align-items-center" style={{ width: "70%", height: "100%" }}>
                    <span className="ayarlarTextStyle01 tiklanmaEngelle">
                      {t("bildirimler")}
                    </span>
                  </div>
                  {/*modal kapat tuşu*/}
                  <div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">
                    <div onClick={() => bildirimKapatButon("bildirim-kapali-id")} className="flex box justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                    </div>
                  </div>
                </div>
                <div style={{
                    width: "100%",
                    height: "calc(100% - 51px)",
                    backgroundColor: "#fbfafa",
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                  }}
                  className="flex flex-column active-scroll">
                  <Bildirim
                    notification={notificationList}
                    isMobile={isMobile}
                    width={width}
                    height={height}
                  />
                </div>
              </div>
            </div>
          )}

          {modal === "tokensiz-giris-uyari-modal-id" && (
            <div style={{
                width: isMobile ? width : width <= 475 ? width : 425,
                height: isMobile ? height : height <= 795 ? height : 788,
                position: "absolute",
                zIndex: 555555,
                backgroundColor: "#000000a1",
                borderRadius: isMobile ? 0 : 8,
              }} className="flex justify-content-center align-items-center tiklanmaEngelle">
              <div style={{
                  width: isMobile ? "95%" : width < 350 ? width : "90%",
                  height: isMobile ? "95%" : height < 370 ? height : "90%",
                  backgroundColor: "#ffffff",
                  borderRadius: 8,
                }} className="flex-column justify-content-center align-items-center active-scroll">
                {/*modal kapat tuşu*/}
                <div style={{ width: "100%", height: 48 }} className="flex justify-content-end align-items-end pr-5">
                  <div onClick={() => setModal("modalKapatId")} className="flex box justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                  </div>
                </div>
                {/*mozared*/}
                <div className="flex-row justify-center items-center" style={{ width: "100%", height: 70 }}>
                  <div style={{ width: "100%", height: "100%" }} className="flex justify-content-center align-items-center">
                    <span className=" logoTextStyle1 tiklanmaEngelle">
                      MOZARED
                    </span>
                  </div>
                </div>
                {/*başlık*/}
                <div className="flex justify-content-center align-items-center" style={{ width: "100%", height: 70 }}>
                  <div style={{
                      width: "90%",
                      height: "100%",
                      borderBottomColor: "#ddd",
                      borderBottomWidth: 2,
                    }}>
                    <div style={{ width: "100%" }} className="flex flex-column justify-content-center align-items-center">
                      <span className="languageSelectDropDownText3 tiklanmaEngelle"></span>
                      <span className="languageSelectDropDownText4 tiklanmaEngelle">
                        {t("oturum_ac_button_text")}
                      </span>
                    </div>
                  </div>
                </div>
                {/*bilgilendirme ve konum sil butonu*/}
                <div className="flex flex-column justify-content-center align-items-center" style={{ width: "100%", height: "calc(100% - 188px)" }}>
                  <div style={{ width: "90%", height: "100%" }} className="flex flex-column align-items-center">
                    <span className="languageSelectDropDownText3 tiklanmaEngelle mt-5 text-center mx-2">
                      {girisYapUyarilar}
                    </span>

                    <div onClick={() => tokensizGirisButonu()} style={{ width: 280, height: 40 }} className="flex justify-center items-center cursorPointer etkinlikOlusturButtonStyle mt-5">
                      <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
                        {t("oturum_ac_button_text")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}


          {modal === "paket-modal-id" && (
              <animated.div {...bind()} className="modal-drag flex justify-center items-center" style={{
                width: isMobile ? width : width <= 475 ? width : 425,
                height: isMobile ? height : height <= 795 ? height : 788,
                position: "absolute", zIndex: 55555, ...modalSpring, y, touchAction: 'none'}}>
                <div className="flex flex-column items-center justify-center" style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff", borderTopRightRadius: 8, borderTopLeftRadius: 8,
                }}>
                  <div style={{width: "100%", height: 51}} className="flex justify-center items-center">
                    <div className="flex justify-content-center align-items-center" style={{ width: 64, height: "100%" }}></div>
                    <div className="flex justify-content-center align-items-center" style={{ width: "calc(100% - 128px)", height: "100%" }}>
                      <div className="scaleStyle" style={{width: "70%", height: 8, backgroundColor: "#ddd", borderRadius: 8, cursor: "pointer"}}>

                      </div>
                    </div>
                    {/* modal kapat tuşu*/}
                    <div style={{width: 64, height: "100%"}}>

                    </div>
                  </div>
                  <div style={{ width: "100%", height: "calc(100% - 51px)", backgroundColor: "#fbfafa"}} className="flex flex-column active-scroll">
                    <div style={{width: 200, height: 200}}>

                    </div>
                  </div>
                </div>
              </animated.div>
          )}
        </div>
      )}
    </>
  );
}
