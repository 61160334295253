// actions/Etkinlik/etkinlikKatilimcilariAction.js

import axios from "../../../../utils/CustomAxios";
import {
    ETKINLIK_KATILIMCILARI_REQUEST,
    ETKINLIK_KATILIMCILARI_SUCCESS,
    ETKINLIK_KATILIMCILARI_DATA_CLEAR
} from '../../../types';
import {showToast} from "../../../../utils/Functions";


export const etkinlikKatilimcilariRequest = () => {
    return {
        type: ETKINLIK_KATILIMCILARI_REQUEST
    }
}

export const etkinlikKatilimcilariSuccess = (events) => {
    return {
        type: ETKINLIK_KATILIMCILARI_SUCCESS,
        payload: events
    }
}

export const etkinlikKatilimcilariDataClear = () => {
    return {
        type: ETKINLIK_KATILIMCILARI_DATA_CLEAR
    }
}

export const etkinlikKatilimcilariAction = (secilenEventId) => {
    return (dispatch) => {
        dispatch(etkinlikKatilimcilariRequest());
        axios.get(`customer/event-participant/${secilenEventId}?filter=accepted`)
            .then((res) => {
                if (res.data.status === 1) {
                    const seciliEvent = res.data.data;
                    dispatch(etkinlikKatilimcilariSuccess(seciliEvent));
                }
            })
            .catch((err) => {
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{type: "error", message: err.message, second: 5}]);
                }
            });
    }
}
