import React, {useEffect, useRef, useState, useCallback, useMemo} from "react";
import "../../styles/main.css";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDown, faAngleUp, faCheck, faComment, faCopy, faFilter, faGear,
    faLocationPinLock, faPaperPlane, faReply, faTrash, faUserSlash, faXmark
} from "@fortawesome/free-solid-svg-icons";
import {animated, config, useChain, useSpring, useSpringRef} from "@react-spring/web";
import {useDrag} from "@use-gesture/react";
import {MenuItem, ContextMenuTrigger} from 'react-contextmenu';
import {useDispatch, useSelector} from "react-redux";
import {exploreAction} from "../../store/actions/Explore/exploreAction";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.locatecontrol/dist/L.Control.Locate.css";
import "leaflet.locatecontrol";
import UyeProfilDetay from "../../components/UyeProfilDetay";
import {Logo} from "../../assets/svg/Logo";
import CinsiyetVeCinselYonelimSec from "../../components/CinsiyetVeCinselYonelimSec";
import {datas} from "../../utils/datas";
import YasTercihi from "../../components/YasTercihi";
import UyeBittiLoading from "../../components/UyeBittiLoading";
import axios from "../../utils/CustomAxios";
import {showToast} from "../../utils/Functions";

function HaritaScreen({user, coords, isGeolocationEnabled, isGeolocationAvailable, girisYapUyariModal, socketRef, mapRoomList, mapMessageList, setModal2}) {
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();
    const mapRoomGroupId = mapRoomList[0]?.id ? mapRoomList[0]?.id : null;
    const {t} = useTranslation();
    const mapRef = useRef(null);
    const messagesEndRef = useRef(null);
    const exploreData = useSelector((state) => state.explore.exploreData);
    const aktifPaketVarMi = useSelector((state) => state.paketDurumBilgisi.hasActivePackage);
    const allUsers = useRef([]);
    const filteredUsers = useRef([]);
    let circleLayers = useRef([]);
    const mapContainerRef = useRef(null);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [genderFilter, setGenderFilter] = useState(localStorage.getItem("genderFilter") || "unspecified");
    const [orientationFilter, setOrientationFilter] = useState(localStorage.getItem("orientationFilter") || "unspecified");
    const [ageRange, setAgeRange] = useState(JSON.parse(localStorage.getItem("ageRange")) || [18, 45]);
    const [tiklananUyeId, setTiklananUyeId] = useState(null);
    const [mesajYazilanUye, setMesajYazilanUye] = useState(null);
    const [txtMapMessage, setTxtMapMessage] = useState("");
    const [txtMessage, setTxtMessage] = useState("");
    let [beniHaritadaGizleToggle, setBeniHaritadaGizleToggle] = useState(false);

    const [modal, setModal] = useState("modalKapatId");
    const [chatModal, setChatModal] = useState(true);
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
    const [chatModalType, setChatModalType] = useState("");
    const [error, setError] = useState("");

    const [isReply, setIsReply] = useState(false);
    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [mapMsg, setMapMsg] = useState(null);
    const [copyMessage, setCopyMessage] = useState(null);
    const [tempAgeRange, setTempAgeRange] = useState(ageRange);


    useEffect(() => {
        setBeniHaritadaGizleToggle(user?.hide_me_map);
    }, [user]);
    useEffect(() => {
        if (token) {
            const userGroupInfo = localStorage.getItem('userGroupInfo');
            const storedUserId = localStorage.getItem('userIdGroupInfo');
            const currentUserId = user?.id;

            if (socketRef.current) {
                // Sunucuya socket isteği yap
                socketRef.current.emit('join_map_group');
                socketRef.current.emit("all_chat_groups");


                if (!mapRoomGroupId || mapRoomList.length === 0) {
                    setIsLoading(true);
                } else {
                    socketRef.current.emit("enter_map_chat", mapRoomGroupId);
                    afterSendMessageOrJoin();
                    setIsLoading(false);
                }

                const handleGroupStatus = (data) => {
                    if (data.status === 'user_in_group') {
                        // Kullanıcının grupta olması durumunda yapılacaklar
                        localStorage.setItem('userGroupInfo', 'kullanici_grupta');
                        localStorage.setItem('userIdGroupInfo', currentUserId);
                    } else if (data.status === 'user_not_in_group') {
                        // Kullanıcının grupta olmaması durumunda yapılacaklar
                        axios.post(`customer/mapGroupChat/create`)
                            .then((res) => {
                                if (res.data.status === 1) {
                                    localStorage.setItem('userGroupInfo', 'kullanici_grupta');
                                    localStorage.setItem('userIdGroupInfo', currentUserId);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    } else if (data.status === 'group_not_found') {
                        axios.post(`customer/mapGroupChat/create`)
                            .then((res) => {
                                if (res.data.status === 1) {
                                    localStorage.setItem('userGroupInfo', 'kullanici_grupta');
                                    localStorage.setItem('userIdGroupInfo', currentUserId);
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        // Grup bulunamaması durumunda yapılacaklar
                    } else if (data.status === 'error') {
                        console.error("Sunucu hatası: ", data.message);
                        // Sunucu hatası durumunda yapılacaklar
                    }
                };

                // Kullanıcı grupta değilse veya storedUserId currentUserId ile eşleşmiyorsa sunucuya istek at
                if (!userGroupInfo || (currentUserId && currentUserId !== storedUserId)) {
                    // Kullanıcı grupta değil
                } else if (currentUserId === storedUserId) {
                    // Kullanıcı grupta ve local storage güncel
                }

                // Socket olaylarını dinle
                socketRef.current.on('group_status', handleGroupStatus);

                // Cleanup
                return () => {
                    if (socketRef.current) {
                        socketRef.current.off('group_status', handleGroupStatus);
                    }
                };
            }
        }
    }, [user, mapRoomGroupId, token, socketRef]);
    // MapContainer ve coords mevcutsa haritayı başlatır
    useEffect(() => {
        const shouldInitializeMap = () => {
            if (mapContainerRef.current && coords) {
                return true;
            }
            return false;
        };

        const tryInitializeMap = () => {
            if (shouldInitializeMap()) {
                initializeMap();
            } else {
                setTimeout(tryInitializeMap, 100); // 100ms sonra tekrar dene
            }
        };

        tryInitializeMap();

        return () => {
            if (mapRef.current) {
                saveMapState();
                mapRef.current.remove();
                mapRef.current = null;
            }
        };
    }, [mapRef.current, mapContainerRef.current, coords]);
    // Haritayı başlatır
    const initializeMap = () => {
        if (!mapContainerRef.current) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const maxZoomLevel = 15;
        const minZoomLevel = 5;
        const defaultCoords = {latitude: 39.925533, longitude: 32.866287};
        let latitude = localStorage.getItem("mapLatitude") ?? coords?.latitude ?? defaultCoords.latitude;
        let longitude = localStorage.getItem("mapLongitude") ?? coords?.longitude ?? defaultCoords.longitude;
        const initialZoom = localStorage.getItem("mapZoom") ?? 11;

        // Koordinatların geçerliliğini kontrol et
        const latitudeParsed = parseFloat(latitude);
        const longitudeParsed = parseFloat(longitude);
        if (isNaN(latitudeParsed) || isNaN(longitudeParsed) || !isValidCoordinate(latitudeParsed, longitudeParsed)) {
            console.error("Invalid coordinates:", latitude, longitude);
            latitude = defaultCoords.latitude;
            longitude = defaultCoords.longitude;
        }

        // Önceki haritayı kaldırır
        if (mapRef.current) {
            mapRef.current.remove();
            mapRef.current = null;
        }

        // Yeni haritayı başlatır
        const initializedMap = L.map(mapContainerRef.current, {
            minZoom: minZoomLevel,
            maxZoom: maxZoomLevel,
        }).setView([latitude, longitude], initialZoom);

        // Harita katmanını ekler
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: "",
        }).addTo(initializedMap);

        // locate kontrolünü ekler
        L.control.locate({
            position: 'topleft',
            drawCircle: true,
            follow: true,
            setView: 'untilPanOrZoom', // Konuma giderken belirli bir zoom seviyesi ayarlamak için
            keepCurrentZoomLevel: false, // Belirli bir zoom seviyesi kullanmak için false yapıldı
            drawMarker: false, // Konum marker'ını göstermemek için false yapıldı
            circleStyle: {
                weight: 1,
                clickable: false
            },
            icon: 'leaflet-control-locate-location-arrow',
            metric: true,
            strings: {
                title: t("bana_nerede_oldugumu_goster"),
                outsideMapBoundsMsg: t("haritanin_disindasiniz")
            },
            locateOptions: {
                maxZoom: 11,
                watch: true,
                enableHighAccuracy: true,
                maximumAge: 10000,
                timeout: 10000
            }
        }).addTo(initializedMap);

        mapRef.current = initializedMap;

        // Harita hareket ettiğinde veya zoom değiştiğinde güncellemeleri yapar
        // initializedMap.on("moveend", handleMapUpdate());
        // initializedMap.on("zoomend", () => {
        //     saveMapState();
        //     setChatModal(false);
        // });
        initializedMap.on("moveend", handleMapUpdate);
        initializedMap.on("zoomend", handleMapUpdate);
        handleMapUpdate();

        setIsLoading(false);
    };
    // MapContainer mevcutsa haritayı yeniden başlatır ve bileşen
    // unmounted olduğunda harita durumunu kaydeder ve haritayı kaldırır
    useEffect(() => {
        if (mapContainerRef.current) {
            initializeMap();
        }
        return () => {
            if (mapRef.current) {
                // Haritayı kaldır ve temizle
                saveMapState();
                mapRef.current.remove();
                mapRef.current = null;
            }
        };
    }, [coords, mapContainerRef.current]);
    // İlk veri çekme işlemini yapar
    useEffect(() => {
        if (token) {
            if (!mapRoomGroupId || mapRoomList.length === 0) {
                setIsLoading(true);
            } else {
                fetchFilteredData();
            }
        }
    }, [dispatch, user?.id, token, genderFilter, orientationFilter, ageRange, mapRoomGroupId, mapRoomList]);
    // Harita güncellendiğinde veri çeker
    const handleMapUpdate = useCallback( () => {
        if (!mapRef.current) return;

        const bounds = getMapBounds();
        const zoom = getMapZoom();

        if (token) {
            const center = mapRef.current.getCenter();

            // Koordinatları doğrulama
            if (!isValidCoordinate(center.lat, center.lng)) {
                console.error("Invalid coordinates:", center.lat, center.lng);
                return;
            }

            if (socketRef.current && mapRoomGroupId) {
                socketRef.current.emit("update_user_location", {
                    groupId: mapRoomGroupId,
                    userId: user?.id,
                    lat: center.lat,
                    lng: center.lng,
                    zoomLevel: zoom
                });
            }
        }

        // Mevcut katmanları kaldır
        circleLayers.current.forEach((layer) => mapRef.current.removeLayer(layer));
        circleLayers.current = [];

        saveMapState();
        dispatch(exploreAction(user?.id, token, bounds, zoom, genderFilter, orientationFilter, ageRange, aktifPaketVarMi));
    }, [mapRoomGroupId, token, dispatch, user?.id, genderFilter, orientationFilter, ageRange, socketRef.current, aktifPaketVarMi]);
    // Harita güncellendiğinde veri çeker
    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.on("moveend", handleMapUpdate);
            mapRef.current.on("zoomend", handleMapUpdate);
        }
        return () => {
            if (mapRef.current) {
                mapRef.current.off("moveend", handleMapUpdate);
                mapRef.current.off("zoomend", handleMapUpdate);
            }
        };
    }, [handleMapUpdate, genderFilter, orientationFilter, ageRange]);
    // Filtreler değiştiğinde günceller ve filtre durumunu kaydeder
    useEffect(() => {
        applyFilters();
        saveFilterState();
    }, [genderFilter, orientationFilter, ageRange]);
    // Filtrelenmiş verileri çeker
    const fetchFilteredData = useCallback(() => {
        const bounds = getMapBoundsFromStorage() || getMapBounds();
        const zoom = getMapZoomFromStorage() || getMapZoom();

        if (bounds && zoom) {
            dispatch(exploreAction(user?.id, token, bounds, zoom, genderFilter, orientationFilter, ageRange, aktifPaketVarMi));
        } else {
            setIsLoading(false);
        }
    }, [dispatch, user?.id, token, genderFilter, orientationFilter, ageRange, aktifPaketVarMi]);
    // Filtre durumunu localStorage'a kaydeder
    const saveFilterState = useCallback(() => {
        localStorage.setItem("genderFilter", genderFilter);
        localStorage.setItem("orientationFilter", orientationFilter);
        localStorage.setItem("ageRange", JSON.stringify(ageRange));
    }, [genderFilter, orientationFilter, ageRange]);
    // Filtreleri uygular ve grupları günceller
    const applyFilters = useCallback(() => {
        const filtered = allUsers.current.filter(filterUsers);
        filteredUsers.current = filtered;
        updateGroups(filtered, getMapZoom());
    }, [genderFilter, orientationFilter, ageRange]);
    const isValidCoordinate = (lat, lng) => {
        return !isNaN(lat) && !isNaN(lng) && lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
    };
    // Harita durumunu kaydeder
    const saveMapState = useCallback(() => {
        if (mapRef.current) {
            const center = mapRef.current.getCenter();
            localStorage.setItem("mapLatitude", center.lat);
            localStorage.setItem("mapLongitude", center.lng);
            localStorage.setItem("mapZoom", mapRef.current.getZoom());

            const bounds = mapRef.current.getBounds();
            localStorage.setItem("northEastLat", bounds.getNorthEast().lat);
            localStorage.setItem("northEastLng", bounds.getNorthEast().lng);
            localStorage.setItem("southWestLat", bounds.getSouthWest().lat);
            localStorage.setItem("southWestLng", bounds.getSouthWest().lng);
        }
    }, []);
    // Harita sınırlarını döner
    const getMapBounds = useCallback(() => {
        if (!mapRef.current) return null;
        const bounds = mapRef.current.getBounds();
        return {
            northEastLat: bounds.getNorthEast().lat,
            northEastLng: bounds.getNorthEast().lng,
            southWestLat: bounds.getSouthWest().lat,
            southWestLng: bounds.getSouthWest().lng,
        };
    }, []);
    // Harita sınırlarını localStorage'dan alır
    const getMapBoundsFromStorage = useCallback(() => {
        const northEastLat = parseFloat(localStorage.getItem("northEastLat"));
        const northEastLng = parseFloat(localStorage.getItem("northEastLng"));
        const southWestLat = parseFloat(localStorage.getItem("southWestLat"));
        const southWestLng = parseFloat(localStorage.getItem("southWestLng"));

        if (isNaN(northEastLat) || isNaN(northEastLng) || isNaN(southWestLat) || isNaN(southWestLng)) {
            return null;
        }

        return {
            northEastLat,
            northEastLng,
            southWestLat,
            southWestLng,
        };
    }, []);
    // Harita zoom seviyesini döner
    const getMapZoom = useCallback(() => {
        if (!mapRef.current) return null;
        return mapRef.current.getZoom();
    }, []);
    // Harita zoom seviyesini localStorage'dan alır
    const getMapZoomFromStorage = useCallback(() => {
        return parseInt(localStorage.getItem("mapZoom"), 10);
    }, []);
    // Marker boyutunu hesaplar
    const getMarkerSizeForZoom = useCallback((zoomLevel) => {
        if (zoomLevel >= 13) return 30;
        if (zoomLevel === 12) return 22;
        if (zoomLevel === 11) return 27;
        if (zoomLevel === 10) return 27;
        if (zoomLevel === 9) return 27;
        if (zoomLevel === 8) return 27;
        if (zoomLevel === 7) return 27;
        if (zoomLevel === 6) return 25;
        return 18;
    }, []);
    // Marker yazı boyutunu hesaplar
    const getMarkerTextSizeForZoom = useCallback((zoomLevel) => {
        if (zoomLevel >= 13) return 12;
        if (zoomLevel === 12) return 9;
        if (zoomLevel === 11) return 12;
        if (zoomLevel === 10) return 12;
        if (zoomLevel === 9) return 12;
        if (zoomLevel === 8) return 12;
        if (zoomLevel === 7) return 12;
        if (zoomLevel === 6) return 12;
        return 8;
    }, []);
    // Kullanıcıları gruplar ve harita üzerinde gösterir
    const updateGroups = useCallback((groups, zoomLevel) => {
        if (!mapRef.current) return;

        // Mevcut daireleri ve markerları kaldır
        circleLayers.current.forEach((layer) => layer.remove());
        circleLayers.current = [];

        const markerSize = getMarkerSizeForZoom(zoomLevel);
        const markerTextSize = getMarkerTextSizeForZoom(zoomLevel);

        groups.forEach((group) => {
            if (isNaN(group.radius) || group.radius === null || group.radius === undefined) {
                console.error("Invalid radius:", group.radius);
                return;
            }
            const circle = L.circle(group.center, {
                radius: group?.radius, // Daire yarıçapını metreye çevir
                color: "#EB3428", // Sınır rengi
                fillColor: "#EB3428", // Dolgu rengi
                fillOpacity: 0.3, // Dolgu opaklığı
            }).addTo(mapRef.current);
            const userCount = group.users.length;
            const userCountMarker = L.marker(group.center, {
                icon: L.divIcon({
                    html: `<div class="user-count-marker" style="width: ${markerSize}px; height: ${markerSize}px; line-height: ${markerSize}px; font-size: ${markerTextSize}px;">${userCount >= 99 ? "99+" : userCount}</div>`,
                    className: "custom-div-icon",
                    iconSize: [markerSize, markerSize],
                    iconAnchor: [markerSize / 2, markerSize / 2],
                }),
            }).addTo(mapRef.current);

            // Kullanıcı sayısı marker'ına tıklama olayını ekle
            userCountMarker.on("click", () => {
                setSelectedUsers(group.users);
                daireIcindekiUyelerModalFonksiyon();
            });

            // Daireye tıklama olayını ekle
            circle.on("click", () => {
                setSelectedUsers(group.users);
                daireIcindekiUyelerModalFonksiyon();
            });

            circleLayers.current.push(circle, userCountMarker);
        });
    }, [getMarkerSizeForZoom, getMarkerTextSizeForZoom]);
    // Yeni veriler geldiğinde mevcut verilere ekler ve filtre uygular
    useEffect(() => {
        if (exploreData) {
            updateGroups(exploreData);
            setIsLoading(false);
        }
    }, [exploreData, updateGroups]);
    // Kullanıcıları filtreler
    const filterUsers = useCallback((user) => {
        const matchesGender = genderFilter === "unspecified" || user.gender === genderFilter;
        const matchesOrientation = orientationFilter === "unspecified" || user.sexual_orientation === orientationFilter;
        const matchesAge = user.age >= ageRange[0] && user.age <= ageRange[1];
        return matchesGender && matchesOrientation && matchesAge;
    }, [genderFilter, orientationFilter, ageRange]);
    // handleMessageClick fonksiyonunu ekleyin
    const handleMessageClick = (message) => {
        if (mapRef.current) {
            const { lat, lng, zoomLevel } = message;
            mapRef.current.setView([lat, lng], zoomLevel);
        }
    };
    const uyeyeGit = (id, type) => {
        if (token) {
            setTiklananUyeId(id);
            modalButton("profil-detay-modal-id", null);
            setChatModalType(type);
        }
    };
    const daireIcindekiUyelerModalFonksiyon = () => {
        setModal("daire-icindeki-uyeler-modal-id");
    };
    const cinsiyetSelectButton = (e) => {
        if (token) {
            setGenderFilter(e);
            localStorage.setItem("genderFilter", e);
        }
    };
    const cinselYonelimSelectButton = (e) => {
        if (token) {
            setOrientationFilter(e);
            localStorage.setItem("orientationFilter", e);
        }
    };
    const yasTercihiHandleClick = (e) => {
        if (token) {
            setTempAgeRange(e);
            localStorage.setItem("ageRange", JSON.stringify(e));
        }
    };
    // Filtreleme işlemini uygula butonuna bağlamak için güncelleyin.
    const handleApplyFiltersClick = useCallback(() => {
        setAgeRange(tempAgeRange);
    }, [tempAgeRange]);
    const sayfayiYenileButton = () => {
        window.location.reload();
    };
    const createMessage = () => {
        if (token) {
            if (!txtMessage.trim()) {
                setError(t("bos_mesaj_gonderemezsiniz"));

                setTimeout(() => {
                    setError("");
                }, 1000);
            } else {
                const newMessage = {
                    userId: user?.id,
                    content: txtMessage,
                    username: `${user.first_name} ${user.last_name}`,
                };

                axios
                    .post(`customer/chat/create/${mesajYazilanUye?.id}`, newMessage)
                    .then((res) => {
                        if (res.data.status === 1) {
                            setModal("daire-icindeki-uyeler-modal-id");
                            setTxtMessage("");
                        } else {
                            console.log("Grup oluşturma başarısız");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    };
    const sohbetOlusturFonksiyon = (mesajYazilanUye) => {
        if (token) {
            setModal("sohbet-olustur-modal-id");
            setMesajYazilanUye(mesajYazilanUye);
            setChatModalType("sohbet-olustur");
        }
    };
    const enterGonder = (event, type) => {
        if (token) {
            if (event.keyCode === 13) {
                if (type === "txtMessage") {
                    createMessage(mesajYazilanUye?.id);
                }

                if (type === "txtMapMessage") {
                    if (chatModal === false) {
                        setChatModal(true)
                    }
                    mapSendMessage();
                }
                if (type === "reply") {
                    handleReplyMessage();
                }
            }
        }
    };
    const konumGizleIslem = () => {
        if (token) {
            window.location = `/Ayarlar?devicePlatform=${localStorage.getItem("devicePlatform")}`;
        }
    };
    const tokensizGirisButonu = (uyarilar) => {
        girisYapUyariModal(uyarilar);
    };
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        // if (mapMessageList.length <= 0) {
        //     setChatModal(false);
        // }

        if (shouldScrollToBottom) {
            scrollToBottom();
        }
    }, [mapMessageList, shouldScrollToBottom]);
    const afterSendMessageOrJoin = () => {
        setShouldScrollToBottom(true);
    };
    const chatModalButton = () => {
        if (chatModal) {
            setChatModal(false);
        } else {
            setChatModal(true);
        }
        afterSendMessageOrJoin();
    }
    const modalButton = (id, type) => {
        // Eğer aynı modal zaten açıksa, hiçbir şey yapma
        if (id === modal) {
            return;
        }

        if (modal === "modalKapatId") {
            api.start({ y: 0 }); // y pozisyonunu sıfırla (modalı ekranda göster)
            setTimeout(() => {
                setModal(id);
            }, 300);
        } else if (id !== "modalKapatId") {
            // Eğer modal açık ise önce kapat, sonra yenisini aç
            api.start({ y: 300 }); // Mevcut modalı animasyonlu şekilde kapat
            setTimeout(() => {
                setModal("modalKapatId"); // Mevcut modalı kapat
                setTimeout(() => {
                    api.start({ y: 0 }); // y pozisyonunu sıfırla (yeni modalı ekranda göster)
                    setModal(id); // Yeni modalı aç
                }, 300); // Kapanma animasyonu süresi kadar bekle
            }, 300); // Kapanma animasyonu süresi
        } else if ((id === "daire-icindeki-uyeler-modal-id" && type === "sohbet-olustur") || chatModalType === "txtMapMessage") {
            api.start({ y: 300 }); // Modalı ekrandan çıkar
            setTimeout(() => {
                api.start({ y: 0 }); // y pozisyonunu sıfırla
                setModal("daire-icindeki-uyeler-modal-id"); // Diğer modala geri dön
            }, 300); // Animasyon süresi
        } else {
            api.start({ y: 300 }); // Modalı ekrandan çıkar
            setTimeout(() => {
                setModal("modalKapatId"); // Modalı kapat
            }, 300); // Animasyon süresi
        }
    }
    const mapSendMessage = () => {
        if (token) {
            const center = mapRef.current.getCenter();
            const zoom = mapRef?.current.getZoom();

            if (socketRef.current) {
                if (!txtMapMessage.trim()) {
                    setError(t("bos_mesaj_gonderemezsiniz"));

                    setTimeout(() => {
                        setError("");
                    }, 1000);
                } else {

                    const newMessage = {
                        groupId: mapRoomGroupId,
                        userId: user?.id,
                        content: txtMapMessage,
                        username: `${user.first_name} ${user.last_name}`,
                        lat: center.lat,
                        lng: center.lng,
                        zoomLevel: zoom
                    };

                    socketRef.current.emit('send_map_message', newMessage);
                    setTxtMapMessage("");
                }
                if (chatModal === false) {
                    setChatModal(true)
                }
            }
        }
    };
    const handleContextMenuShow = (mapMsg) => {
        setContextMenuVisible(true);
        setMapMsg(mapMsg);
    };
    const handleContextMenuHide = () => {
        setContextMenuVisible(false);
        setMapMsg(null);
    };
    const handleCopyMessage = () => {
        setContextMenuVisible(false);
        setCopyMessage(mapMsg.message);
        setError("mesaj kopyalandı.");
        setTimeout(() => {
            setError("");
        }, 1000);
    };
    const replyButton = (id) => {
        setIsReply(id);
        setContextMenuVisible(false);
    }
    const handleReplyMessage = () => {
        if (token) {
            if (socketRef.current) {
                if (!txtMapMessage.trim()) {
                    setError(t("bos_mesaj_gonderemezsiniz"));

                    setTimeout(() => {
                        setError("");
                    }, 1000);
                } else {
                    const newMessage = {
                        replyMessageId: mapMsg?.id,
                        replyMessage: txtMapMessage,
                        userId: user?.id,
                        group_id: mapRoomGroupId,
                        username: `${user.first_name} ${user.last_name}`,
                        lat: mapMsg?.lat,
                        lng: mapMsg?.lng,
                        zoomLevel: mapMsg?.zoomLevel
                    };

                    socketRef.current.emit('reply_message_map', newMessage);

                    setIsReply(false);
                    setMapMsg(null);
                    setTxtMapMessage("");
                }
            }
        }
    };
    let beniHaritadaGizleToggleButton = () => {
        if (token) {
            setBeniHaritadaGizleToggle(beniHaritadaGizleToggle === false);
            axios.put(`customer/profile/hide-me-map`, {
                    hide_me_map: beniHaritadaGizleToggle === false,
                })
                .then((res) => {
                    showToast(res?.data?.messages);
                    if (res?.data?.status === 1) {
                        let user_data = user;
                        user_data.hide_me_map = beniHaritadaGizleToggle === false;
                        localStorage.setItem("customer_data", JSON.stringify(user_data));
                        // Harita güncelleme fonksiyonunu çağır
                        handleMapUpdate();

                        // Mevcut katmanları kaldır
                        circleLayers.current.forEach((layer) => mapRef.current.removeLayer(layer));
                        circleLayers.current = [];
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        showToast(err.response.data.messages);
                    } else {
                        showToast([{type: "error", message: err.message, second: 5}]);
                    }
                });
        }
    };

    const springApi = useSpringRef();
    const [{ y }, api] = useSpring(() => ({ y: 0 }));
    const bind = useDrag(({ movement: [, my], velocity, last }) => {
        // Eşik değeri (80 piksel)
        const threshold = 80;

        if (last) {
            // Eşik değerini geçip geçmediğine bakıyoruz
            if (my > threshold || velocity > 0.05) {
                // Eşik geçilmişse modalı tamamen kaydır
                api.start({ y: 1000 }); // Modalı ekrandan çıkar
                setTimeout(() => {
                    api.start({ y: 0 }); // y pozisyonunu sıfırla
                    // Modal türüne göre işlemleri yap
                    if (chatModalType === "txtMapMessage") {
                        setModal("modalKapatId");
                        setChatModalType("");
                    } else if (chatModalType === "daire-icindeki-uye-profil" || chatModalType === "sohbet-olustur") {
                        setModal("daire-icindeki-uyeler-modal-id");
                        setChatModalType("");
                        setMesajYazilanUye(null);
                    } else {
                        setModal("modalKapatId"); // Modalı kapat
                    }
                }, 300); // Animasyon süresi
            } else {
                // Eşik geçilmediyse modalı eski yerine getir
                api.start({ y: 0 });
            }
        } else {
            // Eşik değerinden önce modalın parmağı takip etmesini sağla
            if (my > 0) {
                api.start({ y: my }); // Modalı parmak hareketine göre kaydır
            }
        }
    });
    const { size, ...rest } = useSpring({
        ref: springApi,
        config: config.stiff,
        from: {
            size: '20%'},
        to: {
            size: chatModal ? '35%' : '16%',
            background: chatModal ? 'transparent' : 'transparent',
        }
    });
    useChain(chatModal ? [springApi] : [springApi], [0, chatModal ? 0.1 : 0.2]);

    const haritaDivStyle = useMemo(() => ({
        width: isMobile ? width : width <= 475 ? width : 425,
        height: isMobile ? height - 96 : height <= 795 ? height - 96 : 788 - 96,
    }), [isMobile, width, height]);
    const haritaDivStyle1 = useMemo(() => ({
        height: isMobile ? height - 147 : height <= 795 ? height - 147 : 788 - 147,
    }), [isMobile, height]);
    const halfBottomModal1 = useMemo(() => ({
        width: "100%", height: "60%", position: "absolute", zIndex: 555555555, bottom: 0,
    }), []);
    const halfBottomModal2 = useMemo(() => ({
        width: "100%", height: "100%", backgroundColor: "#fff", borderTopRightRadius: 8, borderTopLeftRadius: 8,
    }), []);
    const profilDetayModalStyle = useMemo(() => ({
        width: "100%", height: "100%", backgroundColor: "#fff",
    }), []);

    const modalSpring = useSpring({
        transform: modal === "daire-icindeki-uyeler-modal-id" || modal === "filtre-modal-id" || modal === "ayarlar-modal-id" || modal === "sohbet-olustur-modal-id" || modal === "profil-detay-modal-id" ? "translateY(0%)" : "translateY(100%)",  // Açılma ve kapanma animasyonu
        opacity: modal === "daire-icindeki-uyeler-modal-id" || modal === "filtre-modal-id" || modal === "ayarlar-modal-id" || modal === "sohbet-olustur-modal-id" || modal === "profil-detay-modal-id" ? 1 : 0,
        config: { tension: 300, friction: 30 },    // Animasyon hızını ve ataletini ayarlar
    });
    const contextMenuClass = useMemo(() => {
        return contextMenuVisible ? 'context-menu open' : 'context-menu close';
    }, [contextMenuVisible]);

    return (
        <div style={haritaDivStyle} className="zort tiklanmaEngelle">
            {isLoading ? (
                <div style={haritaDivStyle}>
                    <div style={{width: "100%", height: "50%"}}>
                        <UyeBittiLoading user={user} width={width} height={height}/>
                    </div>
                    <div style={{width: "100%", height: "20%"}} className="flex flex-column justify-center items-center">
                        <div className="flex flex-column items-center">
                            <span className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" : "uyeBittiLoadingUyariTextFontDesktopStyle"}`}>
                                {t("yukleniyor")}
                            </span>
                        </div>
                    </div>
                </div>
            ) : !isGeolocationEnabled || !isGeolocationAvailable  ? (
                <>
                    <div style={{width: "100%", height: "50%"}}>
                        <UyeBittiLoading user={user} width={width} height={height}/>
                    </div>
                    <div style={{width: "100%", height: "50%"}} className="flex flex-column items-center mt-4">
                        <div style={{width: "60%"}} className="flex flex-column items-center">
                           <span className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" : "uyeBittiLoadingUyariTextFontDesktopStyle"}`}>
                               {!isGeolocationEnabled && t("lütfen_konumu_actiktan_sonra_tekrar_deneyin")}
                               {!isGeolocationAvailable && t("konum_bilgisi_alinamadi")}
                           </span>
                        </div>
                        <div style={{width: "80%", height: 44}}
                             onClick={() => sayfayiYenileButton()}
                             className="flex cursorPointer justify-center items-center shadow uyeBittiLoadingButtonStyle mt-3">
                           <span className="uyeBittiLoadingButtonTextStyle tiklanmaEngelle">
                               {t("tekrar_dene")}
                           </span>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div style={{width: "100%", height: "100%", position: "absolute", zIndex: 555}}>
                        <div className="flex shadow-sm profilDetayShadow" style={{
                            width: "100%",
                            height: 51,
                            backgroundColor: "#fff",
                            borderLeftWidth: 1,
                            borderRightWidth: 1}}>
                            <div className="flex justify-start items-center" style={{width: "calc(100% - 128px)", height: "100%"}}>
                                <span className={`tiklanmaEngelle ml-5 ${width <= 400 ? "etkinlikOlusturListeleBaslikTextFont1Style" : "etkinlikOlusturListeleBaslikTextFont2Style"}`}>
                                    {t("map")}
                                </span>
                            </div>
                            <div onClick={() => !token ? tokensizGirisButonu(t("filtreleme_yapabilmek_icin_lutfen_oturum_acin")) : modalButton("filtre-modal-id", null)}
                                 className="flex justify-center items-center" style={{width: 64, height: "100%"}}>
                                <div className="flex justify-content-center align-items-center">
                                    <div className="flex filterIconStyle justify-content-center align-items-center">
                                        <FontAwesomeIcon icon={faFilter} className="fa-lg"/>
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => !token ? tokensizGirisButonu(t("filtreleme_yapabilmek_icin_lutfen_oturum_acin")) : modalButton("ayarlar-modal-id", null)}
                                 className="flex justify-center items-center" style={{width: 64, height: "100%"}}>
                                <div className="flex justify-content-center align-items-center">
                                    <div className="flex filterIconStyle justify-content-center align-items-center">
                                        <FontAwesomeIcon icon={faGear} className="fa-lg"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={haritaDivStyle1} className="profilDetayShadow">
                            <div style={{width: "100%", height: "100%"}}>
                                <div ref={mapContainerRef} style={{width: "100%", height: "100%"}} className="tiklanmaEngelle"></div>
                                {token && (
                                    <animated.div style={{ ...rest, width: "100%", height: size, position: "absolute", bottom: 0, zIndex: 555555, background: `linear-gradient(to top, rgba(0, 0, 0, 0.35) 10%, rgba(0, 0, 0, 0.35) 30%, rgba(0, 0, 0, 0) 100%)`}}
                                                  className="flex-col mapMessageContainer">
                                        <div onClick={() => chatModalButton(false)}
                                             style={{width: "100%", height: 30, borderTopRightRadius: 8, borderTopLeftRadius: 8, borderTopWidth: 2, borderTopColor: "#eb34284d"}}
                                             className="flex justify-center items-center">
                                            <FontAwesomeIcon icon={chatModal === true ? faAngleDown : faAngleUp} style={{color: "#EB3428"}} className="fa-lg"/>
                                        </div>

                                        <div className="flex flex-col" style={{width: "100%", height: "calc(100% - 30px)"}}>
                                            <div style={{width: "100%", height: "calc(100% - 40px)"}} className="flex-col justify-end px-3 pb-3 pt-2 active-scroll">
                                                {mapMessageList.length > 0 &&
                                                    mapMessageList.map((mapMsg, index) => {
                                                        let previousUsername = null;
                                                        if (index > 0) {previousUsername = mapMessageList[index - 1].username}
                                                        let showUsername = mapMsg?.username !== previousUsername;

                                                        return (
                                                            <div className="flex flex-column" key={mapMsg?.id}>
                                                                {showUsername && (
                                                                    <div onClick={() => parseInt(mapMsg?.userId) !== parseInt(user?.id) && uyeyeGit(mapMsg?.userId, "txtMapMessage")}
                                                                         className={`mt-3 mb-1 ${parseInt(mapMsg?.userId) === parseInt(user?.id) ? 
                                                                             "gidenMesajlarKullaniciStyle pr-1" : "gelenMesajlarKullaniciStyle pl-1 flex items-center"} tiklanmaEngelle`}>
                                                                        {parseInt(mapMsg?.userId) !== parseInt(user?.id) && (
                                                                            <>
                                                                                {mapMsg?.image ? (
                                                                                    <img className="mesajlarProfilFotoStyle122 mr-1" src={mapMsg?.image} alt=""/>
                                                                                ) : (
                                                                                    <Logo className="mesajlarProfilFotoStyle122 tiklanmaEngelle mr-1" color={"transparent"}/>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {mapMsg?.username}
                                                                    </div>
                                                                )}
                                                                <ContextMenuTrigger  id={`contextmenu_${mapMsg.id}`} collect={() => handleContextMenuShow(mapMsg)}>
                                                                    <div onClick={() => handleMessageClick(mapMsg)} className={` ${ parseInt(mapMsg?.userId) === parseInt(user?.id) ? "flex justify-end" : "flex justify-start"}`}>
                                                                        <div key={mapMsg?.id} className={`flex flex-col boxShadowTop boxShadowBottom p-2 mb-1 mt-1
                                                                        ${parseInt(mapMsg?.userId) === parseInt(user?.id) ? "gidenMesajlarStyle pr-2" : "gelenMesajlarStyle pl-2"} tiklanmaEngelle`}>
                                                                            {mapMsg?.replyMessage && (
                                                                                <div className={`mb-1 ${parseInt(mapMsg?.userId) === parseInt(user?.id)
                                                                                    ? "flex justify-end" : "flex justify-start"}`}>
                                                                                    <div className="flex flex-col px-2 py-1" style={{width: "100%", backgroundColor: "#cecece80", fontSize: 12,
                                                                                        borderTopLeftRadius: parseInt(mapMsg?.userId) === parseInt(user?.id) ? 8 : 12,
                                                                                        borderTopRightRadius: parseInt(mapMsg?.userId) === parseInt(user?.id) ? 12 : 8,
                                                                                        borderBottomLeftRadius: parseInt(mapMsg?.userId) === parseInt(user?.id) ? 8 : 0,
                                                                                        borderBottomRightRadius: parseInt(mapMsg?.userId) === parseInt(user?.id) ? 0 : 8,
                                                                                    }}>
                                                                                        <span className="mapMessageReplyNameText">
                                                                                            {mapMsg.replyMessage.username}
                                                                                        </span>
                                                                                        <span className="mapMessageReplyText">
                                                                                            {mapMsg.replyMessage.content}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {mapMsg?.message}
                                                                        </div>
                                                                    </div>
                                                                </ContextMenuTrigger>
                                                            </div>
                                                        );
                                                    })}
                                                <div ref={messagesEndRef} />
                                            </div>
                                            <div style={{width: "100%", height: 40}} className="flex justify-content-around align-items-start">
                                                <div className="flex" style={{width: "calc(95% - 65px)", height: "90%"}}>
                                                    <input
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            zIndex: 1,
                                                            position: "relative",
                                                            backgroundColor: "#ffffff7d"
                                                    }}
                                                        className="mapChatInputStyle focus:outline-none pl-2"
                                                        value={txtMapMessage}
                                                        onKeyDown={(e) => enterGonder(e, isReply ? "reply" : "txtMapMessage")}
                                                        onChange={(e) => setTxtMapMessage(e.target.value)}
                                                        onFocus={() => chatModal === false && setChatModal(true)}
                                                        placeholder={error ? "" : t("mesaj_yaz")}
                                                        maxLength={200}
                                                        autoCapitalize="none"
                                                    />
                                                    {error && (
                                                        <div style={{position: "absolute", zIndex: 10}} className="ml-2 py-1">
                                                            <span className="mapHataMesajiYaziStyle">
                                                                {error}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex justify-content-center align-items-center" style={{width: 65, height: "90%"}}>
                                                    <div style={{width: "100%", height: "100%",  backgroundColor: "#ffffff7d"}}
                                                         className="cursorPointer flex justify-content-center align-items-center mapChatInputStyle"
                                                         onClick={isReply === true ? handleReplyMessage : mapSendMessage}>
                                                        <FontAwesomeIcon
                                                            icon={faPaperPlane}
                                                            style={{padding: 4}}
                                                            className={`sliderButton ${txtMessage === "" ? "mesajGonder1" : "mesajGonder2"}`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {isReply && (
                                                <div className="flex justify-around items-center" style={{position: "absolute", zIndex: 222, width: "100%", bottom: 45}}>
                                                    <div className="flex items-center pl-2" style={{width: "calc(95% - 65px)", height: 40, backgroundColor: "#ffffffe0", borderRadius: 8}}>
                                                        <FontAwesomeIcon className="sliderButton fa-lg" icon={faReply}/>
                                                        <div className="flex flex-col ml-3">
                                                            <span className="mapMessageReplyNameText">{mapMsg?.username}</span>
                                                            <span className="mapMessageReplyText">{mapMsg?.message}</span>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => replyButton(false)} style={{width: 65, backgroundColor: "#ffffffe0", height: 40, borderRadius: 8}} className="flex justify-center items-center py-1">
                                                        <FontAwesomeIcon className="sliderButton fa-lg" icon={faXmark}/>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </animated.div>
                                )}
                                {beniHaritadaGizleToggle === true && (
                                    <div className="flex justify-center items-center" style={{position: "absolute", zIndex: 555555, width: "100%", height: 85, top: "45%", backgroundColor: "rgba(0,0,0,0.50)"}}>
                                        <div className="flex flex-col justify-center items-center" style={{width: "90%", height: "100%"}}>
                                            <div className="haritaUyariBaslikStyle tiklanmaEngelle text-yellow-300">{t("uyari")}</div>
                                            <div className="haritaUyariAciklamaStyle tiklanmaEngelle">
                                                {t("harita_uyari_aciklama")}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {contextMenuVisible && (
                        <div id={`contextmenu_${mapMsg?.id}`} className={contextMenuClass}>
                            {parseInt(mapMsg?.userId) === parseInt(user?.id) ? (
                                <div id={`contextmenu_${mapMsg?.id}`} className="flex flex-row justify-around items-center tiklanmaEngelle" style={{width: "100%", height: "100%", backgroundColor: "#fff"}}>
                                    <MenuItem onClick={() => handleCopyMessage()} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faCopy}/>
                                        <span>{t("kopyala")}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => replyButton(true)} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faReply}/>
                                        <span>{t("cevapla")}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleContextMenuHide()} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                        <span>{t("kapat")}</span>
                                    </MenuItem>
                                </div>
                            ) : (
                                <div id={`contextmenu_${mapMsg?.id}`} className="flex flex-row justify-around items-center tiklanmaEngelle" style={{width: "100%", height: "100%", backgroundColor: "#fff"}}>
                                    <MenuItem onClick={() => handleCopyMessage()} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faCopy} />
                                        <span>{t("kopyala")}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => replyButton(true)} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faReply} />
                                        <span>{t("cevapla")}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleContextMenuHide()} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                        <span>{t("kapat")}</span>
                                    </MenuItem>
                                </div>
                            )}
                        </div>
                    )}

                    {modal === "daire-icindeki-uyeler-modal-id" && (
                        <animated.div {...bind()} className="modal-drag flex justify-center items-center tiklanmaEngelle" style={{ ...halfBottomModal1, ...modalSpring, y, touchAction: 'none'}}>
                            <div className="flex flex-column items-end justify-center" style={halfBottomModal2}>
                                <div style={{width: "100%", height: 51}} className="flex justify-center items-center">
                                    <div className="flex justify-content-center align-items-center" style={{width: 64, height: "100%"}}></div>
                                    <div className="flex justify-content-center align-items-center" style={{width: "calc(100% - 128px)", height: "100%"}}>
                                        <div className="scaleStyle" style={{width: "70%", height: 8, backgroundColor: "#ddd", borderRadius: 8, cursor: "pointer"}}>

                                        </div>
                                    </div>
                                    {/* modal kapat tuşu*/}
                                    <div style={{width: 64, height: "100%"}}>

                                    </div>
                                    {/*<div onClick={() => modalButton("modalKapatId", null)} style={{width: 64, height: "100%"}} className="flex justify-content-center align-items-center">*/}
                                    {/*    <div className="flex box justify-content-center align-items-center">*/}
                                    {/*        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div style={{width: "100%", height: "calc(100% - 51px)"}} className="flex flex-column active-scroll">
                                    {selectedUsers.map((exploreDataUsers) => {
                                        // Diğer kullanıcıların isOnline durumunu kontrol et
                                        const mapRoomListOnlineUsers = mapRoomList.flatMap(room => room.users.filter(user => user.isOnline === true));

                                        // exploreDataUsers ile eşleşen kullanıcıları bul ve borderColor özelliğini güncelle
                                        const matchedUser = mapRoomListOnlineUsers.find(mapUser => mapUser.userId === parseInt(exploreDataUsers.id));

                                        const borderColor = matchedUser ? "#44da80" : "#EB3428";
                                        // const borderColor = aktifPaketVarMi ? matchedUser ? "#44da80" : "#EB3428" : "";

                                        return (
                                            <div key={exploreDataUsers?.id} className="flex justify-content-center" style={{width: "100%"}}>
                                            <div className="flex justify-between align-items-center kartGolgeStyle1 pointer bg-white"
                                                 key={exploreDataUsers?.id}
                                                 style={{
                                                     width: "95%",
                                                     height: 60,
                                                     marginTop: 5,
                                                     marginBottom: 5,
                                                     borderRadius: 10,
                                                 }}>
                                                <div onClick={() => !token ? tokensizGirisButonu(t("uyelerin_profillerine_erisebilmek_icin_lutfen_oturum_acin")) : uyeyeGit(exploreDataUsers?.id, "daire-icindeki-uye-profil")}
                                                     className="flex" style={{width: "calc(100% - 70px)", height: "100%"}}>
                                                    <div className="flex justify-content-center align-items-center profilFotoStyle"
                                                         style={{width: 70, height: "100%"}}>
                                                        <div style={{
                                                            width: 50,
                                                            height: 50,
                                                            borderWidth: 2,
                                                            borderRadius: 100,
                                                            borderColor: borderColor,
                                                            backgroundColor: "transparent",
                                                        }} className="flex justify-content-center align-items-center">
                                                            {exploreDataUsers?.profile_photos ? (
                                                                <img style={{
                                                                    width: 45,
                                                                    height: 45,
                                                                    borderRadius: "100%",
                                                                    objectFit: "cover",
                                                                }} className="tiklanmaEngelle"
                                                                     src={exploreDataUsers?.profile_photos[0].url + exploreDataUsers?.profile_photos[0].file_name}
                                                                     alt=""/>
                                                            ) : (
                                                                <Logo
                                                                    className="tiklanmaEngelle"
                                                                    color={"transparent"}/>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div style={{width: "calc(100% - 200px)", height: "100%"}}
                                                         className="flex justify-content-start align-items-center tiklanmaEngelle haritaTextStyle">
                                                        {exploreDataUsers?.first_name}{" "}{exploreDataUsers?.last_name}
                                                    </div>
                                                    <div style={{width: 130, height: "100%"}}
                                                         className="flex justify-content-center align-items-center tiklanmaEngelle haritaTextStyle">
                                                        {t("yas")}: {exploreDataUsers?.age}
                                                    </div>
                                                </div>
                                                {exploreDataUsers?.id !== user?.id && exploreDataUsers?.unmatched_message_acceptance === true && (
                                                    <div onClick={() => !token ? tokensizGirisButonu(t("uyelere_mesaj_gonderebilmek_icin_lutfen_oturum_acin")) : sohbetOlusturFonksiyon(exploreDataUsers)}
                                                         style={{
                                                             width: 70,
                                                             height: "100%",
                                                             backgroundColor: "#ddd",
                                                             borderTopRightRadius: 10,
                                                             borderBottomRightRadius: 10,
                                                         }} className="flex justify-center items-center genelHoverStyle">
                                                        <FontAwesomeIcon
                                                            icon={faComment}
                                                            className="fa-lg"
                                                            style={{opacity: 0.7}}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </animated.div>
                    )}

                    {modal === "filtre-modal-id" && (
                        <animated.div {...bind()} className="modal-drag flex justify-center items-center tiklanmaEngelle" style={{ ...halfBottomModal1, ...modalSpring, y, touchAction: 'none'}}>
                            <div className="flex flex-column items-end justify-center" style={halfBottomModal2}>
                                <div style={{width: "100%", height: 51}} className="flex justify-center items-center">
                                    <div className="flex justify-content-center align-items-center" style={{width: 64, height: "100%"}}></div>
                                    <div className="flex justify-content-center align-items-center" style={{width: "calc(100% - 128px)", height: "100%"}}>
                                        <div className="scaleStyle" style={{width: "70%", height: 8, backgroundColor: "#ddd", borderRadius: 8, cursor: "pointer"}}>

                                        </div>
                                    </div>
                                    {/* modal kapat tuşu*/}
                                    <div style={{width: 64, height: "100%"}}>

                                    </div>
                                    {/*<div onClick={() => modalButton("modalKapatId", null)} style={{width: 64, height: "100%"}} className="flex justify-content-center align-items-center">*/}
                                    {/*    <div className="flex box justify-content-center align-items-center">*/}
                                    {/*        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div style={{width: "100%", height: "calc(100% - 102px)", backgroundColor: "#fbfafa"}} className="active-scroll">
                                    <div style={{
                                        width: "100%",
                                        height: 100,
                                        marginBottom: 10,
                                        marginTop: 5,
                                        backgroundColor: "#fff"
                                    }} className={`flex flex-column justify-center items-center`}>
                                        <div style={{width: "95%", height: "50%"}}
                                             className="flex items-end">
                                            <div
                                                className="flex justify-start profilDuzenleBaslikTextStyle01 tiklanmaEngelle"
                                                style={{width: "50%", textAlign: "start"}}>
                                                {t("yas_tercihi")}
                                            </div>
                                            <div
                                                className="flex justify-end mr-1 profilDuzenleBaslikTextStyle01 tiklanmaEngelle"
                                                style={{width: "50%", textAlign: "end"}}>
                                                {tempAgeRange[0]} - {tempAgeRange[1]}
                                            </div>
                                        </div>
                                        <YasTercihi
                                            yasTercihiHandleClick={yasTercihiHandleClick}
                                            yasTercih={tempAgeRange}
                                        />
                                    </div>

                                    <div style={{
                                        width: "100%",
                                        height: 200,
                                        marginBottom: 10,
                                        marginTop: 15,
                                        backgroundColor: "#fff",
                                    }} className="flex flex-column">
                                        <div style={{width: "100%", height: 43}} className="flex items-center pl-3">
                                            <span className="profilDuzenleBaslikTextStyle01 tiklanmaEngelle">
                                                {t("cinsiyet")}
                                            </span>
                                        </div>
                                        <div style={{width: "100%", height: 155}}>
                                            <CinsiyetVeCinselYonelimSec
                                                cinsiyet={datas().cinsiyetData[genderFilter]?.value || ""}
                                                handleDataSelectButton={cinsiyetSelectButton}
                                                data={Object.values(datas().cinsiyetData)}
                                            />
                                        </div>
                                    </div>

                                    <div style={{width: "100%", height: 250, marginBottom: 10, marginTop: 15, backgroundColor: "#fff"}}>
                                        <div style={{width: "100%", height: 43}}
                                             className="flex items-center pl-3">
                                            <span className="profilDuzenleBaslikTextStyle01 tiklanmaEngelle">
                                                {t("cinsel_yonelim")}
                                            </span>
                                        </div>
                                        <div style={{width: "100%", height: 155}}>
                                            <CinsiyetVeCinselYonelimSec
                                                cinselYonelim={datas().cinselYonelimData[orientationFilter]?.value || ""}
                                                handleDataSelectButton={cinselYonelimSelectButton}
                                                data={Object.values(datas().cinselYonelimData)}
                                            />
                                        </div>
                                        <div style={{width: "100%", height: 52, backgroundColor: "#fff"}}
                                            onClick={() => !token ? tokensizGirisButonu(t("secim_yapabilmek_icin_lutfen_oturum_acin")) : setOrientationFilter("unspecified")}
                                            className={`flex pl-3 cursorPointer ${orientationFilter === "unspecified" ? "profilDuzenleRightArrowIcon22" : "profilDuzenleRightArrowIcon2"}`}>
                                            <div style={{width: "65%", height: "100%"}}
                                                 className="flex items-center profilDuzenleTextStyle03 tiklanmaEngelle">
                                                {t("belirtilmemis")}
                                            </div>
                                            <div style={{width: "35%", height: "100%"}}
                                                 className="flex items-center justify-end pr-4 tiklanmaEngelle">
                                                {orientationFilter === "unspecified" && (
                                                    <FontAwesomeIcon icon={faCheck}/>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{width: "100%", height: 51, backgroundColor: "#fbfafa"}} className="flex justify-center items-center">
                                    <div onClick={handleApplyFiltersClick}
                                         className="flex justify-center items-center cursorPointer etkinlikOlusturButtonStyle px-4 py-2">
                                        <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
                                            {t("uygula")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}

                    {modal === "ayarlar-modal-id" && (
                        <animated.div {...bind()} className="modal-drag flex justify-center items-center tiklanmaEngelle" style={{ ...halfBottomModal1, ...modalSpring, y, touchAction: 'none'}}>
                            <div className="flex flex-column items-end justify-center" style={halfBottomModal2}>
                                <div style={{width: "100%", height: 51}} className="flex justify-center items-center">
                                    <div className="flex justify-content-center align-items-center" style={{width: 64, height: "100%"}}></div>
                                    <div className="flex justify-content-center align-items-center" style={{width: "calc(100% - 128px)", height: "100%"}}>
                                        <div className="scaleStyle" style={{width: "70%", height: 8, backgroundColor: "#ddd", borderRadius: 8, cursor: "pointer"}}>

                                        </div>
                                    </div>
                                    {/* modal kapat tuşu*/}
                                    <div style={{width: 64, height: "100%"}}>

                                    </div>
                                    {/*<div onClick={() => modalButton("modalKapatId", null)} style={{width: 64, height: "100%"}} className="flex justify-content-center align-items-center">*/}
                                    {/*    <div className="flex box justify-content-center align-items-center">*/}
                                    {/*        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div style={{width: "100%", height: "calc(100% - 51px)", backgroundColor: "#fbfafa"}} className="active-scroll">
                                    <div style={{width: "100%", height: 70, marginBottom: 5, marginTop: 10, backgroundColor: "#fff"}}
                                         className={`flex justify-between items-center px-1.5 px-md-2`}>
                                        <div className="profilDuzenleBaslikTextStyle01 tiklanmaEngelle ml-1" style={{fontSize: 13}}>
                                            <FontAwesomeIcon icon={faLocationPinLock} className="fa-lg mr-2" style={{color: "#21262E", opacity: 0.7}}/>
                                            {t("mevcut_konumumu_sil")}
                                        </div>
                                        <div onClick={() => !token ? tokensizGirisButonu(t("konumunuzu_silebilmek_icin_lutfen_oturum_acin")) : konumGizleIslem()}
                                             className="flex justify-center items-center">
                                            <div className="flex justify-content-center align-items-center">
                                                <div className="flex filterIconStyle justify-content-center align-items-center">
                                                    <FontAwesomeIcon icon={faTrash} className="fa-lg"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{width: "100%", height: 70, backgroundColor: "#fff"}} className="flex">
                                        <div style={{width: "65%", height: "100%"}} className="flex justify-start items-center pl-3">
                                            <span className="profilDuzenleBaslikTextStyle01 tiklanmaEngelle ml-1" style={{fontSize: 13}}>
                                                <FontAwesomeIcon icon={faUserSlash} className="fa-lg mr-2" style={{color: "#21262E", opacity: 0.7}}/>
                                                {t("beni_haritada_gizle")}
                                            </span>
                                        </div>

                                        <div style={{width: "35%", height: "100%"}} className="flex justify-end items-center mr-3 cursorPointer">
                                            <div style={{width: "65%", height: "55%", backgroundColor: "#fff", borderRadius: 10, borderWidth: 1}}
                                                 className={`flex justify-center items-center`}>
                                                <div className={`flex`} style={{width: "100%", height: "100%"}}
                                                     onClick={() => beniHaritadaGizleToggleButton()}>
                                                    <div style={{width: "35%"}} className={`flex justify-center items-center ${beniHaritadaGizleToggle === false ? "toggleKapaliStyle1" : "toggleAcikStyle1"}`}>
                                                        {beniHaritadaGizleToggle === false ? (
                                                            <FontAwesomeIcon icon={faXmark} className={`${beniHaritadaGizleToggle === false ? "slideLeftKapaliIcon" : ""}`} inverse/>
                                                        ) : (
                                                            <FontAwesomeIcon icon={faCheck} className={`${beniHaritadaGizleToggle === true ? "slideRightAcikIcon" : ""}`} inverse/>
                                                        )}
                                                    </div>
                                                    <div style={{width: "65%"}} className="flex justify-center items-center">
                                                        {beniHaritadaGizleToggle === false ? (
                                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                                 className={`toggleKapaliTextStyle1 tiklanmaEngelle ${beniHaritadaGizleToggle === false ? "slideLeftKapaliText" : ""}`}>
                                                                {t("kapali")}
                                                            </div>
                                                        ) : (
                                                            <div style={{fontSize: isMobile ? 12 : width <= 390 ? 12 : 15}}
                                                                 className={`toggleAcikTextStyle1 tiklanmaEngelle ${beniHaritadaGizleToggle === true ? "slideRightAcikText" : ""}`}>
                                                                {t("acik")}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}

                    {modal === "profil-detay-modal-id" && (
                        <animated.div {...bind()} className="modal-drag flex justify-center items-center tiklanmaEngelle" style={{width: "100%", height: "100%", position: "absolute", zIndex: 555555555, backgroundColor: "rgba(0,0,0,0.63)", y, touchAction: 'none', ...modalSpring}}>
                            <div className="flex flex-column items-center justify-center" style={profilDetayModalStyle}>
                                <div style={{width: "100%", height: 51, borderLeftWidth: 1, borderRightWidth: 1}} className="flex justify-center items-center">
                                    <div className="flex justify-content-center align-items-center"
                                         style={{width: 64, height: "100%"}}></div>
                                    <div className="flex justify-content-center align-items-center" style={{width: "calc(100% - 128px)", height: "100%"}}>
                                        <div className="scaleStyle" style={{width: "70%", height: 8, backgroundColor: "#ddd", borderRadius: 8, cursor: "pointer"}}>

                                        </div>
                                    </div>
                                    {/* modal kapat tuşu*/}
                                    <div style={{width: 64, height: "100%"}}>

                                    </div>
                                    {/*<div onClick={() => modalButton(chatModalType === "txtMapMessage" ? "modalKapatId" : "daire-icindeki-uyeler-modal-id", null)}*/}
                                    {/*     style={{width: 64, height: "100%"}}*/}
                                    {/*     className="flex justify-content-center align-items-center">*/}
                                    {/*    <div className="flex box justify-content-center align-items-center">*/}
                                    {/*        /!*<FontAwesomeIcon icon={faXmark} className="fa-lg"/>*!/*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div style={{width: "100%", height: "calc(100% - 51px)"}} className="flex flex-column active-scroll">
                                    <UyeProfilDetay
                                        tiklananUyeId={tiklananUyeId}
                                        profilDetayModal={"profil-detay-modal-id"}
                                    />
                                </div>
                            </div>
                        </animated.div>
                    )}

                    {modal === "sohbet-olustur-modal-id" && (
                        <animated.div {...bind()} className="modal-drag flex justify-center items-center tiklanmaEngelle" style={{width: "100%", height: 185, position: "absolute", zIndex: 5555, bottom: 0, y, touchAction: 'none', ...modalSpring}}>
                            <div className="flex flex-column items-end justify-center" style={halfBottomModal2}>
                                <div style={{width: "100%", height: 51}} className="flex justify-center items-center">
                                    <div className="flex justify-content-center align-items-center" style={{width: 64, height: "100%"}}></div>
                                    <div className="flex justify-content-center align-items-center" style={{width: "calc(100% - 128px)", height: "100%"}}>
                                        <div className="scaleStyle" style={{width: "70%", height: 8, backgroundColor: "#ddd", borderRadius: 8, cursor: "pointer"}}>

                                        </div>
                                    </div>
                                    {/* modal kapat tuşu*/}
                                    <div style={{width: 64, height: "100%"}}>

                                    </div>
                                    {/*<div onClick={() => modalButton("daire-icindeki-uyeler-modal-id", "sohbet-olustur")} style={{width: 64, height: "100%"}} className="flex justify-content-center align-items-center">*/}
                                    {/*    <div className="flex box justify-content-center align-items-center">*/}
                                    {/*        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div style={{width: "100%", height: "calc(100% - 51px)", backgroundColor: "#fbfafa"}} className="active-scroll flex-column">
                                    <div className="flex flex-col justify-between" style={{width: "100%", height: 70}}>
                                        <span className="haritaSohbetBaslatTextStyle pt-2">{t("sohbet_baslat")}</span>
                                        <span className="haritaSohbetBaslatUyeTextStyle pl-4">{mesajYazilanUye?.first_name} {mesajYazilanUye?.last_name}</span>
                                    </div>
                                    <div style={{width: "100%", height: "calc(100% - 70px)"}} className="flex justify-content-center align-items-center">
                                        <div className="flex ml-2 py-1" style={{width: "calc(100% - 65px)", height: 50}}>
                                            <input
                                                style={{
                                                    backgroundColor: "#fff",
                                                    width: "100%",
                                                    height: "100%",
                                                    zIndex: 1,
                                                    position: "relative",
                                                }}
                                                className="mesajlarInputStyle1 focus:outline-none pl-2"
                                                value={txtMessage}
                                                onKeyDown={(e) => enterGonder(e, "txtMessage")}
                                                onChange={(e) => setTxtMessage(e.target.value)}
                                                placeholder={error ? "" : t("mesaj_yaz")}
                                                maxLength={200}
                                                autoCapitalize="none"
                                            />
                                            {error && (
                                                <div className="mx-3 my-3" style={{position: "absolute", zIndex: 10}}>
                                                <span className="konusmaListesiHataMesajiYaziStyle">
                                                    {error}
                                                </span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex justify-center items-center" style={{width: 65, height: 50}}>
                                            <div style={{width: "85%", height: "80%"}}
                                                 className="cursorPointer flex justify-content-center align-items-center mesajlarButtonStyle1"
                                                 onClick={createMessage}>
                                                <FontAwesomeIcon
                                                    icon={faPaperPlane}
                                                    className={`mesajlarGonderButtonStyle1 sliderButton ${txtMessage === "" ? "mesajGonder1" : "mesajGonder2"}`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}
                </>
            )}
        </div>
    );
}

export default HaritaScreen;
