export function EventIcon({className,active=false}) {
    return (
        <svg
            data-name="event"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            style={{width:24}}
            className={className}
        >
            <path
                d="M38.07 27.43c-5.15-.94-9.3 6.1-12.41 4.46-1.7-.9-2.06-3.85-1.72-5.82 1.04-6.06 10.13-11.09 17.23-10 9.68 1.48 17.07 14.98 15.03 25.89-2.34 12.51-17.69 23.21-33.07 19.78C2.17 57.07.49 30.47 1.61 30.05c.92-.35 6.83 18.94 20.35 21.26 10.03 1.72 22.95-6.19 23.13-14.08.1-4.15-3.18-9.1-7.02-9.8Z"
                style={{
                    fill: (active?"#ED1F24":"#ddd"),
                }}
            />
            <path
                d="M25.93 36.57c5.15.94 9.3-6.1 12.41-4.46 1.7.9 2.06 3.85 1.72 5.82-1.04 6.06-10.13 11.09-17.23 10C13.15 46.45 5.76 32.95 7.8 22.04 10.14 9.53 25.5-1.17 40.87 2.26c20.96 4.67 22.64 31.27 21.51 31.69-.92.35-6.83-18.94-20.35-21.26C32 10.97 19.08 18.88 18.9 26.77c-.1 4.15 3.18 9.1 7.02 9.8Z"
                style={{
                    fill: (active?"#ED1F24":"#ddd"),
                }}
            />
        </svg>
    );
}
