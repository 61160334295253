// reducers/begenmediklerinReducer.js

import {
    BEGENMEDIKLERIN_PAGE,
    BEGENMEDIKLERIN_REQUEST,
    BEGENMEDIKLERIN_SUCCESS,
    BEGENMEDIKLERIN_IS_FETCHING,
    BEGENMEDIKLERIN_HAS_MORE,
    UYEYI_CIKAR,
    UYEYI_EKLE
} from '../../types';

const initialState = {
    begenmediklerinData: [],
    page: 0,
    isFetching: false,
    error: "",
    hasMore: true
};

export default function begenmediklerinReducer(state = initialState, action) {
    switch(action.type) {
        case BEGENMEDIKLERIN_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case BEGENMEDIKLERIN_SUCCESS:
            return {
                ...state,
                begenmediklerinData: action.payload,
                isFetching: false
            };
        case BEGENMEDIKLERIN_IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            };
        case BEGENMEDIKLERIN_HAS_MORE:
            return {
                ...state,
                hasMore: action.payload
            };
        case BEGENMEDIKLERIN_PAGE:
            return {
                ...state,
                page: state.page + 1
            };
        case UYEYI_CIKAR:
            if (action.payload.list === "begenmediklerim") {
                return {
                    ...state,
                    begenmediklerinData: state.begenmediklerinData.filter(
                        (uye) => parseInt(uye.customer_id) !== parseInt(action.payload.uyeId)
                    )
                };
            }
            return state;
        case UYEYI_EKLE:
            if (action.payload.list === "begenmediklerim") {
                return {
                    ...state,
                    begenmediklerinData: [...state.begenmediklerinData, action.payload.uyeDetay]
                };
            }
            return state;
        default:
            return state;
    }
}
