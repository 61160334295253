// actions/Etkinlik/etkinligeKatilanUyeyiSilEylemAction.js

import axios from "../../../../utils/CustomAxios";
import {
    ETKINLIGE_KATILAN_UYEYI_SIL_EYLEM_SUCCESS,
    ETKINLIGE_KATILAN_UYEYI_SIL_BUTTON
} from '../../../types';
import {showToast} from "../../../../utils/Functions";
import {etkinlikKatilimcilariAction} from "../IsteklerUyelerReddedilenlerDataCek/etkinlikKatilimcilariAction";

export const etkinligeKatilanUyeyiSilEylemSuccess = (message) => {
    return {
        type: ETKINLIGE_KATILAN_UYEYI_SIL_EYLEM_SUCCESS,
        payload: message
    }
}

export const etkinligeKatilanUyeyiSilButton = data => {
    return {
        type: ETKINLIGE_KATILAN_UYEYI_SIL_BUTTON,
        payload: data
    }
};

export const etkinligeKatilanUyeyiSilEylemAction = (customerId, secilenEventId, data) => {
    return (dispatch) => {
        return axios.delete(`customer/event-participant/remove/${secilenEventId}`, {data: {customer_id: customerId}})
            .then((res) => {
                showToast(res.data.messages);
                if (res.data.status === 1) {
                    dispatch(etkinligeKatilanUyeyiSilEylemSuccess(res.data.messages))
                    let updatedParticipants = data.event_participants.filter((item) => {
                        return parseInt(item.customer_id) !== parseInt(customerId);
                    });
                    let updatedData = {...data, event_participants: updatedParticipants};
                    dispatch(etkinligeKatilanUyeyiSilButton(updatedData));
                    dispatch(etkinlikKatilimcilariAction(secilenEventId)); // Katılımcıları güncelle
                }
            })
            .catch((err) => {
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{type: "error", message: err.message, second: 5}]);
                }
            });
    }
}
