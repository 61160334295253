import React from "react";
import {Slider, Handles, Tracks, Rail} from 'react-compound-slider';
import "../styles/main.css";

const sliderStyle = {
    position: 'relative',
    width: '94%',
    height: "50%"
}
const railStyle = {
    position: 'absolute',
    width: '100%',
    height: 10,
    borderRadius: 5,
    cursor: 'pointer',
    backgroundColor: '#7c8591',
}

export default function MesafeTercihi({mesafeTercihiHandleClick, mesafeTercih}) {

    return (
        <Slider
            className="flex items-center"
            onChange={e => mesafeTercihiHandleClick(e)}
            /*onUpdate={e => mesafeTercihiHandleClick(e)}*/
            rootStyle={sliderStyle}
            domain={[1, 200]}
            step={1}
            mode={2}
            values={mesafeTercih}>
            <Rail>
                {({ getRailProps }) => (
                    <div style={railStyle} {...getRailProps()} />
                )}
            </Rail>
            <Handles>
                {({ handles, getHandleProps }) => (
                    <div className="slider-handles tiklanmaEngelle">
                        {handles.map(handle => (
                            <div key={handle.id}
                                 className="slideTopButtonStyle"
                                 style={{
                                     left: `${handle.percent}%`,
                                     position: 'absolute',
                                     marginLeft: -12,
                                     marginTop: -12,
                                     zIndex: 2,
                                     width: 22,
                                     height: 22,
                                     textAlign: 'center',
                                     cursor: 'pointer',
                                     borderRadius: '50%',
                                     backgroundColor: '#fff',
                                     color: '#fff',
                                 }}
                                 {...getHandleProps(handle.id)}>
                            </div>
                        ))}
                    </div>
                )}
            </Handles>
            <Tracks right={false}>
                {({ tracks, getTrackProps }) => (
                    <div className="slider-tracks">
                        {tracks.map(({ id, source, target }) => (
                            <div
                                key={id}
                                 className="slideBackgroundColorStyle tiklanmaEngelle"
                                style={{
                                    position: 'absolute',
                                    height: 10,
                                    marginTop: -5,
                                    zIndex: 1,
                                    borderRadius: 5,
                                    cursor: 'pointer',
                                    left: `${source.percent}%`,
                                    width: `${target.percent - source.percent}%`,
                                }}
                                {...getTrackProps()}
                            />
                        ))}
                    </div>
                )}
            </Tracks>
        </Slider>
    )
}
