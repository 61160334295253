// actions/Etkinlik/etkinlikKatilimIstekleriReddedilenlerAction.js

import axios from "../../../../utils/CustomAxios";
import {
    ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_REQUEST,
    ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_SUCCESS,
    ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_DATA_CLEAR
} from '../../../types';
import {showToast} from "../../../../utils/Functions";


export const etkinlikKatilimIstekleriReddedilenlerRequest = () => {
    return {
        type: ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_REQUEST
    }
}

export const etkinlikKatilimIstekleriReddedilenlerSuccess = (events) => {
    return {
        type: ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_SUCCESS,
        payload: events
    }
}

export const etkinlikKatilimIstekleriReddedilenlerDataClear = () => {
    return {
        type: ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_DATA_CLEAR
    }
}

export const etkinlikKatilimIstekleriReddedilenlerAction = (secilenEventId) => {
    return (dispatch) => {
        dispatch(etkinlikKatilimIstekleriReddedilenlerRequest());
        axios.get(`customer/event-participant/${secilenEventId}?filter=rejected`)
            .then((res) => {
                if (res.data.status === 1) {
                    const seciliEvent = res.data.data;
                    dispatch(etkinlikKatilimIstekleriReddedilenlerSuccess(seciliEvent));
                }
            })
            .catch((err) => {
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{type: "error", message: err.message, second: 5}]);
                }
            });
    }
}
