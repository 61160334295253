import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export default function ResimDegistirmeYonTuslari({yonDegeri, geri, ileri, toplamResim, swiperId, top}) {
    const cardDivStyle2 = {
        width: 50,
        height: 50,
        backgroundColor: "#00000080",
        borderRadius: 100
    };

    return (
       <div>
           {yonDegeri === true ? null : (
               <div>
                   {toplamResim <= 0 ? null : (
                       <div>
                           {swiperId === 0 ? (
                               <div style={{position: "absolute", top: top, right: "2%"}}>
                                   <button style={cardDivStyle2}
                                           className="flex justify-content-center align-items-center sliderButton"
                                           type="button" onClick={() => ileri()}>
                                       <FontAwesomeIcon icon={faAngleRight} className="fa-2x" inverse style={{color: "#fff"}}/>
                                   </button>
                               </div>
                           ) : swiperId === toplamResim ? (
                               <div style={{position: "absolute", top: top, left: "2%"}}>
                                   <button style={cardDivStyle2}
                                           className="flex justify-content-center align-items-center sliderButton"
                                           type="button" onClick={() => geri()}>
                                       <FontAwesomeIcon icon={faAngleLeft} className="fa-2x" inverse style={{color: "#fff"}}/>
                                   </button>
                               </div>
                           ) : (
                               <div>
                                   <div style={{position: "absolute", top: top, right: "2%"}}>
                                       <button style={cardDivStyle2}
                                               className="flex justify-content-center align-items-center sliderButton"
                                               type="button" onClick={() => ileri()}>
                                           <FontAwesomeIcon icon={faAngleRight} className="fa-2x" inverse style={{color: "#fff"}}/>
                                       </button>
                                   </div>
                                   <div style={{position: "absolute", top: top, left: "2%"}}>
                                       <button style={cardDivStyle2}
                                               className="flex justify-content-center align-items-center sliderButton"
                                               type="button" onClick={() => geri()}>
                                           <FontAwesomeIcon icon={faAngleLeft} className="fa-2x" inverse style={{color: "#fff"}}/>
                                       </button>
                                   </div>
                               </div>
                           )}
                       </div>
                   )}
               </div>
           )}
       </div>
    )
}
