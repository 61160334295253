import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function SonUyeler() {
    const {t} = useTranslation();
    const [showImages, setShowImages] = useState(false);
    const [imagesArray, setImagesArray] = useState([]);
    const images=[
        '850185f8-2d63-4a19-9184-aad6f52bd931-0.png',
        '0ez60bmuhe0dz0.webp',
        'a7tHjdFK2uqF81vaIyxy.jpg',
        '1lr750fmy7vw65.webp',
        '3y7ckqcgcxci8t.webp',
        '0ms93wkcfv1vim.webp',
        '26kltpptjuob74.webp',
        '8eu4xf7s1evppl.webp',
        '693h6q3oukiabe.webp',
        '50f500gh22yylv.webp',
        '41ya29xlhnwb2y.webp',
        'bgjcihseo0i36b.webp',
        'auyojcx58ageeq.webp',
        'czx64fj2ptq457.webp',
        'hjzwtiqdcvwh5n.webp',
        'irvt28wku6w53h.webp',
        'bvv0tyd7oa80gz.webp',
        'c1vyropqfrt344.webp',
        'gwqwdxvslhuhuo.webp',
        'oyG0GoUrHgnpenLn2xMc.jpg',
        'd6zeajm9h4ccbe.webp',
        'llvx7y7xd04yto.webp',
        'gotvidpezn7pkz.webp',
        'efwq12z8pngnnp.webp',
        'jvnaiv39qmo110.webp',
        'm2fq3qo92mcpsw.webp',
        'fzqzcgp5d14eyl.webp',
        'tOBU5atPDLu5JfoCLUFa.jpg',
        'TNNMzQXhxDjULXQ1vLYE.jpg',
        'ymhglKk3SHkj3BvbvOWV.jpg',
        'o1enx9bfn047dy.webp',
        'pvbtoi6cwq3c36.webp',
        'uyu2lbtrpwo94o.webp',
        'qenylfok170u9m.webp',
        'v1rpyk0c8xhtal.webp',
        'mb09hk1klb7bpe.webp',
        'mb14olfrn70wow.webp',
        'v82rf8s15pstd5.webp',
        'w3n0vrflspoqws.webp',
        'xwUstmeuk95xlAEGjSmR.jpg',
        'WoByorR7Dez1ESjHweY7.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.42 (2).jpg',
        'x2t76f73lnzwn7.webp',
        'w056quzatkrghz.webp',
        'WhatsApp Image 2023-07-05 at 14.30.42 (1).jpg',
        'x7npj2ofopi6fn.webp',
        'zioouh8ou6s1x2.webp',
        'wv2u21wdiz68dn.webp',
        'WhatsApp Image 2023-07-05 at 14.30.44.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.43 (1).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.44 (1).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.43 (2).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.43.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.42.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.47 (1).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.46 (1).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.45 (1).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.47 (2).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.46.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.45.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.47.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.48 (2).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.47 (3).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.48 (1).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.49 (2).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.50 (1).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.49 (1).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.48 (3).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.49.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.48.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.50 (2).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.51 (2).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.52.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.50.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.51 (3).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.51 (1).jpg',
        'WhatsApp Image 2023-07-05 at 14.32.02.jpg',
        'WhatsApp Image 2023-07-05 at 14.30.52 (1).jpg',
        'WhatsApp Image 2023-07-05 at 14.30.51.jpg',
        's4iz5ykakmx0pi.webp',
        'i8pj0oruyx4xoj.webp',
        'gdfir811d8d9hp.webp',
        '2ivbrsygjp72at.webp',
        '6mw3629kyfv8z4.webp',
        '3q6z8j5wla3us9.webp',
        '28ouog7pf4czvm.webp',
        'jc6with9iymi3y.webp',
        'onzv6gaw2fdr1k.webp',
        'ugol27xrf55aqi.webp',
        'xrj4f9zr5uahj5.webp'
    ];

    useEffect(() => {
        const shuffledArray = Array.from({length: 100}, (_, i) => i);
        for (let i = images.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        setImagesArray(shuffledArray.slice(0, 10)); // Rastgele 10 fotoğrafı al
    }, []);

    useEffect(() => {
        const cycle = async () => {
            setShowImages(true);
            await new Promise((resolve) => setTimeout(resolve, 250000)); // 2 seconds to display images
            setShowImages(false);
        };
        console.log(imagesArray);
        cycle();
    }, []);

    return (
        <div style={{height: 60, width: "100%",background:"#fff3"}}>
            <div style={{width:"80%", margin:"auto"}}>
                <small className="sonUyelerYazi ">{t('son_uyeler')}:</small>
            </div>
            <div className="flex justify-center items-center">
                {showImages && (
                    <div className="flex justify-center items-center image-container">
                        <div className="flex justify-center items-center image-wrapper">
                            {imagesArray.map((value) => {
                                return (
                                    <div key={value} className="flex justify-center tiklanmaEngelle items-center image mx-1">
                                        <img onMouseDown={(e) => {
                                            e.preventDefault();
                                        }} onClick={(e) => {
                                             e.preventDefault();
                                         }}
                                         src={"./img/new-users/"+images[value]} style={{borderRadius:"100%",userSelect: "none"}} className={"tiklanmaEngelle"} alt="" width="50" height="50"/>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
