import React from "react";

export default function ResimMermiButonlar({swiperId, bulletButton, aktifUye, toplamResim, referans, width}) {
    const x = (id) => {
        bulletButton(id)
    }

    const bulletStyle1 = {
        width: "100%",
        height: 20,
        position: "absolute",
        zIndex: 5555
    };

    let uzunluk = width / aktifUye?.media?.length;


    return (
        <div className="flex justify-center">
            {toplamResim > 0 ? (
                <div className="flex items-center justify-center shadowStyle111" style={bulletStyle1}>
                    {aktifUye?.media?.map((item, i2) => {
                        return (
                            <button onClick={() => x(i2)} key={i2} ref={referans[i2]} style={{
                                width: uzunluk,
                                height: 6,
                                borderRadius: 30,
                                backgroundColor: "#fff",
                                marginRight: 5,
                                marginLeft: 5,
                                opacity: swiperId === i2 ? 0.9 : 0.5}}></button>
                        )
                    })}
                </div>
            ) : (null)}
        </div>
    )
}
