import React, {useEffect, useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import "../../styles/main.css";
import ParolaSifirlaModal from "../../components/Modallar/ParolaSifirlaModal";
import HesapOlusturModal from "../../components/Modallar/HesapOlusturModal";
import OturumAcModal from "../../components/Modallar/OturumAcModal";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {
    faEarthAmerica,
    faEarthAsia,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocation, useNavigate} from "react-router-dom";
import IntroTexts from "./IntroTexts";
import SonUyeler from "../../components/SonUyeler";
import InstagramSvg from "../../assets/svg/SosyalMedyaSimgeleri/InstagramSvg";

let genislik = 900;
export default function Baslangic() {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();
    const selected = localStorage.getItem("i18nextLng") || "en";
    const languageMap = useMemo(() =>  ({
        en: {
            label: t("ingilizce"),
            id: 1,
            kod: "en",
            dir: "ltr",
            active: true,
            showMenu: true,
        },
        "en-US": {
            label: t("ingilizce"),
            id: 2,
            kod: "en",
            dir: "ltr",
            active: false,
            showMenu: false,
        },
        tr: {
            label: t("turkce"),
            id: 3,
            kod: "tr",
            dir: "ltr",
            active: false,
            showMenu: false,
        },
        "tr-TR": {
            label: t("turkce"),
            id: 4,
            kod: "tr",
            dir: "ltr",
            active: false,
            showMenu: true,
        },
        de: {
            label: t("almanca"),
            id: 5,
            kod: "de",
            dir: "ltr",
            active: false,
            showMenu: true,
        },
        "de-DE": {
            label: t("almanca"),
            id: 6,
            kod: "de",
            dir: "ltr",
            active: false,
            showMenu: false,
        },
    }), [t]);

    const [devicePlatform, setDevicePlatform] = useState(localStorage.getItem("devicePlatform"));

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const platformFromURL = urlParams.get("devicePlatform");
        if (platformFromURL) {
            localStorage.setItem("devicePlatform", platformFromURL);
            setDevicePlatform(platformFromURL);
        }
    }, [devicePlatform]);

    const [menuAnchor, setMenuAnchor] = React.useState(null);
    React.useEffect(() => {
        document.body.dir = languageMap[selected]?.dir;
    }, [languageMap, menuAnchor, selected]);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const yonledirme = location?.state?.id === "hesapOlusturdanGeldi" ? "oturumAcAcik" : "modalKapat";
    const [modal, setModal] = useState(yonledirme);

    const modalAktif = (id) => {
        setModal(id);
    };
    const modalAktifDegil = (id) => {
        navigate(`${location.search}`, {state: {id: "", name: ""}});
        setModal(id);
    };

    const baslangicDivStyle = {
        width: width,
        height: height,
        // background: "linear-gradient(to right, #1e5799 0%, #2ce0bf 19%, #76dd2c 40%, #dba62b 60%, #e02cbf 83%, #1e5799 100%)",
        // backgroundSize: "10000px 100%",
        // animation: "bg 15s linear infinite",
    };
    const baslangicDivStyle1 = {
        zIndex: 1,
        position: "absolute",
    };
    const baslangicDivStyle2 = {
        height: isMobile ? 55 : 80,
        width: width,
    };
    const baslangicDivStyle3 = {
        height: isMobile ? height - 55 : height - 80,
        width: width,
    };
    const baslangicModalDivStyle = {
        width: width,
        height: height,
        position: "absolute",
        zIndex: 55,
        backgroundColor: "#00000063",
    };
    const baslangicModalDivStyle1 = {
        width: isMobile || width <= genislik || height <= 625 ? "100%" : 440,
        height: isMobile || width <= genislik || height <= 625 ? "100%" : 677,
        backgroundColor: "#fff",
        borderRadius: isMobile || width <= genislik ? 0 : 8,
    };
    const baslangicModalDivStyle2 = {
        width: isMobile || width <= genislik || height <= 625 ? "100%" : 440,
        height: isMobile || width <= genislik || height <= 625 ? "100%" : 590,
        backgroundColor: "#fff",
        borderRadius: isMobile || width <= genislik ? 0 : 8,
    };

    const modalStyle = {
        position: "absolute",
        zIndex: 57,
        backgroundColor: "transparent"
    }

    const anaSayfayaDon = () => {
        window.location = `/AnaSayfa?devicePlatform=${localStorage.getItem("devicePlatform")}`;
    };

    return (
        <>
            {!isMobile && (height <= 470 || width <= 275) ? (
                <div className="flex justify-center items-center capitalize">
                    <span className="ekranDesteklenmiyorTextStyle text-center px-3">
                        {t("ekran_boyutu_desteklenmiyor")}
                    </span>
                </div>
            ) : (
                <>
                    <div className="bgg"></div>
                    <div className="bgg bgg2"></div>
                    <div className="bgg bgg3"></div>

                    <div style={baslangicDivStyle}>
                        <div style={baslangicDivStyle1}>
                            {isMobile || width <= genislik ? (
                                <>
                                    <div style={baslangicDivStyle2} className="flex">
                                        <div style={{width: "100%", height: "100%"}}
                                             className="flex justify-content-center align-items-center">
                                            <div className="grow w-64 pl-5">
                                                <div className="flex flex-row align-items-center">
                                                    <span className="logoTextBaslangicStyle tiklanmaEngelle">
                                                        MOZARED
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="grow w-64 pr-0 pr-md-5 flex justify-end items-center">
                                                <div style={{width: "40%"}}></div>
                                                <div style={{width: "60%"}}
                                                     className="flex justify-content-end align-items-center">
                                                    <div className="mr-10">
                                                        <div onClick={() => modalAktif("dilSecAcik")}
                                                             className="flex justify-center items-center languageIconStyle cursorPointer ml-5">
                                                            {(languageMap[selected]?.kod || "en") === "en" ? (
                                                                <FontAwesomeIcon icon={faEarthAmerica}/>
                                                            ) : (languageMap[selected]?.kod || "en") ===
                                                            "tr" ? (
                                                                <FontAwesomeIcon icon={faEarthAsia}/>
                                                            ) : null}
                                                            <span
                                                                className="ml-2 languageSelectDropDownText1 tiklanmaEngelle">
                                                                {languageMap[selected]?.label}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={baslangicDivStyle3} className="active-scroll">
                                        <div className="flex flex-column"
                                             style={{height: "calc(100% - 60px)", width: "100%"}}>
                                            {/* hoş geldin ve mozared yazısı */}
                                            <div style={{width: "100%", height: "45%"}}
                                                 className={`flex flex-column justify-content-center align-items-center`}>
                                                <IntroTexts
                                                    isMobile={isMobile}
                                                    width={width}
                                                    genislik={genislik}
                                                />
                                            </div>

                                            {/* kayıt ol buton */}
                                            <div onClick={() => modalAktif("hesapAcAcik")}
                                                 className={`flex justify-content-center ${
                                                     height >= 700
                                                         ? "align-items-center"
                                                         : "align-items-start"
                                                 }`}
                                                 style={{width: "100%", marginBottom: 15}}>
                                                <div
                                                    className="loginMobileButtonStyle1 flex justify-content-center align-items-center">
                                                    <span className="loginMobileButtonTextStyle1 tiklanmaEngelle">
                                                        {t("kayit_ol_buton_text")}
                                                    </span>
                                                </div>
                                            </div>

                                            {/* oturum aç buton */}
                                            <div onClick={() =>
                                                token ? anaSayfayaDon() : modalAktif("oturumAcAcik")
                                            }
                                                 className={`flex justify-content-center ${
                                                     height >= 500
                                                         ? "align-items-start"
                                                         : "align-items-end"
                                                 }`}
                                                 style={{width: "100%", marginBottom: 15}}>
                                                <div
                                                    className={`loginMobileButtonStyle3 flex justify-content-center align-items-center`}>
                                                    {token ? (
                                                        <span className="loginMobileButtonTextStyle2 tiklanmaEngelle">
                                                            {t("ana_sayfaya_don")}
                                                        </span>
                                                    ) : (
                                                        <span className="loginMobileButtonTextStyle2 tiklanmaEngelle">
                                                            {t("oturum_ac_button_text")}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex flex-column justify-end items-center">
                                                {!token ? (
                                                    <>
                                                        <div style={{marginBottom: 15}}>
                                                            <span style={{color: "#fff"}}
                                                                  className="languageSelectDropDownText1 tiklanmaEngelle">
                                                                {t("veya")}
                                                            </span>
                                                        </div>
                                                        <div onClick={() => anaSayfayaDon()}
                                                             className={`flex justify-content-center align-items-center`}
                                                             style={{width: "100%"}}>
                                                            <div
                                                                className={`loginHeaderPcButton flex justify-content-center align-items-center px-2`}>
                                                                <span style={{fontSize: 15}}
                                                                      className="loginHeaderPcButtonText tiklanmaEngelle">
                                                                    {t("uygulamayi_kesfet")}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>

                                        <SonUyeler/>

                                        <div className="flex flex-col justify-center items-center py-5"
                                             style={{width: "100%", backgroundColor: "#fbfafa"}}>
                                            <div className="row" style={{width: "80%", height: "45%"}}>
                                                <div
                                                    className="flex justify-start items-start col-12 col-md-6 my-3 pl-0">
                                                    <section>
                                                        <h3 className="text-capitalize">{t("yasal")}</h3>
                                                        <ol className="p-0 m-0">
                                                            <li>
                                                                <a className={"hakkimizdaYaziStyle"}
                                                                   href={
                                                                       (languageMap[selected]?.kod || "en") ===
                                                                       "tr"
                                                                           ? "./gizlilik-politikasi.html"
                                                                           : "./gizlilik-politikasi.html"
                                                                   }>
                                                                    {t("gizlilik_politikasi")}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className={"hakkimizdaYaziStyle"}
                                                                   href={
                                                                       (languageMap[selected]?.kod || "en") ===
                                                                       "tr"
                                                                           ? "./cookie-policy.html"
                                                                           : "./cookie-policy.html"
                                                                   }>
                                                                    {t("cerez_politikasi")}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className={"hakkimizdaYaziStyle"}
                                                                   href={
                                                                       (languageMap[selected]?.kod || "en") ===
                                                                       "tr"
                                                                           ? "./terms-of-use.html"
                                                                           : "./terms-of-use.html"
                                                                   }>
                                                                    {t("kullanim_kosullari")}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className={"hakkimizdaYaziStyle"}
                                                                   href={
                                                                       (languageMap[selected]?.kod || "en") ===
                                                                       "tr"
                                                                           ? "./transfer-of-personal-data-abroad.html"
                                                                           : "./transfer-of-personal-data-abroad.html"
                                                                   }>
                                                                    {t(
                                                                        "kisisel_verilerin_yurt_disina_aktarilmasina_iliskin_acik_riza"
                                                                    )}
                                                                </a>
                                                            </li>
                                                        </ol>
                                                    </section>
                                                </div>
                                                <div
                                                    className="flex justify-start items-start col-12 col-md-6 my-3 pl-0">
                                                    <section>
                                                        <h3 className="text-capitalize">
                                                            {t("sosyal_medya_hesaplari")}
                                                        </h3>
                                                        <div className="flex justify-start items-center p-0 m-0">
                                                            <div className="mr-5">
                                                                <a href={
                                                                    "https://www.instagram.com/mozaredcom/?igshid=MzRlODBiNWFlZA%3D%3D"
                                                                }>
                                                                    <InstagramSvg color="black" hover="#EB3428"/>
                                                                </a>
                                                            </div>
                                                            {/*<div className="mr-5">*/}
                                                            {/*    <FacebookSvg color="black" hoverColor="#EB3428"/>*/}
                                                            {/*</div>*/}
                                                            {/*<div className="mr-5">*/}
                                                            {/*    <TikTokSvg color="black" hoverColor="#EB3428"/>*/}
                                                            {/*</div>*/}
                                                            {/*<div className="mr-5">*/}
                                                            {/*    <TwitterSvg color="black" hoverColor="#EB3428"/>*/}
                                                            {/*</div>*/}
                                                            {/*<div className="mr-5">*/}
                                                            {/*    <YouTubeSvg color="black" hoverColor="#EB3428"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </section>
                                                </div>
                                                <div
                                                    className="flex justify-start items-start col-12 col-md-6 my-3 pl-0">
                                                    <section>
                                                        <h3 className="text-capitalize">{t("destek")}</h3>
                                                        <ol className="p-0 m-0">
                                                            <li>
                                                                <a className={"hakkimizdaYaziStyle"}
                                                                   href={
                                                                       (languageMap[selected]?.kod || "en") ===
                                                                       "tr"
                                                                           ? "./support-tr.html"
                                                                           : "./support.html"
                                                                   }>
                                                                    {t("destek")}
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a className={"hakkimizdaYaziStyle"}
                                                                   href={
                                                                       (languageMap[selected]?.kod || "en") ===
                                                                       "tr"
                                                                           ? "./about-tr.html"
                                                                           : "./about.html"
                                                                   }>
                                                                    {t("hakkinda")}
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a className={"hakkimizdaYaziStyle"}
                                                                   href={
                                                                       (languageMap[selected]?.kod || "en") ===
                                                                       "tr"
                                                                           ? "./support-tr.html#sss"
                                                                           : "./support.html#faqs"
                                                                   }>
                                                                    {t("sikca_sorulan_sorular")}
                                                                </a>
                                                            </li>
                                                        </ol>
                                                    </section>
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    width: "80%",
                                                    height: "45%",
                                                    borderTopColor: "#d4d8de",
                                                    borderBottomColor: "#d4d8de",
                                                    borderTopWidth: 1,
                                                    borderBottomWidth: 1,
                                                }}
                                                className="flex flex-col justify-center items-start my-3 py-3">
                                                {devicePlatform === "ios" ? (
                                                    <>
                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_ios_1")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_ios_2")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_ios_3")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_ios_4")}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_1")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_2")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_3")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_4")}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="flex row justify-between"
                                                 style={{width: "80%", height: "10%"}}>
                                                <div className={
                                                    "flex justify-center items-center col-12 my-2"
                                                }>
                                                    <a className={"hakkimizdaYaziStyle text-center"}
                                                       href={
                                                           (languageMap[selected]?.kod || "en") === "tr"
                                                               ? "./about-tr.html"
                                                               : "./about.html"
                                                       }>
                                                        {t("hakkinda")}
                                                    </a>
                                                    <span className="hakkimizdaYaziStyle mx-2">/</span>
                                                    <a className={"hakkimizdaYaziStyle text-center"}
                                                       href={
                                                           (languageMap[selected]?.kod || "en") === "tr"
                                                               ? "./support-tr.html"
                                                               : "./support.html"
                                                       }>
                                                        {t("destek")}
                                                    </a>
                                                    <span className="hakkimizdaYaziStyle mx-2">/</span>
                                                    <a className={"hakkimizdaYaziStyle text-center"}
                                                       href={
                                                           (languageMap[selected]?.kod || "en") === "tr"
                                                               ? "./support-tr.html#sss"
                                                               : "./support.html#faqs"
                                                       }>
                                                        {t("sikca_sorulan_sorular")}
                                                    </a>
                                                </div>

                                                <div className="flex justify-center items-center col-12 my-2">
                                                    <span className="hakkimizdaYaziStyle text-center">
                                                        © 2023 Mozared, {t("tum_haklari_saklidir")}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={baslangicDivStyle2} className="flex">
                                        <div style={{width: "100%", height: "100%"}}
                                             className="flex justify-content-center align-items-center">
                                            <div className="grow w-64 pl-5">
                                                <div className="flex flex-row align-items-center tiklanmaEngelle">
                                                    <span className="logoTextBaslangicStyle tiklanmaEngelle">
                                                        MOZARED
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="grow w-64 pr-5 flex justify-end items-center">
                                                <div style={{width: "100%"}}
                                                     className="flex justify-content-end align-items-center">
                                                    <div onClick={() => modalAktif("dilSecAcik")}
                                                         className="flex justify-center items-center languageIconStyle cursorPointer mr-4">
                                                        {(languageMap[selected]?.kod || "en") === "en" ? (
                                                            <FontAwesomeIcon icon={faEarthAmerica}/>
                                                        ) : (languageMap[selected]?.kod || "en") ===
                                                        "tr" ? (
                                                            <FontAwesomeIcon icon={faEarthAsia}/>
                                                        ) : null}
                                                        <span
                                                            className="ml-2 languageSelectDropDownText1 tiklanmaEngelle">
                                                            {languageMap[selected]?.label}
                                                        </span>
                                                    </div>

                                                    <div className="flex justify-end items-center mr-4">
                                                        {!token ? (
                                                            <div onClick={() => anaSayfayaDon()}
                                                                 className={`flex justify-content-center ${
                                                                     height >= 500
                                                                         ? "align-items-start"
                                                                         : "align-items-end"
                                                                 }`}
                                                                 style={{height: "20%", width: "100%"}}>
                                                                <div
                                                                    className={`loginHeaderPcButton flex justify-content-center align-items-center px-2`}>
                                                                    <span style={{fontSize: 15}}
                                                                          className="loginHeaderPcButtonText tiklanmaEngelle">
                                                                        {t("uygulamayi_kesfet")}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    <div onClick={() =>
                                                        token
                                                            ? anaSayfayaDon()
                                                            : modalAktif("oturumAcAcik")
                                                    }
                                                         className="loginHeaderPcButton flex justify-content-center align-items-center px-2">
                                                        {token ? (
                                                            <span className="loginHeaderPcButtonText tiklanmaEngelle">
                                                                {t("ana_sayfaya_don")}
                                                            </span>
                                                        ) : (
                                                            <span style={{fontSize: 15}}
                                                                  className="loginHeaderPcButtonText tiklanmaEngelle">
                                                                {t("oturum_ac_button_text")}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={baslangicDivStyle3} className="active-scroll">
                                        <div
                                            className="flex flex-column align-items-center"
                                            style={{height: "calc(100% - 60px)", width: "100%"}}
                                        >
                                            <div
                                                style={{width: "100%", height: "45%"}}
                                                className="flex flex-column justify-content-center align-items-center"
                                            >
                                                <div
                                                    className="flex flex-column justify-content-center align-items-center">
                                                    <IntroTexts
                                                        isMobile={isMobile}
                                                        width={width}
                                                        genislik={genislik}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                style={{width: "100%"}}
                                                onClick={() => modalAktif("hesapAcAcik")}
                                                className={`flex justify-content-center ${
                                                    height <= 500
                                                        ? "align-items-end"
                                                        : "align-items-start"
                                                }`}
                                            >
                                                <div
                                                    className="loginPcButtonStyle1 flex justify-content-center align-items-center">
                          <span className="loginPcButtonTextStyle2 tiklanmaEngelle">
                            {t("kayit_ol_buton_text")}
                          </span>
                                                </div>
                                            </div>
                                        </div>

                                        <SonUyeler/>

                                        <div
                                            className="flex flex-col justify-center items-center py-5"
                                            style={{width: "100%", backgroundColor: "#fbfafa"}}
                                        >
                                            <div
                                                className="row"
                                                style={{width: "80%", height: "45%"}}
                                            >
                                                <div className="flex justify-start items-start col-4 pl-0">
                                                    <section>
                                                        <h3 className="text-capitalize">{t("yasal")}</h3>
                                                        <ol className="p-0 m-0">
                                                            <li>
                                                                <a
                                                                    className={"hakkimizdaYaziStyle"}
                                                                    href={
                                                                        (languageMap[selected]?.kod || "en") ===
                                                                        "tr"
                                                                            ? "./gizlilik-politikasi.html"
                                                                            : "./gizlilik-politikasi.html"
                                                                    }
                                                                >
                                                                    {t("gizlilik_politikasi")}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className={"hakkimizdaYaziStyle"}
                                                                    href={
                                                                        (languageMap[selected]?.kod || "en") ===
                                                                        "tr"
                                                                            ? "./cookie-policy.html"
                                                                            : "./cookie-policy.html"
                                                                    }
                                                                >
                                                                    {t("cerez_politikasi")}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className={"hakkimizdaYaziStyle"}
                                                                    href={
                                                                        (languageMap[selected]?.kod || "en") ===
                                                                        "tr"
                                                                            ? "./terms-of-use.html"
                                                                            : "./terms-of-use.html"
                                                                    }
                                                                >
                                                                    {t("kullanim_kosullari")}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className={"hakkimizdaYaziStyle"}
                                                                    href={
                                                                        (languageMap[selected]?.kod || "en") ===
                                                                        "tr"
                                                                            ? "./transfer-of-personal-data-abroad.html"
                                                                            : "./transfer-of-personal-data-abroad.html"
                                                                    }
                                                                >
                                                                    {t(
                                                                        "kisisel_verilerin_yurt_disina_aktarilmasina_iliskin_acik_riza"
                                                                    )}
                                                                </a>
                                                            </li>
                                                        </ol>
                                                    </section>
                                                </div>
                                                <div className="flex justify-start items-start col-4 pl-0">
                                                    <section>
                                                        <h3 className="text-capitalize">
                                                            {t("sosyal_medya_hesaplari")}
                                                        </h3>
                                                        <div className="flex justify-start items-center p-0 m-0 mt-3">
                                                            <div className="mr-5">
                                                                <a
                                                                    href={
                                                                        "https://www.instagram.com/mozaredcom/?igshid=MzRlODBiNWFlZA%3D%3D"
                                                                    }
                                                                >
                                                                    <InstagramSvg color="black" hover="#EB3428"/>
                                                                </a>
                                                            </div>
                                                            {/*<div className="mr-5">*/}
                                                            {/*    <FacebookSvg color="black" hover="#EB3428"/>*/}
                                                            {/*</div>*/}
                                                            {/*<div className="mr-5">*/}
                                                            {/*    <TikTokSvg color="black" hover="#EB3428"/>*/}
                                                            {/*</div>*/}
                                                            {/*<div className="mr-5">*/}
                                                            {/*    <TwitterSvg color="black" hover="#EB3428"/>*/}
                                                            {/*</div>*/}
                                                            {/*<div className="mr-5">*/}
                                                            {/*    <YouTubeSvg color="black" hover="#EB3428"/>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </section>
                                                </div>
                                                <div className="flex justify-start items-start col-4 pl-0">
                                                    <section>
                                                        <h3 className="text-capitalize">{t("destek")}</h3>
                                                        <ol className="p-0 m-0">
                                                            <li>
                                                                <a
                                                                    className={"hakkimizdaYaziStyle"}
                                                                    href={
                                                                        (languageMap[selected]?.kod || "en") ===
                                                                        "tr"
                                                                            ? "./support-tr.html"
                                                                            : "./support.html"
                                                                    }
                                                                >
                                                                    {t("destek")}
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a
                                                                    className={"hakkimizdaYaziStyle"}
                                                                    href={
                                                                        (languageMap[selected]?.kod || "en") ===
                                                                        "tr"
                                                                            ? "./about-tr.html"
                                                                            : "./about.html"
                                                                    }
                                                                >
                                                                    {t("hakkinda")}
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a
                                                                    className={"hakkimizdaYaziStyle"}
                                                                    href={
                                                                        (languageMap[selected]?.kod || "en") ===
                                                                        "tr"
                                                                            ? "./support-tr.html#sss"
                                                                            : "./support.html#faqs"
                                                                    }
                                                                >
                                                                    {t("sikca_sorulan_sorular")}
                                                                </a>
                                                            </li>
                                                        </ol>
                                                    </section>
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    width: "80%",
                                                    height: "45%",
                                                    borderTopColor: "#d4d8de",
                                                    borderBottomColor: "#d4d8de",
                                                    borderTopWidth: 1,
                                                    borderBottomWidth: 1,
                                                }}
                                                className="flex flex-col justify-center items-start my-3 py-3"
                                            >
                                                {devicePlatform === "ios" ? (
                                                    <>
                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_ios_1")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_ios_2")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_ios_3")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_ios_4")}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_1")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_2")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_3")}
                                                        </div>

                                                        <div className="mt-2 mb-2 footerTanitimTextStyle">
                                                            {t("footer_tanitim_yazisi_4")}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div
                                                className="flex justify-between my-2"
                                                style={{width: "80%", height: "10%"}}
                                            >
                                                <div className={"flex justify-center items-center"}>
                                                    <a
                                                        className={"hakkimizdaYaziStyle text-center"}
                                                        href={
                                                            (languageMap[selected]?.kod || "en") === "tr"
                                                                ? "./about-tr.html"
                                                                : "./about.html"
                                                        }
                                                    >
                                                        {t("hakkinda")}
                                                    </a>
                                                    <span className="hakkimizdaYaziStyle mx-2">/</span>
                                                    <a
                                                        className={"hakkimizdaYaziStyle text-center"}
                                                        href={
                                                            (languageMap[selected]?.kod || "en") === "tr"
                                                                ? "./support-tr.html"
                                                                : "./support.html"
                                                        }
                                                    >
                                                        {t("destek")}
                                                    </a>
                                                    <span className="hakkimizdaYaziStyle mx-2">/</span>
                                                    <a
                                                        className={"hakkimizdaYaziStyle text-center"}
                                                        href={
                                                            (languageMap[selected]?.kod || "en") === "tr"
                                                                ? "./support-tr.html#sss"
                                                                : "./support.html#faqs"
                                                        }
                                                    >
                                                        {t("sikca_sorulan_sorular")}
                                                    </a>
                                                </div>

                                                <div className="flex justify-center items-center">
                          <span className="hakkimizdaYaziStyle text-center">
                            © 2023 Mozared, {t("tum_haklari_saklidir")}
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        {modal === "oturumAcAcik" && (
                            <div className="flex justify-content-center align-items-center"
                                 style={{width: width, height: height}}>
                                <div style={baslangicModalDivStyle}
                                     onClick={() => modalAktifDegil("modalKapat")}></div>
                                <div style={{...modalStyle, ...baslangicModalDivStyle1}}
                                     className="active-scroll flex flex-column align-items-center">
                                    {/*modal kapat tuşu*/}
                                    <div style={{width: "100%", height: 65}}
                                         className="flex justify-content-end align-items-center pr-5">
                                        <div onClick={() => modalAktifDegil("modalKapat")}
                                             className="flex box justify-content-center align-items-center">
                                            <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                        </div>
                                    </div>
                                    {/*oturum aç*/}
                                    <OturumAcModal/>
                                    {/*parola sıfırlama*/}
                                    <div onClick={() => modalAktif("parolaSifirlaAcik")}
                                         style={{width: "80%", height: 65}}
                                         className="sifremiUnuttumDivStyle11 flex justify-center items-center">
                                        <span className="sifremiUnuttumTextStyle11 tiklanmaEngelle">
                                            {t("giris_yapmakta_problem_mi_yasiyorsunuz")}
                                        </span>
                                    </div>

                                </div>
                            </div>
                        )}

                        {modal === "hesapAcAcik" && (
                            <div className="flex justify-content-center align-items-center"
                                 style={{width: width, height: height}}>
                                <div style={baslangicModalDivStyle} onClick={() => modalAktifDegil("modalKapat")}></div>
                                <div style={{...modalStyle, ...baslangicModalDivStyle1}}
                                     className="active-scroll flex flex-column align-items-center">
                                    {/*modal kapat tuşu*/}
                                    <div style={{width: "100%", height: 65}}
                                         className="flex justify-content-end align-items-center pr-5">
                                        <div onClick={() => modalAktifDegil("modalKapat")}
                                             className="flex box justify-content-center align-items-center">
                                            <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                        </div>
                                    </div>
                                    {/*hesap oluştur*/}
                                    <HesapOlusturModal />
                                    {/*parola sıfırlama*/}
                                    <div onClick={() => modalAktif("parolaSifirlaAcik")}
                                         style={{width: "80%", height: 65}}
                                         className="sifremiUnuttumDivStyle11 flex justify-center items-center">
                                        <span className="sifremiUnuttumTextStyle11 tiklanmaEngelle">
                                            {t("giris_yapmakta_problem_mi_yasiyorsunuz")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {modal === "parolaSifirlaAcik" && (
                            <div className="flex justify-content-center align-items-center"
                                 style={{width: width, height: height}}>
                                <div style={baslangicModalDivStyle}
                                     onClick={() => modalAktifDegil("modalKapat")}></div>
                                <div style={{...modalStyle, ...baslangicModalDivStyle2}}
                                     className="active-scroll flex flex-column align-items-center">
                                    {/*modal kapat tuşu*/}
                                    <div style={{width: "100%", height: "10%"}}
                                         className="flex justify-content-end align-items-center pr-5">
                                        <div onClick={() => modalAktifDegil("modalKapat")}
                                             className="flex box justify-content-center align-items-center">
                                            <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                        </div>
                                    </div>
                                    {/*parola sıfırla*/}
                                    <ParolaSifirlaModal/>
                                </div>
                            </div>
                        )}

                        {modal === "dilSecAcik" && (
                            <div className="flex justify-content-center align-items-center"
                                 style={{width: width, height: height}}>
                                <div style={baslangicModalDivStyle}
                                     onClick={() => modalAktifDegil("modalKapat")}></div>
                                <div style={{...modalStyle, ...baslangicModalDivStyle2}}
                                     className="active-scroll flex flex-column align-items-center">
                                    {/*modal kapat tuşu*/}
                                    <div style={{width: "100%", height: "10%"}}
                                         className="flex justify-content-end align-items-end pr-5">
                                        <div onClick={() => modalAktifDegil("modalKapat")}
                                             className="flex box justify-content-center align-items-center">
                                            <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                        </div>
                                    </div>
                                    {/*baslik*/}
                                    <div className="flex-row justify-center items-center"
                                         style={{width: "100%", height: "10%"}}>
                                        <div style={{width: "100%", height: "100%"}}
                                             className="flex justify-content-center align-items-center">
                                            <span className=" logoTextStyle1 tiklanmaEngelle">
                                                MOZARED
                                            </span>
                                        </div>
                                    </div>
                                    {/*secili olan dil*/}
                                    <div className="flex justify-content-center"
                                         style={{width: "100%", height: "15%"}}>
                                        <div style={{
                                            width: "90%",
                                            height: "100%",
                                            borderBottomColor: "#ddd",
                                            borderBottomWidth: 2,
                                        }}>
                                            <div className="languageSelectDropDownText3 tiklanmaEngelle">
                                                {t("dil_sec")}
                                            </div>
                                            <div className="languageSelectDropDownText4 tiklanmaEngelle">
                                                {languageMap[selected].label}
                                            </div>
                                        </div>
                                    </div>
                                    {/*dil sec*/}
                                    <div className="flex justify-content-center"
                                         style={{width: "100%"}}>
                                        <div className="grid gap-2 grid-cols-2 my-3"
                                             style={{width: "90%", height: 40}}>
                                            {Object.values(languageMap)
                                                .filter((item) => item?.showMenu)
                                                .map((item) => (
                                                    <React.Fragment key={item?.id}>
                                                        <div
                                                            className={`flex justify-center items-center cursorPointer pt-2 pb-2 tiklanmaEngelle ${
                                                                item.kod === languageMap[selected].kod
                                                                    ? "languageSelectDropDownText22"
                                                                    : "languageSelectDropDownText2"
                                                            }`}
                                                            onClick={() => {
                                                                i18next.changeLanguage(item.kod);
                                                                setMenuAnchor(null);
                                                            }}>
                                                            {item.label}
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
