import {BLOCK_LIST_SUCCESS, UN_BLOCK_SUCCESS, UN_BLOCK_DATA_GUNCELLE} from '../../types';

const initialState = {
    blockListData: []
};

const blockListReducer = (state = initialState, action) => {


    switch (action.type) {
        case BLOCK_LIST_SUCCESS:
            return {
                ...state,
                blockListData: action.payload
            };
        case UN_BLOCK_SUCCESS:
            return {
                ...state
            };
        case UN_BLOCK_DATA_GUNCELLE:
            console.log(action.payload)
            console.log(state.blockListData)
            return {
                ...state,
                blockListData: state.blockListData.filter((item) => item?.customer_id !== action.payload)
            };
        default:
            return state;
    }
};


export default blockListReducer;
