// reducer/Etkinlik/etkinlikKatilimcilariReducer.js

import {
    ETKINLIK_KATILIMCILARI_REQUEST,
    ETKINLIK_KATILIMCILARI_SUCCESS,
    ETKINLIGE_KATILAN_UYEYI_SIL_BUTTON,
    ETKINLIGE_KATILAN_UYEYI_SIL_EYLEM_SUCCESS,
    ETKINLIK_KATILIMCILARI_DATA_CLEAR
} from '../../types';

const initialState = {
    loading: false,
    etkinlikKatilimcilariData: [],
    error: ''
}

const etkinlikKatilimcilariReducer = (state = initialState, action) => {
    switch (action.type) {
        case ETKINLIK_KATILIMCILARI_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ETKINLIK_KATILIMCILARI_SUCCESS:
            return {
                loading: false,
                etkinlikKatilimcilariData: action.payload,
                error: ''
            }
        case ETKINLIGE_KATILAN_UYEYI_SIL_BUTTON:
            return {
                ...state,
                etkinlikKatilimcilariData: action.payload
            };
        case ETKINLIGE_KATILAN_UYEYI_SIL_EYLEM_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: ''
            }
        case ETKINLIK_KATILIMCILARI_DATA_CLEAR:
            return {
                ...state,
                etkinlikKatilimcilariData: []
            }
        default: return state
    }
}

export default etkinlikKatilimcilariReducer;
