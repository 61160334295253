import React, {useState,useEffect} from "react";
import "../../styles/main.css";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "../../utils/CustomAxios";
import Yukleniyor from "../Yukleniyor";
import SartlarModal from "./SartlarModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {isMobile} from "react-device-detect";
// import tr from "react-datepicker";


export default function HesapOlusturModal() {
    const {t} = useTranslation();
    let location = useLocation();
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const selected = localStorage.getItem("i18nextLng") || "en";
    const secilenDil = selected === "en" ? 56 : selected === "tr" ? 175 : 56;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [dilId, setDilId] = useState(secilenDil);
    const [loading, setLoading] = useState(false);
    const [bilgi, setBilgi] = useState();
    const [sartlarModal, setSartlarModal] = useState(false);
    const [sartlarId, setSartlarId] = useState("");
    const [categoryId, setCategoryId] = useState();
    const [pageId, setPageId] = useState();

    const hesapOlusturModalButton = async () => {
        setLoading(true)
        axios.post(`auth/register/first_step`, {
            email: email,
            password: password,
            language_id: dilId,
        }).then((res) => {
            setLoading(false)
            setBilgi(res.data.messages);
            if (res.data.status === 1) {
                const urlParams = new URLSearchParams(window.location.search);
                const devicePlatform = urlParams.get('devicePlatform');
                if(devicePlatform) {
                    localStorage.setItem('devicePlatform', devicePlatform);
                }
                navigate(`/HesapOlustur${location.search}`,
                    {state: {email: email, password: password}}
                );
            }
        }).catch((err) => {
            setBilgi(err?.response?.data?.messages);
            setLoading(false)
            //#TODO:toastr gelecek
        });
    }
    const enterGiris = (event) => {
        if (event.keyCode === 13) {
            if (email !== "" && password !== "") {
                hesapOlusturModalButton();
            }
        }
    }

    const sartlarModalButton = (id, categoryId, pageId) => {
        setSartlarModal(true)
        setSartlarId(id)
        setCategoryId(categoryId)
        setPageId(pageId)
    }

    const sartlarModalButtonKapat = () => {
        setSartlarModal(false)
    }


    return (
        <div style={{width: "100%", height: 'calc(100% - 130px)'}}
             className="flex flex-column justify-content-center align-items-center">
            <div style={{width: "100%", height: "100%", zIndex: 1}}
                 className="flex flex-column justify-content-center align-items-center">
                {/*modal logo*/}
                <div style={{width: "100%", height: "7%"}} className="flex flex-row align-items-center justify-content-center logoTextStyle1 tiklanmaEngelle">
                    <span>
                        MOZARED
                    </span>
                </div>
                {/*başlık*/}
                <div style={{width: "100%", height: "8%"}} className="flex justify-content-center align-items-center">
                    <span className="modalBaslikTextStyle1 tiklanmaEngelle">{t("kayit_ol_baslik_text")}</span>
                </div>
                {/*koşullar ver politikalar*/}
                <div style={{width: "80%", height: "25%"}} className="flex justify-content-center align-items-center tiklanmaEngelle">
                    <span className={`${width <= 390 && height <= 570 ? "modalBilgiTextStyleMobile" : "modalBilgiTextStyle1"} tiklanmaEngelle`}>
                        {t("hesap_olustur_a_tiklayarak")}
                        <span onClick={() => sartlarModalButton("kullanim-kosullari-id",1, 2)} className="modalBilgiTextStyle2 tiklanmaEngelle">{t("kullanim_kosullari_ve_sartlari_kabul_ediyorsunuz")}</span>
                        {t("verilerinizin_nasil_islendigini_ogrenmek_icin")}
                        <span onClick={() => sartlarModalButton("kisisel-veri-aktarimi-id",1, 8)}
                              className="modalBilgiTextStyle2 tiklanmaEngelle">{t("kisisel_verilerin_yurt_disina_aktarilmasi")}</span>
                        <span>, </span>
                        <span onClick={() => sartlarModalButton("gizlilik-politikasi-id", 1, 1)}
                              className="modalBilgiTextStyle2 tiklanmaEngelle">{t("gizlilik_politikasi")}</span>
                        {t("ve")}
                        <span onClick={() => sartlarModalButton("cerez-politikasi-id",1, 3)}
                              className="modalBilgiTextStyle2 tiklanmaEngelle">{t("cerez_politikasi")}</span>
                        {t("inceleyebilirsiniz")}
                    </span>
                </div>
                {/*inputlar*/}
                <div style={{width: "80%", height: "50%"}}
                     className="flex flex-column justify-content-center align-items-center">
                    <form className="w-full max-w-sm">
                        <div className="md:flex md:items-center mb-6">
                            <div className="md:w-full">
                                <input
                                    className="bg-gray-50 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                    id="inline-full-name" type="text" placeholder={t("e_posta")} value={email}
                                    onChange={event => setEmail(event.target.value)} autoCapitalize='none'
                                    onKeyDown={(e) => enterGiris(e)}
                                />
                            </div>
                        </div>
                        <div className="md:flex md:items-center mb-3">
                            <div className="md:w-full">
                                <input
                                    className="bg-gray-50 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                    id="inline-password" type="password" placeholder={t("parola")} value={password}
                                    onChange={event => setPassword(event.target.value)} autoCapitalize='none'
                                    onKeyDown={(e) => enterGiris(e)}
                                />
                            </div>
                        </div>
                        <div>
                            {bilgi?.map((item) => {
                                return (
                                    <div className="flex justify-center items-center" key={item.message}>
                                        <span className="uyariTextStyle tiklanmaEngelle">{item?.message}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="md:flex md:items-center mt-3">
                            <div className="md:w-full">
                                <button onClick={() => email === "" || password === "" ? null : hesapOlusturModalButton()} className={`flex justify-center items-center ${email === "" || password === "" ? "butonAktifDegil" : "butonAktif shadow" }`} type="button">
                                    <span className={`font-bold tiklanmaEngelle buttonTextStyle1111`}>{t("kayit_ol_buton_text")}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {sartlarModal === true && (
                <div style={{width: width, height: height, position: "absolute", zIndex: 5555, backgroundColor: "#00000063"}} className="flex justify-content-center align-items-center">
                    <div style={{
                        width: isMobile || width <= 900 ? "100%" : "80%",
                        height: isMobile || width <= 900 ? "100%" : "80%",
                        backgroundColor: "#fff",
                        borderRadius: isMobile || width <= 900 ? 0 : 8
                    }}>
                        <div style={{width: "100%", height: "10%"}} className="flex">
                            <div style={{width: "20%", height: "100%"}}>

                            </div>
                            <div style={{width: "60%", height: "100%"}} className="flex flex-row align-items-center justify-content-center">
                                <span className=" logoTextStyle1 tiklanmaEngelle">
                                    MOZARED
                                </span>
                            </div>
                            <div style={{width: "20%", height: "100%"}} className="flex justify-content-end align-items-center pr-5">
                                <div onClick={() => sartlarModalButtonKapat()} className="flex box justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                </div>
                            </div>
                        </div>

                        <div style={{width: "100%", height: "90%"}}>
                            <SartlarModal categoryId={categoryId} pageId={pageId} sartlarModalButton={sartlarModalButton} sartlarId={sartlarId} sartlarModal={sartlarModal}/>
                        </div>
                    </div>
                </div>
            )}

            {loading === true && (
                <div style={{width: width, height: height, position: "absolute", zIndex: 5555, backgroundColor: "#00000063"}} className="flex justify-content-center align-items-center">
                    <Yukleniyor/>
                </div>
            )}
        </div>
    )
}
