// Action Types

import { PAKET_DURUM_BILGISI } from '../types';

// Action Creator
export const paketAction = (status) => {
    return {
        type: PAKET_DURUM_BILGISI,
        payload: status,
    };
};
