// actions/Etkinlik/reddedilenUyeyiSilButtonSilEylemAction.js

import axios from "../../../../utils/CustomAxios";
import {
    REDDEDILEN_UYEYI_SIL_EYLEM_SUCCESS,
    REDDEDILEN_UYEYI_SIL_BUTTON
} from '../../../types';
import {showToast} from "../../../../utils/Functions";
import {etkinlikKatilimIstekleriReddedilenlerAction} from "../IsteklerUyelerReddedilenlerDataCek/etkinlikKatilimIstekleriReddedilenlerAction";

export const reddedilenUyeyiSilEylemSuccess = (message) => {
    return {
        type: REDDEDILEN_UYEYI_SIL_EYLEM_SUCCESS,
        payload: message
    }
}

export const reddedilenUyeyiSilButton = data => {
    return {
        type: REDDEDILEN_UYEYI_SIL_BUTTON,
        payload: data
    }
};

export const reddedilenUyeyiSilEylemAction = (customerId, secilenEventId, data) => {
    return (dispatch) => {
        return axios.delete(`customer/event-participant/remove/${secilenEventId}`, {data: {customer_id: customerId}})
            .then((res) => {
                showToast(res.data.messages);
                if (res.data.status === 1) {
                    dispatch(reddedilenUyeyiSilEylemSuccess(res.data.messages))
                    let updatedParticipants = data.event_participants.filter((item) => {
                        return parseInt(item.customer_id) !== parseInt(customerId);
                    });
                    let updatedData = {...data, event_participants: updatedParticipants};
                    dispatch(reddedilenUyeyiSilButton(updatedData));
                    dispatch(etkinlikKatilimIstekleriReddedilenlerAction(secilenEventId)) // reddedilenleri güncelle
                }
            })
            .catch((err) => {
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{type: "error", message: err.message, second: 5}]);
                }
            });
    }
}
