// actions/Explore/exploreDataAction.js

import axios from "../../../utils/CustomAxios";
import {
    EXPLORE_DATA_REQUEST,
    EXPLORE_DATA_SUCCESS,
    EXPLORE_DATA_LOADING
} from '../../types';

export const exploreDataRequest = () => {
    return {
        type: EXPLORE_DATA_REQUEST
    }
}

export const exploreDataSuccess = (explore) => {
    return {
        type: EXPLORE_DATA_SUCCESS,
        payload: explore
    }
}
export const exploreDataLoading = (loading) => {
    return {
        type: EXPLORE_DATA_LOADING,
        payload: loading
    }
}

export const exploreAction = (customer_id, token, bounds, zoom, genderFilter, orientationFilter, ageRange, aktifPaketVarMi) => {
    return (dispatch) => {
        dispatch(exploreDataLoading(true));
        dispatch(exploreDataRequest());

        const { northEastLat, northEastLng, southWestLat, southWestLng } = bounds;

        // Geçerli koordinat ve zoom seviyesinin kontrolü
        if (isNaN(northEastLat) || isNaN(northEastLng) || isNaN(southWestLat) || isNaN(southWestLng) || isNaN(zoom)) {
            console.error('Invalid bounds or zoom level:', bounds, zoom);
            dispatch(exploreDataLoading(false));
            return;
        }

        axios.get(`${token ? "customer/explore" : "web/explore"}`, {
            params: {
                northEastLat,
                northEastLng,
                southWestLat,
                southWestLng,
                zoom,
                genderFilter,
                orientationFilter,
                ageRange,
                aktifPaketVarMi,
            },
            paramsSerializer: params => {
                const queryString = Object.keys(params).map(key => {
                    if (Array.isArray(params[key])) {
                        return params[key].map(val => `${key}=${val}`).join('&');
                    }
                    return `${key}=${params[key]}`;
                }).join('&');
                return queryString;
            }
        })
            .then(response => {
                dispatch(exploreDataLoading(false));
                if (response.data.status === 1) {
                    dispatch(exploreDataSuccess(response.data.data.customers));
                }
            })
            .catch(error => {
                dispatch(exploreDataLoading(false));
                if (error.response) {
                    console.log(error.response.data.messages);
                } else {
                    console.log({ type: "error", message: error.message, second: 5 });
                }
            });
    }
}
