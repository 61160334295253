import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/main.css';
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import i18n from "i18next";
import {faBell, faBug, faCheck, faFire, faHeart, faRightToBracket, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EventIcon} from "../../assets/svg/EventIcon";


function Bildirim({notification, isMobile, width, height}) {
    const {t} = useTranslation();
    let location = useLocation();
    const navigate = useNavigate();
    const etkinlikSayfasinaYonlendir = (content) => {
        const etkinlikDetayGeriId = "etkinlikDetayGeriId";
        const begeniSayfasiGeriId = "begeniSayfasiGeriId";

        if (["new_event_created", "event_join", "event_accept", "event_reject"].includes(content)) {
            navigate(`/AnaSayfa${location.search}`, { state: { id: etkinlikDetayGeriId } });
        }

        if (["like", "match"].includes(content)) {
            navigate(`/AnaSayfa${location.search}`, { state: { id: begeniSayfasiGeriId } });
        }
    }

    return (
        <div style={{width: "100%", height: "100%"}}>
            {notification.length > 0 ? (
                notification.map((notification,i) => {
                    if (notification.type !== "message") {
                        return (
                            <div key={"ntf"+i} style={{width: "100%", height: 55, backgroundColor: "#fff"}} className={`flex my-2  tiklanmaEngelle`} onClick={() => etkinlikSayfasinaYonlendir(notification.content)}>
                                <div style={{width: "15%", height: "100%"}} className="flex justify-center items-center">
                                    {notification.content === "like" ?
                                        <FontAwesomeIcon icon={faHeart} className="fa-xl" style={{color: "#ff7854"}}/> :
                                        notification.content === "match" ?
                                            <FontAwesomeIcon icon={faFire} className="fa-xl"
                                                             style={{color: "#fd267d"}}/> :
                                            notification.content === "event_join" ?
                                                <FontAwesomeIcon icon={faRightToBracket} className="fa-xl"
                                                                 style={{color: "#CA4246"}}/> :
                                                notification.content === "event_accept" ?
                                                    <FontAwesomeIcon icon={faCheck} className="fa-xl"
                                                                     style={{color: "#44da80"}}/> :
                                                    notification.content === "event_reject" ?
                                                        <FontAwesomeIcon icon={faXmark} className="fa-xl"
                                                                         style={{color: "#fd267d"}}/> :
                                                        notification.content === "new_event_created" ?
                                                            <EventIcon active={true}/> :
                                                            <FontAwesomeIcon icon={faBug} className="fa-xl"
                                                                             style={{color: "#fd267d"}}/>}
                                </div>
                                <div style={{width: "85%", height: "100%"}} className={`flex justify-start items-center bildirimTextStyle1 ${isMobile ? "bildirimTextStyleFontSizeMobile" : width <= 400 ? "bildirimTextStyleFontSizePcWidth400px" : "bildirimTextStyleFontSizePc"}`}>
                                    {i18n.t(notification.content)}
                                </div>
                            </div>
                        )
                    } else {
                        return "";
                    }
                })
            ) : (
                <div style={{width: "100%", height: "100%"}} className="flex flex-column justify-center items-center">
                    <FontAwesomeIcon icon={faBell} className="fa-xl" style={{color: "#ddd"}}/>
                    <span className="begenenlerTextStyle1 tiklanmaEngelle">{t("bildirimleriniz_burada_listelenir")}</span>
                </div>
            )
            }
        </div>
    )
}

export default Bildirim;
