// blockEylemiAction.js

import axios from "../../../utils/CustomAxios";
import { BLOCK_LIST_SUCCESS } from '../../types';
import {showToast} from "../../../utils/Functions";

export const blockListAction = (uyeId) => {
    return (dispatch) => {
        axios.get('customer/block/list').then((res) => {
            if (res.data.status === 1) {
                dispatch(blockListBasarili(res.data.data.blocksList));
            }
        }).catch((err) => {
            if (err.response) {
                showToast(err.response.data.messages);
            } else {
                showToast([{type: "error", message: err.message, second: 5}]);
            }
        })
    };
};

export const blockListBasarili = (blockData) => {
    return {
        type: BLOCK_LIST_SUCCESS,
        payload: blockData
    };
};
