import React from "react";
import "../../styles/main.css";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import ConfettiSvg from "../../assets/svg/ConfettiSvg";
import {Logo} from "../../assets/svg/Logo";


export default function EslesilinceAcilanModal({eslesilenUye, height, width, user}) {
    const {t} = useTranslation();

    return (
        <div style={{width: "100%", height: "100%"}} className="flex flex-column justify-content-center align-items-center">
            {/*modal logo*/}
            <div style={{width: "100%", height: "15%"}} className="flex flex-row align-items-center justify-content-center">
                <div className="logoTextStyle1 pt-2 tiklanmaEngelle">
                    <span>
                        MOZARED
                    </span>
                </div>
            </div>
            {/*başlık*/}
            <div style={{width: "100%", height: "20%"}} className="flex flex-column justify-content-center align-items-center">
                <span className="hesapOluşturModalTextStyle11 tiklanmaEngelle mb-3">{t("tebrikler_eslestiniz")}</span>
                {/* <span className="hesapOluşturModalTextStyle12 pt-2 tiklanmaEngelle">Eşleştiniz hemen mesaj gönder.</span>*/}
                <ConfettiSvg width={48} height={48}/>
            </div>
            {/*profil fotografı ve ad, soyad*/}
            <div style={{width: "80%", height: "65%"}}>
                <div style={{width: "100%", height: "55%"}} className="flex justify-center items-center">
                    {eslesilenUye ? (
                        <div>
                            {eslesilenUye?.media ? (
                                <img className={`tiklanmaEngelle ${isMobile ? "profileFotoDivStyle1" : width <= 250 || height <= 470 ? "uyeProfilDetayEnDusukPixel" : width <= 500 || height <= 550 ? "profileFotoDivStyle122" : "profileFotoDivStyle1"}`}
                                    src={eslesilenUye?.media[0]?.url + eslesilenUye?.media[0]?.file_name} alt=''/>
                            ) : (
                                <Logo className={`tiklanmaEngelle ${isMobile ? "profileFotoDivStyle1" : width <= 250 || height <= 470 ? "uyeProfilDetayEnDusukPixel" : width <= 500 || height <= 550 ? "profileFotoDivStyle122" : "profileFotoDivStyle1"}`}/>
                            )
                            }
                        </div>
                    ) : (
                        <img
                            className={`tiklanmaEngelle ${isMobile ? "profileFotoDivStyleMobile" : width <= 500 || height <= 550 ? "profileFotoDivStyle122" : "profileFotoDivStyle1"}`}
                            src={"https://picsum.photos/200"} alt=''/>
                    )}
                </div>
                <div className="flex justify-center items-center" style={{width: "100%", height: "45%"}}>
                    <span className="profileTextStyle1">{eslesilenUye && (`${eslesilenUye?.first_name} ${eslesilenUye?.last_name} (${eslesilenUye?.age})`)}</span>
                </div>
            </div>
        </div>
    )
}
