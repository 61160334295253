import React, {useEffect, useState} from "react";
import "../../styles/main.css";
import {useTranslation} from "react-i18next";
import axios from "../../utils/CustomAxios";
import {showToast} from "../../utils/Functions";
import GradientSvg from "../../assets/svg/GradientSvg";
import {CircularProgressbar} from "react-circular-progressbar";

export default function ParolaSifirlaModal() {
    const {t} = useTranslation();
    const idCSS = "benBirIdyim";
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [second, setSecond] = useState(0);
    const [formType, setFormType] = useState("create-code");
    const [boyut,setBoyut] = useState(100);


    let kalanSaniye=180;
    useEffect(() => {
            let myInterval = setInterval(() => {

                if (second > 0) {
                    if(boyut>0){
                        setBoyut((100/kalanSaniye)*second);
                    }else{
                        setBoyut(0);
                    }
                    setSecond(second - 1);

                }
                if (second <= 0) {
                    clearInterval(myInterval)
                    setFormType("create-code")
                }
            }, 1000)
            return () => {
                clearInterval(myInterval);
            };
    });

    const resetPassword = () => {
        setSecond(0)
        kalanSaniye=180;
        setBoyut(100);
        if (email !== "") {
            axios.post(`auth/forget/create-code`, {
                "email": email,
            }).then((res) => {
                showToast(res.data.messages);
                if (res.data.status === 1) {
                    const diffTime = Math.floor(Math.abs(new Date(res.data.data.expired_at) - new Date()) / 1000);
                    setSecond(diffTime);
                    setFormType('check-code');
                }
            }).catch((err) => {
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{type: "error", message: err.message, second: 5}]);
                }
            });
        }

    }

    const checkCodeButton = code === "" ? (
        <div className="sifremiUnuttumButtonStyle22 flex justify-content-center align-items-center pr-5 pl-5 pt-2 pb-2 tiklanmaEngelle">
            {t("gonder")}
        </div>
    ) : (
        <div onClick={() => resetPassword()} className="sifremiUnuttumButtonStyle1 flex justify-content-center align-items-center pr-5 pl-5 pt-2 pb-2 tiklanmaEngelle">
            {t("gonder")}
        </div>
    )

    return (formType === 'create-code' ? (
        <div style={{width: "100%", height: "90%"}}
                  className="flex flex-column justify-content-center align-items-center">
                {/*modal logo*/}
                <div style={{width: "100%", height: "10%"}} className="flex flex-row align-items-center justify-content-center logoTextStyle1 tiklanmaEngelle">
                    <span>
                        MOZARED
                    </span>
                </div>
                {/*başlık*/}
                <div style={{width: "100%", height: "15%"}} className="flex justify-content-center align-items-center mt-3">
                    <span className="modalBaslikTextStyle1 tiklanmaEngelle">{t("hesap_kurtarma")}</span>
                </div>

                {/*bilgi*/}
                <div style={{width: "80%", height: "15%"}} className="flex justify-content-center align-items-center mt-3">
                    <span className="sifremiUnuttumModalBilgiTextStyle1 tiklanmaEngelle">{t("e_posta_adresinize_aninda_giris_yapmanizi_saglayacak_bir_baglanti_gonderecegiz")}</span>
                </div>
                {/*inputlar*/}
                <div style={{width: "80%", height: "50%"}}
                     className="flex flex-column justify-content-center align-items-center">
                    <form style={{width: "100%", height: "100%"}}>
                        <div style={{width: "100%", height: "35%"}}
                             className="flex justify-content-start align-items-start pt-4">
                            <input
                                className="bg-gray-50 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                id="inline-full-name" type="text" placeholder={t("e_posta")} value={email}
                                onChange={text => setEmail(text.target.value)} autoCapitalize='none'/>
                        </div>
                        <div style={{width: "100%", height: "55%"}}
                             className="flex justify-content-center align-items-center">
                            <button onClick={() => email === "" ? null : resetPassword()} className={`flex justify-center items-center ${email === "" ? "butonAktifDegil" : "butonAktif shadow"}`} type="button">
                                <span className={`font-bold tiklanmaEngelle buttonTextStyle1111`}>{t("e_posta_gonder")}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            ) : (
                <div style={{width: "100%", height: "90%"}} className="flex flex-column justify-content-center align-items-center">
                    {/*modal logo*/}
                    <div style={{width: "100%", height: "10%"}} className="flex flex-row align-items-center justify-content-center logoTextStyle1 tiklanmaEngelle">
                    <span>
                        MOZARED
                    </span>
                </div>
                    {/*başlık*/}
                    <div style={{width: "100%", height: "%15"}} className="flex justify-content-center align-items-center mt-3">
                        <span className="modalBaslikTextStyle1 tiklanmaEngelle">{t("hesap_kurtarma")}</span>
                    </div>
                    {/*bilgi*/}
                    <div style={{width: "80%", height: "35%"}} className="flex justify-center align-items-center mt-3">
                        <div style={{position: "absolute", zIndex: 2}} className={`tiklanmaEngelle ${second < 20 ? "logo sifremiUnuttumModalSayacTextStyle2" : "sifremiUnuttumModalSayacTextStyle1"}`}>
                            <span>
                                 {second}
                            </span>
                        </div>

                        <div style={{position: "absolute", zIndex: 1, width: 150, height: 150}}>
                            <GradientSvg idCSS={idCSS}/>
                            <CircularProgressbar circleRatio={1} strokeWidth={3.5} value={boyut} styles={{
                                trail: {
                                    strokeLinecap: 'butt',
                                    transformOrigin: 'center',
                                },
                                path: {
                                    stroke: `url(#${idCSS})`,
                                    strokeLinecap: 'butt',
                                    transformOrigin: 'center',
                                },
                            }}/>
                    </div>
                </div>
                    {/*inputlar*/}
                    <div style={{width: "80%", height: "40%"}} className="flex flex-column justify-content-center align-items-center">
                    <form style={{width: "100%", height: "100%"}}>
                        <div style={{width: "100%", height: "35%"}}
                             className="flex justify-content-start align-items-start pt-4">
                            <input
                                className="bg-gray-50 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-400"
                                id="inline-full-name" type="text" placeholder={t("code")} value={code}
                                onChange={text => setCode(text.target.value)}/>
                        </div>
                        <div style={{width: "100%", height: "55%"}}
                             className="flex justify-content-center align-items-center">
                            <button onClick={() => code === "" ? null : resetPassword()} className={`flex justify-center items-center ${code === "" ? "butonAktifDegil" : "butonAktif shadow"}`} type="button">
                                <span className={`font-bold tiklanmaEngelle buttonTextStyle1111`}>{t("gonder")}</span>
                            </button>
                        </div>
                    </form>
                </div>
                </div>
        )
    )
}
