// reducer/Etkinlik/etkinlikListesiReducer.js

import {
    ETKINLIK_LISTESI_REQUEST,
    ETKINLIK_LISTESI_SUCCESS,
    ETKINLIK_LISTESI_CLEAR,
    ETKINLIK_LISTESI_LOADING,
    ETKINLIK_SIL_SUCCESS,
    ETKINLIK_SIL_LOADING
} from '../../types';

const initialState = {
    loading: false,
    eventListData: [],
    error: '',
    etkinlikSilLoading: false
}

const etkinlikListesiReducer = (state = initialState, action) => {
    switch (action.type) {
        case ETKINLIK_LISTESI_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ETKINLIK_LISTESI_SUCCESS:
            return {
                ...state,
                loading: false,
                eventListData: action.payload,
                error: ''
            }
        case ETKINLIK_LISTESI_CLEAR:
            return {
                ...state,
                eventListData: []
            }
        case ETKINLIK_LISTESI_LOADING:
            return {
                ...state,
                loading: action.payload
            };

        case ETKINLIK_SIL_SUCCESS:
            return {
                ...state,
                eventListData: state.eventListData.filter(etkinlik => etkinlik.id !== action.payload),
                etkinlikSilLoading: false
            };
        case ETKINLIK_SIL_LOADING:
            return {
                ...state,
                etkinlikSilLoading: action.payload
            }
        default: return state
    }
}

export default etkinlikListesiReducer;
