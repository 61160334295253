// reducer/Explore/exploreDataReducer.js

import {
    EXPLORE_DATA_REQUEST,
    EXPLORE_DATA_SUCCESS,
    EXPLORE_DATA_LOADING
} from '../../types';

const initialState = {
    loading: false,
    exploreData: [],
}

const exploreReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXPLORE_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case EXPLORE_DATA_SUCCESS:
            return { ...state, exploreData: action.payload, loading: false };
        case EXPLORE_DATA_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default: return state
    }
}

export default exploreReducer;
