export function HotOrNotIcon({className,active=false}) {
    return (
        <svg
            id="hotOrNot"
            data-name="hotOrNot"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            className={className}
            style={{width:24}}
        >
            <defs>
                <style>
                    {
                        ".hot-or-not{stroke-width:2.13px;stroke-linecap:round;stroke-miterlimit:10}"
                    }
                </style>
            </defs>
            <path
                className="hot-or-not"
                style={{fill:(active?"#8dd3f6":"#ddd"),stroke:(active?"#316bb3":"#ddd")}}
                d="m30.31 9.5-12.43 6.94M17.88 16.44l12.43 6.94M25.07 12.42l4.78 2.59M25.63 20.77l4.55-2.96M25.63 20.67l-.16 5.43M25.07 7.01l.08 5.43M22.54 4.66l-4.76 2.61M22.55 27.73l-4.67-2.77"
            />
            <path
                d="M9.41 10.29c.16.16.31.32.45.48.75.8 1.53 1.63 2.76 1.63.3 0 .59-.05.87-.16.3-.12.55-.27.75-.44l-.03 17.38c-1.82-.23-3.55-.77-5.11-1.61-1.37-.74-3.25-1.75-4.38-3.88-.31-.59-1.05-1.98-.88-3.82.93 1.49 2.04 2.24 3.37 2.25 2.3 0 3.56-2.41 3.89-3.15 1.26-2.81.1-5.95-1.7-8.68m6.99-9.8Zm0 0c-.48.33-1.18.9-1.81 1.78-1.21 1.69-1.46 3.57-1.56 4.64-.2 2.14.16 3.16-.29 3.33-.04.01-.08.02-.12.02-.61 0-1.52-1.62-3.22-2.78C7.6 6.26 5.51 5.95 3.74 5.95c-.35 0-.69.01-1.01.03 1.15 1.03 8.43 7.65 6.43 12.1-.07.15-.87 1.89-1.95 1.89H7.2c-1.77-.02-2.93-4.7-3.11-5.45-.59.63-2.11 2.41-2.37 5.09-.23 2.44.7 4.26 1.12 5.05 1.44 2.71 3.74 3.95 5.26 4.76 3.12 1.68 6.14 1.98 8.05 1.98h.19c.02-10.31.03-20.61.05-30.92Z"
                style={{
                    fill: (active?"#d2242a":"#ddd"),
                }}
            />

        </svg>
    );
}
