// unBlockEylemiAction.js

import axios from "../../../utils/CustomAxios";
import {UN_BLOCK_SUCCESS, UN_BLOCK_DATA_GUNCELLE} from '../../types';
import {showToast} from "../../../utils/Functions";

export const unBlockEylemiAction = (id) => {
    return (dispatch) => {
        return axios.delete(`customer/block/${id}`)
            .then((res) => {
                if (res.data.status === 1) {
                    dispatch(unBlockEylemiBasarili(res.data.status));
                    dispatch(unBlockDataGuncelle(id));
                }
            })
            .catch((err) => {
                if (err.response) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{ type: "error", message: err.message, second: 5 }]);
                }
            });
    };
};
export const unBlockEylemiBasarili = (unBlock) => {
    return {
        type: UN_BLOCK_SUCCESS,
        payload: unBlock
    };
};
export const unBlockDataGuncelle = (id) => {
    return {
        type: UN_BLOCK_DATA_GUNCELLE,
        payload: id
    };
};
