import React, {createRef, useEffect, useState} from "react";
import "../../styles/main.css";
import {a, animated, useSpring} from "@react-spring/web";
import {useDrag} from "@use-gesture/react";
import {isMobile} from "react-device-detect";
import axios from "../../utils/CustomAxios";
import ResimDegistirmeYonTuslari from "../../components/ResimDegistirmeYonTuslari";
import ResimMermiButonlar from "../../components/ResimMermiButonları";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleInfo,
    faHeart,
    faRotateLeft,
    faX,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
/*import GradientSvg from "../../assets/svg/GradientSvg";
import {CircularProgressbar} from "react-circular-progressbar";*/
import {useTranslation} from "react-i18next";
import UyeBittiLoading from "../../components/UyeBittiLoading";
import {useLocation, useNavigate} from "react-router-dom";
import {datas} from "../../utils/datas";
import {showToast} from "../../utils/Functions";
import zodiac from "../../utils/zodiac";
import EslesilinceAcilanModal from "../../components/Modallar/EslesilinceAcilanModal";
import {toast} from "react-toastify";
import {Logo} from "../../assets/svg/Logo";

function HotOrNot({
                      user,
                      loading,
                      setLoading,
                      coords,
                      isGeolocationAvailable,
                      isGeolocationEnabled,
                      girisYapUyariModal
                  }) {
    const token = localStorage.getItem("token");
    /*    const percentage = 3;
          const idCSS = "benBirIdyim";*/
    const {t} = useTranslation();
    const navigate = useNavigate();
    let location = useLocation();
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const [uyeler, setUyeler] = useState([]);
    const [aktifUye, setAktifUye] = useState(null);
    const [burc, setBurc] = useState(null);
    const [uyeAlmaSayisi, setUyeAlmaSayisi] = useState(0);

    const uyeleriGetir = async (aktifUyeOlustur = false) => {
        if (token) {
            setLoading(true);
            await axios
                .get(`customer/hot-or-not?limit=4&page=${uyeAlmaSayisi}`)
                .then((res) => {
                    /*showToast(res.data.messages);*/
                    setLoading(false);
                    if (res.data.status === 1) {
                        setUyeAlmaSayisi(uyeAlmaSayisi + 1);
                        let customers = res.data.data.customers;
                        customers = uyeler.concat(customers);
                        setUyeler(customers);
                        if (customers.length - 1 > aktifIndex) {
                            setAktifUye(customers[aktifIndex]);
                        }
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    if (err.response) {
                        showToast(err.response.data.messages);
                    } else {
                        showToast([{type: "error", message: err.message, second: 5}]);
                    }
                });
        }
    };
    useEffect(() => {
        if (token) {
            uyeleriGetir(true);
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            if (aktifUye && aktifUye.date_of_birth) {
                let dogum_gunu = new Date(aktifUye.date_of_birth);
                let burc = zodiac().getSignByDate(
                    {
                        day: dogum_gunu.getDate(),
                        month: dogum_gunu.getMonth() + 1,
                    },
                    localStorage.getItem("i18nextLng") || "en"
                );

                setBurc(`${burc.symbol} ${burc.name}` || "");
            }
        }
    }, [aktifUye, token]);

    const [flipped, set] = useState(false);
    const {transform, opacity} = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
        config: {mass: 5, tension: 650, friction: 100},
    });
    const [yon, setYon] = useState("");
    const [yonDegeri, setYonDegeri] = useState(false);

    const left = {
        bg: `linear-gradient(120deg, #f093fb 0%, #f5576c 100%)`,
        justifySelf: "end",
    };
    const right = {
        bg: `linear-gradient(120deg, #96fbc4 0%, #f9f586 100%)`,
        justifySelf: "start",
    };

    const extraSlide = 0; // Ekstra kaydırma mesafesi
    const transThreshold = 200; // Sürükleme mesafesi eşiği
    const waitTime = 2000; // 2 saniye beklemek için milisaniye olarak

    const [{x, bg, scale, justifySelf, visibility}, api] = useSpring(() => ({
        x: 0,
        visibility: "visible",
        scale: 1,
        ...left,
    }));

    let resetTimeout = null;
    const bind = useDrag(({active, movement: [x]}) => {
        const isGoingLeft = x < 0;
        const absx = Math.abs(x);
        const reachedThreshold = absx > transThreshold;
        setYonDegeri(active);
        if (active) {
            clearTimeout(resetTimeout); // Önceki bir bekleme süresi varsa iptal et
            resetTimeout = null;
        }
        if (!active && reachedThreshold) {
            const endX = isGoingLeft ? -extraSlide : extraSlide; // Kaydırma mesafesine göre ekstra kaydırma hesaplanır
            // Eğer sürükleme mesafesi eşiği aşıldıysa ve kullanıcı bıraktıysa, animasyonu yok et ve ekstra kaydır
            api.start({
                x: endX,
                scale: 1,
                visibility: "hidden",
                ...(x < 0 ? left : right),
                immediate: (name) => active && name === "x",
            });
            if (x <= -200) {
                if (token) {
                    begenme();
                } else {
                    tokensizGirisButonu(t("eslesme_baslatabilmek_icin_lutfen_oturum_acın"));
                }
                setLoading(true);
            }
            if (x >= 200) {
                if (token) {
                    begen();
                } else {
                    tokensizGirisButonu(t("eslesme_baslatabilmek_icin_lutfen_oturum_acın"));
                }
                setLoading(true);
            }
            // Belirli bir süre sonra animasyonun konumunu sıfırla ve hemen başlangıç konumunda görünür yap
            resetTimeout = setTimeout(() => {
                api.start({
                    x: 0,
                    scale: 1,
                    visibility: "visible",
                    ...left,
                    ...(x < 0 ? left : right),
                });
                setLoading(false);
            }, waitTime);
        } else {
            // Eğer sürükleme mesafesi eşiği aşılmadıysa animasyonun başlangıç konumuna ve görünürlüğüne dön
            api.start({
                x: active ? x : 0,
                scale: active ? 1 : 1,
                visibility: "visible",
                ...(x < 0 ? left : right),
                immediate: (name) => active && name === "x",
            });
            if (x < 0) {
                setYon("sol");
            }
            if (x > 0) {
                setYon("sağ");
            }
        }
    });

    const avSize = x.to({
        map: Math.abs,
        range: [50, 300],
        output: [0.5, 1],
        extrapolate: "clamp",
    });

    const [swiperId, setSwiperId] = useState(0);
    const toplamResim = aktifUye?.media ? aktifUye?.media?.length - 1 : 0;
    let referans = [];
    if (aktifUye && aktifUye.media) {
        referans = aktifUye.media.reduce((acc, val, i2) => {
            acc[i2] = createRef();
            return acc;
        }, {});
    }

    const goruntuyuKaydir = (i2) => {
        setSwiperId(i2);
        referans[i2].current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
        });
    };
    const ileri = () => {
        if (swiperId === toplamResim) {
        } else {
            goruntuyuKaydir(swiperId + 1);
        }
    };
    const geri = () => {
        if (swiperId === 0) {
        } else {
            goruntuyuKaydir(swiperId - 1);
        }
    };
    const bulletButton = (id) => {
        goruntuyuKaydir(id);
    };
    const [eslesildi, setEslesildi] = useState("henuz-eslesilmedi");
    const [eslesilenUye, setEslesilenUye] = useState([]);
    const [aktifIndex, setAktifIndex] = useState([0]);

    const eslesilinceAcilanModaliKapatmaButonu = (id) => {
        setEslesildi(id);
    };

    const begen = () => {
        if (token) {
            set(false);
            setLoading(true);
            if (aktifUye) {
                if (uyeler.length - 1 - aktifIndex < 3) {
                    uyeleriGetir();
                    resetTimeout = setTimeout(() => {
                        api.start({
                            x: 0,
                            scale: 1,
                            visibility: "visible",
                        });
                        setLoading(false);
                    }, waitTime);
                }
                axios
                    .post(`customer/like/send/${aktifUye.id}`)
                    .then((res) => {
                        if (res.data.status === 1) {
                            const yeniIndex = parseInt(aktifIndex) + 1;
                            if (uyeler[yeniIndex]) {
                                setAktifUye(uyeler[yeniIndex]);
                                setAktifIndex(yeniIndex);
                            } else {
                                setAktifUye(null);
                            }
                            if (res.data.data.match === true) {
                                setEslesilenUye(aktifUye);
                                setEslesildi("eslesildi-modali-ac");
                            } else {
                                setEslesildi("henuz-eslesilmedi");
                            }
                        }
                        resetTimeout = setTimeout(() => {
                            api.start({
                                x: 0,
                                scale: 1,
                                visibility: "visible",
                            });
                            setLoading(false);
                        }, waitTime);
                    })
                    .catch((err) => {
                        if (err.response) {
                            showToast(err.response.data.messages);
                        } else {
                            showToast([{type: "error", message: err.message, second: 5}]);
                        }
                    });
            }
        }
    };
    const begenme = () => {
        if (token) {
            set(false);
            setLoading(true);
            if (aktifUye) {
                if (uyeler.length - 1 - aktifIndex < 3) {
                    uyeleriGetir();
                    resetTimeout = setTimeout(() => {
                        api.start({
                            x: 0,
                            scale: 1,
                            visibility: "visible",
                        });
                        setLoading(false);
                    }, waitTime);
                }
                axios
                    .post(`customer/dislike/send/${aktifUye.id}`)
                    .then((res) => {
                        if (res.data.status === 1) {
                            const yeniIndex = parseInt(aktifIndex) + 1;
                            if (uyeler[yeniIndex]) {
                                setAktifUye(uyeler[yeniIndex]);
                                setAktifIndex(yeniIndex);
                            } else {
                                setAktifUye(null);
                            }
                        }
                        resetTimeout = setTimeout(() => {
                            api.start({
                                x: 0,
                                scale: 1,
                                visibility: "visible",
                            });
                            setLoading(false);
                        }, waitTime);
                    })
                    .catch((err) => {
                        if (err.response) {
                            showToast(err.response.data.messages);
                        } else {
                            showToast([{type: "error", message: err.message, second: 5}]);
                        }
                    });
            }
        }
    };
    const geriAl = async () => {
        if (token) {
            setLoading(true);
            const yeniIndex = parseInt(aktifIndex) - 1;
            if (uyeler[yeniIndex]) {
                await axios
                    .post(`customer/cancel/${uyeler[yeniIndex].id}`)
                    .then((res) => {
                        if (res.data.status === 1) {
                            setAktifUye(uyeler[yeniIndex]);
                            setAktifIndex(yeniIndex);
                        }
                        resetTimeout = setTimeout(() => {
                            api.start({
                                x: 0,
                                scale: 1,
                                visibility: "visible",
                            });
                            setLoading(false);
                        }, waitTime);
                    })
                    .catch((err) => {
                        if (err.response) {
                            showToast(err.response.data.messages);
                        } else {
                            showToast([{type: "error", message: err.message, second: 5}]);
                        }
                    });
            } else {
                toast("uye yok");
            }
        }
    };

    const cardDivStyle = {
        width: isMobile ? width : width <= 475 ? width : 425,
        height: isMobile ? height - 180 : height <= 795 ? height - 180 : 788 - 180,
    };
    const cardDivStyle1 = {
        width: isMobile ? width : width <= 475 ? width : 425,
        height: isMobile ? height - 180 : height <= 795 ? height - 180 : 788 - 180,
        background: bg,
    };
    const cardDivStyle3 = {
        width: isMobile ? width : width <= 475 ? width : 425,
        height: 84,
    };
    const cardDivStyleUyeBittiEkranStyle = {
        width: isMobile ? width : width <= 475 ? width : 425,
        height: isMobile ? height - 96 : height <= 795 ? height - 96 : 788 - 96,
    };
    const eslesilinceAcilanModalDivStyle1 = {
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 555555,
        backgroundColor: "#00000063",
    };
    const eslesilinceAcilanModalDivStyle2 = {
        width: isMobile || width <= 900 ? "100%" : "90%",
        height: isMobile || width <= 900 ? "100%" : "90%",
        backgroundColor: "#fff",
        borderRadius: isMobile || width <= 900 ? 0 : 8,
    };

    const AyarlaraYonlendirButton = () => {
        navigate(`/Ayarlar${location.search}`);
    };
    const sayfayiYenileButton = () => {
        window.location.reload();
    };

    const tokensizGirisButonu = (uyari) => {
        girisYapUyariModal(uyari);
    };

    return (
        <div className="flex justify-content-center align-items-center">
            {!token ? (
                <div
                    style={{
                        width: isMobile ? width : width <= 475 ? width : 425,
                        height: isMobile
                            ? height - 96
                            : height <= 795
                                ? height - 96
                                : 788 - 96,
                    }}
                >
                    <div style={cardDivStyle}>
                        <a.div
                            className={`${flipped === false ? "c" : "c2"} ${"back"}`}
                            style={{
                                opacity: opacity.to((o) => 1 - o),
                                transform,
                            }}
                        >
                            <ResimMermiButonlar
                                toplamResim={toplamResim}
                                aktifUye={aktifUye}
                                swiperId={swiperId}
                                referans={referans}
                                bulletButton={bulletButton}
                                width={width}
                            />
                            <animated.div
                                {...bind()}
                                className="itemStyle"
                                style={{...cardDivStyle1, visibility}}
                            >
                                <animated.div
                                    className="av flex align-items-center justify-content-center"
                                    style={{scale: avSize, justifySelf}}
                                >
                                    {yon === "sol" ? (
                                        <FontAwesomeIcon
                                            icon={faX}
                                            className="fa-xl"
                                            width={24}
                                            height={24}
                                            style={{color: "#ff4754"}}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faHeart}
                                            className="fa-xl"
                                            width={24}
                                            height={24}
                                            style={{color: "#28e1b2"}}
                                        />
                                    )}
                                </animated.div>
                                <animated.div className="fg fg1" style={{x, scale}}>
                                    <div className="w-full flex-shrink-0">
                                        <Logo
                                            style={cardDivStyle}
                                            className="cardStyle tiklanmaEngelle"
                                        />
                                    </div>
                                </animated.div>
                            </animated.div>
                            <ResimDegistirmeYonTuslari
                                top={"45%"}
                                yonDegeri={yonDegeri}
                                ileri={""}
                                geri={""}
                                toplamResim={toplamResim}
                                swiperId={swiperId}
                            />
                        </a.div>
                        <a.div
                            className={`${flipped === true ? "c" : "c2"} ${"front"}`}
                            style={{opacity, transform, rotateY: "180deg"}}
                        >
                            <div style={cardDivStyle1} className="active-scroll">
                                <div
                                    style={{width: "100%", height: "40%"}}
                                    className="flex justify-content-center align-items-center profilDetayShadow"
                                >
                                    <div className="flex flex-column justify-content-center align-items-center">
                                        <Logo
                                            className={`${
                                                isMobile
                                                    ? "profileFotoDivStyle1"
                                                    : width <= 250 || height <= 470
                                                        ? "uyeProfilDetayEnDusukPixel"
                                                        : width <= 500 || height <= 550
                                                            ? "profileFotoDivStyle122"
                                                            : "profileFotoDivStyle1"
                                            }`}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{width: "100%", height: "60%"}}
                                    className="flex flex-column"
                                >
                                    <div className="flex flex-column px-3 py-3">
                                        {/*isim, yaş, burç*/}
                                        <div className="flex justify-start items-center">
                      <span
                          className={
                              "profilDetayNameTextStyle tiklanmaEngelle my-2"
                          }
                      >
                        Mozared
                      </span>
                                            <span className="profilDetayTextStyle tiklanmaEngelle my-1 mx-1">
                        -
                      </span>
                                            <small
                                                className="profilDetayTextStyle tiklanmaEngelle my-1 mx-1"
                                                style={{fontSize: "0.9em"}}
                                            >
                                                -
                                            </small>
                                        </div>
                                        {/*mesafe*/}
                                        <div className="flex items-center">
                      <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                        {t("mesafe")}:{" "}
                      </span>
                                            <span className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">
                        0 km
                      </span>
                                        </div>
                                        {/*cinsiyet*/}
                                        <div className="flex items-center">
                      <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                        {t("cinsiyet")}:{" "}
                      </span>
                                            <span className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">
                        -
                      </span>
                                        </div>
                                        {/*cinsel yönelim*/}
                                        <div className="flex items-center">
                      <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                        {t("cinsel_yonelim")}:{" "}
                      </span>
                                            <span className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">
                        -
                      </span>
                                        </div>
                                        {/*aciklama*/}
                                        <div className="flex flex-column justify-center">
                      <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                        {t("aciklama")}:{" "}
                      </span>
                                            <span
                                                style={{width: "100%", height: 100}}
                                                className="profilDetayAciklamaStyle active-scroll mb-3 tiklanmaEngelle"
                                            >
                        -
                      </span>
                                        </div>
                                        {/*bir süper güç seçebilirsen hangisini seçerdin*/}
                                        <div className="flex flex-column justify-center">
                      <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                        {t("bir_super_guc_secebilseydin_hangisini_secerdin")}:{" "}
                      </span>
                                            <span
                                                style={{width: "100%", height: 50}}
                                                className="flex items-center profilDetayTextStyle active-scroll mb-3 tiklanmaEngelle"
                                            >
                        -
                      </span>
                                        </div>
                                        {/*en çok ne zaman mutlu hissedersin*/}
                                        <div className="flex flex-column justify-center">
                      <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                        {t("kendini_en_mutlu_hissettigin_an")}:{" "}
                      </span>
                                            <span
                                                style={{width: "100%", height: 50}}
                                                className="flex items-center profilDetayTextStyle active-scroll mb-3 tiklanmaEngelle"
                                            >
                        -
                      </span>
                                        </div>
                                        {/*okul*/}
                                        <div className="flex items-center">
                      <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                        {t("okul")}:{" "}
                      </span>
                                            <span className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">
                        -
                      </span>
                                        </div>
                                        {/*sigara kullanımı*/}
                                        <div className="flex items-center mb-2">
                      <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                        {t("sigara_kullanimi")}:{" "}
                      </span>
                                            <span className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">
                        -
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a.div>
                    </div>

                    <div style={cardDivStyle3} className="TabBarHomeDivStyle1">
                        <div
                            className="flex"
                            style={{
                                width: "100%",
                                position: "absolute",
                                zIndex: 7777,
                                paddingRight: 15,
                                marginTop: "-55px",
                                paddingTop: 12,
                                paddingBottom: 12,
                                background:
                                    flipped === true
                                        ? "transparent"
                                        : `linear-gradient(to top, #000000bf 25%, #0000009c 50%,  #00000000 100%)`,
                            }}
                        >
                            <div
                                className={
                                    "flex justify-start items-center text-white pl-4 tiklanmaEngelle"
                                }
                                style={{width: "80%"}}
                            >
                                {flipped === false && (
                                    <>
                                        Mozared
                                        <small className="ml-1">(-)</small>
                                    </>
                                )}
                            </div>
                            <div
                                onClick={() => set((state) => !state)}
                                className="flex justify-end items-end"
                                style={{width: "20%"}}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    className="fa-2x cursorPointer fa-beat"
                                    style={{
                                        color: flipped === false ? "#ddd" : "#bebaba",
                                    }}
                                />
                                {/*{aktifUye && `${aktifUye.id} - ${aktifUye?.first_name || ''} ${aktifUye?.last_name || ''} ${aktifUye?.age || ''}  ${aktifUye?.gender || ''}`}*/}
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#fbfafa",
                            }}
                            className={`flex flex-row grid grid-cols-${
                                aktifIndex > 0 ? 3 : 2
                            } gap-5 items-center justify-center`}
                        >
                            <div className="flex items-center justify-center text-white">
                                <button
                                    className={"btn btn-light rounded-circle p-3 shadow"}
                                    onClick={() => tokensizGirisButonu(t("eslesme_baslatabilmek_icin_lutfen_oturum_acın"))}
                                >
                                    <FontAwesomeIcon
                                        icon={faHeart}
                                        className="fa-xl"
                                        width={24}
                                        height={24}
                                        style={{color: "#28e1b2"}}
                                    />
                                </button>
                            </div>

                            <div className="flex items-center justify-center text-white">
                                <button
                                    className={"btn btn-light rounded-circle p-3 shadow"}
                                    onClick={() => tokensizGirisButonu(t("eslesme_baslatabilmek_icin_lutfen_oturum_acın"))}
                                >
                                    <FontAwesomeIcon
                                        icon={faX}
                                        className="fa-xl"
                                        width={24}
                                        height={24}
                                        style={{color: "#ff4754"}}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <>
                        {!loading ? (
                            <>
                                {isGeolocationAvailable && isGeolocationEnabled ? (
                                    <>
                                        {aktifUye ? (
                                            <div style={{
                                                width: isMobile ? width : width <= 475 ? width : 425,
                                                height: isMobile
                                                    ? height - 96
                                                    : height <= 795
                                                        ? height - 96
                                                        : 788 - 96,
                                            }}>
                                                <div style={cardDivStyle}>
                                                    <a.div
                                                        className={`${
                                                            flipped === false ? "c" : "c2"
                                                        } ${"back"}`}
                                                        style={{
                                                            opacity: opacity.to((o) => 1 - o),
                                                            transform,
                                                        }}
                                                    >
                                                        <ResimMermiButonlar
                                                            toplamResim={toplamResim}
                                                            aktifUye={aktifUye}
                                                            swiperId={swiperId}
                                                            referans={referans}
                                                            bulletButton={bulletButton}
                                                            width={width}
                                                        />
                                                        <animated.div
                                                            {...bind()}
                                                            className="itemStyle"
                                                            style={{...cardDivStyle1, visibility}}
                                                        >
                                                            <animated.div
                                                                className="av flex align-items-center justify-content-center"
                                                                style={{scale: avSize, justifySelf}}
                                                            >
                                                                {yon === "sol" ? (
                                                                    <FontAwesomeIcon
                                                                        icon={faX}
                                                                        className="fa-xl"
                                                                        width={24}
                                                                        height={24}
                                                                        style={{color: "#ff4754"}}
                                                                    />
                                                                ) : (
                                                                    <FontAwesomeIcon
                                                                        icon={faHeart}
                                                                        className="fa-xl"
                                                                        width={24}
                                                                        height={24}
                                                                        style={{color: "#28e1b2"}}
                                                                    />
                                                                )}
                                                            </animated.div>
                                                            <animated.div
                                                                className="fg fg1"
                                                                style={{x, scale}}
                                                            >
                                                                {aktifUye?.media ? (
                                                                    <div
                                                                        className="sliderStyle"
                                                                        style={cardDivStyle}
                                                                    >
                                                                        <div className="carousel1">
                                                                            {aktifUye?.media?.map((item, i2) => {
                                                                                return (
                                                                                    <div
                                                                                        className="w-full flex-shrink-0"
                                                                                        key={i2}
                                                                                        ref={referans[i2]}
                                                                                    >
                                                                                        <img
                                                                                            style={cardDivStyle}
                                                                                            className="cardStyle"
                                                                                            src={item.url + item.file_name}
                                                                                            alt=""
                                                                                            key={item.id}
                                                                                        />
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="w-full flex-shrink-0">
                                                                        <Logo
                                                                            style={cardDivStyle}
                                                                            className="cardStyle tiklanmaEngelle"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </animated.div>
                                                        </animated.div>
                                                        <ResimDegistirmeYonTuslari
                                                            top={"45%"}
                                                            yonDegeri={yonDegeri}
                                                            ileri={ileri}
                                                            geri={geri}
                                                            toplamResim={toplamResim}
                                                            swiperId={swiperId}
                                                        />
                                                    </a.div>
                                                    <a.div
                                                        className={`${
                                                            flipped === true ? "c" : "c2"
                                                        } ${"front"}`}
                                                        style={{opacity, transform, rotateY: "180deg"}}
                                                    >
                                                        <div
                                                            style={cardDivStyle1}
                                                            className="active-scroll"
                                                        >
                                                            <div
                                                                style={{width: "100%", height: "40%"}}
                                                                className="flex justify-content-center align-items-center profilDetayShadow"
                                                            >
                                                                {/* <div className={`${isMobile ? "profileDivStyleMobile" : width <= 500 || height <= 550 ? "profileDivStyle333" : "profileDivStyle3"}`}>
                                          <GradientSvg idCSS={idCSS}/>
                                          <CircularProgressbar circleRatio={0.75} strokeWidth={3.5} value={percentage} styles={{
                                                  trail: {
                                                      strokeLinecap: 'butt',
                                                      transform: 'rotate(-135deg)',
                                                      transformOrigin: 'center center',
                                                  },
                                                  path: {
                                                      stroke: `url(#${idCSS})`, height: "100%",
                                                      strokeLinecap: 'butt',
                                                      transform: 'rotate(-135deg)',
                                                      transformOrigin: 'center center',
                                                  },
                                              }}/>
                                      </div>*/}
                                                                <div
                                                                    className="flex flex-column justify-content-center align-items-center">
                                                                    {aktifUye?.media && token ? (
                                                                        <img
                                                                            className={`${
                                                                                isMobile
                                                                                    ? "profileFotoDivStyle1"
                                                                                    : width <= 250 || height <= 470
                                                                                        ? "uyeProfilDetayEnDusukPixel"
                                                                                        : width <= 500 || height <= 550
                                                                                            ? "profileFotoDivStyle122"
                                                                                            : "profileFotoDivStyle1"
                                                                            }`}
                                                                            src={
                                                                                aktifUye?.media[0]?.url +
                                                                                aktifUye?.media[0]?.file_name
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    ) : (
                                                                        <Logo
                                                                            className={`${
                                                                                isMobile
                                                                                    ? "profileFotoDivStyle1"
                                                                                    : width <= 250 || height <= 470
                                                                                        ? "uyeProfilDetayEnDusukPixel"
                                                                                        : width <= 500 || height <= 550
                                                                                            ? "profileFotoDivStyle122"
                                                                                            : "profileFotoDivStyle1"
                                                                            }`}
                                                                        />
                                                                    )}
                                                                    {/*  <div style={{position: "absolute", top: "85%"}} className={`flex justify-content-center align-items-center ${width <= 500 || height <= 550 ? "profileYuzdeDivStyle22" : "profileYuzdeDivStyle1"}`}>
                                              <span className={`${isMobile ? "profileYuzdeTextStyle22" : width <= 500 || height <= 550 ? "profileYuzdeTextStyle22" : "profileYuzdeTextStyle1"}`}>
                                                  {`${percentage}%`} {t("tamamlandi")}
                                              </span>
                                          </div>*/}
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{width: "100%", height: "60%"}}
                                                                className="flex flex-column"
                                                            >
                                                                <div className="flex flex-column px-3 py-3">
                                                                    {/*isim, yaş, burç*/}
                                                                    <div className="flex justify-start items-center">
                                    <span
                                        className={
                                            "profilDetayNameTextStyle tiklanmaEngelle my-2"
                                        }
                                    >
                                      {aktifUye &&
                                          `${aktifUye?.first_name || "-"} ${
                                              aktifUye?.last_name || ""
                                          }`}
                                    </span>
                                                                        <span
                                                                            className="profilDetayTextStyle tiklanmaEngelle my-1 mx-1">
                                      ({aktifUye?.age || ""})
                                    </span>
                                                                        <small
                                                                            className="profilDetayTextStyle tiklanmaEngelle my-1 mx-1"
                                                                            style={{fontSize: "0.9em"}}
                                                                        >
                                                                            {burc}
                                                                        </small>
                                                                    </div>
                                                                    {/*mesafe*/}
                                                                    <div className="flex items-center">
                                    <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                                      {t("mesafe")}:{" "}
                                    </span>
                                                                        <span
                                                                            className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">
                                      {aktifUye && token
                                          ? `${aktifUye?.distance}`
                                          : "-"}{" "}
                                                                            km
                                    </span>
                                                                    </div>
                                                                    {/*cinsiyet*/}
                                                                    <div className="flex items-center">
                                    <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                                      {t("cinsiyet")}:{" "}
                                    </span>
                                                                        <span
                                                                            className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">
                                      {aktifUye && token
                                          ? `${
                                              aktifUye?.gender
                                                  ? datas().cinsiyetData[
                                                  aktifUye.gender
                                                  ]?.label || "-"
                                                  : "-"
                                          }`
                                          : ""}
                                    </span>
                                                                    </div>
                                                                    {/*cinsel yönelim*/}
                                                                    <div className="flex items-center">
                                    <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                                      {t("cinsel_yonelim")}:{" "}
                                    </span>
                                                                        <span
                                                                            className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">
                                      {aktifUye && token
                                          ? `${
                                              aktifUye?.sexual_orientation
                                                  ? datas().cinselYonelimData[
                                                  aktifUye?.sexual_orientation
                                                  ]?.label || "-"
                                                  : "-"
                                          }`
                                          : ""}
                                    </span>
                                                                    </div>
                                                                    {/*aciklama*/}
                                                                    <div className="flex flex-column justify-center">
                                    <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                                      {t("aciklama")}:{" "}
                                    </span>
                                                                        <span
                                                                            style={{width: "100%", height: 100}}
                                                                            className="profilDetayAciklamaStyle active-scroll mb-3 tiklanmaEngelle"
                                                                        >
                                      {aktifUye &&
                                          `${aktifUye?.description || "-"}`}
                                    </span>
                                                                    </div>
                                                                    {/*bir süper güç seçebilirsen hangisini seçerdin*/}
                                                                    <div className="flex flex-column justify-center">
                                    <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                                      {t(
                                          "bir_super_guc_secebilseydin_hangisini_secerdin"
                                      )}
                                        :{" "}
                                    </span>
                                                                        <span
                                                                            style={{width: "100%", height: 50}}
                                                                            className="flex items-center profilDetayTextStyle active-scroll mb-3 tiklanmaEngelle"
                                                                        >
                                      {aktifUye &&
                                          `${aktifUye?.company || "-"}`}
                                    </span>
                                                                    </div>
                                                                    {/*en çok ne zaman mutlu hissedersin*/}
                                                                    <div className="flex flex-column justify-center">
                                    <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                                      {t("kendini_en_mutlu_hissettigin_an")}:{" "}
                                    </span>
                                                                        <span
                                                                            style={{width: "100%", height: 50}}
                                                                            className="flex items-center profilDetayTextStyle active-scroll mb-3 tiklanmaEngelle"
                                                                        >
                                      {aktifUye &&
                                          `${aktifUye?.job_title || "-"}`}
                                    </span>
                                                                    </div>
                                                                    {/*okul*/}
                                                                    <div className="flex items-center">
                                    <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                                      {t("okul")}:{" "}
                                    </span>
                                                                        <span
                                                                            className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">
                                      {aktifUye && `${aktifUye?.school || "-"}`}
                                    </span>
                                                                    </div>
                                                                    {/*sigara kullanımı*/}
                                                                    <div className="flex items-center mb-2">
                                    <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">
                                      {t("sigara_kullanimi")}:{" "}
                                    </span>
                                                                        <span
                                                                            className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">
                                      {aktifUye && token
                                          ? `${
                                              aktifUye?.smoking
                                                  ? datas().sigaraKullanimiData[
                                                  aktifUye.smoking
                                                  ]?.label || "-"
                                                  : "-"
                                          }`
                                          : ""}
                                    </span>
                                                                    </div>
                                                                    {/*   <div>
                                                      <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">{t('website')}: </span>
                                                      <span className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">{aktifUye && `${aktifUye?.website || '-'}`}</span>
                                                  </div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a.div>
                                                </div>

                                                <div
                                                    style={cardDivStyle3}
                                                    className="TabBarHomeDivStyle1"
                                                >
                                                    <div
                                                        className="flex"
                                                        style={{
                                                            width: "100%",
                                                            position: "absolute",
                                                            zIndex: 7777,
                                                            paddingRight: 15,
                                                            marginTop: "-55px",
                                                            paddingTop: 12,
                                                            paddingBottom: 12,
                                                            background:
                                                                flipped === true
                                                                    ? "transparent"
                                                                    : `linear-gradient(to top, #000000bf 25%, #0000009c 50%,  #00000000 100%)`,
                                                        }}>
                                                        <div className={"flex justify-start items-center text-white pl-4 tiklanmaEngelle"}
                                                             style={{width: "80%"}}>
                                                            {flipped === false && (
                                                                <>
                                                                    {aktifUye?.first_name || ""}{" "}
                                                                    {aktifUye?.last_name || ""}
                                                                    <small>({aktifUye?.age || ""})</small>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div onClick={() => set((state) => !state)}
                                                             className="flex justify-end items-end" style={{width: "20%"}}>
                                                            <FontAwesomeIcon icon={faCircleInfo} className="fa-2x cursorPointer fa-beat"
                                                                             style={{
                                                                                 color: flipped === false ? "#ddd" : "#bebaba",
                                                                             }}
                                                            />
                                                            {/*{aktifUye && `${aktifUye.id} - ${aktifUye?.first_name || ''} ${aktifUye?.last_name || ''} ${aktifUye?.age || ''}  ${aktifUye?.gender || ''}`}*/}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        backgroundColor: "#fbfafa",
                                                    }} className={`flex flex-row grid grid-cols-${aktifIndex > 0 ? 3 : 2} gap-5 items-center justify-center`}>
                                                        <div onClick={() => begen()} className="flex items-center justify-center text-white">
                                                            <button className="btn btn-light rounded-circle p-3 shadow">
                                                                <FontAwesomeIcon
                                                                    icon={faHeart}
                                                                    className="fa-xl"
                                                                    width={24}
                                                                    height={24}
                                                                    style={{color: "#28e1b2"}}
                                                                />
                                                            </button>
                                                        </div>
                                                        {aktifIndex > 0 ? (
                                                            <div onClick={() => geriAl("geriAl-id")}
                                                                className="flex items-center justify-center text-white">
                                                                <button className="btn btn-light rounded-circle p-3 shadow">
                                                                    <FontAwesomeIcon
                                                                        icon={faRotateLeft}
                                                                        className="fa-xl"
                                                                        width={24}
                                                                        height={24}
                                                                        style={{color: "#fdcd54"}}
                                                                    />
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <div onClick={() => begenme()}
                                                             className="flex items-center justify-center text-white">
                                                            <button className="btn btn-light rounded-circle p-3 shadow">
                                                                <FontAwesomeIcon
                                                                    icon={faX}
                                                                    className="fa-xl"
                                                                    width={24}
                                                                    height={24}
                                                                    style={{color: "#ff4754"}}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            // üye bitti kısmı
                                            <div style={cardDivStyleUyeBittiEkranStyle}
                                                 className="profilDetayShadow">
                                                <div style={{width: "100%", height: "50%"}}
                                                     className="flex justify-center items-center">
                                                    <UyeBittiLoading
                                                        user={user}
                                                        width={width}
                                                        height={height}
                                                    />
                                                </div>
                                                <div style={{width: "100%", height: "20%"}}
                                                     className="flex flex-column justify-center items-center px-7">
                                                    <div className="flex flex-column items-center">
                                                        <span className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${
                                                            width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" 
                                                                : "uyeBittiLoadingUyariTextFontDesktopStyle"
                                                        }`}>
                                                            {t("potansiyel_esleşmeler_tukendi")}
                                                        </span>
                                                        <span className={`uyeBittiLoadingUyariTextStyle tiklanmaEngelle ${
                                                                width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" 
                                                                    : "uyeBittiLoadingUyariTextFontDesktopStyle"}`}>
                                                            {t("ayarlara_giderek_tercihlerini_degistir")}
                                                        </span>
                                                    </div>
                                                    <div style={{width: "100%"}}
                                                         className="flex flex-column items-center mt-4">
                                                        <div style={{width: "80%", height: 44}}
                                                             onClick={() => AyarlaraYonlendirButton()}
                                                             className="flex cursorPointer justify-center items-center shadow uyeBittiLoadingButtonStyle">
                                                            <span className="uyeBittiLoadingButtonTextStyle tiklanmaEngelle">
                                                                {t("ayarlara_git")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    // konum açtırma kısmı
                                    <div style={cardDivStyleUyeBittiEkranStyle}>
                                        <div style={{width: "100%", height: "50%"}}>
                                            <UyeBittiLoading
                                                user={user}
                                                width={width}
                                                height={height}
                                            />
                                        </div>
                                        <div style={{width: "100%", height: "50%"}}
                                             className="flex flex-column items-center mt-4">
                                            <div style={{width: "60%"}} className="flex flex-column items-center">
                                                <span className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${
                                                    width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" 
                                                        : "uyeBittiLoadingUyariTextFontDesktopStyle"}`}>
                                                    {t("lütfen_konumu_actiktan_sonra_tekrar_deneyin")}
                                                </span>
                                            </div>
                                            <div style={{width: "80%", height: 44}}
                                                onClick={() => sayfayiYenileButton()}
                                                className="flex cursorPointer justify-center items-center shadow uyeBittiLoadingButtonStyle mt-3">
                                                <span className="uyeBittiLoadingButtonTextStyle tiklanmaEngelle">
                                                    {t("tekrar_dene")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            // loading kısmı
                            <div style={cardDivStyleUyeBittiEkranStyle}>
                                <div style={{width: "100%", height: "50%"}}>
                                    <UyeBittiLoading user={user} width={width} height={height}/>
                                </div>
                                <div style={{width: "100%", height: "20%"}}
                                     className="flex flex-column justify-center items-center">
                                    <div className="flex flex-column items-center">
                                        <span className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${
                                            width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" 
                                                : "uyeBittiLoadingUyariTextFontDesktopStyle"}`}>
                                            {t("yukleniyor")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>

                    {eslesildi === "eslesildi-modali-ac" && (
                        <div style={eslesilinceAcilanModalDivStyle1}
                             className="flex justify-content-center align-items-center">
                            <div style={eslesilinceAcilanModalDivStyle2}
                                 className="flex flex-column align-items-center justify-content-center">
                                {/*modal kapat tuşu*/}
                                <div style={{width: "100%", height: "10%"}}
                                     className="flex justify-content-end align-items-center pr-5">
                                    <div onClick={() => eslesilinceAcilanModaliKapatmaButonu("henuz-eslesilmedi")}
                                         className="flex box justify-content-center align-items-center">
                                        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                    </div>
                                </div>
                                {/*eslesilen bilgileri buraya gelecek*/}
                                <div style={{width: "100%", height: "90%"}}>
                                    <EslesilinceAcilanModal
                                        eslesilenUye={eslesilenUye}
                                        width={width}
                                        height={height}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default HotOrNot;
