import React, { useEffect, useState } from "react";
import "../../styles/main.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocation,
  faUser,
  faClock,
  faCalendarDays,
  faCalendarDay,
  faVenusMars,
  faArrowsLeftRightToLine,
  faXmark,
  faRotateRight,
  faAngleLeft,
  faComments,
  faInfinity,
  faCheck,
  faUserXmark,
  faUsers,
  faUserCheck,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import UyeProfilDetay from "../../components/UyeProfilDetay";
import { datas } from "../../utils/datas";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Logo } from "../../assets/svg/Logo";
import { useDispatch, useSelector } from "react-redux";
import {
  secilenEtkinlikDetayAction,
  secilenEtkinlikDetayDataClear,
} from "../../store/actions/Etkinlik/secilenEtkinlikDetayAction";
import {
  etkinlikKatilimIstekleriAction,
  etkinlikKatilimIstekleriDataClear,
} from "../../store/actions/Etkinlik/IsteklerUyelerReddedilenlerDataCek/etkinlikKatilimIstekleriAction";
import {
  etkinlikKatilimcilariAction,
  etkinlikKatilimcilariDataClear,
} from "../../store/actions/Etkinlik/IsteklerUyelerReddedilenlerDataCek/etkinlikKatilimcilariAction";
import {
  etkinlikKatilimIstekleriReddedilenlerAction,
  etkinlikKatilimIstekleriReddedilenlerDataClear,
} from "../../store/actions/Etkinlik/IsteklerUyelerReddedilenlerDataCek/etkinlikKatilimIstekleriReddedilenlerAction";
import { etkinlikKatilEylemAction } from "../../store/actions/Etkinlik/EtkinlikKatılımIslemleri/etkinligeKatilEylemAction";
import { etkinlikIstegiGeriCekEylemAction } from "../../store/actions/Etkinlik/EtkinlikKatılımIslemleri/etkinlikIstegiGeriCekEylemAction";
import { etkinligeKabulEtEylemAction } from "../../store/actions/Etkinlik/KatilanUyeyiSilKabulEtRedEtIslem/etkinligeKabulEtEylemAction";
import { etkinligeKatilimIsteginiReddetEylemAction } from "../../store/actions/Etkinlik/KatilanUyeyiSilKabulEtRedEtIslem/etkinligeKatilimIsteginiReddetEylemAction";
import { etkinligeKatilanUyeyiSilEylemAction } from "../../store/actions/Etkinlik/KatilanUyeyiSilKabulEtRedEtIslem/etkinligeKatilanUyeyiSilEylemAction";
import { reddedilenUyeyiSilEylemAction } from "../../store/actions/Etkinlik/KatilanUyeyiSilKabulEtRedEtIslem/reddedilenUyeyiSilEylemAction";
import { etkinlikSilEylemAction } from "../../store/actions/Etkinlik/etkinlikSilEylemAction";
import Harita from "../../components/Harita";
import UyeBittiLoading from "../../components/UyeBittiLoading";
import {animated, useSpring} from "@react-spring/web";
import {useDrag} from "@use-gesture/react";

function EtkinlikDetay({
  secilenEvent,
  user,
  etkinlikOlusturDetayYonlendirIslem,
}) {
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const secilenEventId = secilenEvent?.id;
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [etkinlikDetayGorModal, setEtkinlikDetayGorModal] =
    useState("modalKapali");
  const [profilDetayModal, setProfilDetayModal] = useState("modalKapali");
  const [etkinlikSilModal, setEtkinlikSilModal] = useState("modalKapali");
  const [tiklananUyeId, setTiklananUyeId] = useState();
  const [etkinlikDetayTabBar, setEtkinlikDetayTabBar] = useState("istekler-id");

  const secilenEtkinlikDetayData = useSelector((state) => state.secilenEtkinlikDetay.secilenEtkinlikDetayData);
  const secilenEtkinlikDetayLoading = useSelector((state) => state.secilenEtkinlikDetay.loading);
  const etkinlikKatilButtonDurumu = useSelector((state) => state.secilenEtkinlikDetay.secilenEtkinlikDetayData.participation_status);
  const etkinlikKatilimIstekleriData = useSelector((state) => state.etkinlikKatilimIstekleri.etkinlikKatilimIstekleriData);
  const etkinlikKatilimcilariData = useSelector((state) => state.etkinlikKatilimcilari.etkinlikKatilimcilariData);
  const etkinlikKatilimIstekleriReddedilenlerData = useSelector((state) => state.etkinlikKatilimIstekleriReddedilenler.etkinlikKatilimIstekleriReddedilenlerData);
  const etkinlikSilLoading = useSelector((state) => state.etkinlikListesi.etkinlikSilLoading);
  const etkinlikKatilimIstekVeGeriCekLoadingData = useSelector((state) => state.secilenEtkinlikDetay.etkinlikKatilimIstekVeGeriCekLoading);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    if (token) {
      //verileri temizle
      dispatch(secilenEtkinlikDetayDataClear());
      dispatch(etkinlikKatilimcilariDataClear());
      dispatch(etkinlikKatilimIstekleriDataClear());
      dispatch(etkinlikKatilimIstekleriReddedilenlerDataClear());

      //verileri al
      dispatch(secilenEtkinlikDetayAction(secilenEventId));
      dispatch(etkinlikKatilimIstekleriAction(secilenEventId));
      dispatch(etkinlikKatilimcilariAction(secilenEventId));
      dispatch(etkinlikKatilimIstekleriReddedilenlerAction(secilenEventId));
    }
  }, [dispatch, secilenEventId, token]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const etkinlikKatilimIstekleri = () => {
    if (token) {
      dispatch(etkinlikKatilimIstekleriAction(secilenEventId));
    }
  };
  const etkinlikKatilimcilari = () => {
    if (token) {
      dispatch(etkinlikKatilimcilariAction(secilenEventId));
    }
  };
  const etkinlikKatilimIstekleriReddedilenler = () => {
    if (token) {
      dispatch(etkinlikKatilimIstekleriReddedilenlerAction(secilenEventId));
    }
  };
  const etkinligeKatilIslem = (customerId) => {
    if (token) {
      dispatch(etkinlikKatilEylemAction(secilenEventId, customerId));
    }
  };
  const etkinlikIsteginiGeriCekIslem = () => {
    if (token) {
      dispatch(etkinlikIstegiGeriCekEylemAction(secilenEventId));
    }
  };
  const acceptIslem = (customerId, eventId) => {
    if (token) {
      dispatch(
        etkinligeKabulEtEylemAction(
          customerId,
          eventId,
          etkinlikKatilimIstekleriData
        )
      );
    }
  };
  const rejectIslem = (customerId, eventId) => {
    if (token) {
      dispatch(
        etkinligeKatilimIsteginiReddetEylemAction(
          customerId,
          eventId,
          etkinlikKatilimIstekleriData
        )
      );
    }
  };
  const removeIslemUyeler = (eventId, customerId) => {
    if (token) {
      dispatch(
        etkinligeKatilanUyeyiSilEylemAction(
          customerId,
          eventId,
          etkinlikKatilimcilariData
        )
      );
    }
  };
  const removeIslemReddedilenler = (eventId, customerId) => {
    if (token) {
      dispatch(
        reddedilenUyeyiSilEylemAction(
          customerId,
          eventId,
          etkinlikKatilimIstekleriReddedilenlerData
        )
      );
    }
  };
  // Tarih formatlama işlemi için bir fonksiyon
  function formatDate(date) {
    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  // Saat formatlama işlemi için bir fonksiyon
  function formatTime(date) {
    let hour = String(date.getHours()).padStart(2, "0");
    let minute = String(date.getMinutes()).padStart(2, "0");
    return `${hour}:${minute}`;
  }
  // Etkinlik süresi hesaplama işlemi için bir fonksiyon
  function calculateDuration(start_date, end_date) {
    let seconds = Math.floor((end_date - start_date) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;

    return { days, hours, minutes, seconds };
  }

  // Etkinlik başlangıç ve bitiş tarihleri
  let baslangicTarih = new Date(secilenEtkinlikDetayData?.start_date);
  let bitisTarih = new Date(secilenEtkinlikDetayData?.end_date);

  // Bugünün tarihi
  let today = new Date();

  // Tarih ve saat bilgilerini formatlama
  let baslangicTarihiToplam = formatDate(baslangicTarih);
  let bitisTarihiToplam = formatDate(bitisTarih);
  let bugununTarihiToplam = formatDate(today);

  let baslangicSaatVeDakika = formatTime(baslangicTarih);
  let bitisSaatVeDakika = formatTime(bitisTarih);

  // Etkinlik süresini hesaplama
  let duration = calculateDuration(baslangicTarih, bitisTarih);

  const UyeProfilDetayModalAc = (id, tiklananUyeId) => {
    if (token) {
      setProfilDetayModal(id);
      setTiklananUyeId(tiklananUyeId);
    }
  };
  const UyeProfilDetayModalKapat = (id) => {
    setProfilDetayModal(id);
    setTiklananUyeId();
  };
  const EtkinlikOlusturModalIslem = (id) => {
    setEtkinlikDetayGorModal(id);
  };
  const etkinlikDetayGeriButton = (id) => {
    etkinlikOlusturDetayYonlendirIslem(id);
  };
  const konusmaBaslatButton = (odaId) => {
    navigate(`/AnaSayfa${location.search}`, {
      state: {
        id: "mesajlarGeriId",
        name: "mesajlarGeri",
        buttonId: "baslat",
        odaId: odaId,
      },
    });
  };
  const etkinlikDetaylariTabBar = (id) => {
    setEtkinlikDetayTabBar(id);
  };

  const etkinlikOlusturModalStyle = {
    width: "95%",
    height: "90%",
    backgroundColor: "#fbfafa",
    borderRadius: 8,
  };
  const profilDetayModalStyle = {
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    borderLeftWidth: 1,
    borderRightWidth: 1,
  };

  const etkinlikSilModalIslem = (id) => {
    if (token) {
      setEtkinlikSilModal(id);
    }
  };
  const handleCancelClick = () => {
    if (token) {
      dispatch(etkinlikSilEylemAction(secilenEtkinlikDetayData.id));
      etkinlikDetayGeriButton("etkinlik-detay-sayfasi-git-id-0");
    }
  };

  const tokensizGirisButonu = () => {
    window.location = `/?devicePlatform=${localStorage.getItem(
      "devicePlatform"
    )}`;
  };

  const [showIcon, setShowIcon] = useState(null);

  const [{ x1, scale, opacity }, api1] = useSpring(() => ({
    x1: 0,
    scale: 1,
    opacity: 0,
    config: { tension: 300, friction: 30 },
  }));

  const bind2 = useDrag(({ movement: [mx], velocity, last }) => {
    // Eşik değeri (80 piksel)
    const threshold = 80;

    if (!last) {
      // Yalnızca sağa kaydırma işlemi
      if (mx > 0) {
        setShowIcon(faAngleLeft); // Sağ kaydırma için ikonu göster
        api1.start({
          x1: mx,
          scale: 1 + Math.min(mx / 200, 0.5), // Kaydırılan mesafeye göre büyüme
          opacity: Math.min(mx / 100, 1), // Opaklık arttırılır
        });
      }
    } else {
      // Kaydırma işlemi tamamlandığında
      if (mx > threshold || velocity > 0.5) {
        // Kaydırma belirli bir mesafeyi geçerse (örneğin 100px)
        api1.start({ x1: mx, scale: 1.5, opacity: 1 });
        setTimeout(() => {
          // İkonu kaydırma sonrası tekrar gizlemek için (örneğin bir işlem tamamlandığında)
          api1.start({ x1: 0, scale: 1, opacity: 0 });
          setShowIcon(null);
          etkinlikDetayGeriButton("etkinlik-detay-sayfasi-git-id-0");
        }, 300);
      } else {
        // Kaydırma mesafesi yeterli değilse işlemi iptal et
        api1.start({ x1: 0, scale: 1, opacity: 0 });
        setShowIcon(null);
      }
    }
  }, { axis: 'x' });

  return (
    <div>
      {!token ? (
        <div onClick={() => tokensizGirisButonu()} style={{ width: 180, height: 40 }}
             className="flex justify-center items-center cursorPointer etkinlikOlusturButtonStyle">
          <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
            {t("oturum_ac_button_text")}
          </span>
        </div>
      ) : (
        <>
          <div  style={{width: "100%", height: "100%", position: "absolute", zIndex: 555}}>
            <div className="flex profilDetayShadow" style={{width: "100%", height: 51, backgroundColor: "#fff", borderLeftWidth: 1, borderRightWidth: 1}}>
              {/*geri butonu*/}
              <div className="closebtn flex justify-start items-center" style={{ width: "20%" }}>
                <button onClick={() => etkinlikDetayGeriButton("etkinlik-detay-sayfasi-git-id-0")}
                        className="arrow flex justify-center items-center px-3 py-3">
                  <FontAwesomeIcon icon={faAngleLeft} className="sliderButton fa-xl"/>
                </button>
              </div>

              {/*sohbete katıl butonu*/}
              <div className="flex justify-center items-center ayarlarTextStyle01 tiklanmaEngelle" style={{ height: "100%", width: "80%" }}>
                {etkinlikKatilButtonDurumu === "accepted" && (
                  <div onClick={() => konusmaBaslatButton("")}
                       className="flex justify-end items-center  buttonTextStyle1111 cursorPointer profileHeaderSolOkStyle pr-3"
                       style={{ height: "100%", width: "100%" }}>
                    <FontAwesomeIcon icon={faComments} className="mr-3" />
                    <span className="etkinlikOlusturDetaySohbeteKatilTextStyle tiklanmaEngelle">
                      {t("sohbete_katil")}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <animated.div {...bind2()} style={{ width: "100%", height: "calc(100% - 51px)", x1, touchAction: 'none' }} className="flex-column active-scroll">
              {secilenEtkinlikDetayLoading === true ? (
                <div className="flex flex-column justify-center items-center" style={{ width: "100%", height: "100%" }}>
                  <div style={{ width: "100%", height: "50%" }}>
                    <UyeBittiLoading width={width} height={height} />
                  </div>
                  <div style={{ width: "100%", height: "20%" }} className="flex flex-column justify-start items-center">
                    <div className="flex flex-column items-center">
                      <span className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${
                        width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" 
                            : "uyeBittiLoadingUyariTextFontDesktopStyle"}`}>
                        {t("yukleniyor")}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {/*etkinlik adı*/}
                  <div className="flex justify-center items-center mt-4" style={{ width: "100%", height: 80 }}>
                    <div className="flex justify-center items-center" style={{ width: "80%", height: "100%" }}>
                      <span style={{ width: "100%", height: "100%" }}
                            className="flex justify-center items-center tiklanmaEngelle etkinlikOlusturDetayNameTextStyle">
                        {secilenEtkinlikDetayData?.name}
                      </span>
                    </div>
                  </div>
                  {/*kullanıcı profil resmi, adı, soyadı, kişi sayısının olduğu bölüm*/}
                  <div className="flex" style={{ width: "100%", height: 120 }}>
                    <div className="flex justify-center items-center" style={{ width: "30%", height: "100%" }}>
                      {secilenEtkinlikDetayData?.customer?.media ? (
                        <img className={`etkinlikImageStyle tiklanmaEngelle`} src={
                          secilenEtkinlikDetayData?.customer?.media?.url + secilenEtkinlikDetayData?.customer?.media?.file_name}
                             alt=""
                        />
                      ) : (
                        <Logo className={`tiklanmaEngelle etkinlikImageStyle`}/>
                      )}
                    </div>
                    <div className="flex" style={{ width: "70%", height: "100%" }}>
                      <div className="flex justify-start items-center" style={{ width: "60%", height: "100%" }}>
                        <span className="tiklanmaEngelle etkinlikOlusturDetayFirstAndLastNameTextStyle1">
                          {secilenEtkinlikDetayData?.customer?.first_name}{" "}
                          {secilenEtkinlikDetayData?.customer?.last_name}
                        </span>
                      </div>
                      <div className="flex justify-center items-center" style={{ width: "40%", height: "100%" }}>
                        <div className="flex justify-center items-center"
                             style={{width: 45, height: 45, borderWidth: 1, borderRadius: 100}}>
                          {secilenEtkinlikDetayData?.number_of_participants ===
                          101 ? (
                            <FontAwesomeIcon icon={faInfinity} className="etkinlikUserIconStyle"/>
                          ) : (
                            <span className="tiklanmaEngelle etkinlikOlusturKonuTextStyle2">
                              {secilenEtkinlikDetayData?.number_of_participants}
                            </span>
                          )}
                          <FontAwesomeIcon icon={faUser} className="etkinlikUserIconStyle"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*açıklama kısmı*/}
                  <div className="flex" style={{ width: "100%", height: 140 }}>
                    <div style={{ height: "100%", width: "10%" }}></div>
                    <div className="flex justify-center items-center pt-3" style={{ height: "100%", width: "80%" }}>
                      <span style={{ width: "100%", height: "100%" }}
                            className="tiklanmaEngelle etkinlikOlusturDetayKonuTextStyle1">
                        {secilenEtkinlikDetayData?.description}
                      </span>
                    </div>
                    <div style={{ height: "100%", width: "5%" }}></div>
                  </div>

                  {secilenEtkinlikDetayData?.participation_status ===
                    "accepted" && (
                    <>
                      {secilenEtkinlikDetayData?.latitude &&
                        secilenEtkinlikDetayData?.longitude && (
                          <Harita
                            latitude={secilenEtkinlikDetayData.latitude}
                            longitude={secilenEtkinlikDetayData.longitude}
                            secilenEtkinlikDetay={secilenEtkinlikDetayData}
                            bugununTarihiToplam={bugununTarihiToplam}
                            baslangicTarihiToplam={baslangicTarihiToplam}
                            bitisTarihiToplam={bitisTarihiToplam}
                            baslangicSaatVeDakika={baslangicSaatVeDakika}
                            bitisSaatVeDakika={bitisSaatVeDakika}
                          />
                        )}
                    </>
                  )}

                  {/*yaş aralığı, cinsiyet, uzaklık, etkinlik süresi, başlangıç süresi, bitiş süresi bölümü*/}
                  <div className="flex flex-column items-center justify-center" style={{ width: "100%", height: 350 }}>
                    {/*yaş aralığı, cinsiyet*/}
                    <div className="flex" style={{ height: "20%", width: "80%" }}>
                      <div className="flex" style={{ width: "100%", height: "100%" }}>
                        <div className="flex justify-center items-center" style={{ width: "50%", height: "100%" }}>
                          <div className="flex items-center" style={{ width: "17%", height: "100%" }}>
                            <FontAwesomeIcon icon={faArrowsLeftRightToLine} className="etkinlikMapLocationIconStyle fa-2x"/>
                          </div>
                          <div className="flex flex-column" style={{ width: "83%", height: "100%" }}>
                            <div className="flex items-center" style={{ width: "100%", height: "35%" }}>
                              <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmBaslikTextStyle1 ml-2">
                                {t("yas_araligi")}
                              </span>
                            </div>
                            <div className="flex" style={{ width: "100%", height: "65%" }}>
                              <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmTextStyle1 ml-2">
                                {secilenEtkinlikDetayData?.minimum_age} -{" "}
                                {secilenEtkinlikDetayData?.maximum_age}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex" style={{ width: "50%", height: "100%" }}>
                          <div className="flex items-center" style={{ width: "25%", height: "100%" }}>
                            <FontAwesomeIcon icon={faVenusMars} className="etkinlikMapLocationIconStyle"/>
                          </div>
                          <div style={{ width: "75%", height: "100%" }}>
                            <div className="flex items-center" style={{ width: "100%", height: "35%" }}>
                              <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmBaslikTextStyle1 ml-2">
                                {t("cinsiyet")}
                              </span>
                            </div>
                            <div className="flex" style={{ width: "100%", height: "65%" }}>
                              <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmTextStyle1 ml-2">
                                {secilenEtkinlikDetayData?.gender
                                  ? datas().cinsiyetData[
                                      secilenEtkinlikDetayData?.gender
                                    ]?.label
                                  : `${t("bos")}`}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*uzaklık mesafesi*/}
                    <div className="flex" style={{ height: "20%", width: "80%" }}>
                      <div className="flex items-center" style={{ width: "10%", height: "100%" }}>
                        <FontAwesomeIcon icon={faMapLocation} className="etkinlikMapLocationIconStyle"/>
                      </div>
                      <div className="flex flex-column" style={{ width: "90%", height: "100%" }}>
                        <div className="flex items-center" style={{ width: "100%", height: "35%" }}>
                          <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmBaslikTextStyle1 ml-2">
                            {t("uzaklik")}
                          </span>
                        </div>
                        <div style={{ width: "100%", height: "65%" }}>
                          <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmTextStyle1 ml-2">
                            {secilenEtkinlikDetayData?.distance} km
                          </span>
                        </div>
                      </div>
                    </div>
                    {/*etkinlik süresi*/}
                    <div className="flex" style={{ height: "20%", width: "80%" }}>
                      <div className="flex items-center" style={{ width: "10%", height: "100%" }}>
                        <FontAwesomeIcon icon={faClock} className="etkinlikMapLocationIconStyle"/>
                      </div>
                      <div className="flex flex-column" style={{ width: "90%", height: "100%" }}>
                        <div className="flex items-center" style={{ width: "100%", height: "35%" }}>
                          <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmBaslikTextStyle1 ml-2">
                            {t("etkinlik_suresi")}
                          </span>
                        </div>
                        <div style={{ width: "100%", height: "65%" }}>
                          <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmTextStyle1 ml-2">
                            {duration.days} {t("gunYazi")} {duration.hours}{" "}
                            {t("saat")} {duration.minutes} {t("dakika")}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/*etkinlik başlangıç süresi*/}
                    <div className="flex" style={{ height: "20%", width: "80%" }}>
                      <div className="flex items-center" style={{ width: "10%", height: "100%" }}>
                        <FontAwesomeIcon icon={faCalendarDays} className="etkinlikMapLocationIconStyle"/>
                      </div>
                      <div className="flex flex-column" style={{ width: "90%", height: "100%" }}>
                        <div className="flex items-center" style={{ width: "100%", height: "35%" }}>
                          <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmBaslikTextStyle1 ml-2">
                            {t("etkinlik_baslangic_tarihi")}
                          </span>
                        </div>
                        <div style={{ width: "100%", height: "65%" }}>
                          <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmTextStyle1 ml-2">
                            {bugununTarihiToplam === baslangicTarihiToplam
                              ? `${t("bugun")}`
                              : baslangicTarihiToplam}{" "}
                            | {baslangicSaatVeDakika}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/*etkinlik bitiş süresi*/}
                    <div className="flex" style={{ height: "20%", width: "80%" }}>
                      <div className="flex items-center" style={{ width: "10%", height: "100%" }}>
                        <FontAwesomeIcon
                          icon={faCalendarDay}
                          className="etkinlikMapLocationIconStyle"
                        />
                      </div>
                      <div className="flex flex-column" style={{ width: "90%", height: "100%" }}>
                        <div className="flex items-center" style={{ width: "100%", height: "35%" }}>
                          <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmBaslikTextStyle1 ml-2">
                            {t("etkinlik_bitis_tarihi")}
                          </span>
                        </div>
                        <div style={{ width: "100%", height: "65%" }}>
                          <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmTextStyle1 ml-2">
                            {bugununTarihiToplam === bitisTarihiToplam
                              ? `${t("bugun")}`
                              : bitisTarihiToplam}{" "}
                            | {bitisSaatVeDakika}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*etkinlik detay, beklemede, iptal, etkinliğe katıl, butonları */}
                  <div style={{ width: "100%", height: 120 }}>
                    {secilenEvent?.customer?.id === parseInt(user?.id) ? (
                      <div className="flex flex-column justify-center items-center">
                        {etkinlikSilLoading === true ? (
                          <div style={{ width: 142, height: 120 }} className="flex justify-center items-center">
                            <div className="flex justify-center items-center" style={{width: "90%", height: 5, backgroundColor: "#ddd", borderRadius: 100}}>
                              <div id="animatedDiv" />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div style={{ width: "100%", height: 70 }} className="flex flex-column justify-center items-center">
                              <div onClick={() => EtkinlikOlusturModalIslem("etkinlikDetayiniGorModalId")}
                                   style={{ width: "60%", height: 50 }}
                                   className="flex cursorPointer justify-center items-center shadow etkinlikOlusturButtonStyle">
                                <span className="etkinlikOlusturDetayKatilimIstekleriniGorButtonTextStyle tiklanmaEngelle">
                                  {t("etkinlik_detayini_gör")}
                                </span>
                              </div>
                            </div>

                            <div style={{ width: "100%", height: 50 }}
                                 onClick={() => etkinlikSilModalIslem("etkinlikSilModalId")}
                                 className="flex justify-center items-center">
                              <span className="etkinlikDetayBuEtkinligeArtikKatilamazsinizTextStyle tiklanmaEngelle">
                                <FontAwesomeIcon icon={faTrash} className="fa-md" style={{ color: "#EB3428" }}/>
                                {" "}{t("etkinligi_iptal_et")}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="flex justify-center items-center" style={{ width: "100%", height: "100%" }}>
                        <>
                          {etkinlikKatilimIstekVeGeriCekLoadingData === true ? (
                            <div style={{ width: 142, height: 120 }} className="flex justify-center items-center">
                              <div className="flex justify-center items-center"
                                   style={{width: "90%", height: 5, backgroundColor: "#ddd", borderRadius: 100,}}>
                                <div id="animatedDiv" />
                              </div>
                            </div>
                          ) : (
                            <>
                              {etkinlikKatilButtonDurumu === "pending" ? (
                                <div style={{ width: "100%", height: "100%" }}
                                     className="flex flex-column justify-center items-center">
                                  <div>
                                    <span className="etkinlikDetayBeklemedeTextStyle">
                                      {t("beklemede")}
                                    </span>
                                  </div>

                                  <div onClick={() => etkinlikIsteginiGeriCekIslem("katil")}
                                       style={{ width: "60%", height: 50 }}
                                       className="flex cursorPointer justify-center items-center shadow etkinlikOlusturDetayBeklemedeButtonStyle">
                                    <span className="etkinlikOlusturDetayKatilButtonTextStyle tiklanmaEngelle">
                                      {t("iptal_et")}
                                    </span>
                                  </div>
                                </div>
                              ) : etkinlikKatilButtonDurumu === "canceled" ? (
                                <span className="etkinlikDetayBuEtkinligeArtikKatilamazsinizTextStyle tiklanmaEngelle">
                                  {t("artik_bu_etkinlige_katilamazsiniz")}
                                </span>
                              ) : etkinlikKatilButtonDurumu === "rejected" ? (
                                <span className="etkinlikDetayBuEtkinligeArtikKatilamazsinizTextStyle tiklanmaEngelle">
                                  {t("isteginiz_reddedildi")}
                                </span>
                              ) : etkinlikKatilButtonDurumu === "accepted" ? (
                                <span className="etkinlikDetayIstekOnaylandiTextStyle tiklanmaEngelle">
                                  {t("isteginiz_onaylandi")}
                                </span>
                              ) : etkinlikKatilButtonDurumu === "removed" ? (
                                <span className="etkinlikDetayBuEtkinligeArtikKatilamazsinizTextStyle tiklanmaEngelle">
                                  {t("etkinlikten_cikarildin")}
                                </span>
                              ) : (
                                <div onClick={() => etkinligeKatilIslem(secilenEvent?.customer?.id)}
                                     style={{ width: "60%", height: 50 }}
                                     className="flex cursorPointer justify-center items-center shadow etkinlikOlusturDetayKatilButtonStyle">
                                  <span className="etkinlikOlusturDetayKatilButtonTextStyle tiklanmaEngelle">
                                    {t("etkinlige_katil")}
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      </div>
                    )}
                  </div>
                </>
              )}
            </animated.div>

            {showIcon && (
                <animated.div style={{
                  position: 'absolute',
                  top: '50%',
                  left: x1.to(x => `${x}px`),
                  transform: 'translate(-50%, -50%)',
                  scale,
                  opacity,
                }}>
                  <FontAwesomeIcon icon={showIcon} style={{ fontSize: '1.4rem', color: "#EB3428" }} />
                </animated.div>
            )}
          </div>

          {etkinlikDetayGorModal === "etkinlikDetayiniGorModalId" && (
            <div className="flex justify-center items-center" style={{width: "100%", height: "100%", position: "absolute", zIndex: 55555, backgroundColor: "rgba(0,0,0,0.63)",}}>
              <div className="flex flex-column items-center justify-center" style={etkinlikOlusturModalStyle}>
                {/*yenile butonu, modalın başlığı, kapat tuşu kısmı*/}
                <div style={{ width: "100%", height: "20%" }} className="flex justify-center items-center">
                  <div className="flex justify-content-center align-items-center" style={{ width: "15%", height: "100%" }}>
                    <div onClick={() => etkinlikDetayTabBar === "uyeler-id" ? etkinlikKatilimcilari()
                        : etkinlikDetayTabBar === "reddedilenler-id" ? etkinlikKatilimIstekleriReddedilenler()
                            : etkinlikKatilimIstekleri()}
                         className="flex yenileButtonuStyle justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faRotateRight} className="fa-xl" />
                    </div>
                  </div>

                  <div className="flex justify-content-center align-items-center" style={{ width: "70%", height: "100%" }}>
                    {etkinlikDetayTabBar === "istekler-id" && (
                      <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                        {t("katilim_istekleri")}
                      </span>
                    )}
                    {etkinlikDetayTabBar === "uyeler-id" && (
                      <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                        {t("uyeler")}
                      </span>
                    )}
                    {etkinlikDetayTabBar === "reddedilenler-id" && (
                      <span className="profilDuzenleModalBaslikTextStyle1 tiklanmaEngelle">
                        {t("reddedilenler")}
                      </span>
                    )}
                  </div>
                  {/*modal kapat tuşu*/}
                  <div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">
                    <div onClick={() => EtkinlikOlusturModalIslem("modalKapat")} className="flex box justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                    </div>
                  </div>
                </div>
                {/*katılım istekleri, katılan üyeler ve reddedilenler tabbar butonları*/}
                <div style={{ width: "100%", height: "9.3%" }} className="flex active-scroll">
                  <div style={{width: "33.33%", height: "100%", backgroundColor: "#fff"}}
                       onClick={() => etkinlikDetaylariTabBar("istekler-id")}>
                    <div style={{ width: "100%", height: "93%" }} className="flex justify-center items-center">
                      <span style={{fontSize: isMobile ? 12 : width <= 400 ? 12 : 13}}
                            className={`tiklanmaEngelle ${
                              etkinlikDetayTabBar === "istekler-id" 
                                  ? "begenenlerVeBegendiklerimTabBarTextStyle" 
                                  : "begenenlerVeBegendiklerimTabBarTextStyle1"}`}>
                        {t("istekler")}
                      </span>
                    </div>
                    <div style={{ width: "100%", height: "7%" }} className={`${etkinlikDetayTabBar === "istekler-id" ? "begenenlerTabBarStyle" : ""}`}></div>
                  </div>
                  <div style={{width: "33.33%", height: "100%", backgroundColor: "#fff"}}
                       onClick={() => etkinlikDetaylariTabBar("uyeler-id")}>
                    <div style={{ width: "100%", height: "93%" }} className="flex justify-center items-center">
                      <span style={{fontSize: isMobile ? 12 : width <= 400 ? 12 : 13,}}
                            className={`tiklanmaEngelle ${etkinlikDetayTabBar === "uyeler-id" 
                                ? "begenenlerVeBegendiklerimTabBarTextStyle" 
                                : "begenenlerVeBegendiklerimTabBarTextStyle1"
                            }`}>
                        {t("uyeler")}
                      </span>
                    </div>
                    <div style={{ width: "100%", height: "7%" }} className={`${etkinlikDetayTabBar === "uyeler-id" ? "begenenlerTabBarStyle" : ""}`}></div>
                  </div>
                  <div style={{width: "33.33%", height: "100%", backgroundColor: "#fff"}}
                       onClick={() => etkinlikDetaylariTabBar("reddedilenler-id")}>
                    <div style={{ width: "100%", height: "93%" }} className="flex justify-center items-center">
                      <span style={{fontSize: isMobile ? 12 : width <= 400 ? 12 : 13,}}
                            className={`tiklanmaEngelle ${
                              etkinlikDetayTabBar === "reddedilenler-id" 
                                  ? "begenenlerVeBegendiklerimTabBarTextStyle" 
                                  : "begenenlerVeBegendiklerimTabBarTextStyle1"}`}>
                        {t("reddedilenler")}
                      </span>
                    </div>
                    <div style={{ width: "100%", height: "7%" }}
                         className={`${etkinlikDetayTabBar === "reddedilenler-id" ? "begenenlerTabBarStyle" : ""}`}></div>
                  </div>
                </div>
                <div style={{ width: "100%", height: "70.7%" }} className="flex">
                  {etkinlikDetayTabBar === "istekler-id" && (
                    <>
                      {etkinlikKatilimIstekleriData?.event_participants?.length >= 1 ? (
                        <div style={{ width: "100%", height: "100%" }} className="active-scroll">
                          {etkinlikKatilimIstekleriData?.event_participants?.map(
                            (item) => {
                              return (
                                <div key={item?.customer?.id} style={{backgroundColor: "#fff", width: "100%", height: 65}}
                                     className="flex mt-1 mb-1">
                                  <div onClick={() => UyeProfilDetayModalAc("profilDetayModalId", item?.customer?.id)}
                                       style={{ width: "20%", height: "100%" }}
                                       className="flex justify-center items-center">
                                    {item?.customer?.media ? (
                                      <img className={`etkinlikImageStyle tiklanmaEngelle`}
                                           src={item?.customer?.media?.url + item?.customer?.media?.file_name}
                                           alt=""/>
                                    ) : (
                                      <Logo className={`etkinlikImageStyle tiklanmaEngelle`}/>
                                    )}
                                  </div>
                                  <div style={{ width: "30%", height: "100%" }} className="flex justify-start items-center tiklanmaEngelle">
                                    <span className="profilDetayNameTextStyle tiklanmaEngelle">
                                      {item?.customer?.first_name}{" "}{item?.customer?.last_name}
                                    </span>
                                  </div>
                                  <div style={{ width: "50%", height: "100%" }} className="flex justify-start items-center">
                                    <div style={{ width: "50%", height: "100%" }} className="flex justify-center items-center">
                                      <div onClick={() => acceptIslem(item?.customer?.id, item?.event_id)}
                                           style={{ width: "60%", height: 30 }}
                                           className="flex cursorPointer justify-center items-center etkinlikOlusturDetayKatilButtonStyle">
                                        <FontAwesomeIcon icon={faCheck} inverse/>
                                      </div>
                                    </div>
                                    <div style={{ width: "50%", height: "100%" }} className="flex justify-center items-center">
                                      <div onClick={() => rejectIslem(item?.customer?.id, item?.event_id)}
                                           style={{ width: "60%", height: 30 }}
                                           className="flex cursorPointer justify-center items-center etkinlikOlusturButtonStyle">
                                        <FontAwesomeIcon icon={faXmark} inverse/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <div style={{ width: "100%", height: "100%" }} className="flex flex-column justify-center items-center">
                          <FontAwesomeIcon icon={faUserCheck} className="fa-xl" style={{ color: "#d4d8de" }}/>
                          <span className="begenenlerTextStyle1 tiklanmaEngelle">
                            {t("etkinlige_katilma_istekleri_burada_listelenir")}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {etkinlikDetayTabBar === "uyeler-id" && (
                    <>
                      {etkinlikKatilimcilariData?.event_participants?.length >=
                      1 ? (
                        <div style={{ width: "100%", height: "100%" }} className="active-scroll">
                          {etkinlikKatilimcilariData?.event_participants?.map(
                            (item) => {
                              return (
                                <div key={item?.customer?.id} style={{backgroundColor: "#fff", width: "100%", height: 65}}
                                     className="flex mt-1 mb-1">
                                  <div onClick={() => UyeProfilDetayModalAc("profilDetayModalId", item?.customer?.id)}
                                       style={{ width: "20%", height: "100%" }}
                                       className="flex justify-center items-center">
                                    {item?.customer?.media ? (
                                      <img className={`etkinlikImageStyle tiklanmaEngelle`}
                                           src={item?.customer?.media?.url + item?.customer?.media?.file_name} alt=""/>
                                    ) : (
                                      <Logo className={`etkinlikImageStyle tiklanmaEngelle`}/>
                                    )}
                                  </div>
                                  <div style={{ width: "30%", height: "100%" }}
                                       className="flex justify-start items-center tiklanmaEngelle">
                                    {parseInt(item?.customer?.id) === parseInt(user.id) ? (
                                        <span className="profilDetayNameTextStyle tiklanmaEngelle">
                                          ({t("sen")})
                                        </span>
                                    ) : (
                                        <span className="profilDetayNameTextStyle tiklanmaEngelle">
                                          {item?.customer?.first_name}{" "}{item?.customer?.last_name}
                                        </span>
                                    )}
                                  </div>
                                  <div style={{ width: "50%", height: "100%" }} className="flex justify-start items-center">
                                    <div style={{ width: "50%", height: "100%" }} className="flex justify-center items-center"></div>
                                    <div style={{ width: "50%", height: "100%" }} className="flex justify-center items-center">
                                      {parseInt(item?.customer?.id) === parseInt(user.id) ? null : (
                                        <div onClick={() => removeIslemUyeler(item?.event_id, item?.customer?.id)}
                                             style={{ width: "60%", height: 30 }}
                                             className="flex cursorPointer justify-center items-center etkinlikOlusturButtonStyle">
                                          <FontAwesomeIcon icon={faXmark} inverse/>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <div style={{ width: "100%", height: "100%" }} className="flex flex-column justify-center items-center">
                          <FontAwesomeIcon icon={faUsers} className="fa-xl" style={{ color: "#d4d8de" }}/>
                          <span className="begenenlerTextStyle1 tiklanmaEngelle">
                            {t("etkinlige_katilan_uyeler_burada_listelenir")}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {etkinlikDetayTabBar === "reddedilenler-id" && (
                    <>
                      {etkinlikKatilimIstekleriReddedilenlerData?.event_participants?.length >= 1 ? (
                        <div style={{ width: "100%", height: "100%" }} className="active-scroll">
                          {etkinlikKatilimIstekleriReddedilenlerData?.event_participants?.map(
                            (item) => {
                              return (
                                <div key={item?.customer?.id} style={{backgroundColor: "#fff", width: "100%", height: 65}} className="flex mt-1 mb-1">
                                  <div onClick={() => UyeProfilDetayModalAc("profilDetayModalId", item?.customer?.id)}
                                       style={{ width: "20%", height: "100%" }}
                                       className="flex justify-center items-center">
                                    {item?.customer?.media ? (
                                      <img className={`etkinlikImageStyle tiklanmaEngelle`}
                                           src={item?.customer?.media?.url + item?.customer?.media?.file_name}
                                           alt=""/>
                                    ) : (
                                      <Logo className={`etkinlikImageStyle tiklanmaEngelle`}/>
                                    )}
                                  </div>
                                  <div style={{ width: "30%", height: "100%" }} className="flex justify-start items-center tiklanmaEngelle">
                                    <span className="profilDetayNameTextStyle tiklanmaEngelle">
                                      {item?.customer?.first_name}{" "}{item?.customer?.last_name}
                                    </span>
                                  </div>
                                  <div style={{ width: "50%", height: "100%" }} className="flex justify-start items-center">
                                    <div style={{ width: "50%", height: "100%" }} className="flex justify-center items-center"></div>
                                    <div style={{ width: "50%", height: "100%" }} className="flex justify-center items-center">
                                      <div onClick={() => removeIslemReddedilenler(item?.event_id, item?.customer?.id)}
                                           style={{ width: "60%", height: 30 }}
                                           className="flex cursorPointer justify-center items-center etkinlikOlusturButtonStyle">
                                        <FontAwesomeIcon icon={faXmark} inverse/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <div style={{ width: "100%", height: "100%" }} className="flex flex-column justify-center items-center">
                          <FontAwesomeIcon icon={faUserXmark} className="fa-xl" style={{ color: "#d4d8de" }}/>
                          <span className="begenenlerTextStyle1 tiklanmaEngelle">
                            {t("etkinlige_katilma_istekleri_reddedilen_uyeler_burada_listelenir")}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {etkinlikSilModal === "etkinlikSilModalId" && (
            <div className="flex flex-column justify-center items-center" style={{width: "100%", height: "100%", position: "absolute", zIndex: 55555, backgroundColor: "rgba(0,0,0,0.63)"}}>
              <div className="flex flex-column active-scroll" style={{width: "90%", height: "70%", backgroundColor: "#fff", borderRadius: 8}}>
                <div className="flex justify-end items-end px-3 py-2" style={{ width: "100%", height: 51 }}>
                  <div onClick={() => etkinlikSilModalIslem("engelle-modal-kapali")}
                       className="flex box justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                  </div>
                </div>
                <div style={{ width: "100%", height: "calc(100% - 51px)" }}
                     className="flex flex-column justify-center items-center px-3">
                  <div className="flex flex-column justify-center items-center my-3">
                    <span className="engelleBaslikTextStyle tiklanmaEngelle">
                      {t("uyari")}
                    </span>
                  </div>

                  <div className="my-3 text-center">
                    <span className="profilDetayNameTextStyle mx-2">
                      {t("bu_etkinlik_ve_etkinlik_mesajlasma_grubu_silinecek")}
                    </span>
                  </div>

                  <div onClick={() => handleCancelClick()} className="flex justify-center items-center py-4">
                    <div className="engelleBaslikTextStyle3">
                      {t("etkinligi_sil")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {profilDetayModal === "profilDetayModalId" && (
            <div className="flex justify-center items-center" style={{width: "100%", height: "100%", position: "absolute", zIndex: 55555}}>
              <div className="flex flex-column items-center justify-center" style={profilDetayModalStyle}>
                <div style={{width: "100%", height: 51, borderLeftWidth: 1, borderRightWidth: 1}} className="flex justify-center items-center">
                  <div className="flex justify-content-center align-items-center" style={{ width: "15%", height: "100%" }}></div>
                  <div className="flex justify-content-center align-items-center" style={{ width: "70%", height: "100%" }}></div>
                  {/* modal kapat tuşu*/}
                  <div style={{ width: "15%", height: "100%" }} className="flex justify-content-center align-items-center">
                    <div onClick={() => UyeProfilDetayModalKapat("modalKapat")} className="flex box justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faXmark} className="fa-lg" />
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%", height: "100%" }} className="flex flex-column active-scroll">
                  <UyeProfilDetay tiklananUyeId={tiklananUyeId} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default EtkinlikDetay;
