import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../styles/main.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { isMobile } from "react-device-detect";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  faAngleLeft,
  faGear,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GradientSvg from "../../assets/svg/GradientSvg";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import zodiac from "../../utils/zodiac";
import { Logo } from "../../assets/svg/Logo";

function Profil({ closeNav, profileStatus, user }) {
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  const idCSS = "benBirIdyim";
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [burc, setBurc] = useState("");
  const [puan, setPuan] = useState(0);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (token) {
      if (user) {
        let temp_puan = 0;
        temp_puan += user.date_of_birth ? 10 : 0;
        temp_puan += user.school ? 10 : 0;
        temp_puan += user.company ? 10 : 0;
        temp_puan += user.job_title ? 10 : 0;
        temp_puan += user.description ? 10 : 0;
        temp_puan += user.smoking ? 10 : 0;
        temp_puan += user.sexual_orientation ? 10 : 0;
        temp_puan +=
          user.media && user.media.length ? user.media.length * 10 : 0;

        setPuan(temp_puan);

        let dogum_gunu = new Date(user.date_of_birth);
        let burc = zodiac().getSignByDate(
          {
            day: dogum_gunu.getDate(),
            month: dogum_gunu.getMonth() + 1,
          },
          localStorage.getItem("i18nextLng") || "en"
        );
        setBurc(`${burc.symbol} ${burc.name}` || "");
      }
    }
  }, [user, token]);

  const AyarlarButton = () => {
    let path = `/Ayarlar${location.search}`;
    navigate(path);
  };
  const ProfilDuzenleButton = () => {
    let path = `/ProfilDuzenle${location.search}`;
    navigate(path);
  };
  /* const MedyaEkleButton = () => {
        let path = "/MedyaEkle"
        navigate(path)
    }*/

  const profileDivStyle = {
    width:
      profileStatus === "kapali"
        ? "0%"
        : isMobile
        ? width
        : width <= 475
        ? width
        : 425,
    height: isMobile ? height : height <= 795 ? height : 788,
    borderWidth: profileStatus === "kapali" || isMobile ? 0 : 0,
    borderRadius: isMobile ? 0 : 8,
  };
  const profileDivStyle1 = {
    width: isMobile ? width : width <= 475 ? width : 425,
    height: 425,
  };

  return (
    <div id="myNav" className="overlay profileDivStyle1" style={profileDivStyle}>
      {/*profil kapat sol ok buton*/}
      <div className="closebtn flex justify-start items-center" style={{ width: "100%", height: 51 }}>
        <button onClick={closeNav} className="arrow flex justify-center items-center px-3 py-3">
          <FontAwesomeIcon icon={faAngleLeft} className="sliderButton fa-xl"/>
        </button>
      </div>
      <div style={{ width: "100%", height: "calc(100% - 51px)" }}>
        {/*profil resmi,kullanıcı adı, ayarlar,profil düzenle,fotograf ekle bölümü*/}
        <div style={profileDivStyle1} className="profileDivStyle">
          <div className="flex justify-content-center align-items-center" style={{ height: "50%", width: "100%" }}>
            <div className={`profileDivStyle3`}>
              <GradientSvg idCSS={idCSS} />
              <CircularProgressbar
                circleRatio={0.75}
                strokeWidth={3.5}
                value={puan}
                styles={{
                  trail: {
                    strokeLinecap: "butt",
                    transform: "rotate(-135deg)",
                    transformOrigin: "center center",
                  },
                  path: {
                    stroke: `url(#${idCSS})`,
                    height: "100%",
                    strokeLinecap: "butt",
                    transform: "rotate(-135deg)",
                    transformOrigin: "center center",
                  },
                }}
              />
            </div>

            <div className="flex flex-column justify-content-center align-items-center profileFotoDivStyle2">
              {user?.profilePhoto && token ? (
                <img
                  className={`profileFotoDivStyle1`}
                  src={user?.profilePhoto || ""}
                  alt=""
                />
              ) : (
                <Logo className={`profileFotoDivStyle1`} />
              )}
              <div
                style={{ position: "absolute", top: "85%" }}
                className={`flex justify-content-center align-items-center profileYuzdeDivStyle1`}
              >
                <span className={`tiklanmaEngelle profileYuzdeTextStyle1`}>
                  {`${puan}%`} {t("tamamlandi")}
                </span>
              </div>
            </div>
          </div>
          <div
            className="flex flex-column justify-center items-center"
            style={{ height: "20%", width: "100%" }}
          >
            <div className={`profileTextStyle1 tiklanmaEngelle`}>
              {user && `${user.first_name} ${user.last_name} (${user.age})`}
            </div>
            <small className="tiklanmaEngelle profileTextStyle2">
              {user && burc}
            </small>
          </div>
          <div className="flex" style={{ height: "30%", width: "100%" }}>
            <div
              style={{ width: "50%", height: "100%" }}
              className="flex flex-column justify-start items-center"
            >
              <div
                onClick={AyarlarButton}
                className={`flex justify-content-center align-items-center cursorPointer profileButtonStyle2`}
              >
                <FontAwesomeIcon
                  icon={faGear}
                  className={`profileButtonsIconsStyle1`}
                />
              </div>
              <span className={`tiklanmaEngelle profileTextStyle2`}>
                {t("ayarlar")}
              </span>
            </div>

            <div
              style={{ width: "50%", height: "100%" }}
              className="flex flex-column justify-start items-center"
            >
              <div
                onClick={ProfilDuzenleButton}
                className={`flex justify-content-center align-items-center cursorPointer profileButtonStyle2`}
              >
                <FontAwesomeIcon
                  icon={faPencil}
                  className={`profileButtonsIconsStyle1`}
                />
              </div>
              <span className={`tiklanmaEngelle profileTextStyle2`}>
                {t("profil_duzenle")}
              </span>
            </div>
            {/*    <div
                                className="flex flex-column justify-content-center align-items-center mt-2.5 mb-12 mb-sm-5">
                                <div onClick={MedyaEkleButton}
                                     className={`flex justify-content-center align-items-center cursorPointer ${isMobile ? "profileButtonStyle1" : width <= 500 || height <= 550 ? "profileButtonStyle122" : "profileButtonStyle1"}`}
                                     style={{backgroundColor: "#ff4458"}}>
                                    <div className="flex justify-center items-center" style={{position: "absolute"}}>
                                        <FontAwesomeIcon icon={faCamera}
                                                         className={`${isMobile ? "profileButtonsIconsStyle2" : width <= 500 || height <= 550 ? "profileButtonsIconsStyle22" : "profileButtonsIconsStyle2"}`}/>
                                    </div>
                                    <div className="profileMedyaEkleDiv1 flex justify-content-center align-items-center"
                                         style={profileDivStyle2}>
                                        <span className="profileMedyaEkleTextStlye1 tiklanmaEngelle">
                                            +
                                        </span>
                                    </div>
                                </div>
                                <span
                                    className={`${isMobile ? "profileTextStyle21" : width <= 500 || height <= 550 ? "profileTextStyle21" : "profileTextStyle2"}`}>{t("fotograf_ekle")}</span>
                            </div>*/}
          </div>
        </div>
        {/*slider bölümü*/}
        <div style={{ width: "100%", height: "calc(100% - 425px)" }}>
          <div
            className="flex justify-center items-center"
            style={{ width: "100%", height: "100%" }}
          >
            {profileStatus === "kapali" ? null : (
              <Swiper
                style={{ height: "100%", width: "100%" }}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                modules={[Autoplay, Pagination]}
                spaceBetween={1}
                slidesPerView={1}
                pagination={{ clickable: true }}
                /*onSwiper={(swiper) => console.log(swiper)}*/
              >
                <SwiperSlide className="flex flex-column justify-content-center align-items-center m-0 p-3.5">
                  <div className="sliderTextStyle1 flex justify-content-center align-items-center tiklanmaEngelle">
                    {t(
                      "aski_bulmanin_ve_etkinliklerle_heyecani_paylasmanin_yeni_yolu"
                    )}
                  </div>
                  <div className="sliderTextStyle2 flex justify-content-center align-items-center tiklanmaEngelle">
                    {t("eglenmenin_en_kolay_yolu")}
                  </div>
                </SwiperSlide>

                <SwiperSlide className="flex flex-column justify-content-center align-items-center m-0 p-3.5">
                  <div className="sliderTextStyle1 flex justify-content-center align-items-center tiklanmaEngelle">
                    {t("sonsuza_kadar_guvenli_ve_eglenceli_bir_topluluk")}
                  </div>
                  <div className="sliderTextStyle2 flex justify-content-center align-items-center tiklanmaEngelle">
                    {t("keyifli_zaman_gecirmen_icin_yaptik")}
                  </div>
                </SwiperSlide>

                <SwiperSlide className="flex flex-column justify-content-center align-items-center m-0 p-3.5">
                  <div className="sliderTextStyle1 flex justify-content-center align-items-center tiklanmaEngelle">
                    {t("sohbetin_keyfini_cıkar")}
                  </div>
                  <div className="sliderTextStyle2 flex justify-content-center align-items-center tiklanmaEngelle">
                    {t("yeni_insanlarla_tanisma_zamani")}
                  </div>
                </SwiperSlide>
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profil;
