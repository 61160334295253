// reducer/Etkinlik/etkinlikKatilimIstekleriReddedilenlerReducer.js

import {
    ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_REQUEST,
    ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_SUCCESS,
    REDDEDILEN_UYEYI_SIL_EYLEM_SUCCESS,
    REDDEDILEN_UYEYI_SIL_BUTTON,
    ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_DATA_CLEAR
} from '../../types';

const initialState = {
    loading: false,
    etkinlikKatilimIstekleriReddedilenlerData: [],
    error: ''
}

const etkinlikKatilimIstekleriReddedilenlerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_SUCCESS:
            return {
                loading: false,
                etkinlikKatilimIstekleriReddedilenlerData: action.payload,
                error: ''
            }
        case REDDEDILEN_UYEYI_SIL_BUTTON:
            return {
                ...state,
                etkinlikKatilimIstekleriReddedilenlerData: action.payload
            };
        case REDDEDILEN_UYEYI_SIL_EYLEM_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: ''
            }
        case ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_DATA_CLEAR:
            return {
                ...state,
                etkinlikKatilimIstekleriReddedilenlerData: []
            }
        default: return state
    }
}

export default etkinlikKatilimIstekleriReddedilenlerReducer;
