// actions/Etkinlik/etkinlikKatilEylemAction.js

import axios from "../../../../utils/CustomAxios";
import {
    ETKINLIK_KATIL_EYLEM_SUCCESS, ETKINLIK_KATIL_ISTEK_GERI_CEK_EYLEM_LOADING
} from '../../../types';
import {showToast} from "../../../../utils/Functions";

export const etkinlikKatilEylemSuccess = (message) => {
    return {
        type: ETKINLIK_KATIL_EYLEM_SUCCESS,
        payload: message
    }
}
export const etkinlikKatilEylemLoading = (isLoading) => {
    return {
        type: ETKINLIK_KATIL_ISTEK_GERI_CEK_EYLEM_LOADING,
        payload: isLoading
    };
};

export const etkinlikKatilEylemAction = (secilenEventId, customerId) => {
    return (dispatch) => {
        dispatch(etkinlikKatilEylemLoading(true));
        return axios.post(`customer/event-participant/join/${secilenEventId}`, {customer_id: customerId}).then((res) => {
            dispatch(etkinlikKatilEylemLoading(false));
            showToast(res.data.messages);
            if (res.data.status === 1) {
                dispatch(etkinlikKatilEylemSuccess(res.data.messages))
            }
        }).catch((err) => {
            dispatch(etkinlikKatilEylemLoading(false));
            if(err.response){
                showToast(err.response.data.messages);
            }else{
                showToast([{type:"error",message:err.message,second:5}]);
            }
        })
    }
}
