import React, {createRef, useEffect, useState} from "react";
import {datas} from "../utils/datas";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHeart, faX, faXmark } from "@fortawesome/free-solid-svg-icons";
import ResimMermiButonlar from "./ResimMermiButonları";
import ResimDegistirmeYonTuslari from "./ResimDegistirmeYonTuslari";
import LikeInfoEmoji from "./LikeInfoEmoji";
import {Logo} from "../assets/svg/Logo";
import { useDispatch, useSelector } from 'react-redux';
import { begenEylemi } from '../store/actions/Profil/begenEylemiAction';
import { begenmemeEylemi } from '../store/actions/Profil/begenmemeEylemiAction';
import { blockEylemi } from '../store/actions/Profil/blockEylemiAction';
import { uyeDetayBilgileriAction } from "../store/actions/Profil/uyeDetayBilgileriAction";
import { begendiklerinAction } from "../store/actions/BegenenlerBegendiklerinBegenmediklerin/begendiklerinAction";
import { begenmediklerinAction } from "../store/actions/BegenenlerBegendiklerinBegenmediklerin/begenmediklerinAction";
import UyeBittiLoading from "./UyeBittiLoading";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";


export default function UyeProfilDetay({tiklananUyeId, profilDetayModal}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [engelleModal, setEngelleModal] = useState("engelle-modal-kapali");
    let user_data = JSON.parse(localStorage.getItem('customer_data'));

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const uyeDetayBilgileri = useSelector(state => state.uyeDetayBilgileri);
    const burcBilgileri = useSelector(state => state.uyeDetayBilgileri.burc);
    const yukleniyor = useSelector(state => state.uyeDetayBilgileri.loading);
    const begenVeBegenmemeYukleniyor = useSelector(state => state.uyeDetayBilgileri.begenVeBegenmeLoading);

    useEffect(() => {
        dispatch(uyeDetayBilgileriAction(tiklananUyeId));
    }, [tiklananUyeId, dispatch])

    const [swiperId, setSwiperId] = useState(0);
    const toplamResim = uyeDetayBilgileri?.media ? uyeDetayBilgileri?.media?.length - 1 : 0;
    let referans = [];
    if (uyeDetayBilgileri && uyeDetayBilgileri.media) {
        referans = uyeDetayBilgileri.media.reduce((acc, val, i2) => {
            acc[i2] = createRef();
            return acc;
        }, {});
    }

    const goruntuyuKaydir = i2 => {
        setSwiperId(i2);
        referans[i2].current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
        })
    };
    const ileri = () => {
        if (swiperId !== toplamResim) {
            goruntuyuKaydir(swiperId + 1);
        }
    };
    const geri = () => {
        if (swiperId !== 0) {
            goruntuyuKaydir(swiperId - 1);
        }
    };
    const bulletButton = (id) => {
        goruntuyuKaydir(id);
    };

    const begen = async () => {
      await dispatch(begenEylemi(tiklananUyeId)).then(() => {
            dispatch(begendiklerinAction());
            dispatch(begenmediklerinAction());
        })
    }
    const begenme = async () => {
       await dispatch(begenmemeEylemi(tiklananUyeId)).then(() => {
            dispatch(begendiklerinAction());
            dispatch(begenmediklerinAction());
        })
    }

    const engelleModalIslem = (id) => {
        setEngelleModal(id)
    }
    const block = () => {
        dispatch(blockEylemi(tiklananUyeId));
    }

    const [resimBuyutId, setResimBuyutId] = useState(false);

    const [{ x }, api] = useSpring(() => ({ x: 0 }));

    const bind = useDrag(({ movement: [mx], velocity, direction: [dx], last}) => {
        if (last) {
            if (Math.abs(mx) > 100 || velocity > 0.5) {
                // Hareketin yeterince büyük olduğunu veya hızın yeterli olduğunu kontrol eder
                const direction = dx > 0 ? 1 : -1; // Sağ için 1, sol için -1
                api.start({ x: direction * 1000 }); // Modalı ekrandan çıkar
                setTimeout(() => {
                    api.start({ x: 0 }); // x pozisyonunu sıfırla
                    setResimBuyutId(false);
                }, 300); // Animasyon süresi
            } else {
                api.start({ x: 0 }); // Modalı eski yerine getir
            }
        } else {
            api.start({ x: mx }); // Modalı parmak hareketine göre sağa/sola kaydır
        }
    });

    const resimBuyut = () => {
        setResimBuyutId(true);
        api.start({ scale: 1.5 }); // Resmi büyüt
    };

    return (
        <div className="flex flex-column" style={{width: "100%", height: "100%"}}>
            {yukleniyor === false ? (
                <>
                    <div style={{width: "100%", height: "100%", position: "relative", zIndex: 555}} className="active-scroll">
                        <div style={{width: "100%", height: 250, backgroundColor: "#fff"}} className="flex justify-center items-center">
                            <div className="flex flex-column" style={{width: "100%", height: "100%", zIndex: 15, position: "relative"}}>
                                <ResimMermiButonlar toplamResim={toplamResim} aktifUye={uyeDetayBilgileri}
                                                    swiperId={swiperId} referans={referans} bulletButton={bulletButton} width={width}/>
                                {uyeDetayBilgileri?.media ? (
                                    <div style={{width: "100%", height: "100%"}} className="carousel2 sliderStyle">
                                        {uyeDetayBilgileri?.media?.map((item, i2) => {
                                            return (
                                                <div className="w-full flex-shrink-0 profilFotoEkle222" key={i2} ref={referans[i2]} onClick={() => resimBuyut(true)}>
                                                    <img className="uyeProfilDetayMedia tiklanmaEngelle" src={item.url + item.file_name} alt='' key={item.id}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : (
                                    <Logo className={`tiklanmaEngelle uyeProfilDetayMedia`}/>
                                )}
                                <ResimDegistirmeYonTuslari top={'40%'} ileri={ileri} geri={geri} toplamResim={toplamResim} swiperId={swiperId}/>
                            </div>
                        </div>
                        <div style={{width: "100%", height: 'calc(100% - 250px)'}} className="flex flex-column px-3 py-3">
                                <div className="flex flex-column">
                                    {/*isim, yaş, burç*/}
                                    <div className="flex justify-start items-center">
                                        <span className={"profilDetayNameTextStyle tiklanmaEngelle my-2"}>{uyeDetayBilgileri && `${uyeDetayBilgileri?.first_name || '-'} ${uyeDetayBilgileri?.last_name || ''}`}</span>
                                        <span className="profilDetayTextStyle tiklanmaEngelle my-1 mx-1">({uyeDetayBilgileri?.age || ''})</span>
                                        <small className="profilDetayTextStyle tiklanmaEngelle my-1 mx-1" style={{fontSize: 15}}>{burcBilgileri}</small>
                                    </div>
                                    {/*mesafe*/}
                                    <div className="flex items-center">
                                        <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">{t('mesafe')}: </span>
                                        <span className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">{uyeDetayBilgileri ? `${uyeDetayBilgileri?.distance}` : '-'} km</span>
                                    </div>
                                    {/*cinsiyet*/}
                                    <div className="flex items-center">
                                        <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">{t('cinsiyet')}: </span>
                                        <span className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">{uyeDetayBilgileri ? `${uyeDetayBilgileri?.gender ? datas().cinsiyetData[uyeDetayBilgileri?.gender]?.label || '-' : '-'}` : ''}</span>
                                    </div>
                                    {/*cinsel yönelim*/}
                                    <div className="flex items-center">
                                        <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">{t('cinsel_yonelim')}: </span>
                                        <span className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">{uyeDetayBilgileri ? `${uyeDetayBilgileri?.sexual_orientation ? datas().cinselYonelimData[uyeDetayBilgileri?.sexual_orientation]?.label || '-' : '-'}` : ''}</span>
                                    </div>
                                    {/*aciklama*/}
                                    <div className="flex flex-column justify-center">
                                        <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">{t('aciklama')}: </span>
                                        <span style={{width: "100%", height: 100}} className="profilDetayAciklamaStyle active-scroll mb-3 tiklanmaEngelle">{uyeDetayBilgileri && `${uyeDetayBilgileri?.description || '-'}`}</span>
                                    </div>
                                    {/*bir süper güç seçebilirsen hangisini seçerdin*/}
                                    <div className="flex flex-column justify-center">
                                        <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">{t('bir_super_guc_secebilseydin_hangisini_secerdin')}: </span>
                                        <span style={{width: "100%", height: 80}} className="profilDetayAciklamaStyle active-scroll mb-3 tiklanmaEngelle">{uyeDetayBilgileri && `${uyeDetayBilgileri?.company || '-'}`}</span>
                                    </div>
                                    {/*en çok ne zaman mutlu hissedersin*/}
                                    <div className="flex flex-column justify-center">
                                        <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">{t('kendini_en_mutlu_hissettigin_an')}: </span>
                                        <span style={{width: "100%", height: 80}} className="profilDetayAciklamaStyle active-scroll mb-3 tiklanmaEngelle">{uyeDetayBilgileri && `${uyeDetayBilgileri?.job_title || '-'}`}</span>
                                    </div>
                                    {/*okul*/}
                                    <div className="flex items-center">
                                        <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">{t('okul')}: </span>
                                        <span className="profilDetayTextStyle my-1 mx-1 tiklanmaEngelle">{uyeDetayBilgileri && `${uyeDetayBilgileri?.school || '-'}`}</span>
                                    </div>
                                    {/*sigara kullanımı*/}
                                    <div className="flex items-center">
                                        <span className="profilDetayTextStyleThin my-2 tiklanmaEngelle">{t('sigara_kullanimi')}: </span>
                                        <span className={`profilDetayTextStyle my-1 mx-1 tiklanmaEngelle`}>{uyeDetayBilgileri ? `${uyeDetayBilgileri?.smoking ? datas().sigaraKullanimiData[uyeDetayBilgileri.smoking]?.label || '-' : '-'}` : ''}</span>
                                    </div>

                                    {parseInt(user_data.id) !== parseInt(tiklananUyeId) && (
                                        <>
                                            {uyeDetayBilgileri && profilDetayModal ? (
                                                <div style={{width: "100%", borderTopWidth: 1, borderBottomWidth: 1}} className={`flex-row grid grid-flow-col auto-cols-max gap-14 items-center justify-center mb-2 mt-2`}>
                                                    {begenVeBegenmemeYukleniyor === false ? (
                                                        <>
                                                            {!uyeDetayBilgileri.sent_like ? (
                                                                <div onClick={() => begen()}
                                                                     className="flex items-center justify-center text-white my-3">
                                                                    <button className={"btn btn-light rounded-circle p-3 shadow"}>
                                                                        <FontAwesomeIcon icon={faHeart} className="fa-xl" width={24} height={24}
                                                                                         style={{color: "#28e1b2"}}/>
                                                                    </button>
                                                                </div>
                                                            ) : ''}

                                                            {!uyeDetayBilgileri?.dislike ?
                                                                (<div onClick={() => begenme()}
                                                                      className="flex items-center justify-center text-white my-3">
                                                                        <button className={"btn btn-light rounded-circle p-3 shadow"}>
                                                                            <FontAwesomeIcon icon={faX} className="fa-xl" width={24} height={24}
                                                                                             style={{color: "#ff4754"}}/>
                                                                        </button>
                                                                    </div>
                                                                ) : ''}
                                                        </>
                                                    ) : (
                                                        <div style={{width: 142, height: 58}} className="flex justify-center items-center my-3">
                                                            <div className="flex justify-center items-center" style={{width: "90%", height: 5, backgroundColor: "#ddd", borderRadius: 100}}>
                                                                <div id="animatedDiv"/>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : ''}
                                        </>
                                    )}

                                    {/*engelle butonu*/}

                                    {parseInt(user_data.id) === parseInt(tiklananUyeId) ? (
                                        <div className="my-4 mx-3 cursorPointer mb-5">

                                        </div>
                                    ) : (
                                        <div onClick={() => engelleModalIslem("engelle-modal-acik")} className="flex justify-center items-center my-4 mx-3 cursorPointer mb-5">
                                            <span className="uyeProfilEngelleText tiklanmaEngelle">{t("engelle")}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                    </div>
                    {uyeDetayBilgileri?
                        <div className={"position-absolute fs-6"} style={{margin: "-35px 0 0 5px"}}>
                                {(uyeDetayBilgileri.match) ?
                                    <LikeInfoEmoji text={t("bu_kisiyle_eslestiniz")} emoji={"💞"}/> : ''}
                                {(uyeDetayBilgileri.dislike) ?
                                    <LikeInfoEmoji text={t("bu_kisiyi_begenmediniz")} emoji={"😏"}/> : ''}
                                {(uyeDetayBilgileri.sent_like) ?
                                    <LikeInfoEmoji text={t("bu_kisiyi_begendiniz")} emoji={"😍"}/> : ''}
                                {(uyeDetayBilgileri.incoming_like) ?
                                    <LikeInfoEmoji text={t("bu_kisi_sizi_begendi")} emoji={"🥰"}/> : ''}
                            </div>
                        :''}
                    {engelleModal === "engelle-modal-acik" && (
                        <div className="flex flex-column justify-center items-center" style={{width: "100%", height: "97%", position: "absolute", zIndex: 55555, backgroundColor: "rgba(0,0,0,0.63)"}}>
                            <div className="flex flex-column active-scroll" style={{width: "90%", height: "80%", backgroundColor: "#fff", borderRadius: 8}}>
                                    <div className="flex justify-end items-end px-3 py-2" style={{width: "100%", height: 51}}>
                                        <div onClick={() => engelleModalIslem("engelle-modal-kapali")} className="flex box justify-content-center align-items-center">
                                            <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                        </div>
                                    </div>
                                    <div style={{width: "100%", height: 'calc(100% - 51px)'}} className="flex flex-column justify-center items-center">
                                        <div style={{width: "100%", height: "40%"}} className="flex flex-column justify-center items-center">
                                            <span className="engelleBaslikTextStyle tiklanmaEngelle">{t("uyari")}</span>
                                            <span className="engelleTextStyle tiklanmaEngelle py-1 pb-5">{t("esleşmeniz_ve_sohbetiniz_sonlandirilacak")}</span>
                                            <div className="flex flex-col justify-center">
                                                <span className="profilDetayNameTextStyle tiklanmaEngelle py-1">"{uyeDetayBilgileri?.first_name+" "+uyeDetayBilgileri?.last_name}"</span>
                                                <span className="engelleTextStyle tiklanmaEngelle py-1">{t("engellenecek_emin_misin")}?</span>
                                            </div>
                                        </div>
                                        <div style={{width: "100%", height: "30%"}} onClick={() => block()} className="flex justify-center items-center py-4">
                                            <div className="engelleBaslikTextStyle3">{t("engelle")}</div>
                                        </div>
                                        <div style={{width: "100%", height: "30%"}} className="flex flex-column justify-center items-center px-2">
                                            <span className="engelleBaslikTextStyle2 tiklanmaEngelle py-1">{t("engeli_nasil_kaldiririm")}</span>
                                            <div className="flex flex-column justify-center items-center">
                                                <span className="engelleTextStyle2 tiklanmaEngelle">{t("profil")} -> {t("ayarlar")} -> {t("engellenenler")}</span>
                                                <span className="engelleTextStyle3 tiklanmaEngelle"> {t("adımlarini_takip_ederek_engeli_kaldirabilirsiniz")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    )}

                    {resimBuyutId && (
                        <animated.div {...bind()} className="flex flex-column justify-center items-center" style={{width: "100%", height: "calc(100% - 51px)", position: "absolute", zIndex: 55555, backgroundColor: "rgba(0,0,0,0.63)", x, touchAction: 'none'}}>
                            <div className="flex justify-center items-center" style={{width: "90%", height: "90%", backgroundColor: "#fff", borderRadius: 8}}>
                                  {uyeDetayBilgileri?.media?.map((item, i2) => {
                                      return (
                                          <img src={item.url + item.file_name} // Görsel URL'sini buraya ekleyin
                                               alt=""
                                               key={item.id}
                                               style={{borderRadius: 8}}
                                               className="uyeProfilResimBuyut tiklanmaEngelle profilFotoEkle222"
                                          />
                                      )
                                  })}
                            </div>
                        </animated.div>
                    )}
                </>
            ) : (
                <div style={{width: "100%", height: "100%"}} className="flex flex-column justify-center items-center">
                    <div style={{width: "100%", height: "50%"}}>
                        <UyeBittiLoading width={width} height={height}/>
                    </div>
                    <div style={{width: "100%", height: "20%"}} className="flex flex-column justify-start items-center">
                        <div className="flex flex-column items-center">
                            <span className={`uyeBittiLoadingUyariTextStyle mb-1 tiklanmaEngelle ${width <= 350 ? "uyeBittiLoadingUyariTextFontMobileStyle" : "uyeBittiLoadingUyariTextFontDesktopStyle"}`}>
                                {t("yukleniyor")}
                            </span>
                        </div>
                    </div>
                </div>
                )}
        </div>
    )
}
