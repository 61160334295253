import React from "react";
import "../../styles/main.css";
import {useTranslation} from "react-i18next";


export default function UyariModal({width, height, handleDataSelectButton}) {
    const { t } = useTranslation();

    const closeModal = (id) => {
        handleDataSelectButton(id)
    }

    return (
        <div style={{width: "100%", height: "100%"}} className="flex flex-column justify-content-center align-items-center active-scroll">
            {/*modal logo*/}
            <div style={{width: "100%", height: 55}} className="flex flex-row align-items-center justify-content-center">
                <div className="logoTextStyle1 pt-2 tiklanmaEngelle">
                    <span>
                        MOZARED
                    </span>
                </div>
            </div>
            {/*başlık*/}
            <div style={{width: "100%", height: 60}} className="flex flex-column justify-content-center align-items-center">
                <span className="hesapOluşturModalTextStyle11 tiklanmaEngelle">{t("hos_geldiniz")}</span>
                <span className="hesapOluşturModalTextStyle12 pt-2 tiklanmaEngelle">{t("lutfen_kurallarimiza_uymayi_unutmayin")}</span>
            </div>
            {/*uyarılar*/}
            <div style={{width: "80%", height: 'calc(100% - 115px)'}}>
                <div className="mt-3">
                    <div className="flex flex-row">
                        <div className="flex justify-content-center align-items-center">
                            <span className="hesapOluşturModalLogoTextStyle1 tiklanmaEngelle">✓</span>
                        </div>
                        <div className={`ml-2 textTransformCapitalize tiklanmaEngelle ${width <= 500 || height <= 550 ? "hesapOluşturModalTextStyle1322" : "hesapOluşturModalTextStyle13"}`}>{t("guvenli_olun")}</div>
                    </div>
                    <div className="mt-1">
                        <span className={`textTransformCapitalize tiklanmaEngelle ${width <= 500 || height <= 550 ? "hesapOluşturModalTextStyle1422" : "hesapOluşturModalTextStyle14"}`}>{t("guvenli_olun_aciklama_text")}</span>
                    </div>
                </div>

                <div className="mt-3">
                    <div className="flex flex-row">
                        <div className="flex justify-content-center align-items-center">
                            <span className="hesapOluşturModalLogoTextStyle1 tiklanmaEngelle">✓</span>
                        </div>
                        <div className={`ml-2 textTransformCapitalize tiklanmaEngelle ${width <= 500 || height <= 550 ? "hesapOluşturModalTextStyle1322" : "hesapOluşturModalTextStyle13"}`}>{t("ozgun_olun")}</div>
                    </div>
                    <div className="mt-1">
                        <span className={`textTransformCapitalize tiklanmaEngelle ${width <= 500 || height <= 550 ? "hesapOluşturModalTextStyle1422" : "hesapOluşturModalTextStyle14"}`}>{t("ozgun_olun_aciklama_text")}</span>
                    </div>
                </div>

                <div className="mt-3">
                    <div className="flex flex-row">
                        <div className="flex justify-content-center align-items-center">
                            <span className="hesapOluşturModalLogoTextStyle1 tiklanmaEngelle">✓</span>
                        </div>
                        <div className={`ml-2 textTransformCapitalize tiklanmaEngelle ${width <= 500 || height <= 550 ? "hesapOluşturModalTextStyle1322" : "hesapOluşturModalTextStyle13"}`}>{t("gizliliginize_dikkat_edin")}</div>
                    </div>
                    <div className="mt-1">
                        <span className={`textTransformCapitalize tiklanmaEngelle ${width <= 500 || height <= 550 ? "hesapOluşturModalTextStyle1422" : "hesapOluşturModalTextStyle14"}`}>{t("gizliliginize_dikkat_edin_aciklama_text")}</span>
                    </div>
                </div>

                <div className="mt-3">
                    <div className="flex flex-row">
                        <div className="flex justify-content-center align-items-center">
                            <span className="hesapOluşturModalLogoTextStyle1 tiklanmaEngelle">✓</span>
                        </div>
                        <div className={`ml-2 textTransformCapitalize tiklanmaEngelle ${width <= 500 || height <= 550 ? "hesapOluşturModalTextStyle1322" : "hesapOluşturModalTextStyle13"}`}>{t("eglenin")}</div>
                    </div>
                    <div className="mt-1">
                        <span className={`textTransformCapitalize tiklanmaEngelle ${width <= 500 || height <= 550 ? "hesapOluşturModalTextStyle1422" : "hesapOluşturModalTextStyle14"}`}>{t("eglenin_aciklama_text")}</span>
                    </div>
                </div>

                {/*modal kapat tuşu*/}
                <div style={{width: "100%", height: 80}}>
                    <div style={{width: "100%", height: "100%"}} className="flex justify-center items-center" onClick={() => closeModal("")}>
                        <div className="hesapOluşturModalButtonStyle1 flex justify-center items-center">
                            <span className="loginPcButtonTextStyle2 tiklanmaEngelle">{t("onayliyorum")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
