// actions/begendiklerinAction.js

import axios from "../../../utils/CustomAxios";
import {
    BEGENDIKLERIN_PAGE,
    BEGENDIKLERIN_REQUEST,
    BEGENDIKLERIN_SUCCESS,
    BEGENDIKLERIN_IS_FETCHING,
    BEGENDIKLERIN_HAS_MORE
} from '../../types';
import {showToast} from "../../../utils/Functions";

export const begendiklerinAction = () => {
    return async (dispatch, getState) => {
        const { page, isFetching, hasMore, begendiklerinData } = getState().begendiklerinSorgu;
        if (!isFetching && hasMore) {
            dispatch(begendiklerinRequest());
            dispatch({ type: BEGENDIKLERIN_IS_FETCHING, payload: true });
            try {
                const res = await axios.get(`customer/like?limit=10&page=${page}`);
                if (res.data.status === 1) {
                    const newData = res.data.data.likes || [];
                    const updatedData = mergeData(begendiklerinData, newData); // Verileri birleştir
                    dispatch(begendiklerinSuccess(updatedData));
                    if (newData.length < 10) {
                        dispatch({ type: BEGENDIKLERIN_HAS_MORE, payload: false });
                    } else {
                        dispatch(begendiklerinIncrementPage(page));
                    }
                }
            } catch (err) {
                if(err.response){
                    showToast(err.response.data.messages);
                }else{
                    showToast([{type:"error",message:err.message,second:5}]);
                }
            } finally {
                dispatch({ type: BEGENDIKLERIN_IS_FETCHING, payload: false });
            }
        }
    };
};

// Verileri birleştir
const mergeData = (existingData, newData) => {
    // Önceki verilerden kullanıcıların 'id'lerini al ve bir Set oluştur
    const userIdSet = new Set(existingData.map(item => item.customer_id));

    // Yeni veri kümesini filtrele, yalnızca önceki veri kümesinde olmayan 'id'lere sahip olanları tut
    const filteredNewData = newData.filter(item => !userIdSet.has(item.customer_id));

    // Önceki verilere yeni verileri ekle
    return [...existingData, ...filteredNewData];
};

const begendiklerinRequest = () => {
    return {
        type: BEGENDIKLERIN_REQUEST
    };
};

const begendiklerinSuccess = (data) => {
    return {
        type: BEGENDIKLERIN_SUCCESS,
        payload: data
    };
};

export const begendiklerinIncrementPage = (currentPage) => {
    return {
        type: BEGENDIKLERIN_PAGE,
        payload: currentPage + 1
    };
};
