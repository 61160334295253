import { PAKET_DURUM_BILGISI } from '../types';


const initialState = {
    hasActivePackage: false,
};

const paketReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAKET_DURUM_BILGISI:
            return {
                ...state,
                hasActivePackage: action.payload,
            };
        default:
            return state;
    }
};

export default paketReducer;
