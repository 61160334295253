// actionCreators.js

import axios from '../../../utils/CustomAxios';
import {ETKINLIK_SIL_SUCCESS, ETKINLIK_SIL_LOADING} from '../../types';
import {showToast} from "../../../utils/Functions";

export function etkinlikSilEylemAction(secilenEtkinlikDetayDataId) {
    return dispatch => {
        dispatch(etkinlikSilLoading(true));

        return axios.delete(`customer/event/${secilenEtkinlikDetayDataId}`)
            .then((res) => {
                dispatch(etkinlikSilLoading(false));
                if (Array.isArray(res.data.messages)) {
                    showToast(res.data.messages);
                }
                if (res.data.status === 1) {
                    dispatch(etkinlikSilSuccess(secilenEtkinlikDetayDataId));
                }
            })
            .catch((err) => {
                dispatch(etkinlikSilLoading(false));
                if (err.response && Array.isArray(err.response.data.messages)) {
                    showToast(err.response.data.messages);
                } else {
                    showToast([{type: "error", message: err.message, second: 5}]);
                }
            });
    }
}

export const etkinlikSilSuccess = (data) => {
    return {
        type: ETKINLIK_SIL_SUCCESS,
        payload: data
    };
};

export const etkinlikSilLoading = (isLoading) => {
    return {
        type: ETKINLIK_SIL_LOADING,
        payload: isLoading
    };
};
