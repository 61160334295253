import React from 'react';
import "../styles/main.css";
import "../styles/main.css";
import {isMobile} from "react-device-detect";
import GradientSvg from "../assets/svg/GradientSvg";
import {CircularProgressbar} from "react-circular-progressbar";
import {Logo} from "../assets/svg/Logo";

function UyeBittiLoading({user, height, width}) {
    const percentage = 50;
    const idCSS = "UyeBittiLoadingId";


    return (
        <div style={{width: "100%", height: "100%"}} className="flex justify-center items-center">
            <div className={`tiklanmaEngelle ${isMobile ? "profileDivStyle333" : width <= 500 || height <= 550 ? "profileDivStyle333" : "profileDivStyle3"}`}>
                <GradientSvg idCSS={idCSS}/>
                <CircularProgressbar className="rotate" circleRatio={1} strokeWidth={3.5} value={percentage} styles={{
                    trail: {
                        strokeLinecap: 'butt',
                        transform: 'rotate(-135deg)',
                        transformOrigin: 'center center',
                    },
                    path: {
                        stroke: `url(#${idCSS})`, height: "100%",
                        strokeLinecap: 'butt',
                        transform: 'rotate(-135deg)',
                        transformOrigin: 'center center',
                    },
                }}/>
            </div>
            <div className="flex flex-column justify-center items-center profileFotoDivStyle2">
                {user ? (
                    <Logo className={`tiklanmaEngelle ${isMobile ? "profileFotoDivStyleMobile" : width <= 500 || height <= 550 ? "profileFotoDivStyle122" : "uyeBittiLoadingFotoStylePc"}`}/>
                ) : (
                    <Logo className={`tiklanmaEngelle ${isMobile ? "profileFotoDivStyleMobile" : width <= 500 || height <= 550 ? "profileFotoDivStyle122" : "uyeBittiLoadingFotoStylePc"}`}/>
                )}

            </div>
        </div>
    );
}


export default UyeBittiLoading;
