// actions/Etkinlik/etkinlikIstegiGeriCekAction.js

import axios from "../../../../utils/CustomAxios";
import {
    ETKINLIK_ISTEGI_GERI_CEK_SUCCESS,
    ETKINLIK_KATIL_ISTEK_GERI_CEK_EYLEM_LOADING
} from '../../../types';
import {showToast} from "../../../../utils/Functions";

export const etkinlikIstegiGeriCekEylemSuccess = (message) => {
    return {
        type: ETKINLIK_ISTEGI_GERI_CEK_SUCCESS,
        payload: message
    }
}
export const etkinlikIstegiGeriCekEylemLoading = (isLoading) => {
    return {
        type: ETKINLIK_KATIL_ISTEK_GERI_CEK_EYLEM_LOADING,
        payload: isLoading
    };
};

export const etkinlikIstegiGeriCekEylemAction = (secilenEventId) => {
    return (dispatch) => {
        dispatch(etkinlikIstegiGeriCekEylemLoading(true));
        return axios.put(`customer/event-participant/cancel/${secilenEventId}`).then((res) => {
            dispatch(etkinlikIstegiGeriCekEylemLoading(false));
            showToast(res.data.messages);
            if (res.data.status === 1) {
                dispatch(etkinlikIstegiGeriCekEylemSuccess(res.data.messages))
            }
        }).catch((err) => {
            dispatch(etkinlikIstegiGeriCekEylemLoading(false));
            if(err.response){
                showToast(err.response.data.messages);
            }else{
                showToast([{type:"error",message:err.message,second:5}]);
            }
        })
    }
}
