import React from "react";
import ReactDOMServer from 'react-dom/server';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import Leaflet from 'leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {library} from "@fortawesome/fontawesome-svg-core";
import {faLocationDot} from '@fortawesome/free-solid-svg-icons'
import 'leaflet/dist/leaflet.css'
import '../styles/main.css'
import {useTranslation} from "react-i18next";
library.add(faLocationDot)

function Harita({latitude, longitude, secilenEtkinlikDetay, baslangicTarihiToplam, baslangicSaatVeDakika, bitisTarihiToplam, bitisSaatVeDakika, bugununTarihiToplam}) {
    const {t} = useTranslation();
    const position = [parseFloat(latitude), parseFloat(longitude)]  // Bu koordinatları istediğiniz gibi değiştirebilirsiniz

    const markerIconHTML = ReactDOMServer.renderToString(<FontAwesomeIcon icon={faLocationDot} className="fa-2x" style={{color: "#EB3428"}}/>)
    const markerIcon = new Leaflet.DivIcon({
        html: markerIconHTML,
    });

    return (
        <div style={{ height: 270, width: "100%" }} className="flex flex-column justify-start items-center">
            <div style={{height: 40, width: "80%"}} className="flex justify-start items-center">
                <span className="tiklanmaEngelle etkinlikOlusturDetaySaatVeKmTextStyle1">etkinlik konumu</span>
            </div>
            <MapContainer  attributionControl={false} center={position} zoom={16} style={{height: 190, width: "80%"}}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position} icon={markerIcon}>
                    <Popup>
                        <div style={{width: "100%"}}>
                            <span className="tiklanmaEngelle haritaPopupTextStyle">{secilenEtkinlikDetay?.customer?.first_name+" "+secilenEtkinlikDetay?.customer?.last_name}</span>
                            <br/>
                            <div className="tiklanmaEngelle haritaPopupTextStyle">{secilenEtkinlikDetay?.name?.length > 30 ? secilenEtkinlikDetay?.name?.substring(0, 30)+"..." : secilenEtkinlikDetay?.name}</div>

                            <span className="tiklanmaEngelle haritaPopupTextStyle">{t("uzaklik")}: </span>
                            <span className="tiklanmaEngelle haritaPopupTextStyle">{secilenEtkinlikDetay?.distance} km</span>

                            <br/>
                            <span className="tiklanmaEngelle haritaPopupTextStyle">{t("baslangic")}: </span>
                            <span className="tiklanmaEngelle haritaPopupTextStyle">{bugununTarihiToplam === baslangicTarihiToplam ? `${t("bugun")}` : baslangicTarihiToplam} | {baslangicSaatVeDakika}</span>

                            <br/>
                            <span className="tiklanmaEngelle haritaPopupTextStyle">{t("bitis")}: </span>
                            <span className="tiklanmaEngelle haritaPopupTextStyle">{bugununTarihiToplam === bitisTarihiToplam ? `${t("bugun")}` : bitisTarihiToplam} | {bitisSaatVeDakika}</span>
                        </div>
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}

export default Harita;
