import React, {useEffect, useRef, useState, useCallback, useMemo} from "react";
import "../../styles/main.css";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faBug,
    faChevronDown,
    faCloudArrowDown,
    faComments,
    faCommentSlash, faCopy, faEdit,
    faEllipsis,
    faEnvelope,
    faEnvelopeOpen,
    faEye,
    faEyeSlash,
    faInbox,
    faMessage,
    faPaperPlane, faPenToSquare,
    faPersonWalkingDashedLineArrowRight,
    faPersonWalkingLuggage, faReply,
    faRotateRight,
    faSpinner,
    faTrash,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {useLocation} from "react-router-dom";
import UyeProfilDetay from "../../components/UyeProfilDetay";
import EtkinlikProfilDetay from "../../components/EtkinlikProfilDetay";
import {
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import axios from "../../utils/CustomAxios";
import {showToast} from "../../utils/Functions";
import {Logo} from "../../assets/svg/Logo";
import {debounce} from "lodash";
import {ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {animated, useSpring} from "@react-spring/web";
import {useDrag} from "@use-gesture/react";

function KonusmaListesi({user, socketRef, messageList, setMessageList, roomList, setRoomList, inputFocusButton,
                            focusKonusmaListesiInput, totalMessageCount, setTotalMessageCount, girisYapUyariModal}) {
    let location = useLocation();
    const token = localStorage.getItem("token");
    const {t} = useTranslation();
    const odaId = location?.state?.odaId ? location?.state?.odaId : null;
    const messagesEndRef = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [txtGroupId, setTxtGroupId] = useState(odaId);
    const [txtUsername, setTxtUsername] = useState();
    const [txtMessage, setTxtMessage] = useState("");
    const [joinRoomDisplay, setJoinRoomDisplay] = useState("room");
    const [txtRoomImage, setTxtRoomImage] = useState("");
    const [txtRoomName, setTxtRoomName] = useState("");
    const [currentRoom, setCurrentRoom] = useState(null);
    const [txtRoomUserId, setTxtRoomUserId] = useState("");
    const [uyeProfilDetayModal, setUyeProfilDetayModal] = useState("profil-detay-modal-kapali");
    const [error, setError] = useState("");
    const [typingUsers, setTypingUsers] = useState({});
    const [onlineUsers, setOnlineUsers] = useState({});
    const timers = useRef({}); // Her grup için zamanlayıcıları saklamak için
    const [isUserInChatRoom, setIsUserInChatRoom] = useState(false);
    const [mesajDetayModal, setMesajDetayModal] = useState("message-info-modal-kapali");
    const [readByUsers, setReadByUsers] = useState([]);
    const [tiklananMesajDetay, setTiklananMesajDetay] = useState(null);
    const [tiklananUyeId, setTiklananUyeId] = useState(null);
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [loadedMessageCount, setLoadedMessageCount] = useState(0);
    const [modal, setModal] = useState("modalKapatId");
    const [geciciUserIdBilgisi, setGeciciUserIdBilgisi] = useState(null);
    const [geciciTypeBilgisi, setGeciciTypeBilgisi] = useState(null);
    const [geciciEventIdBilgisi, setGeciciEventIdBilgisi] = useState(null);
    const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [etkinlikUyeProfiliAcikMi, setEtkinlikUyeProfiliAcikMi] = useState(false);
    const [kaydirilanDivId, setKaydirilanDivId] = useState("");

    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [msg, setMsg] = useState(null);
    const [isReply, setIsReply] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [showEditedMessageId, setShowEditedMessageId] = useState(null);


    useEffect(() => {
        if (token) {
            joinGame();
        }
    }, [joinRoomDisplay, odaId, socketRef, txtGroupId, token]);

    const joinGame = (room) => {
        if (token) {
            if (room?.id) {
                setTxtGroupId(room?.id);
                setCurrentRoom(room);
                socketRef.current.emit("join_room", {
                    username: txtUsername,
                    group_id: room?.id,
                });

                if (room?.id) {
                    socketRef.current.emit("enter_chat", room?.id); // Sohbete giriş olayını emit edin
                }
                setJoinRoomDisplay("message");
                setTxtRoomImage(room?.image);
                setTxtRoomName(room?.name);
                setIsUserInChatRoom(true); // Kullanıcı sohbete girdiğinde
                setModal("modalKapatId");
                setGeciciUserIdBilgisi(null);
                setGeciciTypeBilgisi(null);
                setGeciciEventIdBilgisi(null);
                if (room?.users[1]?.userId === parseInt(user?.id)) {
                    setTxtRoomUserId(room?.users[0]?.userId);
                } else if (room?.users[0]?.userId === parseInt(user?.id)) {
                    setTxtRoomUserId(room?.users[1]?.userId);
                }
                afterSendMessageOrJoin();
                inputFocusButtonKonusmaListesi(true);
            }
        }
    };
    const afterSendMessageOrJoin = () => {
        setShouldScrollToBottom(true);
    };

    const sendMessage = useCallback(() => {
        if (token) {
            if (!txtMessage.trim()) {
                setError(t("bos_mesaj_gonderemezsiniz"));

                setTimeout(() => {
                    setError("");
                }, 1000);
            } else {
                if (isNaN(user?.id) || !txtGroupId) {
                    return;
                }
                const playerId = localStorage.getItem('oneSignalToken');

                socketRef.current.emit("send_message", {
                    username: txtUsername,
                    group_id: txtGroupId,
                    message: txtMessage,
                    currentRoom: currentRoom,
                    readMessage: false,
                    userId: parseInt(user?.id),
                    createdDate: new Date().toISOString(),
                    playerId: playerId
                });
                let group_id = txtGroupId;
                if (roomList?.length > 0) {
                    let yeniKonusmaListesi = roomList?.map((konusma) => {
                        if (konusma?.id === group_id) {
                            return {
                                ...konusma,
                                lastMessageRead: false,
                                lastMessage: txtMessage,
                                lastMessageSender: parseInt(user?.id),
                            };
                        }
                        return konusma;
                    });
                    setRoomList(yeniKonusmaListesi);
                }
                setTxtMessage("");
                setError("");
                afterSendMessageOrJoin();
            }
        }
    }, [token, txtMessage, t, user?.id, txtGroupId, socketRef, txtUsername, currentRoom, roomList, setRoomList]);

    const enterGonder = (event, type) => {
        if (token) {
            if (event.keyCode === 13) {
                if (type === "txtMessage") {
                    sendMessage();
                }
                if (type === "reply") {
                    handleReplyMessage();
                }
                if (type === "edited") {
                    handleEditMessage();
                }
            }
        }
    };

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    useEffect(() => {
        if (token) {
            let user_data = JSON.parse(localStorage.getItem("customer_data"));
            setTxtUsername(`${user_data?.first_name} ${user_data?.last_name}`);
            window.addEventListener("resize", updateDimensions);
            return () => window.removeEventListener("resize", updateDimensions);
        }
    }, [token]);

    // const mesajButton = () => {
    //     navigate(`/Mesajlar{location.search}`, {state: {userId: userId}})
    // }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    };
    useEffect(() => {
        if (shouldScrollToBottom) {
            scrollToBottom();
        }
    }, [messageList, shouldScrollToBottom]);

    // Mesaj okunduğunda sokete bildirim gönderen fonksiyon
    const markMessageAsRead = useCallback(
        (messageId, senderId) => {
            if (token) {
                if (!messageId || !isUserInChatRoom) return;
                if (parseInt(senderId, 10) !== parseInt(user.id, 10)) {
                    // Mesajı gönderen kullanıcı bu değilse
                    socketRef.current.emit("message_read", {
                        msgId: messageId,
                        userId: parseInt(user?.id), // Kullanıcı ID'sini sayı olarak gönder
                    });
                }
            }
        },
        [isUserInChatRoom, user?.id, socketRef, token]
    );

    // Kullanıcının sohbet odasındayken okunmamış mesajları otomatik olarak okundu olarak işaretle
    useEffect(() => {
        if (token) {
            if (isUserInChatRoom) {
                messageList.forEach((message) => {
                    if (!message?.readMessage && parseInt(message?.userId) !== parseInt(user?.id)) {
                        markMessageAsRead(message?.id, message?.userId);
                    }
                });
            }
        }
    }, [isUserInChatRoom, markMessageAsRead, messageList, user?.id, token]);

    // Sunucudan gelen mesaj okundu güncellemelerini dinle
    useEffect(() => {
        if (token && socketRef.current) {
            const currentSocket = socketRef.current;
            const messageReadListener = (data) => {
                const dataId = data?._id ? data?.msgId : data?._id;
                setMessageList((currentMessages) => {
                    return currentMessages.map((msg) => ({
                        ...msg,
                        readBy:
                            msg?.id === dataId
                                ? [...new Set([...msg?.readBy, data?.userId])]
                                : msg?.readBy,
                        readMessage:
                            msg?.id === data?.msgId ? data?.readMessage : msg?.readMessage,
                        readAt: msg?.id === data?.msgId ? data?.readAt : msg?.readAt,
                        readByDetails:
                            msg?.id === data?.msgId
                                ? data?.readByDetails
                                : msg?.readByDetails,
                    }));
                });
            };
            currentSocket.on("message_status_update", messageReadListener);

            return () =>
                currentSocket.off("message_status_update", messageReadListener);
        }
    }, [setMessageList, socketRef, token]);

    useEffect(() => {
        // Grup bilgisinin güncellendiği durumları dinle
        if (token && socketRef.current) {
            const currentSocket = socketRef.current;
            const handleGroupUpdated = (updatedInfo) => {
                setRoomList((rooms) =>
                    rooms.map((room) => {
                        if (room?.id === updatedInfo?.groupId) {
                            return {
                                ...room,
                                lastMessageRead: updatedInfo?.lastMessageRead,
                                readBy: updatedInfo?.readBy,
                            };
                        }
                        return room;
                    })
                );
            };

            currentSocket.on("group_updated", handleGroupUpdated);

            return () => {
                currentSocket.off("group_updated", handleGroupUpdated);
            };
        }
    }, [setMessageList, setRoomList, socketRef, token]);

    // Mesaj yazma durumunu belirten fonksiyon
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleUserTyping = useCallback(
        debounce((isTyping) => {
            if (token) {
                socketRef.current.emit("user_typing", {
                    groupId: txtGroupId,
                    userId: parseInt(user?.id),
                    isTyping,
                });
            }
        }, 300), // 300ms debounce süresi
        [txtGroupId, user?.id, socketRef, token]);

    useEffect(() => {
        if (socketRef.current) {
            const currentSocket = socketRef.current;
            const handleErrorMessage = (data) => {
                if (data.status === 'group_none') {
                    setErrorMessages(data.status);
                    // setErrorMessages((prev) => [...prev, data.status]);
                }
            };

            currentSocket.on('message_status', handleErrorMessage);

            return () => {
                currentSocket.off('message_status', handleErrorMessage);
            };
        }
    }, [socketRef]);

    useEffect(() => {
        if (token && socketRef.current) {
            const currentSocket = socketRef.current;

            const handleDeletedMessage = ({ messageId }) => {
                setMessageList((prevMessages) => prevMessages.filter((msg) => msg.id !== messageId));
            };

            currentSocket.on('message_deleted', handleDeletedMessage);

            return () => {
                currentSocket.off('message_deleted', handleDeletedMessage);
            };
        }
    }, [setMessageList, socketRef, token]);

    // Mesaj yazılırken tetiklenecek
    useEffect(() => {
        if (token && socketRef.current) {
            const handleTypingChange = () => {
                if (txtMessage) {
                    handleUserTyping(true);
                } else {
                    handleUserTyping(false);
                }
            };

            handleTypingChange();
        }
    }, [handleUserTyping, txtMessage, token, socketRef]);

    // Server'dan gelen "yazıyor" durumunu dinle
    useEffect(() => {
        if (token && socketRef.current) {
            const currentSocket = socketRef.current;
            const typingListener = (data) => {
                if (data?.groupId === txtGroupId) {
                    setTypingUsers((prev) => {
                        const currentTypingUsers = prev[data?.groupId] || [];
                        if (
                            data?.isTyping &&
                            !currentTypingUsers?.includes(data?.username)
                        ) {
                            // Kullanıcı yazmaya başladı ve listede yoksa ekleyin
                            return {
                                ...prev,
                                [data?.groupId]: [...currentTypingUsers, data?.username],
                            };
                        } else if (
                            !data?.isTyping &&
                            currentTypingUsers?.includes(data?.username)
                        ) {
                            // Kullanıcı yazmayı durdurdu ve listedeyse çıkarın
                            return {
                                ...prev,
                                [data?.groupId]: currentTypingUsers?.filter(
                                    (username) => username !== data?.username
                                ),
                            };
                        }
                        return prev;
                    });
                }
            };

            currentSocket.on("user_typing", typingListener);

            return () => {
                currentSocket.off("user_typing", typingListener);
                setTypingUsers((prev) => ({
                    ...prev,
                    [txtGroupId]: [],
                }));
            };
        }
    }, [socketRef, txtGroupId, token]);

    // UI'da yazıyor durumunu göstermek için
    const typingMessage =
        typingUsers[txtGroupId] && typingUsers[txtGroupId].length > 0
            ? typingUsers[txtGroupId].length === 1
                ? `${typingUsers[txtGroupId][0]} ${t("yaziyor")}`
                : typingUsers[txtGroupId].length === 2
                    ? `${typingUsers[txtGroupId][0]} ${t("ve")} ${
                        typingUsers[txtGroupId][1]
                    } ${t("yaziyor")}`
                    : `${t("birden_fazla_kullanici_yaziyor")}`
            : "";

    useEffect(() => {
        if (token && socketRef.current) {
            const currentSocket = socketRef.current;
            const handleUserJoinOrLeave = (userId, username, groupId, action) => {
                if (userId !== user.id) {
                    const actionText =
                        action === "joined"
                            ? `${username} ${t("sohbete_girdi")}`
                            : `${username} ${t("sohbetten_cikti")}`;
                    setOnlineUsers((prev) => ({
                        ...prev,
                        [groupId]: actionText,
                    }));

                    if (action === "joined" && groupId === txtGroupId) {
                        setIsUserInChatRoom(true);
                        socketRef.current.emit("message_status_update", {
                            groupId,
                            userId,
                        });

                        if (groupId === txtGroupId) {
                            // Sohbete katılan kullanıcının mesajları okundu olarak işaretle
                            setMessageList((currentMessages) =>
                                currentMessages.map((msg) => {
                                    if (
                                        msg &&
                                        msg?.readBy &&
                                        !msg?.readBy?.includes(parseInt(user?.id))
                                    ) {
                                        return {
                                            ...msg,
                                            readBy: [...msg?.readBy, userId],
                                            readAt: msg.readAt,
                                            readMessage: msg.readMessage,
                                            readByDetails: [
                                                ...msg.readByDetails,
                                                {userId, readAt: new Date().toISOString()},
                                            ],
                                        };
                                    }

                                    return msg;
                                })
                            );
                        }
                    } else if (action === "left" && groupId === txtGroupId) {
                        setIsUserInChatRoom(false);
                        setErrorMessages([]);
                    }

                    if (timers.current[groupId]) {
                        clearTimeout(timers.current[groupId]);
                    }
                    timers.current[groupId] = setTimeout(() => {
                        setOnlineUsers((prev) => ({
                            ...prev,
                            [groupId]: "",
                        }));
                    }, 2000);
                }
            };

            currentSocket.on("user_joined_chat", ({userId, username, groupId}) => {
                handleUserJoinOrLeave(userId, username, groupId, "joined");
            });

            currentSocket.on("user_left_chat", ({userId, username, groupId}) => {
                handleUserJoinOrLeave(userId, username, groupId, "left");
            });

            // Yeni event listener ekleyin
            // currentSocket.on("update_user_status", ({userId, groupId, isOnline}) => {
            //     setRoomList((currentRoomList) => {
            //         return currentRoomList.map((room) => {
            //
            //             if (room.id === groupId) {
            //                 return {
            //                     ...room,
            //                     users: room.users.map((user) =>
            //                         user.userId === userId ? {...user, isOnline} : user
            //                     ),
            //                 };
            //             }
            //             return room;
            //         });
            //     });
            // });

            return () => {
                currentSocket.off("user_joined_chat");
                currentSocket.off("user_left_chat");
                // currentSocket.off("update_user_status");
            };
        }
    }, [socketRef, txtGroupId, user?.id, t, setMessageList, setRoomList, token]);

    // Daha Fazla Yükleme Butonu İçin Event Handler
    const loadMoreMessages = () => {
        if (token) {
            const firstMessage = messageList[0];
            setShouldScrollToBottom(false);

            if (firstMessage && !isLoadingMoreMessages) {
                setIsLoadingMoreMessages(true); // mesaj yükleme başladığında loading'i çalıştır
                socketRef.current.emit("load_more_messages", {
                    groupId: txtGroupId,
                    lastMessageId: firstMessage?.id || firstMessage?._id,
                    message: firstMessage?.message || firstMessage?.content,
                    limit: 10,
                });
            }
        }
    };

    useEffect(() => {
        if (token && socketRef.current) {
            const currentSocket = socketRef.current;
            const handleOlderMessages = (data) => {
                const {messages, totalMessages} = data;

                if (!messages || messages?.length === 0) {
                    // messages ya boş ya da dizi değilse
                    setIsLoadingMoreMessages(false); // Eğer mesaj yoksa yüklemeyi durdur
                    return; // Fonksiyonu burada sonlandır
                }

                // Gelen mesajların id alanlarını kontrol et ve gerekirse _id'yi kullan
                const updatedMessages = messages.map((msg) => ({
                    ...msg,
                    id: msg?.id || msg?._id,
                    message: msg?.message || msg?.content, // content özelliği kullanılmalı, message diye bir özellik yok
                }));

                setMessageList((prevMessages) => [...updatedMessages, ...prevMessages]);
                setTotalMessageCount(totalMessages);
                setLoadedMessageCount(
                    (prevLoaded) => prevLoaded + updatedMessages?.length
                );
                setIsLoadingMoreMessages(false); // Mesajlar başarıyla yüklendiğinde yüklemeyi durdur
            };

            currentSocket.on("older_messages", handleOlderMessages);

            return () => {
                currentSocket.off("older_messages", handleOlderMessages);
            };
        }
    }, [setMessageList, setTotalMessageCount, socketRef, token]);

    // Bu useEffect, mesaj listesi değiştiğinde çalışır ve yüklenen mesaj sayısını günceller
    useEffect(() => {
        if (token) {
            setLoadedMessageCount(messageList?.length);
        }
    }, [messageList, token]);

    // "Daha Fazla Mesaj Yükle" butonunu gösterip göstermeme durumunu kontrol et
    const showLoadMoreButton =
        loadedMessageCount === totalMessageCount ||
        totalMessageCount < loadedMessageCount;

    const geriButton = () => {
        if (token) {
            if (txtGroupId) {
                socketRef.current.emit("leave_chat", txtGroupId); // Sohbetten ayrılış olayını emit edin
            }
        }
        setMesajDetayModal("message-info-modal-kapali");
        setTxtMessage("");
        setMessageList([]);
        setJoinRoomDisplay("room");
        setTxtGroupId(null);
        setCurrentRoom(null);
        setIsUserInChatRoom(false);
        inputFocusButtonKonusmaListesi(false);
        setErrorMessages([]);
        setIsReply(false);
        setMsg(null);
        setTxtMessage("");
        setContextMenuVisible(false);
    };
    const UyeProfilDetayModalAc = (id) => {
        if (token) {
            setUyeProfilDetayModal(id);
        }
    };
    const inputFocusButtonKonusmaListesi = (e) => {
        inputFocusButton(e);
    };
    const messageDetayIslem = async (id, msg) => {
        setMesajDetayModal(id);

        if (msg?.readByDetails) {
            try {
                const readByDetails = msg.readByDetails;
                setTiklananMesajDetay(msg);

                const userDetailsPromises = readByDetails.map(async (detail) => {
                    const response = await axios.get(`customer/explore/${detail.userId}`);
                    const userDetail = response.data.data.customer;
                    const readAtDate = new Date(detail.readAt);
                    const readAtFormatted = `${readAtDate
                        .getDate()
                        .toString()
                        .padStart(2, "0")}/${(readAtDate.getMonth() + 1)
                        .toString()
                        .padStart(2, "0")}/${readAtDate.getFullYear()} ${readAtDate
                        .getHours()
                        .toString()
                        .padStart(2, "0")}:${readAtDate
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}`;

                    return {
                        userId: userDetail.id,
                        first_name: userDetail.first_name,
                        last_name: userDetail.last_name,
                        profile_image: userDetail.media
                            ? `${userDetail.media[0]?.url}${userDetail.media[0]?.file_name}`
                            : null,
                        readAt: readAtFormatted,
                    };
                });

                const userDetails = await Promise.all(userDetailsPromises);

                setReadByUsers(userDetails);
            } catch (error) {
                console.error("Error fetching user details", error);
            }
        }
    };
    const handleScrollPosition = () => {
        const currentScroll = document.getElementById("messageContainer").scrollTop;
        const threshold =
            document.getElementById("messageContainer").scrollHeight * 0.1; // %10
        setShowScrollButton(currentScroll < threshold);
    };
    const deleteMessage = (userId, type, eventId) => {
        if (token) {
            const dataToSend = {
                customer_id: parseInt(userId),
                type: type,
                event_id: parseInt(eventId),
            };

            axios
                .post(`customer/chat/delete`, dataToSend)
                .then((res) => {
                    showToast(res.data.messages);
                    if (res.data.status === 1) {

                    }
                })
                .catch((err) => {
                    if (err.response) {
                        console.log(err.response.data.messages);
                    } else {
                        console.log([{type: "error", message: err.message, second: 5}]);
                    }
                });
        }
    };
    const endMessage = (userId, type, eventId) => {
        if (token) {
            const dataToSend = {
                customer_id: parseInt(userId),
                type: type,
                event_id: parseInt(eventId),
            };

            axios
                .post(`customer/chat/end`, dataToSend)
                .then((res) => {
                    showToast(res.data.messages);
                    if (res.data.status === 1) {
                        setModal("modalKapatId");
                        setGeciciUserIdBilgisi(null);
                        setGeciciTypeBilgisi(null);
                        setGeciciEventIdBilgisi(null);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        console.log(err.response.data.messages);
                    } else {
                        console.log([{type: "error", message: err.message, second: 5}]);
                    }
                });
        }
    };
    const digerIslem = (userId, type, eventId, modalId) => {
        setModal(modalId);
        setGeciciUserIdBilgisi(userId);
        setGeciciTypeBilgisi(type);
        setGeciciEventIdBilgisi(eventId);
    };
    const trailingActions = (userId, type, eventId) => (
        <TrailingActions>
            <SwipeAction onClick={() => !token ? null : digerIslem(userId, type, eventId, "diger-modal-id")}>
                <div key={userId} style={{backgroundColor: "#9fa1a4"}} className="flex flex-col justify-center items-center">
                    <FontAwesomeIcon icon={faEllipsis} className="fa-xl" style={{color: "#fff", paddingRight: 30, paddingLeft: 30}}/>
                    <span className="sohbetListesiDigerTextStyle tiklanmaEngelle">
                        {" "}{t("diger")}{" "}
                    </span>
                </div>
            </SwipeAction>

            <SwipeAction onClick={() => (!token ? null : deleteMessage(userId, type, eventId))}>
                <div key={userId} style={{backgroundColor: "#D1242A"}} className="flex justify-center items-center">
                    <FontAwesomeIcon icon={faTrash} className="fa-xl" style={{color: "#fff", paddingRight: 30, paddingLeft: 30}}/>
                </div>
            </SwipeAction>
        </TrailingActions>
    );
    const tokensizGirisButonu = (uyarilar) => {
        girisYapUyariModal(uyarilar);
    };

    const roomComponents = useMemo(() => {
        return roomList && roomList?.map((room) => {
            const userId = parseInt(user?.id); // Giriş yapan kullanıcının ID'si
            const otherUsers = Array.isArray(room?.users)
                ? room?.users?.filter(
                    (user) => user?.userId !== userId
                )
                : [];
            let timeString = ""; // Varsayılan olarak boş bir string tanımla

            // lastMessageTimestamp tanımlıysa, dönüşümü yap
            if (room?.lastMessageTimestamp) {
                // createdDate'i Date objesine çevir
                const messageDate = new Date(
                    room?.lastMessageTimestamp
                );
                // Saat formatını belirle
                timeString = messageDate?.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                });
            }

            const userControl = room?.users.find(
                (u) => u.userId === parseInt(userId)
            );

            if (
                userControl?.deleteAt &&
                room?.lastMessageTimestamp < userControl?.deleteAt
            ) {
                room.lastMessage = "msj_ulasilmiyor";
            } else if (!room.lastMessage) {
                room.lastMessage = "msj_yok";
            }

            // Diğer kullanıcıların isOnline durumunu kontrol et
            const otherUser = room.users.find(user => user.userId !== userId);
            let borderColor = "";
            if (room.type === "match") {
                borderColor = otherUser?.isOnline === true ? "#44da80" : "#EB3428";
                // borderColor = otherUser?.isOnline === null ? "" : otherUser?.isOnline === true ? "#44da80" : "#EB3428";
            }

            return (
                <SwipeableListItem key={room?.id}
                                   threshold={0.1}
                                   listType={ListType.IOS}
                                   trailingActions={trailingActions(
                                       otherUsers[0]?.userId,
                                       room?.type,
                                       room?.event_id)}>
                    <div style={{width: "100%", height: 70}} key={room?.id}
                         className="flex border-b-2 cursorPointer buttonTextStyle1111 sohbetiSonlandirHoverStyle">
                        <div className="flex" style={{width: isMobile ? "calc(100% - 80px)" : "calc(100% - 120px)"}}
                             onClick={() => joinGame(room)}>
                            <div style={{width: 57, height: "100%"}}
                                 className="flex justify-content-center align-items-center tiklanmaEngelle">
                                <div className="flex justify-content-center align-items-center"
                                     style={{
                                         width: "100%",
                                         height: 57,
                                         borderWidth: 2,
                                         borderColor: borderColor,
                                         borderRadius: 100,
                                     }}>
                                    <img className="mesajlarProfilFotoStyle1" src={room?.image} alt=""/>
                                </div>
                            </div>
                            <div style={{width: "calc(100% - 57px)", height: "100%"}}
                                 className="flex flex-col justify-content-center tiklanmaEngelle">
                                <div className="mesajlarProfilTextStyle1 ml-2">
                                    {room?.name}
                                </div>
                                <div className="ml-2 sonMesajTextStyle" id={`${room.id}_last_message`}>
                                    {room?.lastMessage === "msj_ulasilmiyor" ? (
                                        <FontAwesomeIcon icon={faCommentSlash} className="fa-lg"
                                                         style={{color: "#21262E", opacity: 0.7}}/>
                                    ) : room?.lastMessage === "msj_yok" ? (
                                        <FontAwesomeIcon icon={faInbox} className="fa-lg"
                                                         style={{color: "#21262E", opacity: 0.7}}/>
                                    ) : (
                                        <>
                                            {room?.lastMessageRead && parseInt(room?.lastMessageSender, 10) === parseInt(user?.id, 10) && (
                                                <FontAwesomeIcon icon={faEye} style={{color: "#EB3428", opacity: 0.7}}/>
                                            )}

                                            {parseInt(room?.lastMessageSender, 10) !== parseInt(user?.id, 10) &&
                                                !room?.readBy?.includes(
                                                    parseInt(user?.id, 10)
                                                ) && (
                                                    <FontAwesomeIcon
                                                        icon={faEnvelope}
                                                        className="fa-lg"
                                                        style={{
                                                            color: "#EB3428",
                                                            opacity: 0.7,
                                                        }}
                                                    />
                                                )}

                                            {room?.readBy?.includes(parseInt(user?.id, 10)) &&
                                                parseInt(room?.lastMessageSender, 10) !== parseInt(user?.id, 10) && (
                                                    <FontAwesomeIcon
                                                        icon={faEnvelopeOpen}
                                                        className="fa-lg"
                                                        style={{
                                                            color: "#21262E",
                                                            opacity: 0.7,
                                                        }}
                                                    />
                                                )}

                                            {!room?.lastMessageRead && room?.readBy?.includes(parseInt(user?.id, 10)) &&
                                                parseInt(room?.lastMessageSender, 10) === parseInt(user?.id, 10) && (
                                                    <FontAwesomeIcon
                                                        icon={faEyeSlash}
                                                        style={{
                                                            color: "#21262E",
                                                            opacity: 0.7,
                                                        }}
                                                    />
                                                )}

                                            {!(
                                                (room?.lastMessageRead && parseInt(room?.lastMessageSender, 10) === parseInt(user?.id, 10)) ||
                                                (parseInt(room?.lastMessageSender, 10) !== parseInt(user?.id, 10) &&
                                                    !room?.readBy?.includes(parseInt(user?.id, 10))) ||
                                                (room?.readBy?.includes(parseInt(user?.id, 10)) &&
                                                    parseInt(room?.lastMessageSender, 10) !== parseInt(user?.id, 10)) ||
                                                (!room?.lastMessageRead && room?.readBy?.includes(parseInt(user?.id, 10)) &&
                                                    parseInt(room?.lastMessageSender, 10
                                                    ) === parseInt(user?.id, 10))
                                            ) && (
                                                <FontAwesomeIcon
                                                    icon={faBug}
                                                    style={{
                                                        color: "#EB3428",
                                                        opacity: 0.7,
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}

                                    {room?.lastMessage === "msj_ulasilmiyor" || room?.lastMessage === "msj_yok" ? null : (
                                        <span className={`${room?.lastMessage === "msj_ulasilmiyor" || room?.lastMessage === "msj_yok" ? "" : "ml-1"}`}>
                                          {timeString}
                                        </span>
                                    )}

                                    <span className={`${
                                            room?.lastMessage ===
                                            "msj_ulasilmiyor" ||
                                            room?.lastMessage === "msj_yok"
                                                ? "sonMesajTextStyle1 ml-1"
                                                : "ml-1"
                                        }`}>
                                      {room?.lastMessage ===
                                      "msj_ulasilmiyor" ? (
                                          t("mesaj_bulunamadi")
                                      ) : room?.lastMessage === "msj_yok" ? (
                                          t("henuz_mesaj_yok")
                                      ) : (
                                          <>{room?.lastMessage}</>
                                      )}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/*<div style={{width: 80, height: "100%"}}*/}
                        {/*     className="flex justify-center items-center sliderButton">*/}
                        {/*    <FontAwesomeIcon icon={faAngleRight} className="fa-xl px-3 py-3"/>*/}
                        {/*</div>*/}
                    </div>
                </SwipeableListItem>
            );
        })
    }, [roomList, user?.id, trailingActions, t]);
    const handleContextMenuShow = (msg) => {
        setContextMenuVisible(true);
        setMsg(msg);
    };
    const handleContextMenuHide = () => {
        setContextMenuVisible(false);
        setMsg(null);
    };
    const editButton = (id) => {
        setIsEdited(id);
        setContextMenuVisible(false);
    }
    const showEditedMessageButton = (id) => {
        setShowEditedMessageId(id === showEditedMessageId ? null : id);
    }
    // Mesajı düzenleme işlemleri
    const handleEditMessage = () => {
        if (token) {
            if (socketRef.current) {
                if (!txtMessage.trim()) {
                    setError(t("bos_mesaj_gonderemezsiniz"));

                    setTimeout(() => {
                        setError("");
                    }, 1000);
                } else {
                    const newMessage = {
                        editMessageId: msg?.id,
                        editMessage: txtMessage,
                        userId: user?.id,
                        group_id: txtGroupId,
                        username: `${user.first_name} ${user.last_name}`
                    };

                    socketRef.current.emit('edit_message', newMessage);

                    setIsEdited(false);
                    setMsg(null);
                    setTxtMessage("");
                }
            }
        }
    };
    // Mesajı silme işlemleri
    const handleDeleteMessage = (msg) => {
        socketRef.current.emit('delete_message', {msg});
        setContextMenuVisible(false);
        setError("mesaj silindi.");
        setTimeout(() => {
            setError("");
        }, 1000);
    };
    // Mesajı kopyalama işlemleri
    const handleCopyMessage = () => {
        setContextMenuVisible(false);
        setError("mesaj kopyalandı.");
        setTimeout(() => {
            setError("");
        }, 1000);
    };
    const replyButton = (id) => {
        setIsReply(id);
        setContextMenuVisible(false);
    }
    const handleReplyMessage = () => {
        if (token) {
            if (socketRef.current) {
                if (!txtMessage.trim()) {
                    setError(t("bos_mesaj_gonderemezsiniz"));

                    setTimeout(() => {
                        setError("");
                    }, 1000);
                } else {
                    const newMessage = {
                        replyMessageId: msg?.id,
                        replyMessage: txtMessage,
                        userId: user?.id,
                        group_id: txtGroupId,
                        username: `${user.first_name} ${user.last_name}`
                    };

                    socketRef.current.emit('reply_message', newMessage);

                    setIsReply(false);
                    setMsg(null);
                    setTxtMessage("");
                }
            }
        }
    };

    const gelenMesajlarDivStyle = {
        width: isMobile ? width : width <= 475 ? width : 425,
        height:
            focusKonusmaListesiInput === true
                ? isMobile
                    ? height - 48
                    : height <= 795
                        ? height - 48
                        : 788 - 48
                : isMobile
                    ? height - 96
                    : height <= 795
                        ? height - 96
                        : 788 - 96,
    };
    const gelenMesajlarDivStyle1 = {
        width: "100%",
        height: isMobile ? height - 147 : height <= 795 ? height - 147 : 788 - 147,
    };
    const gelenMesajlarDivStyle2 = {
        height:
            focusKonusmaListesiInput === true
                ? isMobile
                    ? height - 150
                    : height <= 795
                        ? height - 150
                        : 788 - 150
                : isMobile
                    ? height - 198
                    : height <= 795
                        ? height - 198
                        : 788 - 198,
    };
    const etkinlikOlusturModalStyle = {
        width: "100%",
        height: "100%",
        backgroundColor: "#fbfafa",
    };
    const halfBottomModal1 = {
        width: "100%",
        height: "40%",
        position: "absolute",
        zIndex: 55555,
        bottom: 0,
    };
    const halfBottomModal2 = {
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
    };
    const mesajDetayHalfModal = {
        width: "100%",
        height: "80%",
        position: "absolute",
        zIndex: 55555,
        bottom: 0
    };

    const [{ y }, api] = useSpring(() => ({ y: 0 }));

    const bind = useDrag(({ args: [id], movement: [, my], velocity, last }) => {
        const allowedIds = ["profil-detay-modal-id", "message-info-modal-id", "diger-modal-id"];
        // Eşik değeri (80 piksel)
        const threshold = 80;

        setKaydirilanDivId(id);

        if (last) {
            // Eşik değerini geçip geçmediğine bakıyoruz
            if (my > threshold || velocity > 0.05) {
                if (allowedIds.includes(kaydirilanDivId)) {
                    api.start({ y: 1000 }); // Modalı ekrandan çıkar
                    setTimeout(() => {
                        api.start({ y: 0 }); // y pozisyonunu sıfırla
                        if (uyeProfilDetayModal === "profil-detay-modal-acik") {
                            if (etkinlikUyeProfiliAcikMi === true && currentRoom?.type === "event") {
                                setTiklananUyeId(null);
                                setUyeProfilDetayModal("profil-detay-modal-acik");
                                setEtkinlikUyeProfiliAcikMi(false);
                            } else {
                                setUyeProfilDetayModal("profil-detay-modal-kapali");
                            }
                        } else if (mesajDetayModal === "message-info-modal-acik") {
                            setMesajDetayModal("message-info-modal-kapali");
                        } else if (modal === "diger-modal-id") {
                            setModal("modalKapatId");
                        }
                    }, 300); // Animasyon süresi
                }
            } else {
                api.start({ y: 0 }); // Modalı eski yerine getir
            }
        } else {
            if (my > 0) {
                // Eşik değerini aşana kadar modalı parmak hareketine göre kaydır
                if (my < threshold) {
                    api.start({ y: my }); // Modal parmağı takip eder
                } else {
                    // Eşiği geçtikten sonra modal yavaşça kayar
                    api.start({ y: threshold + (my - threshold) * 0.2 });
                }
            }
        }
    });



    const [showIcon, setShowIcon] = useState(null);

    const [{ x1, scale, opacity }, api1] = useSpring(() => ({
        x1: 0,
        scale: 1,
        opacity: 0,
        config: { tension: 300, friction: 30 },
    }));

    const bind2 = useDrag(({ movement: [mx], velocity, last }) => {
        const threshold = 80; // Tamamlama eşiği (px cinsinden)

        if (!last) {
            // Yalnızca sağa kaydırma işlemi
            if (mx > 0) {
                setShowIcon(faAngleLeft); // Sağ kaydırma için ikonu göster
                api1.start({
                    x1: mx,
                    scale: 1 + Math.min(mx / 200, 0.5), // Kaydırılan mesafeye göre büyüme
                    opacity: Math.min(mx / 100, 1), // Opaklık arttırılır
                });
            }
        } else {
            // Kaydırma işlemi tamamlandığında
            if (mx > threshold || velocity > 0.5) {
                // Kaydırma belirli bir mesafeyi geçerse (örneğin 100px)
                api1.start({ x1: mx, scale: 1.5, opacity: 1 });
                setTimeout(() => {
                    // İkonu kaydırma sonrası tekrar gizlemek için (örneğin bir işlem tamamlandığında)
                    api1.start({ x1: 0, scale: 1, opacity: 0 });
                    setShowIcon(null);
                    if (token) {
                        if (txtGroupId) {
                            socketRef.current.emit("leave_chat", txtGroupId); // Sohbetten ayrılış olayını emit edin
                        }
                    }
                    setMesajDetayModal("message-info-modal-kapali");
                    setTxtMessage("");
                    setMessageList([]);
                    setJoinRoomDisplay("room");
                    setTxtGroupId(null);
                    setCurrentRoom(null);
                    setIsUserInChatRoom(false);
                    inputFocusButtonKonusmaListesi(false);
                    setErrorMessages([]);
                    setIsReply(false);
                    setMsg(null);
                    setTxtMessage("");
                    setContextMenuVisible(false);
                }, 300);
            } else {
                // Kaydırma mesafesi yeterli değilse işlemi iptal et
                api1.start({ x1: 0, scale: 1, opacity: 0 });
                setShowIcon(null);
            }
        }
    }, { axis: 'x' });

    const contextMenuClass = useMemo(() => {
        return contextMenuVisible ? 'context-menu open' : 'context-menu close';
    }, [contextMenuVisible]);

    const modalSpring = useSpring({
        transform: mesajDetayModal === "message-info-modal-acik" ||  modal === "diger-modal-id" || uyeProfilDetayModal === "profil-detay-modal-acik" ? "translateY(0%)" : "translateY(100%)",  // Açılma ve kapanma animasyonu
        opacity: mesajDetayModal === "message-info-modal-acik" ||  modal === "diger-modal-id" || uyeProfilDetayModal === "profil-detay-modal-acik" ? 1 : 0,
        config: { tension: 300, friction: 30 }, // Animasyon hızını ve ataletini ayarlar
    });

    return (
        <div style={gelenMesajlarDivStyle} className="profilDetayShadow">
            {!token ? (
                <div style={{width: "100%", height: "100%", position: "absolute", zIndex: 555}}>
                    <div style={{width: "100%", height: "100%"}} className="flex flex-column">
                        <div style={{height: 51, backgroundColor: "#fff", borderRightWidth: 1, borderLeftWidth: 1}}
                             className="flex BegeniDivStyle shadow-sm">
                            <div style={{width: "85%", height: "100%"}} className="flex justify-start items-center">
                                <span className="MessageSpanStyle ml-5 tiklanmaEngelle">
                                    {t("mesajlar")}
                                </span>
                            </div>
                            <div style={{width: "15%", height: "100%"}}
                                 onClick={() => !token ? null : socketRef.current.emit("all_chat_groups")}
                                 className="flex yenileButtonuStyle justify-center items-center">
                                <FontAwesomeIcon icon={faRotateRight} className="fa-xl"/>
                            </div>
                        </div>

                        <div style={gelenMesajlarDivStyle1} className="flex flex-column justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faComments} className="fa-xl" style={{color: "#ddd"}}/>
                            <div className="begenenlerTextStyle1 tiklanmaEngelle">
                                {t("sohbetlerin_burada_gorunecek")}
                            </div>

                            <div onClick={() => tokensizGirisButonu(t("sohbet_baslatabilmek_veya_sohbetlerinize_erismek_icin_lutfen_oturum_acin"))}
                                 style={{width: 180, height: 40}}
                                 className="flex justify-center items-center cursorPointer etkinlikOlusturButtonStyle">
                                <span className="etkinlikOlusturButtonTextStyle tiklanmaEngelle">
                                    {t("oturum_ac_button_text")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div style={{width: "100%", height: "100%", position: "absolute", zIndex: 555}}>
                        {joinRoomDisplay === "room" ? (
                            <div style={{width: "100%", height: "100%"}} className="flex flex-column">
                                <div style={{height: 51, backgroundColor: "#fff", borderRightWidth: 1, borderLeftWidth: 1}}
                                     className="flex BegeniDivStyle shadow-sm">
                                    <div style={{width: "85%", height: "100%"}} className="flex justify-start items-center">
                                        <span className="MessageSpanStyle ml-5 tiklanmaEngelle">
                                            {t("mesajlar")}
                                        </span>
                                    </div>

                                    <div style={{width: "15%", height: "100%"}}
                                         onClick={() => !token ? null : socketRef.current.emit("all_chat_groups")}
                                         className="flex yenileButtonuStyle justify-center items-center">
                                        <FontAwesomeIcon icon={faRotateRight} className="fa-xl"/>
                                    </div>
                                </div>
                                {!roomList ? (
                                    <div style={gelenMesajlarDivStyle1} className="flex flex-column justify-content-center align-items-center">
                                        <FontAwesomeIcon icon={faComments} className="fa-xl" style={{color: "#ddd"}}/>
                                        <div className="begenenlerTextStyle1 tiklanmaEngelle">
                                            {t("sohbetlerin_burada_gorunecek")}
                                        </div>
                                    </div>
                                ) : (
                                    <div style={gelenMesajlarDivStyle1} className="px-1 active-scroll">
                                        {roomComponents}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div style={{width: "100%", height: "100%"}}>
                                {/*header kısmı*/}
                                <div style={{
                                    width: "100%",
                                    height: 51,
                                    backgroundColor: "#fff",
                                    borderRightWidth: 1,
                                    borderLeftWidth: 1,
                                }} className="flex boxShadowBottom">
                                            <div style={{width: 50, height: "100%"}} className="flex justify-center items-center">
                                                    <button onClick={() => geriButton()} className="arrow px-3 py-2">
                                                        <FontAwesomeIcon icon={faAngleLeft} className="sliderButton cursorPointer fa-xl"/>
                                                    </button>
                                                </div>
                                            <div style={{width: "calc(100% - 50px)", height: "100%"}}
                                                 onClick={() => token && UyeProfilDetayModalAc("profil-detay-modal-acik")}
                                                 className="flex justify-center items-center h-100 cursorPointer tiklanmaEngelle">
                                                    <div style={{width: "calc(100% - 80px)", height: "100%"}} className="flex">
                                                        <div style={{width: 40, height: "100%"}} className="flex justify-center items-center">
                                                            <div className={`flex justify-center items-center ${currentRoom && onlineUsers[currentRoom.id] ? "typingAnimation" : ""}`}
                                                                 style={{width: 37, height: 37, borderRadius: "50%", borderColor: isUserInChatRoom ? "#44da80" : "#EB3428", borderStyle: "solid"}}>
                                                                <img className="mesajlarProfilFotoStyle122" src={txtRoomImage} alt=""/>
                                                            </div>
                                                        </div>
                                                        <div style={{width: "calc(100% - 40px)", height: "100%"}} className="flex flex-col justify-center">
                                                <span className="mesajlarProfilTextStyle1">
                                                    {txtRoomName}
                                                </span>
                                                            {currentRoom && onlineUsers[currentRoom.id] && (
                                                                <span className="modalBilgiTextStyleMobile tiklanmaEngelle">
                                                        {onlineUsers[currentRoom.id]}
                                                    </span>
                                                            )}

                                                            {typingMessage && (
                                                                <span className="modalBilgiTextStyleMobile tiklanmaEngelle">
                                                        {typingMessage}
                                                    </span>
                                                            )}

                                                        </div>
                                                    </div>

                                                    <div style={{width: 80, height: "100%"}}></div>
                                                </div>
                                        </div>

                                {/*mesajların listelendiği kısım   style={{marginBottom: typingMessage ? 50 : 0}} `*/}
                                <animated.div  {...bind2()} style={{x1, touchAction: 'none'}}>
                                    <div className="flex flex-column justify-center items-center" style={gelenMesajlarDivStyle2}>
                                                {messageList.length <= 0 ? (
                                                    <div className="flex flex-col justify-center items-center">
                                                        <div className="mb-3">
                                                            <FontAwesomeIcon icon={faMessage} className="fa-3x" style={{color: "#ddd", opacity: 0.5}}/>
                                                        </div>
                                                        <div className="mesajlarHenuzMesajYokTextStyle tiklanmaEngelle">
                                                            {t("henuz_mesaj_yok")}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{width: "100%", height: "100%"}}
                                                         className="active-scroll pl-2 pr-2"
                                                             id="messageContainer" onScroll={handleScrollPosition}>
                                                            <div style={{width: "100%", height: "calc(100% - 30px)"}}>
                                                                {!showLoadMoreButton && (
                                                                    <div style={{width: "100%", height: 30}}
                                                                         className="flex justify-center items-center"
                                                                         onClick={!isLoadingMoreMessages ? loadMoreMessages : null}>
                                                                        {isLoadingMoreMessages ? (
                                                                            <>
                                                                                <FontAwesomeIcon
                                                                                    icon={faSpinner}
                                                                                    className="fa-sm mr-2 fa-spin-pulse"
                                                                                    color={"#21262E"}
                                                                                    style={{opacity: 0.7}}
                                                                                />
                                                                                <span className="txtStyle tiklanmaEngelle"
                                                                                      style={{textTransform: "capitalize"}}>
                                                                        {t("yukleniyor")}
                                                                    </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <FontAwesomeIcon
                                                                                    icon={faCloudArrowDown}
                                                                                    className="fa-sm mr-2"
                                                                                    color={"#21262E"}
                                                                                    style={{opacity: 0.7}}
                                                                                />
                                                                                <span className="txtStyle tiklanmaEngelle"
                                                                                      style={{textTransform: "capitalize"}}>
                                                                        {t("daha_fazla_mesaj_yukle")}
                                                                    </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                )}
                                                                {messageList.length > 0 &&
                                                                    messageList.map((msg, index) => {
                                                                        let previousUsername = null;
                                                                        if (index > 0) {
                                                                            previousUsername = messageList[index - 1].username;
                                                                        }
                                                                        let showUsername =
                                                                            msg?.username !== previousUsername;

                                                                        let timeString = ""; // Varsayılan olarak boş bir string tanımla

                                                                        // lastMessageTimestamp tanımlıysa, dönüşümü yap
                                                                        if (msg?.createdDate) {
                                                                            // createdDate'i Date objesine çevir
                                                                            const messageDate = new Date(msg?.createdDate);
                                                                            // Saat formatını belirle
                                                                            timeString = messageDate.toLocaleTimeString(
                                                                                [],
                                                                                {
                                                                                    hour: "2-digit",
                                                                                    minute: "2-digit",
                                                                                }
                                                                            );
                                                                        }

                                                                        return (
                                                                            <div className="flex flex-column" key={msg?.id}>
                                                                                {showUsername && (
                                                                                    <div
                                                                                        className={`mt-3 mb-1 ${parseInt(msg?.userId) === parseInt(user?.id)
                                                                                            ? "gidenMesajlarKullaniciStyle pr-1" : "gelenMesajlarKullaniciStyle pl-1 "} tiklanmaEngelle`}>
                                                                                        {msg?.username}
                                                                                    </div>
                                                                                )}

                                                                                <ContextMenuTrigger  id={`contextmenu_${msg.id}`} collect={() => handleContextMenuShow(msg)}>
                                                                                    <div className={`${parseInt(msg?.userId) === parseInt(user?.id) ? "flex flex-col items-end" : "flex flex-col items-start"}`}>
                                                                                        {msg?.edited && (
                                                                                            <div style={{maxWidth: "70%"}} className={`flex flex-col ${parseInt(msg?.userId) === parseInt(user?.id) ? "items-end" : "items-start" }  tiklanmaEngelle mt-1`} onClick={() => showEditedMessageButton(msg?.id)}>
                                                                                                <div className={`flex items-center ${parseInt(msg?.userId) === parseInt(user?.id) ? "mr-1" : "ml-1"}`}>
                                                                                                    <FontAwesomeIcon icon={faPenToSquare} style={{width: 10, height: 10, color: "#57c6d9", opacity: 0.7}} className="mr-1"/>
                                                                                                    <span className="editMessageTextStyle">düzenlendi. {showEditedMessageId === msg.id ? "Gizle" : "Gör"}</span>
                                                                                                </div>
                                                                                                {showEditedMessageId === msg.id && (
                                                                                                    <div className={`editMessageTextStyle boxShadowTop boxShadowBottom`} style={{backgroundColor: "#fff", borderRadius: 8, padding: 7}}>
                                                                                                        {msg?.message}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        )}

                                                                                        <div key={msg?.id} className={`flex flex-col p-2 mb-1 mt-1 ${parseInt(msg?.userId) === parseInt(user?.id) ? "gidenMesajlarStyle pr-2" : "gelenMesajlarStyle pl-2"} tiklanmaEngelle`}>
                                                                                            {msg?.replyMessage && (
                                                                                                <div className={`mb-1 ${parseInt(msg?.userId) === parseInt(user?.id)
                                                                                                    ? "flex justify-end" : "flex justify-start"}`}>
                                                                                                    <div className="flex flex-col px-2 py-1" style={{width: "100%", backgroundColor: "#cecece80", fontSize: 12,
                                                                                                        borderTopLeftRadius: parseInt(msg?.userId) === parseInt(user?.id) ? 8 : 12,
                                                                                                        borderTopRightRadius: parseInt(msg?.userId) === parseInt(user?.id) ? 12 : 8,
                                                                                                        borderBottomLeftRadius: parseInt(msg?.userId) === parseInt(user?.id) ? 8 : 0,
                                                                                                        borderBottomRightRadius: parseInt(msg?.userId) === parseInt(user?.id) ? 0 : 8}}>
                                                                                        <span className="mapMessageReplyNameText">
                                                                                            {msg.replyMessage.username}
                                                                                        </span>
                                                                                                        <span className="mapMessageReplyText">
                                                                                            {msg?.replyMessage?.edited ? msg?.replyMessage?.editContent : msg.replyMessage.content}
                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}


                                                                                            {msg?.edited ? msg?.editContent : msg?.message}
                                                                                            <div onClick={() => messageDetayIslem("message-info-modal-acik", msg)}
                                                                                                 className={`flex justify-end ${parseInt(msg?.userId) === parseInt(user?.id) ? "mt-1" : "mt-0"}`}>
                                                                                                {parseInt(msg?.userId) === parseInt(user?.id) && (
                                                                                                    <>
                                                                                        <span className="mr-2">
                                                                                            {timeString}
                                                                                        </span>

                                                                                                        <div onClick={() => messageDetayIslem("message-info-modal-acik", msg)}>
                                                                                                            {msg?.readMessage ? (
                                                                                                                <span className="read-notice">
                                                                                                    <FontAwesomeIcon icon={faEye} style={{color: "#EB3428", opacity: 0.7}}/>
                                                                                                </span>
                                                                                                            ) : (
                                                                                                                <span className="read-notice">
                                                                                                    <FontAwesomeIcon icon={faEyeSlash} style={{color: "#21262E", opacity: 0.7}}/>
                                                                                                </span>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </ContextMenuTrigger>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                <div ref={messagesEndRef}/>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* <div style={{width: "100%", position: "absolute", zIndex: 10, bottom: 55}}>
                                                {typingMessage && ( <span> {typingMessage} </span>)}
                                                </div> */}
                                    {showScrollButton && (
                                        <div className="flex justify-center items-center"
                                             style={{width: 40, height: 40, position: "absolute", zIndex: 10, bottom: 65, left: "45%"}}>
                                            <div className="flex justify-center items-center boxShadowTop boxShadowBottom"
                                                 onClick={scrollToBottom}
                                                 style={{width: 35, height: 35, backgroundColor: "#fbfafa", borderRadius: 100, borderWidth: 1}}>
                                                <FontAwesomeIcon icon={faChevronDown} className="fa-xl" style={{color: "#EB3428"}}/>
                                            </div>
                                        </div>
                                    )}
                                </animated.div>

                                {/*mesaj yazma inputu ve gönder butonu kısmı*/}
                                <div style={{width: "100%", height: 51, backgroundColor: "transparent", borderRightWidth: 1, borderLeftWidth: 1}} className="flex boxShadowTop">
                                                {errorMessages === 'group_none' ? (
                                                    <div style={{width: "100%"}} className="flex align-items-center">
                                                        <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight}
                                                                         style={{color: "#21262E", opacity: 1}} className="mr-2 ml-2 fa-lg"/>
                                                        <div className="flex flex-column" style={{width: "100%"}}>
                                                <span className="bugText" style={{fontSize: 14, opacity: 0.80}}>
                                                    {txtRoomName} {t("sohbeti_sonlandırdı")}
                                                </span>
                                                            <span className="bugText" style={{fontSize: 10, opacity: 0.60}}>
                                                    {t("mesajin_karsi_tarafa_iletilmeyecek")}
                                                </span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="flex py-1"
                                                             style={{width: "calc(100% - 65px)", height: "100%"}}>
                                                            <input
                                                                style={{
                                                                    backgroundColor: "#fff",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    zIndex: 1,
                                                                    position: "relative",
                                                                }}
                                                                className="mesajlarInputStyle1 focus:outline-none pl-2 ml-2"
                                                                value={txtMessage}
                                                                onChange={(e) => setTxtMessage(e.target.value)}
                                                                placeholder={error ? "" : t("mesaj_yaz")}
                                                                maxLength={200}
                                                                autoCapitalize="none"
                                                                onKeyDown={(e) => enterGonder(e, isReply ? "reply" : isEdited ? "edited" : "txtMessage")}
                                                                onFocus={() => inputFocusButtonKonusmaListesi(true)}
                                                            />

                                                            {isReply || isEdited ? (
                                                                <div className="flex justify-around items-center" style={{position: "absolute", zIndex: 222, width: "100%", bottom: 55}}>
                                                                    <div className="flex items-center pl-2 context-menu-item-style" style={{width: "calc(95% - 65px)", height: 40, backgroundColor: "#ffffffe0", borderRadius: 8}}>
                                                                        <div>
                                                                            {isEdited && (
                                                                                <FontAwesomeIcon className="sliderButton fa-lg" icon={faPenToSquare}/>
                                                                            )}

                                                                            {isReply && (
                                                                                <FontAwesomeIcon className="sliderButton fa-lg" icon={faReply}/>
                                                                            )}
                                                                        </div>
                                                                        <div className="flex flex-col ml-3">
                                                                            <span className="mapMessageReplyNameText">{msg?.username}</span>
                                                                            <span className="mapMessageReplyText">{msg?.edited ? msg?.editContent : msg?.message}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div onClick={() => {isReply ? replyButton(false) : isEdited && editButton(false)}}
                                                                         style={{width: 65, backgroundColor: "#ffffffe0", height: 40, borderRadius: 8}}
                                                                         className="flex justify-center items-center py-1 btn border-none context-menu-item-style">
                                                                        <FontAwesomeIcon className="sliderButton fa-lg" icon={faXmark}/>
                                                                    </div>
                                                                </div>
                                                            ) : null}

                                                            {error && (
                                                                <div className="mx-3 my-2"
                                                                     style={{position: "absolute", zIndex: 10}}>
                                                        <span className="konusmaListesiHataMesajiYaziStyle">
                                                            {error}
                                                        </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="flex justify-center items-center"
                                                             style={{width: 65, height: "100%"}}>
                                                            <div style={{width: "85%", height: "80%"}}
                                                                 className="cursorPointer flex justify-content-center align-items-center mesajlarButtonStyle1"
                                                                 onClick={isReply === true ? handleReplyMessage : isEdited === true ? handleEditMessage : sendMessage}>
                                                                <FontAwesomeIcon
                                                                    icon={faPaperPlane}
                                                                    className={`mesajlarGonderButtonStyle1 sliderButton ${
                                                                        txtMessage === "" ? "mesajGonder1" : "mesajGonder2"
                                                                    }`}/>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                {showIcon && (
                                    <animated.div style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: x1.to(x => `${x}px`),
                                        transform: 'translate(-50%, -50%)',
                                        scale,
                                        opacity,
                                    }}>
                                        <FontAwesomeIcon icon={showIcon} style={{ fontSize: '1.4rem', color: "#EB3428" }} />
                                    </animated.div>
                                )}
                            </div>
                        )}
                    </div>

                    {contextMenuVisible && (
                        <div id={`contextmenu_${msg.id}`} className={contextMenuClass}>
                            {parseInt(msg?.userId) === parseInt(user?.id) ? (
                                <div id={`contextmenu_${msg?.id}`} className="flex flex-row justify-around items-center" style={{width: "100%", height: "100%", backgroundColor: "#fff"}}>
                                    <MenuItem onClick={() => editButton(true)} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faEdit}/>
                                        <span>{t("Düzenle")}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleDeleteMessage(msg)} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faTrash}/>
                                        <span>{t("Sil")}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleCopyMessage()} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faCopy}/>
                                        <span>{t("kopyala")}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => replyButton(true)} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faReply}/>
                                        <span>{t("cevapla")}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleContextMenuHide()} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                        <span>{t("kapat")}</span>
                                    </MenuItem>
                                </div>
                            ) : (
                                <div id={`contextmenu_${msg?.id}`} className="flex flex-row justify-around items-center" style={{width: "100%", height: "100%", backgroundColor: "#fff"}}>
                                    <MenuItem onClick={() => handleCopyMessage()} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faCopy} />
                                        <span>{t("kopyala")}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => replyButton(true)} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faReply} />
                                        <span>{t("cevapla")}</span>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleContextMenuHide()} className="flex flex-col btn border-none messageButtonsStyle">
                                        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>
                                        <span>{t("kapat")}</span>
                                    </MenuItem>
                                </div>
                            )}
                        </div>
                    )}

                    {uyeProfilDetayModal === "profil-detay-modal-acik" && (
                        <animated.div {...bind("profil-detay-modal-id")} className="modal-drag flex justify-center items-center" style={{width: "100%", height: "100%", position: "absolute", zIndex: 55555, y, touchAction: 'none', ...modalSpring}}>
                            <div className="flex flex-column items-center justify-center"
                                 style={etkinlikOlusturModalStyle}>
                                    <div style={{width: "100%", height: 51, borderLeftWidth: 1, borderRightWidth: 1, backgroundColor: "#fff"}} className="flex justify-center items-center">
                                        <div className="flex justify-content-center align-items-center"
                                             style={{width: 64, height: "100%"}}></div>
                                        <div className="flex justify-content-center align-items-center" style={{width: "calc(100% - 128px)", height: "100%"}}>
                                            <div className="scaleStyle" style={{width: "70%", height: 8, backgroundColor: "#ddd", borderRadius: 8, cursor: "pointer"}}>

                                            </div>
                                        </div>
                                        {/* modal kapat tuşu*/}
                                        <div style={{width: 64, height: "100%"}}>

                                        </div>
                                        {/*<div onClick={() => UyeProfilDetayModalKapat("profil-detay-modal-kapali")}*/}
                                        {/*     style={{width: 64, height: "100%"}}*/}
                                        {/*     className="flex justify-content-center align-items-center">*/}
                                        {/*    <div className="flex box justify-content-center align-items-center">*/}
                                        {/*        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                <div style={{width: "100%", height: "calc(100% - 51px)"}} className="flex flex-column active-scroll">
                                    {currentRoom?.type === "match" ? (
                                        <UyeProfilDetay tiklananUyeId={txtRoomUserId}/>
                                    ) : (
                                        <EtkinlikProfilDetay
                                            currentRoom={currentRoom}
                                            txtRoomImage={txtRoomImage}
                                            etkinlikUyeProfiliAcikMi={etkinlikUyeProfiliAcikMi}
                                            setEtkinlikUyeProfiliAcikMi={setEtkinlikUyeProfiliAcikMi}
                                            tiklananUyeId={tiklananUyeId}
                                            setTiklananUyeId={setTiklananUyeId}
                                        />
                                    )}
                                </div>
                            </div>
                        </animated.div>
                    )}

                    {mesajDetayModal === "message-info-modal-acik" && (
                        <animated.div {...bind("message-info-modal-id")} className="modal-drag flex justify-center items-center" style={{ ...mesajDetayHalfModal, ...modalSpring, y, touchAction: 'none'}}>
                            <div className="flex flex-column items-end justify-center halfModalToShadowStlye" style={halfBottomModal2}>
                                <div style={{width: "100%", height: 51}} className="flex justify-center items-center">
                                    <div className="flex justify-content-center align-items-center" style={{width: 64, height: "100%"}}></div>
                                    <div className="flex justify-content-center align-items-center" style={{width: "calc(100% - 128px)", height: "100%"}}>
                                        <div className="scaleStyle" style={{width: "70%", height: 8, backgroundColor: "#ddd", borderRadius: 8, cursor: "pointer"}}>

                                        </div>
                                    </div>
                                    {/* modal kapat tuşu*/}
                                    <div style={{width: 64, height: "100%"}}>

                                    </div>
                                    {/*<div onClick={() => messageDetayIslem("message-info-modal-kapali")} style={{width: 64, height: "100%"}}*/}
                                    {/*     className="flex justify-content-center align-items-center">*/}
                                    {/*    <div className="flex box justify-content-center align-items-center">*/}
                                    {/*        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div style={{width: "100%", height: "calc(100% - 51px)", backgroundColor: "#fbfafa"}} className="active-scroll">
                                    <div key={readByUsers?.id}>
                                        <div style={{borderBottomWidth: 1, borderTopWidth: 1}}
                                             className="flex flex-column mt-4 mb-4 py-4">
                                            <div className={`mb-1 gidenMesajlarKullaniciStyle tiklanmaEngelle mr-4`}>
                                                {tiklananMesajDetay?.username}
                                            </div>
                                            <div className={`flex justify-end`}>
                                                <div
                                                    className={`flex flex-col boxShadowTop boxShadowBottom p-2 mb-1 mt-1 txtStyle mr-3`}>
                                                    {tiklananMesajDetay?.message}
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="ml-3">
                                                <FontAwesomeIcon icon={faEye} className="fa-md"
                                                                 style={{color: "#EB3428", opacity: 0.7}}/>
                                                <span className="ml-2 mesajlarProfilTextStyle1">
                                                    {currentRoom?.type === "match" ? t('mesaji_okuyan') : t('mesaji_okuyanlar')}
                                                </span>
                                            </div>
                                            <div className="mb-3 mt-2">
                                                {readByUsers?.length > 0 ? (
                                                    <>
                                                        {readByUsers.map((userDetail) => {
                                                            return (
                                                                <div style={{backgroundColor: "#fff"}}
                                                                     className="flex align-items-center my-1 py-3"
                                                                     key={userDetail?.userId}>
                                                                    <div>
                                                                        <div className="flex justify-content-center align-items-center ml-4 mr-2"
                                                                             style={{width: 40, height: 40, borderRadius: "100%", borderWidth: 1}}>
                                                                            {userDetail?.profile_image ? (
                                                                                <img style={{width: 35, height: 35, borderRadius: "100%", objectFit: "cover"}}
                                                                                     className="tiklanmaEngelle"
                                                                                     src={`${userDetail?.profile_image}`}
                                                                                     alt={`${userDetail?.first_name} ${userDetail?.last_name}`}/>
                                                                            ) : (
                                                                                <Logo className={`profilFotoStyle tiklanmaEngelle`}/>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{width: "100%"}} className="flex justify-content-between align-items-center">
                                                                        <div style={{width: "100%"}} className={`flex mesajlarProfilTextStyle1`}>
                                                                            {userDetail?.first_name ?? "-"}{" "}
                                                                            {userDetail?.last_name ?? "-"}
                                                                        </div>
                                                                        <div className={`flex mr-2 txtStyle text-center`}>
                                                                            {userDetail?.readAt}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <div style={{backgroundColor: "#fff"}} className="flex align-items-center my-1 py-3">
                                                        <div>
                                                            <div className="flex justify-content-center align-items-center ml-4 mr-2"
                                                                style={{width: 35, height: 35, borderRadius: "100%", borderWidth: 1}}>
                                                                <Logo className={`profilFotoStyle tiklanmaEngelle`}/>
                                                            </div>
                                                        </div>
                                                        <div style={{width: "100%"}} className="flex justify-content-between align-items-center">
                                                            <div style={{width: "100%"}} className={`flex txtStyle`}>
                                                                -
                                                            </div>
                                                            <div className={`flex mr-4 txtStyle text-center`}>
                                                                -
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}

                    {modal === "diger-modal-id" && (
                        <animated.div {...bind("diger-modal-id")} className="modal-drag flex justify-center items-center" style={{ ...halfBottomModal1, ...modalSpring, y, touchAction: 'none', ...modalSpring }}>
                            <div className="flex flex-column items-end justify-center halfModalToShadowStlye" style={halfBottomModal2}>
                                <div style={{width: "100%", height: 51}} className="flex justify-center items-center">
                                    <div className="flex justify-content-center align-items-center"
                                         style={{width: 64, height: "100%"}}></div>
                                    <div className="flex justify-content-center align-items-center" style={{width: "calc(100% - 51px)", height: "100%"}}>
                                        <div className="scaleStyle" style={{width: "70%", height: 8, backgroundColor: "#ddd", borderRadius: 8, cursor: "pointer"}}>

                                        </div>
                                    </div>
                                    {/* modal kapat tuşu*/}
                                    <div style={{width: 64, height: "100%"}}>

                                    </div>
                                    {/*<div onClick={() => digerIslem(null, null, null, "modalKapatId")} style={{width: 54, height: "100%"}} className="flex justify-content-center align-items-center">*/}
                                    {/*    <div className="flex box justify-content-center align-items-center">*/}
                                    {/*        <FontAwesomeIcon icon={faXmark} className="fa-lg"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div style={{width: "100%", height: "calc(100% - 51px)", backgroundColor: "#fbfafa"}}
                                     className="flex flex-column active-scroll">
                                    <div onClick={() => endMessage(geciciUserIdBilgisi, geciciTypeBilgisi, geciciEventIdBilgisi)}
                                         style={{
                                             width: "100%",
                                             height: 70,
                                             backgroundColor: "#fff",
                                             borderTopWidth: 1,
                                             borderBottomWidth: 1,
                                         }} className="flex justify-between items-center my-1 sohbetiSonlandirHoverStyle">
                                        <div className="etkinlikOlusturDetaySohbeteKatilTextStyle tiklanmaEngelle pl-4">
                                            {geciciTypeBilgisi === "match" && "sohbeti sonlandır"}
                                            {geciciTypeBilgisi === "event" && t("gruptan_ayril")}
                                        </div>
                                        <div>
                                            {geciciTypeBilgisi === "match" && (
                                                <FontAwesomeIcon icon={faXmark} className="fa-xl" style={{paddingRight: 25}}/>
                                            )}
                                            {geciciTypeBilgisi === "event" && (
                                                <FontAwesomeIcon icon={faPersonWalkingLuggage} className="fa-xl" style={{paddingRight: 25,}}/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}
                </>
            )}
        </div>
    );
}

export default KonusmaListesi;
