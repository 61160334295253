// reducers/begenmediklerinReducer.js

import {
    BEGENDIKLERIN_PAGE,
    BEGENDIKLERIN_REQUEST,
    BEGENDIKLERIN_SUCCESS,
    BEGENDIKLERIN_IS_FETCHING,
    BEGENDIKLERIN_HAS_MORE,
    UYEYI_CIKAR,
    UYEYI_EKLE
} from '../../types';

const initialState = {
    begendiklerinData: [],
    page: 0,
    isFetching: false,
    error: "",
    hasMore: true
};

const begendiklerinReducer = (state = initialState, action) => {
    switch (action.type) {
        case BEGENDIKLERIN_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case BEGENDIKLERIN_SUCCESS:
            return {
                ...state,
                begendiklerinData: action.payload,
                isFetching: false
            };
        case BEGENDIKLERIN_IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            };
        case BEGENDIKLERIN_HAS_MORE:
            return {
                ...state,
                hasMore: action.payload
            };
        case BEGENDIKLERIN_PAGE:
            return {
                ...state,
                page: state.page + 1
            };
        case UYEYI_CIKAR:
            if (action.payload.list === "begendiklerim") {
                return {
                    ...state,
                    begendiklerinData: state.begendiklerinData.filter(
                        (uye) => parseInt(uye.customer_id) !== parseInt(action.payload.uyeId)
                    )
                };
            }
            return state;
        case UYEYI_EKLE:
            if (action.payload.list === "begendiklerim") {
                return {
                    ...state,
                    begendiklerinData: [...state.begendiklerinData, action.payload.uyeDetay]
                };
            }
            return state;
        default:
            return state;
    }
};

export default begendiklerinReducer;
