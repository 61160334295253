import React from "react";
import "../../styles/main.css";
import { useTranslation } from "react-i18next";

export default function BilgiModal() {
    const { t } = useTranslation();
    return (
        <div style={{width: "100%", height: "100%"}} className="flex flex-column justify-content-center align-items-center mt-2">
            <span className="hsapOlusturBilgiModalMobileBaslikTextStyle1 mb-3 tiklanmaEngelle">{t("bilgi_modal_text_baslik")}</span>
            <span className="hsapOlusturBilgiModalMobileBaslikTextStyle2 tiklanmaEngelle">{t("bilgi_modal_text_body")}</span>
        </div>
    )
}
