export const BEGENENLER_PAGE = "BEGENENLER_PAGE";
export const BEGENENLER_REQUEST = 'BEGENENLER_REQUEST';
export const BEGENENLER_SUCCESS = 'BEGENENLER_SUCCESS';
export const BEGENENLER_IS_FETCHING = 'BEGENENLER_IS_FETCHING';
export const BEGENENLER_HAS_MORE = 'BEGENENLER_HAS_MORE';

export const BEGENDIKLERIN_PAGE = "BEGENDIKLERIN_PAGE";
export const BEGENDIKLERIN_REQUEST = 'BEGENDIKLERIN_REQUEST';
export const BEGENDIKLERIN_SUCCESS = 'BEGENDIKLERIN_SUCCESS';
export const BEGENDIKLERIN_IS_FETCHING = 'BEGENDIKLERIN_IS_FETCHING';
export const BEGENDIKLERIN_HAS_MORE = 'BEGENDIKLERIN_HAS_MORE';

export const BEGENMEDIKLERIN_PAGE = "BEGENMEDIKLERIN_PAGE";
export const BEGENMEDIKLERIN_REQUEST = 'BEGENMEDIKLERIN_REQUEST';
export const BEGENMEDIKLERIN_SUCCESS = 'BEGENMEDIKLERIN_SUCCESS';
export const BEGENMEDIKLERIN_IS_FETCHING = 'BEGENMEDIKLERIN_IS_FETCHING';
export const BEGENMEDIKLERIN_HAS_MORE = 'BEGENMEDIKLERIN_HAS_MORE';

export const UYE_DETAY_BILGILERI = 'UYE_DETAY_BILGILERI';
export const UYE_DETAY_BILGILERI_YUKLENIYOR = 'UYE_DETAY_BILGILERI_YUKLENIYOR';
export const BEGEN_EYLEMI_SUCCESS = 'BEGEN_EYLEMI_SUCCESS';
export const BEGENMEME_EYLEMI_SUCCESS = 'BEGENMEME_EYLEMI_SUCCESS';
export const BEGEN_VE_BEGENMEME_EYLEMI_YUKLENIYOR = 'BEGEN_VE_BEGENMEME_EYLEMI_YUKLENIYOR';
export const BLOCK_EYLEMI_SUCCESS = 'BLOCK_EYLEMI_SUCCESS';
export const BLOCK_LIST_SUCCESS = 'BLOCK_LIST_SUCCESS';
export const UN_BLOCK_SUCCESS = 'UN_BLOCK_SUCCESS';
export const UN_BLOCK_DATA_GUNCELLE = 'UN_BLOCK_DATA_GUNCELLE';
export const BURC_BILGILERI = 'BURC_BILGILERI';

export const UYEYI_CIKAR = 'UYEYI_CIKAR';
export const UYEYI_EKLE = 'UYEYI_EKLE';

export const ETKINLIK_LISTESI_REQUEST = 'ETKINLIK_LISTESI_REQUEST';
export const ETKINLIK_LISTESI_SUCCESS = 'ETKINLIK_LISTESI_SUCCESS';
export const ETKINLIK_LISTESI_CLEAR = 'ETKINLIK_LISTESI_CLEAR';
export const ETKINLIK_LISTESI_LOADING = "ETKINLIK_LISTESI_LOADING";

export const SECILEN_ETKINLIK_DETAY_REQUEST = 'SECILEN_ETKINLIK_DETAY_REQUEST';
export const SECILEN_ETKINLIK_DETAY_SUCCESS = 'SECILEN_ETKINLIK_DETAY_SUCCESS';
export const SECILEN_ETKINLIK_DATA_CLEAR = 'SECILEN_ETKINLIK_DATA_CLEAR';
export const SECILEN_ETKINLIK_DETAY_LOADING = 'SECILEN_ETKINLIK_DETAY_LOADING';

export const ETKINLIK_KATIL_EYLEM_SUCCESS = 'ETKINLIK_KATIL_EYLEM_SUCCESS';
export const ETKINLIK_ISTEGI_GERI_CEK_SUCCESS = 'ETKINLIK_ISTEGI_GERI_CEK_SUCCESS';
export const ETKINLIK_KATIL_ISTEK_GERI_CEK_EYLEM_LOADING = 'ETKINLIK_KATIL_ISTEK_GERI_CEK_EYLEM_LOADING';

export const ETKINLIGE_KABUL_ET_EYLEM_SUCCESS = 'ETKINLIGE_KABUL_ET_EYLEM_SUCCESS';
export const ETKINLIGE_REDDET_EYLEM_SUCCESS = 'ETKINLIGE_REDDET_EYLEM_SUCCESS';
export const ETKINLIK_KABUL_VE_RED_BUTTON = 'ETKINLIK_KABUL_VE_RED_BUTTON';

export const ETKINLIK_KATILIM_ISTEKLERI_REQUEST = 'ETKINLIK_KATILIM_ISTEKLERI_REQUEST';
export const ETKINLIK_KATILIM_ISTEKLERI_SUCCESS = 'ETKINLIK_KATILIM_ISTEKLERI_SUCCESS';
export const ETKINLIK_KATILIM_ISTEKLERI_DATA_CLEAR = 'ETKINLIK_KATILIM_ISTEKLERI_DATA_CLEAR';

export const ETKINLIK_KATILIMCILARI_REQUEST = 'ETKINLIK_KATILIMCILARI_REQUEST';
export const ETKINLIK_KATILIMCILARI_SUCCESS = 'ETKINLIK_KATILIMCILARI_SUCCESS';
export const ETKINLIK_KATILIMCILARI_DATA_CLEAR = 'ETKINLIK_KATILIMCILARI_DATA_CLEAR';

export const ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_REQUEST = 'ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_REQUEST';
export const ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_SUCCESS = 'ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_SUCCESS';
export const ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_DATA_CLEAR = 'ETKINLIK_KATILIM_ISTEKLERI_REDDEDILENLER_DATA_CLEAR';

export const ETKINLIGE_KATILAN_UYEYI_SIL_EYLEM_SUCCESS = 'ETKINLIGE_KATILAN_UYEYI_SIL_EYLEM_SUCCESS';
export const ETKINLIGE_KATILAN_UYEYI_SIL_BUTTON = 'ETKINLIGE_KATILAN_UYEYI_SIL_BUTTON';

export const REDDEDILEN_UYEYI_SIL_EYLEM_SUCCESS = 'REDDEDILEN_UYEYI_SIL_EYLEM_SUCCESS';
export const REDDEDILEN_UYEYI_SIL_BUTTON = 'REDDEDILEN_UYEYI_SIL_BUTTON';

export const ETKINLIK_SIL_SUCCESS = 'ETKINLIK_SIL_SUCCESS';
export const ETKINLIK_SIL_LOADING = 'ETKINLIK_SIL_LOADING';

export const EXPLORE_DATA_REQUEST = 'EXPLORE_DATA_REQUEST';
export const EXPLORE_DATA_SUCCESS = 'EXPLORE_DATA_SUCCESS';
export const EXPLORE_DATA_LOADING = 'EXPLORE_DATA_LOADING';


export const PAKET_DURUM_BILGISI = 'PAKET_DURUM_BILGISI';
