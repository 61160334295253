import { BLOCK_EYLEMI_SUCCESS } from '../../types';

const initialState = {};

const blockEylemiReducer = (state = initialState, action) => {
    switch (action.type) {
        case BLOCK_EYLEMI_SUCCESS:
            return {
                ...state
            };
        default:
            return state;
    }
};


export default blockEylemiReducer;
