import axios from "../../../utils/CustomAxios";
import { UYE_DETAY_BILGILERI, BURC_BILGILERI, UYE_DETAY_BILGILERI_YUKLENIYOR } from '../../types';
import zodiac from "../../../utils/zodiac";
import {showToast} from "../../../utils/Functions";

export const uyeDetayBilgileriAction = (tiklananUyeId) => {
    return (dispatch) => {
        dispatch(uyeDetayBilgileriYukleniyor(true));
        axios
            .get(`customer/explore/${tiklananUyeId}`)
            .then((res) => {
                dispatch(uyeDetayBilgileriYukleniyor(false));
                if (res.data.status === 1) {
                    const uyeDetayBilgileri = res.data.data.customer;
                    dispatch(tiklananUyeDetayBilgileri(uyeDetayBilgileri));

                    if (uyeDetayBilgileri && uyeDetayBilgileri.date_of_birth) {
                        let dogum_gunu = new Date(uyeDetayBilgileri.date_of_birth);
                        let burc = zodiac().getSignByDate({
                            day: dogum_gunu.getDate(),
                            month: dogum_gunu.getMonth() + 1
                        }, localStorage.getItem("i18nextLng") || 'en');

                        dispatch(burchesapla((`${burc.symbol} ${burc.name}`) || ''));
                    }
                }
            })
            .catch((err) => {
                dispatch(uyeDetayBilgileriYukleniyor(false));
                if(err.response){
                    showToast(err.response.data.messages);
                }else{
                    showToast([{type:"error",message:err.message,second:5}]);
                }
            });
    };
};


export const burchesapla = (burcBilgileri) => {
    return {
        type: BURC_BILGILERI,
        payload: burcBilgileri,
    };
};

export const tiklananUyeDetayBilgileri = (uyeDetayBilgileri) => {
    return {
        type: UYE_DETAY_BILGILERI,
        payload: uyeDetayBilgileri,
    };
};

export const uyeDetayBilgileriYukleniyor = (loading) => {
    return {
        type: UYE_DETAY_BILGILERI_YUKLENIYOR,
        payload: loading,
    };
};
