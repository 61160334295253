import React, {useRef} from "react";
import {Route, Routes} from "react-router-dom";
import {Provider} from 'react-redux';
import store from './store/index';
import Baslangic from "./screens/Baslangic/Baslangic";
import AnaSayfa from "./screens/AnaSayfa";
import HesapOlustur from "./screens/Baslangic/HesapOlustur";
import OturumAcModal from "./components/Modallar/OturumAcModal";
import Ayarlar from "./screens/Profil/Ayarlar";
import ProfilDuzenle from "./screens/Profil/ProfilDuzenle";
import MedyaEkle from "./screens/Profil/MedyaEkle";
import {GeneralRoute} from "./utils/GeneralRoute";
import {ProtectedRoute} from "./utils/ProtectedRoute";
// import {PublicRoute} from "./utils/PublicRoute";
import EtkinlikDetay from "./screens/Etkinlik/EtkinlikDetay";
import CameraPermission from "./screens/Permissions/CameraPermission";

// #TODO burada mozared.com/api/v1/web/notification-token adresine link ile gelen token ve device id gönderilecek.
// #todo giriş yapılmışsa giriş yapanın bearer tokeni de gönderilecek

export default function App() {
    const socketRef = useRef();

    return (
        <Provider store={store}>
            <Routes>
                <Route path={"/CameraPermission"} element={<CameraPermission />}/>
                <Route element={<GeneralRoute/>}>
                    <Route path="/AnaSayfa" element={<AnaSayfa socketRef={socketRef}/>}/>
                    <Route path="/Baslangic" element={<Baslangic />}/>
                    <Route path="/HesapOlustur" element={<HesapOlustur/>}/>
                    <Route path="/OturumAcModal" element={<OturumAcModal/>}/>
                    <Route path={"/EtkinlikDetay"} element={<EtkinlikDetay />}/>
                </Route>
                <Route element={<ProtectedRoute/>}>
                    <Route path="/Ayarlar" element={<Ayarlar socketRef={socketRef}/>}/>
                    <Route path="/ProfilDuzenle" element={<ProfilDuzenle/>}/>
                    <Route path="/MedyaEkle" element={<MedyaEkle/>}/>
                </Route>
                <Route path="*" element={<AnaSayfa socketRef={socketRef}/>} />
            </Routes>
        </Provider>
    );
}

