import * as React from "react";
import '../../styles/main.css';

function GradientSvg({idCSS}) {
    const gradientTransform = `rotate(10)`;
    return (
        <svg style={{ height: 0 }}>
            <defs>
                <linearGradient id={idCSS} gradientTransform={gradientTransform}>
                    <stop offset="16.29%" stopColor="#ff0009" />
                    <stop offset="85.56%" stopColor="#ED1F24" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default GradientSvg;
